import React, { useState } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { useQueryClient } from 'react-query';

import { usePrevProblemList, useMutatePrevExam } from 'hooks';
import { Table, DisabledTableRow } from '../../style';
import { Badge } from '../../../components';
import Modal from '../../../components/modalComponents/Modal';

const PrevProblemList = ({ examLevel, examId, unitId }) => {
  const queryClient = useQueryClient();
  const [, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  const { data } = usePrevProblemList(examLevel, examId, unitId);

  const { mutate, isLoading: mutateIsLoading } = useMutatePrevExam();
  const handlePrevSelect = (problemId) => {
    if (mutateIsLoading) return;

    swal({
      title: '사전 문제를 등록합니다.',
      text: '등록 하시겠습니까?',
      buttons: true,
      dangerMode: true,
      className: 'mySwal',
    }).then((select) => {
      if (select) {
        mutate(
          { problemId, examId: +examId, unitId: +unitId },
          {
            onSuccess: (data) => {
              if (data) {
                setSelected(problemId);
                swal({
                  text: '사전 문제가 등록되었습니다.',
                  icon: 'success',
                });
              } else {
                swal({
                  text: '요청을 성공하지 못했습니다.',
                  icon: 'error',
                });
              }
            },
            onError: (err) => {
              swal({
                text: '요청을 성공하지 못했습니다.',
                icon: 'error',
              });
            },
            onSettled: () => {
              queryClient.invalidateQueries(['unitList', unitId]);
              queryClient.invalidateQueries(['examList', examId]);
              queryClient.invalidateQueries(['prevProblemList']);
              setOpen((prev) => !prev);
            },
          }
        );
      } else {
        swal({
          text: '선택을 취소했습니다.',
          icon: 'error',
        });
      }
    });
  };

  return (
    <>
      <Link onClick={() => setOpen(true)}>가져오기</Link>
      <div>
        <Modal open={open} size="medium" onClose={() => setOpen(false)}>
          <H2>사전 문제 등록</H2>
          <TableWrapper>
            <Table>
              {data?.map((item, i) => {
                return (
                  <DisabledTableRow
                    onClick={() => handlePrevSelect(item.id)}
                    key={i}
                  >
                    <TableContent className="sm-div">
                      {item.examLevel === 0
                        ? '하'
                        : item.examLevel === 1
                        ? '중'
                        : item.examLevel === 2
                        ? '상'
                        : null}
                    </TableContent>
                    <TableContent className="sm-div">
                      {item.examType === 0 ? (
                        <Badge size="small" color="#ec7807">
                          객관식
                        </Badge>
                      ) : item.examType === 2 ? (
                        <Badge size="small" color="#3e4954">
                          OX
                        </Badge>
                      ) : item.examType === 3 ? (
                        <Badge size="small" color="#f35757">
                          주관식
                        </Badge>
                      ) : null}
                    </TableContent>
                    <TableContent>{item.examQuestion}</TableContent>
                  </DisabledTableRow>
                );
              })}
            </Table>
          </TableWrapper>
        </Modal>
      </div>
    </>
  );
};

const Link = styled.span`
  background-color: #fc9918;
  border: 1px solid #fc9918;
  padding: 2px 4px;
  margin: 0 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #fc9918;
  }
`;

const H2 = styled.h2`
  text-align: center;
  line-height: 37px;
`;

const TableWrapper = styled.div`
  border-radius: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TableContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export default PrevProblemList;
