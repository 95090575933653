import axios from 'axios';
import { useQuery } from 'react-query';

const useCompResult = (userId) => {
  const token = localStorage.getItem('token');
  const { data, isLoading } = useQuery(
    ['compResult', userId],
    async () => {
      return await axios
        .get(`${process.env.REACT_APP_IP}/users/exam/analyze/unit/${userId}`)
        .then((res) => {
          return res.data.groupExamScoreDetails;
        });
    },
    {
      enabled: !!token,
      retry: false,
    }
  );
  return { data, isLoading };
};

export default useCompResult;
