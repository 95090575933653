import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import CustomerLayout from './CustomerLayout';
import CustomerLibrary from './CustomerLibrary/index';
import CustomerLibraryDetail from './CustomerLibrary/Detail';
import CustomerFaq from './FaqList/index';
import FaqDetail from './FaqList/Detail';
import GreetingDetail from './GreetingList/Detail';
import ContactDetail from './ContactList/ContactDetail';
import Greeting from './GreetingList/index';
import GreetingForm from './GreetingList/GreetingForm';
import ContactWrite from './ContactList/ContactWrite';
import ContactList from './ContactList/ContactList';
import Reserve from './Reservation/CustomerReserve';
import Consulting from './Consultation/Consulting';

import AuthContext from 'context/Auth.context';
import { warningMessage } from 'utilities';

const Customer = () => {
  const match = useRouteMatch();

  return (
    <>
      <CustomerLayout>
        <Switch>
          <Route exact path={`${match.path}`}>
            <Redirect to={`${match.path}/library`} />
          </Route>
          <Route
            exact
            path={`${match.url}/library`}
            component={CustomerLibrary}
          />
          <Route exact path={`${match.url}/faq`} component={CustomerFaq} />
          <Route exact path={`${match.url}/contact`} component={ContactList} />
          <Route
            exact
            path={`${match.url}/contact/write`}
            component={ContactWrite}
          />
          <Route
            path={`${match.url}/contact/write/:id`}
            component={ContactWrite}
          />
          <Route exact path={`${match.url}/greeting`} component={Greeting} />
          <Route
            exact
            path={`${match.url}/contact/:id`}
            component={ContactDetail}
          />
          <Route
            exact
            path={`${match.url}/library/:id`}
            component={CustomerLibraryDetail}
          />
          <Route exact path={`${match.url}/faq/:id`} component={FaqDetail} />
          <Route
            exact
            path={`${match.url}/greeting/:id`}
            component={GreetingDetail}
          />
          <Route
            exact
            path={`${match.url}/greetingform`}
            component={GreetingForm}
          />
          <Route
            exact
            path={`${match.url}/greetingform/:id`}
            component={GreetingForm}
          />

          {/* 상담 관리 페이지 [관리자] */}
          <PrivateRoute
            path={`${match.url}/consulting`}
            component={<Consulting />}
          />

          {/* 상담 예약 페이지 [학생] */}
          <PrivateRoute path={`${match.url}/reserve`} component={<Reserve />} />
        </Switch>
      </CustomerLayout>
    </>
  );
};

const PrivateRoute = ({ component, ...rest }) => {
  const { type } = useContext(AuthContext);

  const ACCESS_TYPE = {
    STUDENT: ['1'],
    ADMIN: ['3', '4', '5'],
  };

  const redirectToHome = (message) => {
    warningMessage({ text: message });
    return <Redirect to="/" />;
  };

  const checkAccess = (requiredType, errorMessage) => {
    if (!requiredType.includes(type)) {
      return redirectToHome(errorMessage);
    }
    return component;
  };

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const { pathname } = location;

        if (pathname.includes('reserve')) {
          return checkAccess(
            ACCESS_TYPE.STUDENT,
            '교육생만 접근 가능한 페이지입니다.'
          );
        } else if (pathname.includes('consulting')) {
          return checkAccess(
            ACCESS_TYPE.ADMIN,
            '관리자만 접근 가능한 페이지입니다.'
          );
        }
      }}
    />
  );
};

export default Customer;
