import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import Header1 from 'modules/components/header/Header.jsx';
import MyPageSideBar from './MyPageSideBar';
import CustomerList from '../Customer/CustomerList';
import WriteButton from '../../elements/WriteButton';
import Pagination from '../../elements/Pagination';
import { useFetchMyInquiries, useFetchQuestionCategories } from 'hooks';
import styles from './Mypage.module.scss';
import '../MyPage.css';

const MyQuestion = ({ sidebar = 'left' }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { type } = auth;
  const [active, setActive] = useState(3);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const { data: categories } = useFetchQuestionCategories();

  const {
    data: searchedData,
    isLoading: searchedDataLoading,
    totalValue,
  } = useFetchMyInquiries({
    page,
    pageSize,
  });

  const move = (num) => {
    setPage(num);
  };

  /* 학생이 아니라면 문의하기 목록 페이지로 이동 */
  useEffect(() => {
    if (!['1'].includes(type)) {
      history.push('/customer/contact');
    }
  }, [auth]);

  return (
    <Fragment>
      <Header1 data={{ title: '나의 문의' }} />

      <div className={styles.mypage}>
        <section
          className={[
            styles.wrapper,
            sidebar === 'left'
              ? styles.with_sidebar + ' ' + styles.left
              : sidebar === 'right'
              ? styles.with_sidebar + ' ' + styles.right
              : null,
          ].join(' ')}
        >
          <MyPageSideBar active={active} setActive={setActive} />

          <article className="mypage">
            <div className="mypageTable">
              <div className="mypageContents">
                <CustomerList
                  list={searchedData?.list}
                  isListLoading={searchedDataLoading}
                  boardType="contact"
                  categories={categories || []}
                  isQuiryBoard
                />
              </div>

              <StyledBox>
                <WriteButton text="문의글 작성" to="/customer/contact/write" />
              </StyledBox>

              <StyledBox>
                <Pagination
                  page={page}
                  total={totalValue}
                  perPage={pageSize}
                  move={move}
                />
              </StyledBox>
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > a {
    margin-left: auto;
  }
`;

export default MyQuestion;
