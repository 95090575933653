import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import AuthContext from 'context/Auth.context';

const useMutateExam = () => {
  const auth = useContext(AuthContext);
  const { mutate, isLoading } = useMutation(async (examId) => {
    return await axios
      .put(`${process.env.REACT_APP_IP}/teachers/exam/active`, {
        userId: auth.userId,
        groupId: auth.groupId,
        examId,
        examStatus: 0,
      })
      .then((res) => {
        return res.data;
      });
  });
  return { mutate, isLoading };
};

export default useMutateExam;
