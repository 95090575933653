import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dompurify from 'dompurify';

import { useFetchFaqDetail } from 'hooks';
import { linkify } from 'utilities/common';
import { Loading } from '../../../ui';

const Detail = ({ history }) => {
  const { id } = useParams();

  const { data: detailData, isLoading } = useFetchFaqDetail({ id });
  const { adjacentPostInfo, comments, post } = detailData || {};

  const handleMoveToOtherDetail = (id) => {
    history.push(`/customer/faq/${id}`);
  };

  if (isLoading) return <Loading />;
  return (
    <Wrapper>
      <TitleBox>
        <div className="title">
          <h2>{post?.title}</h2>
        </div>

        <div className="sub-info">
          <div>
            <span>{post?.createdAt.slice(0, 10)} |</span>
            <span>조회 {post?.hitCount}</span>
          </div>
          <div>
            <span>
              <i className="fa-solid fa-user" />
              {post?.author}
            </span>
          </div>
        </div>
      </TitleBox>

      <ContentBox
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(linkify(post?.content || '')),
        }}
      />

      {!!comments?.length && (
        <AnswerBox>
          <span>답변 내용</span>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(linkify(comments[0].content || '')),
            }}
          ></div>
        </AnswerBox>
      )}

      <MoveDetailBox>
        <div>
          {adjacentPostInfo?.prevId && (
            <span
              onClick={() => handleMoveToOtherDetail(adjacentPostInfo?.prevId)}
            >
              <i className="fa-solid fa-chevron-left" />
              이전글
            </span>
          )}
        </div>

        <div>
          {adjacentPostInfo?.nextId && (
            <span
              onClick={() => handleMoveToOtherDetail(adjacentPostInfo?.nextId)}
            >
              다음글
              <i className="fa-solid fa-chevron-right" />
            </span>
          )}
        </div>
      </MoveDetailBox>

      <Link className="go-list" to="/customer/faq">
        목록으로
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-top: 2px solid #333;

  padding-bottom: 200px;

  > span {
    padding-left: 10px;
    font-size: 12px;
    color: #777;
  }

  > a {
    font-size: 16px;
    width: 150px;
    height: 40px;
    background: #ec7422;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    margin: 50px auto 0;

    &:hover {
      filter: brightness(105%);
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  min-height: 60px;
  padding: 10px;
  overflow: hidden;

  > div.title {
    display: flex;
    max-width: 75%;

    h2 {
      max-width: 100%;
      margin-bottom: unset;
      margin-left: 5px;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  > div.sub-info {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;

      i {
        margin-right: 5px;
      }
    }
  }
`;

const ContentBox = styled.div`
  padding: 20px;
  font-size: 16px;
  min-height: 300px;

  border-bottom: 1px solid #eee;

  p {
    font-size: 16px;
    color: #333;
  }
`;

const MoveDetailBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  span {
    display: inline-block;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 15px;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
`;

const AnswerBox = styled.div`
  font-size: 16px;
  min-height: 150px;
  border-bottom: 1px solid #eee;

  > span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
    border: 1px solid #eee;
    background-color: #eee;
    margin-top: 5px;
    border-radius: 15px;
  }
  > div {
    padding: 20px;
  }

  p {
    font-size: 16px;
    color: #333;
  }

  a {
    cursor: pointer;
  }
`;

export default Detail;
