import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useFetchClassBoardList } from 'hooks';
import { BOARD_TITLE } from 'constant/class';
import {
  ClassListContentBox,
  ClassListInputBox,
  ClassListWrapper,
} from '../ClassBoardCommon';
import TaskResultList from './TaskResultList';

const TaskResult = () => {
  const type = 'taskResult';

  const {
    data: list,
    isLoading,
    totalValue,
  } = useFetchClassBoardList({
    type,
  });

  return (
    <Fragment>
      <ClassListWrapper style={{ padding: '0 0 100px' }}>
        <h1>{BOARD_TITLE[type]}</h1>

        <ClassListInputBox>
          <span>
            총 <b>{totalValue}</b>개
          </span>

          <div />
        </ClassListInputBox>

        <ClassListContentBox>
          <TaskResultList list={list} isListLoading={isLoading} />
        </ClassListContentBox>

        <Link className="go-list" to={`/myclass/board/task`}>
          목록으로
        </Link>
      </ClassListWrapper>
    </Fragment>
  );
};

export default TaskResult;
