import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MyPageSideBar = ({ active, setActive }) => {
  const handelClick = (e) => {
    setActive(e);
  };

  return (
    <StyledAside>
      <div className="myPageLeftSide">
        <ul>
          <li className={active === 2 ? 'mypageLeftsideActive' : ''}>
            <Link to="/mypage/my-information" onClick={() => handelClick(2)}>
              <span className="secotext">
                <i className="far fa-address-book fa-2x"></i>
                <i>내 정보 관리</i>
              </span>
            </Link>
          </li>
          <li className={active === 3 ? 'mypageLeftsideActive' : ''}>
            <Link to="/mypage/my-question" onClick={() => handelClick(3)}>
              <span className="secotext">
                <i className="far fa-envelope-open sideMenuLast fa-2x"></i>
                <i>나의 문의</i>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </StyledAside>
  );
};

const StyledAside = styled.aside`
  flex-basis: 180px;
  flex-shrink: 0;

  a > span {
    display: flex;
    flex-direction: column;

    i {
      font-style: unset;
    }
  }
`;

export default MyPageSideBar;
