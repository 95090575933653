import React, { useContext, useRef } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';

import AuthContext from 'context/Auth.context';
import Calendar from '../calendar/Calendar';
import { handleErrorMessage } from 'utilities';

const Modal = ({ title, onClick, onSuccess, onTabClick }) => {
  const auth = useContext(AuthContext);
  const titleRef = useRef(null);
  const meetingTimeRef = useRef(null);
  const foundingMembersRef = useRef(null);
  const descriptionRef = useRef(null);
  const selectRef = useRef(null);
  const { id } = useParams();

  const handleSubmit = (zoom) => {
    if (title === '개설신청') {
      if (window.confirm('개설신청 하시겠습니까?')) {
        const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms`;
        const groupTitle = titleRef.current.value;
        const meetingTime = meetingTimeRef.current.value;
        const foundingMembers = foundingMembersRef.current.value;
        const description = descriptionRef.current.value;
        const limit = selectRef.current.value;

        if (groupTitle.trim() === '') {
          toast.error('그룹명을 입력해주세요.');
          titleRef.current.focus();
          return;
        } else if (limit.trim() === '') {
          toast.error('참여인원을 입력해주세요.');
          selectRef.current.focus();
          return;
        } else if (limit <= 0) {
          toast.error('참여인원은 최소 한명 이상이어야 합니다.');
          selectRef.current.focus();
          return;
        } else if (limit > 100) {
          toast.error('참여인원을 정확히 입력해주세요.');
          selectRef.current.focus();
          return;
        } else if (meetingTime.trim() === '') {
          toast.error('일정을 입력해주세요.');
          meetingTimeRef.current.focus();
          return;
        } else if (description.trim() === '') {
          toast.error('설명을 입력해주세요.');
          descriptionRef.current.focus();
          return;
        } else if (description.length > 1000) {
          toast.error(
            '설명이 너무 깁니다.설명은 최대 1000자까지 쓸 수 있습니다.'
          );
          descriptionRef.current.focus();
          return;
        }

        Axios.post(url, {
          userId: auth.userId,
          title: groupTitle,
          description,
          limit,
          meetingTime,
          foundingMembers,
        })
          .then((response) => {
            onSuccess(response.data);
            onClick();
          })
          .catch(handleErrorMessage);
      }
    } else if (title === '스터디룸 예약') {
      if (
        window.confirm('해당 날짜에 스터디룸 사용 예약을 신청하시겠습니까?')
      ) {
        const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/reserve`;
        Axios.post(url, {
          date: zoom.date,
          time: zoom.time,
          purpose: zoom.purpose,
        })
          .then((response) => {
            if (response.data === 'SUCCESS') {
              toast.success('예약되었습니다.');
              onSuccess(response.data);
              onClick();
            } else if (response.data === 'ALREADY') {
              toast.error('이미 예약된 날짜입니다.');
            } else {
              toast.error('예약에 실패하였습니다.');
            }
          })
          .catch(handleErrorMessage);
      }
    }
  };

  return (
    <Wrapper>
      {title === '개설신청' ? (
        <>
          <Title>개설신청</Title>
          <Row>
            <SubTitle>
              그룹명 <Star />
            </SubTitle>
            <input
              ref={titleRef}
              placeholder="그룹명을 입력해주세요"
              type="text"
            />
          </Row>

          <Row>
            <SubTitle>
              조장 <Star />
            </SubTitle>
            <input
              placeholder="auth.name"
              type="text"
              value={auth.name}
              disabled
            />
          </Row>
          <Row>
            <SubTitle>
              일정 <Star />
            </SubTitle>
            <input
              ref={meetingTimeRef}
              placeholder="ex) 주 2회 or 매주 토 등"
              type="text"
            />
          </Row>
          <Row>
            <SubTitle>초기멤버</SubTitle>
            <textarea
              ref={foundingMembersRef}
              placeholder={`참여를 예상하는 멤버의 이름을 적어주세요.\nex) ${auth.name}, 리누스 토발즈, 빌 게이츠, 스티븐 워즈니악`}
            />
          </Row>
          <Row>
            <SubTitle>
              그룹설명 <Star />
            </SubTitle>
            <textarea
              ref={descriptionRef}
              placeholder={`스터디 그룹에 대한 설명을 간략히 적어주세요`}
            ></textarea>
          </Row>
          <Row>
            <SubTitle>
              최대참여인원 <Star />
            </SubTitle>
            <div style={{ display: 'flex' }}>
              <input
                ref={selectRef}
                min="1"
                max="100"
                style={{ width: '80px' }}
                type="number"
                onInput={(e) =>
                  (e.currentTarget.value = e.currentTarget.value
                    .replace(/[^0-9.]/g, '')
                    .replace(/(\..*)\./g, '$1'))
                }
              ></input>
              <h3
                style={{ fontSize: '16px', fontWeight: '300', padding: '5px' }}
              >
                명
              </h3>
            </div>
          </Row>
          <Btns>
            <div onClick={onClick}>취소</div>
            <div
              onClick={() => {
                handleSubmit();
                onTabClick();
              }}
            >
              등록
            </div>
          </Btns>
        </>
      ) : (
        <Calendar onSubmit={handleSubmit} title={title} />
      )}
    </Wrapper>
  );
};

const Star = styled.span`
  :after {
    content: '*';
    color: red;
  }
`;
const Wrapper = styled.div`
  position: absolute;
  top: auto;
  right: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  border-radius: 5px;
  z-index: 150;

  textarea,
  input {
    width: 200px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &::placeholder {
      font-size: 14px;
    }
  }

  textarea {
    padding: 10px;
    resize: none;
    height: 150px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }
  }

  select {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 26px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  width: 120px;
`;

const Btns = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div {
    margin-left: 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    padding: 7px;
    width: 80px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.6);
  }

  div:last-child {
    border: 1px solid #fc9918;
    background-color: #fc9918;
    color: white;
  }
`;

export default Modal;
