import axios from 'axios';

import { BASE_URL } from './config';

/* 카테고리 조회 */
export const fetchPblUnits = async () => {
  return await axios.get(`${BASE_URL}/users/pbl`);
};

/* 카테고리 별 문제 리스트 조회 */
export const fetchPblProblems = async ({ unitId }) => {
  return await axios.get(`${BASE_URL}/users/pbl/units/${unitId}/problems`);
};

/* ProblemId를 통해 문제 상세 내용 조회 */
export const fetchPblProblemDetail = async ({ unitId, problemId }) => {
  return await axios.get(
    `${BASE_URL}/users/pbl/units/${unitId}/problems/${problemId}`
  );
};

/* 학생 PBL 결과물 제출 */
export const submitPblResult = async ({ problemId, formData }) => {
  return await axios.post(
    `${BASE_URL}/users/pbl/problems/${problemId}`,
    formData
  );
};

/* 강사 결과물 제출한 학생 리스트 목록 조회 */
export const fetchSubmittedStudentList = async ({ problemId, pageNum }) => {
  const params = { pageNum };

  return await axios.get(
    `${BASE_URL}/teachers/pbl/problems/${problemId}/submitters`,
    {
      params,
    }
  );
};

/* 강사 문제 상태 변경 */
export const changePblProblemState = async ({ problemId, state }) => {
  return await axios.put(`${BASE_URL}/teachers/pbl/problems/state`, {
    problemId,
    state,
  });
};

/* 교육생에게 단 피드백 리스트 조회 */
export const fetchStudentCommentList = async ({ problemId, studentId }) => {
  return await axios.get(
    `${BASE_URL}/teachers/pbl/comments/${problemId}/${studentId}`
  );
};

/* 교육생 개개인에게 피드백 등록 */
export const submitTeacherComment = async ({
  problemId,
  studentId,
  comment,
}) => {
  return await axios.post(
    `${BASE_URL}/teachers/pbl/comments/${problemId}/${studentId}`,
    {
      comment,
    }
  );
};

/* 교육생에게 단 댓글 수정 */
export const editTeacherComment = async ({ pblCommentId, comment }) => {
  return await axios.put(`${BASE_URL}/teachers/pbl/comments/${pblCommentId}`, {
    comment,
  });
};

/* 교육생에게 단 댓글 삭제 */
export const removeTeacherComment = async ({ pblCommentId }) => {
  return await axios.delete(
    `${BASE_URL}/teachers/pbl/comments/${pblCommentId}`
  );
};

/* 교육생이 올린 개인 결과물 다운로드 */
export const downloadStudentResult = async ({ problemId, userId }) => {
  const params = { userId };
  return await axios.get(
    `${BASE_URL}/teachers/pbl/problems/${problemId}/submissions/single`,
    { params, responseType: 'blob' }
  );
};

/* 교육생들이 올린 모든 결과 통합 다운로드 */
export const downloadTotalResult = async ({ problemId }) => {
  const params = { problemId };
  return await axios.get(
    `${BASE_URL}/teachers/pbl/problems/${problemId}/submissions`,
    {
      params,
      responseType: 'blob',
    }
  );
};

/* 교육생이 제출한 결과물 반려 처리 */
export const removeSubmittedResult = async ({ problemId, submitId }) => {
  return await axios.delete(
    `${BASE_URL}/teachers/pbl/problems/${problemId}/${submitId}`
  );
};

/* 문제 첨부파일 다운로드 */
export const downloadAttachmentFiles = async ({ problemId, fileName }) => {
  const params = { fileName };

  return await axios.get(
    `${BASE_URL}/users/pbl/problems/${problemId}/attachments`,
    {
      params,
      responseType: 'blob',
    }
  );
};
