import React, { useEffect, useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import AuthContext from 'context/Auth.context';
import { TextEditor } from '../../../elements';
import {
  useCreateGreetingPost,
  useEditGreetingPost,
  useFetchGreetingDetail,
} from 'hooks';
import { errorMessage, successMessage } from 'utilities';

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: 'white',
    fontSize: '1.4rem',
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },
  TextField: {
    label: {
      fontSize: '10px',
    },
  },
}));

const GreetingForm = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const { type, status } = useContext(AuthContext);

  const [textValue, setTextValue] = useState('');

  const { data: detailData } = useFetchGreetingDetail({ id });
  const { post } = detailData || {};

  const { mutateAsync: createGreetingPost, isLoading: isCreateLoading } =
    useCreateGreetingPost({
      categoryId: 41,
    });

  const { mutateAsync: editGreetingPost, isLoading: isEditLoading } =
    useEditGreetingPost({
      id,
      categoryId: 41,
    });

  const isLoading = id ? isEditLoading : isCreateLoading;

  useEffect(() => {
    if (type === null) {
      toast.error('로그인이 필요합니다.');
      history.push('/login');
    } else if (status === 0) {
      toast.error('신청 대기중입니다');
      history.push('/myclass');
    }
  }, []);

  const submit = async (form) => {
    let parsedTextValue = textValue
      .replaceAll('<p>', '')
      .replaceAll('</p>', '');
    const hasOnlyBr = /^<br>(<br>)*$/.test(parsedTextValue);
    if (textValue.trim() === '' || hasOnlyBr || parsedTextValue.trim() === '') {
      errorMessage({ text: '내용을 입력해주세요.' });
      return;
    }

    let response;
    if (id) {
      response = await editGreetingPost({
        title: form.title,
        content: textValue,
      });
    } else {
      response = await createGreetingPost({
        title: form.title,
        content: textValue,
      });
    }
    if (response.status === 201 || response.status === 204) {
      successMessage({ text: '게시글이 정상 등록되었습니다.' });
      history.push('/customer/greeting');
    }
  };

  useEffect(() => {
    if (!detailData) return;

    setValue('title', post.title);
    setTextValue(post.content);
  }, [detailData]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <TitleBox>
        <div>
          <div>
            <input
              name="title"
              type="text"
              {...register('title', {
                required: true,
              })}
              placeholder="제목을 입력해주세요."
            />
          </div>
        </div>
      </TitleBox>

      <Grid item xs={12}>
        <QuillBox>
          <TextEditor
            htmlStr={textValue}
            setHtmlStr={setTextValue}
            placeholder="내용을 입력해주세요."
          />
        </QuillBox>
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            margin: '-5px 0 10px 15px',
            fontFamily: 'Noto Sans KR',
          }}
        ></div>
      </Grid>

      <ButtonBox>
        <Link to="/customer/greeting">취소</Link>
        <button type="submit">
          {isLoading ? (
            <CircularProgress size={30} />
          ) : id ? (
            '게시글 수정'
          ) : (
            '게시글 등록'
          )}
        </button>
      </ButtonBox>
    </form>
  );
};

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #333;

  > div {
    display: flex;
    min-height: 94px;
    align-items: center;
    gap: 14px;
    border-bottom: 1px solid rgb(230, 230, 230);

    &:last-child {
      border-bottom: none;
    }

    > span {
      width: 100px;
      display: inline-block;
      padding-left: 25px;
      box-sizing: border-box;
      font-weight: bold;
      font-size: 17px;
    }

    > div {
      width: 100%;
      padding: 13px 0;

      input[type='text'] {
        width: 80%;
        outline: none;
        height: 45px;
        border: 1px solid rgb(230, 230, 230);
        padding-left: 15px;
        border-radius: 3px;
        font-size: 15px;
      }
    }
  }
`;

const QuillBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0 50px;

  div {
    width: 100%;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;

  a,
  button {
    display: flex;
    width: 185px;
    height: 55px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 15px;
    font-weight: bold;

    &:hover {
      filter: brightness(90%);
    }
  }

  a {
    color: rgb(151, 151, 151);
    border: 1px solid rgb(212, 212, 212);
    text-decoration: none;
    font-size: 16px;
  }

  button {
    color: rgb(255, 255, 255);
    background: #ec7422;
    font-size: 16px;
    cursor: pointer;
  }

  svg {
    color: #fff;
  }
`;

export default GreetingForm;
