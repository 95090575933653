import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AuthContext from '../../../context/Auth.context';
import Header from '../../components/header/Header';
import styles from '../Capatest.module.scss';
import './TestResult.css';
import { handleErrorMessage } from 'utilities';

const TestResult = ({ sidebar = 'left' }) => {
  const history = useHistory();
  const ref = useRef(null);
  const auth = useContext(AuthContext);
  const { userId } = auth;
  const [testResult, setTestResult] = useState([]);

  const ColorTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: 'white',
    },
    selected: { color: 'black', backgroundColor: '#ffebd4 !important' },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#868686',
      color: theme.palette.common.white,
      fontSize: '1.45rem',
      fontWeight: '600',
      textAlign: 'center',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  useEffect(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/exam/result/${userId}`;
    axios
      .get(url)
      .then((response) => {
        setTestResult(response.data);
      })
      .catch(handleErrorMessage);
  }, []);

  const printFunc = () => {
    const html = document.querySelector('html');
    // 출력할 내용 가져오기
    const printContents = document.querySelector('#content').innerHTML;

    const printDiv = document.createElement('DIV');
    printDiv.className = 'print-div';

    html.appendChild(printDiv);

    // 출력할 내용 인쇄 화면 속 태그에 넣어주기
    printDiv.innerHTML = printContents;
    document.body.style.display = 'none';

    window.print();
    document.body.style.display = 'block';
    printDiv.style.display = 'none';
  };

  const handleGoToResultPage = () => {
    history.push(`/capability/test-result/detail`);
  };

  return (
    <Fragment>
      <Header
        data={{
          title: '역량평가 결과',
        }}
      />
      <div className={styles.mypage} ref={ref}>
        {testResult.length === 0 && <NoExamResult />}
        <section
          className={[
            styles.wrapper,
            sidebar === 'left'
              ? styles.with_sidebar + ' ' + styles.left
              : sidebar === 'right'
              ? styles.with_sidebar + ' ' + styles.right
              : null,
          ].join(' ')}
        >
          <article className="capaTestResult" id="content">
            {testResult.map((result, option) => (
              <Fragment key={option}>
                <h2>{result.categoryName}</h2>
                <hr />
                <div className="capaTestScore">
                  <h3>사전 역량평가 결과</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            style={{ width: '28%' }}
                            className="test-result"
                          >
                            응시일자
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '24%' }}
                            className="test-result"
                          >
                            목표 점수
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '24%' }}
                            className="test-result"
                          >
                            전체 평균 점수
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: '24%' }}
                            className="test-result"
                          >
                            내 점수
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <ColorTableRow>
                          <TableCell
                            style={{ fontSize: '1.4rem', textAlign: 'center' }}
                          >
                            {result.firstExamStartDate}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '1.4rem', textAlign: 'center' }}
                          >
                            {result.firstExamGroupTargetScore <= 0
                              ? '-'
                              : result.firstExamGroupTargetScore}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: '1.4rem', textAlign: 'center' }}
                          >
                            {result.firstExamGroupScoreAvg <= 0
                              ? '-'
                              : result.firstExamGroupScoreAvg}
                          </TableCell>
                          {result.firstExamGroupTargetScore <=
                          result.firstExamScoreResult ? (
                            <TableCell
                              style={{
                                fontSize: '1.4rem',
                                textAlign: 'center',
                                color: '#FA7F42',
                              }}
                            >
                              <strong>{result.firstExamScoreResult}</strong>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                fontSize: '1.4rem',
                                textAlign: 'center',
                                color: '#2C95D2',
                              }}
                            >
                              <strong>{result.firstExamScoreResult}</strong>
                            </TableCell>
                          )}
                        </ColorTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                {(result.secondExamStartDate || result.retakeExamStartDate) && (
                  <>
                    <div className="capaTestScore">
                      <h3>사후 역량평가 결과</h3>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell
                                style={{ width: '28%' }}
                                className="test-result"
                              >
                                응시일자
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ width: '24%' }}
                                className="test-result"
                              >
                                목표 점수
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ width: '24%' }}
                                className="test-result"
                              >
                                전체 평균 점수
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ width: '24%' }}
                                className="test-result"
                              >
                                내 점수
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ColorTableRow>
                              <TableCell
                                style={{
                                  fontSize: '1.4rem',
                                  textAlign: 'center',
                                }}
                              >
                                {result.secondExamStartDate}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: '1.4rem',
                                  textAlign: 'center',
                                }}
                              >
                                {result.secondExamGroupTargetScore <= 0
                                  ? '-'
                                  : result.secondExamGroupTargetScore}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: '1.4rem',
                                  textAlign: 'center',
                                }}
                              >
                                {result.secondExamGroupScoreAvg <= 0
                                  ? '-'
                                  : result.secondExamGroupScoreAvg}
                              </TableCell>
                              {result.secondExamGroupTargetScore <=
                              result.secondExamScoreResult ? (
                                <TableCell
                                  style={{
                                    fontSize: '1.4rem',
                                    textAlign: 'center',
                                    color: '#FA7F42',
                                  }}
                                >
                                  <strong>
                                    {result.secondExamScoreResult}
                                  </strong>
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: '1.4rem',
                                    textAlign: 'center',
                                    color: '#2C95D2',
                                  }}
                                >
                                  <strong>
                                    {result.secondExamScoreResult}
                                  </strong>
                                </TableCell>
                              )}
                            </ColorTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}

                {result.retakeExamStartDate && (
                  <>
                    <div className="capaTestScore">
                      <h3>재평가 결과</h3>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell
                                style={{ width: '28%' }}
                                className="test-result"
                              >
                                응시일자
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ width: '24%' }}
                                className="test-result"
                              >
                                목표 점수
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ width: '24%' }}
                                className="test-result"
                              >
                                전체 평균 점수
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ width: '24%' }}
                                className="test-result"
                              >
                                내 점수
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ColorTableRow>
                              <TableCell
                                style={{
                                  fontSize: '1.4rem',
                                  textAlign: 'center',
                                }}
                              >
                                {result.retakeExamStartDate}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: '1.4rem',
                                  textAlign: 'center',
                                }}
                              >
                                {result.retakeExamGroupTargetScore <= 0
                                  ? '-'
                                  : result.retakeExamGroupTargetScore}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: '1.4rem',
                                  textAlign: 'center',
                                }}
                              >
                                {result.retakeExamGroupScoreAvg <= 0
                                  ? '-'
                                  : result.retakeExamGroupScoreAvg}
                              </TableCell>
                              {result.retakeExamGroupTargetScore <=
                              result.retakeExamScoreResult ? (
                                <TableCell
                                  style={{
                                    fontSize: '1.4rem',
                                    textAlign: 'center',
                                    color: '#FA7F42',
                                  }}
                                >
                                  <strong>
                                    {result.retakeExamScoreResult}
                                  </strong>
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{
                                    fontSize: '1.4rem',
                                    textAlign: 'center',
                                    color: '#2C95D2',
                                  }}
                                >
                                  <strong>
                                    {result.retakeExamScoreResult}
                                  </strong>
                                </TableCell>
                              )}
                            </ColorTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
              </Fragment>
            ))}
          </article>
        </section>

        {testResult.length === 0 && (
          <div className="d-flex justify-content-center">
            <button onClick={() => history.goBack()} className="printButton">
              <i className="fa-solid fa-circle-left"></i> 뒤로가기
            </button>
          </div>
        )}
        {testResult.length !== 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ columnGap: '10px' }}
          >
            <button onClick={handleGoToResultPage} className="printButton">
              <i className="fa-solid fa-chart-column" /> 상세보기
            </button>
            <button onClick={() => printFunc()} className="printButton">
              <i className="fas fa-print" /> 인쇄하기
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const NoExamResult = () => {
  return (
    <Wrapper>
      <ImageBox>
        <img src="/assets/images/no-result.jpeg" alt="no result" />
      </ImageBox>
      <NoExamBox>응시한 시험이 존재하지 않습니다. </NoExamBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NoExamBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 30px 0 100px;
  text-shadow: 1px 1px 2px rgba(190, 187, 187, 0.65);
`;

const ImageBox = styled.div`
  width: 200px;
  animation: gelatine 1s infinite;
  animation-delay: 2s;

  img {
    width: 100%;
  }

  @keyframes gelatine {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.95, 1.05);
    }
    50% {
      transform: scale(1.05, 0.95);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

export default TestResult;
