import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import { useEditTeacherComment, useRemoveComment } from 'hooks';
import {
  confirmSwal,
  errorMessage,
  successMessage,
  warningMessage,
} from 'utilities';

export const PBLProblemOpen = ({
  level,
  solvedCount,
  title,
  state,
  newThumbnailImgName,
}) => {
  const [isOpen, setIsOpen] = useState(title === 'NOT AVAILABLE YET');

  useEffect(() => {
    setIsOpen(title === 'NOT AVAILABLE YET');
  }, [title]);

  const parsedLevel = level < 4 ? `기초 ${level}` : `응용 ${Number(level) - 3}`;

  return (
    <ContentBox className={isOpen ? 'lock' : ''}>
      <ImageBox>
        <span className={level < 4 ? 'basic' : 'advanced'}>{parsedLevel}</span>
        <img
          src={
            state === 'HIDE' ? '/icons/coming-soon.png' : newThumbnailImgName
          }
          alt=""
        />
        {isOpen && (
          <div className="lock-box">
            <LockImage className="lock-icon" src={`/icons/lock.png`} />
          </div>
        )}
      </ImageBox>
      <TextBox>
        <span>
          {solvedCount === 0
            ? '첫 시도의 주인공이 되세요!'
            : `${solvedCount}명이 풀었어요.`}
        </span>
        <h2>{isOpen ? '이전 문제를 먼저 풀어주세요.' : title}</h2>
      </TextBox>
    </ContentBox>
  );
};

export const PBLProblemHide = ({ level }) => {
  const parsedLevel = level < 4 ? `기초 ${level}` : `응용 ${Number(level) - 3}`;
  return (
    <ContentBox className="lock">
      <ImageBox>
        <span className={level < 4 ? 'basic' : 'advanced'}>{parsedLevel}</span>
        <img src={`/icons/coming-soon.png`} alt="icon" />
      </ImageBox>
      <TextBox>
        <span>&nbsp;</span>
        <h2 className="coming-soon">준비 중</h2>
      </TextBox>
    </ContentBox>
  );
};

export const PBLProblemClose = ({
  level,
  submitted,
  title,
  solvedCount,
  newThumbnailImgName,
}) => {
  const parsedLevel = level < 4 ? `기초 ${level}` : `응용 ${Number(level) - 3}`;

  return (
    <ContentBox>
      <ImageBox>
        <span className={level < 4 ? 'basic' : 'advanced'}>{parsedLevel}</span>
        <img src={newThumbnailImgName} alt="icon" />
      </ImageBox>
      <TextBox>
        <span>{solvedCount}명이 풀었어요.</span>
        <h2>{title}</h2>
      </TextBox>
      {submitted && <img src="/icons/complete.jpg" alt="complete" />}
    </ContentBox>
  );
};

export const PBLProblemComingSoon = ({ level }) => {
  const parsedLevel = level < 4 ? `기초 ${level}` : `응용 ${Number(level) - 3}`;

  return (
    <ContentBox className="lock">
      <ImageBox>
        <span className={level < 4 ? 'basic' : 'advanced'}>{parsedLevel}</span>
        <img src={`/icons/idea.png`} alt="icon" />
      </ImageBox>
      <TextBox>
        <span>&nbsp;</span>
        <h2 className="coming-soon">문제 출제 준비 중</h2>
      </TextBox>
    </ContentBox>
  );
};

export const PBLComment = ({ author, modifiedAt, comment, pblCommentId }) => {
  const textAreaRef = useRef(null);
  const { register, watch, getValues, setValue } = useForm();
  const timeFormat = `${modifiedAt?.slice(0, 10)} ${modifiedAt?.slice(11, 16)}`;

  const [editMode, setEditMode] = useState(false);

  const { mutateAsync: editComment, isLoading: isEditing } =
    useEditTeacherComment();
  const { mutateAsync: removeComment, isLoading: isRemoving } =
    useRemoveComment();

  useEffect(() => {
    if (isEditing || isRemoving) return;

    setValue('comment', comment);
  }, [comment, isEditing, isRemoving, setValue]);

  const handleChangeEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const handleEditComment = async (commentId, editedComment) => {
    if (comment === watch('comment')) {
      warningMessage({ text: `변경된 내용이 없습니다.` });
      return;
    }
    if (watch('comment').length > 3000) {
      errorMessage({ text: `3000자를 넘길 수 없습니다.` });
      return;
    }

    const response = await editComment({
      pblCommentId: commentId,
      comment: editedComment,
    });
    if (response.status === 204) {
      successMessage({ text: `해당 의견을 수정하였습니다.` });
      setEditMode(false);
    }
  };

  const handleRemoveComment = async (pblCommentId) => {
    const confirm = await confirmSwal({
      title: '해당 의견을 정말로 지우시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      try {
        const response = await removeComment({ pblCommentId });
        if (response.status === 204) {
          successMessage({ text: `해당 의견을 삭제하였습니다.` });
          setEditMode(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <CommentBox>
      <div className="comment-title">
        <b>{author}</b>
        <span className="time">{timeFormat}</span>
        <span className="edit-button" onClick={handleChangeEditMode}>
          {editMode ? (
            <i className="fa-solid fa-pen-to-square" />
          ) : (
            <i className="fa-regular fa-pen-to-square" />
          )}
        </span>
      </div>
      {editMode ? (
        <textarea
          cols="30"
          rows="5"
          ref={textAreaRef}
          {...register('comment')}
        />
      ) : (
        <div className="comment">
          <span>{comment}</span>
        </div>
      )}
      {editMode && (
        <div className="button-box">
          <span onClick={() => handleRemoveComment(pblCommentId)}>
            삭제하기
          </span>
          <span
            onClick={() =>
              handleEditComment(pblCommentId, getValues('comment'))
            }
          >
            {isEditing ? <CircularProgress size={15} /> : '수정하기'}
          </span>
        </div>
      )}
    </CommentBox>
  );
};

const ContentBox = styled.div`
  position: relative;

  &.lock {
    transform: none;
    cursor: default;
  }

  > img {
    position: absolute;
    width: 40%;
    bottom: 20%;
    right: 0;
    transform: rotate(11deg);
    border-radius: 50%;
  }

  img.lock-icon {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    box-shadow: 2px 2px 10px #333;
    border-radius: 50%;
  }
`;

const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;

  &:after {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  > span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    background-color: #fc9918;
    border-radius: 15px 15px 0 0;
    border-bottom: 1px solid #fff;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    bottom: 100%;
    left: 0;
    color: #fff;
    z-index: 1;
    box-shadow: 1px 0 3px #eee;

    &.basic {
      background-color: #fdba51;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LockImage = styled.img``;

const TextBox = styled.div`
  padding-top: 5px;

  > span {
    display: block;
    font-size: 14px;
    color: #999;
    font-weight: bold;
    border-bottom: 1px dotted #eee;
    padding-bottom: 2px;
  }

  h2 {
    padding-top: 5px;
    max-height: 100px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  h2.hiding {
    color: #a8a8a8;
    text-decoration: line-through;
  }

  h2.coming-soon {
    text-align: center;
    color: #a8a8a8;
  }
`;

const CommentBox = styled.div`
  width: 100%;
  padding: 15px 20px 20px;
  box-shadow: 1px 1px 3px #d3d3d3;
  border-radius: 10px;
  margin-bottom: 20px;

  .comment-title {
    margin-bottom: 5px;
    width: 100%;

    b {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }

  .time {
    color: #969696;
    padding-left: 20px;
  }

  .comment {
    font-size: 14px;
    white-space: pre-wrap;
    span {
      white-space: pre-wrap;
    }
  }

  .edit-button {
    float: right;
    font-size: 20px;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    min-height: 200px;
    max-height: 300px;
    resize: none;
    overscroll-behavior: contain;
    font-size: 14px;
  }

  .button-box {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;

    span {
      min-width: 80px;
      padding: 5px 10px;
      background-color: #fc9918;
      border: 1px solid #eee;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        filter: brightness(95%);
      }

      &:first-child {
        background-color: #da565f;
      }
    }
  }
`;
