import React from 'react';
import styled from 'styled-components';

const SearchInput = ({
  value,
  setValue,
  onClickSearch,
  width = '300px',
  placeholder = '검색어를 입력해주세요.',
}) => {
  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const handleClickSearchIcon = () => {
    onClickSearch();
  };

  return (
    <StyledBox $width={width}>
      <input
        placeholder={placeholder}
        name="content"
        value={value}
        onChange={handleChangeValue}
      />
      <div className="search-icon" onClick={handleClickSearchIcon}>
        <img src="/icons/search_icon.png" alt="icon" />
      </div>
    </StyledBox>
  );
};

const StyledBox = styled.div`
  display: flex;

  > input {
    width: ${(p) => p.$width};
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 14px;
    transition: 0.3s;

    ::placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #b2b2b2;
    }

    &:focus {
      border-color: #ec7422;
      box-shadow: 1px 1px 3px 1px rgba(236, 116, 34, 0.3);
    }
  }

  .search-icon {
    padding: 10px;
    background-color: #bebebe;
    margin-left: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      filter: brightness(90%);
    }
  }
`;

export default SearchInput;
