import { useQuery } from 'react-query';
import { fetchFaqDetail } from 'apis/customer';

const useFetchFaqDetail = ({ id }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchFaqDetail', { id }],
    queryFn: async () => await fetchFaqDetail({ id }),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!id,
  });
  return { ...queryReturns };
};

export default useFetchFaqDetail;
