import { useQuery } from 'react-query';

import { fetchGreetingDetail } from 'apis/customer';

const useFetchGreetingDetail = ({ id }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchGreetingDetail', { id }],
    queryFn: async () => await fetchGreetingDetail({ id }),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!id,
  });
  return { ...queryReturns };
};

export default useFetchGreetingDetail;
