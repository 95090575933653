import React, { Fragment, useContext, useState } from 'react';
import { useEffect } from 'react';
import Axios from 'axios';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

import AuthContext from 'context/Auth.context';
import styles from './cloudForm.module.scss';
import { handleErrorMessage } from 'utilities';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    color: 'white',
    fontSize: '1.4rem',
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },

  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  TextField: {
    label: {
      fontSize: '10px',
    },
  },
}));

let timeOut = null;

function CloudForm({ history }) {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem('token');
  const [data, setData] = useState();
  const classes = useStyles();
  const [arr, setArr] = useState([false, false, false, false, false, false]);

  useEffect(() => {
    if (!auth.auth) return;

    //해당 스터디 그룹의 정보 요청
    const url = `${process.env.REACT_APP_IP}/users/practice/cloud`;
    Axios.get(url)
      .then((response) => {
        setData(response.data.data);
      })
      .catch(handleErrorMessage);
  }, []);

  const handleCopy = (e) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    const index = e.target.dataset.idx;
    navigator.clipboard.writeText(e.target.dataset.type);
    const newArr = [...arr];
    newArr[index] = true;
    setArr(newArr);
    timeOut = setTimeout(() => {
      setArr([false, false, false, false, false, false]);
    }, 2000);
  };

  const Icon = (data, i) => {
    return (
      data && (
        <i
          style={{
            position: 'absolute',
            right: 25,
            top: 30,
            fontSize: '20px',
            color: arr[i] ? 'greenyellow' : 'rgba(0,0,0,0.3)',
            cursor: 'pointer',
          }}
          data-type={data}
          data-idx={i}
          className={arr[i] ? 'fas fa-check' : 'fas fa-copy'}
          onClick={handleCopy}
        ></i>
      )
    );
  };

  return (
    <Container
      maxWidth="sm"
      style={{ backgroundColor: 'white', minHeight: '500px' }}
    >
      <div className={classes.paper}>
        {/*
        <Avatar className={classes.avatar}>
        <i className="fas fa-portrait"></i>
        </Avatar>
        
        <Typography component="h1" variant="h5">
          회원 가입
        </Typography>
        */}
        {data && (
          <form className={classes.form}>
            <Grid container spacing={1}>
              {Object.keys(data).map((item, i) => {
                if (item === 'id') {
                  return '';
                } else {
                  return (
                    <Fragment key={i}>
                      <Grid item xs={4}>
                        <div className={styles.inputTitle}>
                          {item.toUpperCase()}
                        </div>
                      </Grid>
                      <Grid className={styles.grid} item xs={8}>
                        <input
                          className={styles.input}
                          type="text"
                          readOnly
                          value={data[item]}
                        />
                        {Icon(data[item], i)}
                      </Grid>
                    </Fragment>
                  );
                }
              })}
            </Grid>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <a
                href={data.cloudUrl}
                color="primary"
                target="_blank"
                //endIcon={<EmojiPeopleIcon/>}
                className={classes.button}
                style={{
                  margin: '20px auto',
                  padding: '10px 30px',
                  borderRadius: '50px',
                  fontFamily: 'Noto Sans KR',
                  color: 'white',
                }}
              >
                <span className="secosub2">AWS 로 이동</span>
              </a>
            </div>
          </form>
        )}
      </div>
    </Container>
  );
}

export default withRouter(CloudForm);
