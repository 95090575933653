import { useMutation, useQueryClient } from 'react-query';

import { createTask, editTask } from 'apis/classBoard';

const useTaskBoard = ({ id, type }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      if (id) {
        /* id 값이 있을 때는 과제 글 수정 */
        return await editTask({ id, formData });
      } else {
        /* id 값이 없을 때는 과제 글 생성 */
        return await createTask({ formData });
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassList', 'fetchClassDetail', { type, id }],
      }),
  });

  return { ...queryReturns };
};

export default useTaskBoard;
