import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import Navbar from '../Navbar';
import { Table, TableHeader, TableRow, Wrapper } from '../style';
import { handleErrorMessage } from 'utilities';

const UnitList = () => {
  const [list, setList] = useState();

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/users/myclass`;

    Axios.get(url)
      .then((response) => {
        setList(response.data.data.unitDtoList);
      })
      .catch(handleErrorMessage);
  }, []);

  return (
    <>
      <Header data={{ title: '목차 상세 작성' }} />
      <Navbar title={'목차 상세 작성'} />
      <Wrapper>
        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>카테고리</div>
            <div>단원명</div>
            <div className="md-div">학습시간</div>
          </TableHeader>
          {list &&
            list.map((item, i) => (
              <Link key={item.id} to={`/teacher/unit-detail/${item.id}`}>
                <TableRow>
                  <div className="sm-div">{i + 1}</div>
                  <div>{item.categoryName}</div>
                  <div>{item.title}</div>
                  <div className="md-div">{item.unitTime}시간</div>
                </TableRow>
              </Link>
            ))}
        </Table>
      </Wrapper>
    </>
  );
};

export default UnitList;
