import React from 'react';
import BackDrop from './BackDrop.jsx';
import { createContext } from 'react';
import styled from 'styled-components';

export const ModalContext = createContext();

const Modal = ({
  open,
  toggle,
  children,
  submitBtn,
  cancelBtn,
  onClose,
  size,
}) => {
  return (
    open && (
      <div style={{ boxSizing: 'border-box' }}>
        <ModalContext.Provider value={{ toggle }}>
          <ModalDiv size={size}>
            <ModalBody size={size}>{children}</ModalBody>
            {(submitBtn || cancelBtn) && (
              <StyledButtonArea>
                {cancelBtn && (
                  <CancelButton onClick={onClose}>{cancelBtn}</CancelButton>
                )}
                {submitBtn && <Button onClose={onClose}>{submitBtn}</Button>}
              </StyledButtonArea>
            )}
          </ModalDiv>
        </ModalContext.Provider>
        <BackDrop onClose={onClose} />
      </div>
    )
  );
};

const ModalDiv = styled.div`
  background-color: white;
  position: fixed;
  min-height: 230px;
  width: ${(props) =>
    props.size === 'large'
      ? '95vw !important'
      : props.size === 'medium'
      ? '60vw !important'
      : '45vw !important'};
  max-height: 95vh;
  z-index: 2000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 75px;
  box-sizing: border-box;
  overflow: hidden;
`;

const ModalBody = styled.div`
  overflow-y: auto;
  max-height: ${(props) =>
    props.size === 'large'
      ? 'calc(95vh - 200px);'
      : props.size === 'medium'
      ? 'calc(70vh - 100px);'
      : 'calc(40vh - 100px);'};
  box-sizing: border-box;
`;

const CancelButton = styled.button`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2em;
  color: white;
  background: rgb(69, 69, 69);
  width: 100px;
  height: 50px;
  border-radius: 10px;
  border: 0;
  outline: 0;
  transform: translateY(50%);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
`;

const Button = styled.button`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2em;
  background: rgb(215, 0, 33);
  width: 100px;
  color: white;
  height: 50px;
  border-radius: 10px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transform: translateY(50%);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

const StyledButtonArea = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

export default Modal;
