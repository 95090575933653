import { useMutation, useQueryClient } from 'react-query';

import { removeTeacherComment } from 'apis/pbl';

const useRemovePblComment = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ pblCommentId }) => {
      return await removeTeacherComment({ pblCommentId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudentCommentList'],
      }),
  });
  return { ...queryReturns };
};

export default useRemovePblComment;
