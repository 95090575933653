import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useFetchPblUnits } from 'hooks';
import PblMain from './PblMain';
import PblProblemDetail from './PBLProblemDetail';
import PblDetailEdit from './PblDetailEdit';
import { warningMessage } from 'utilities';

const Board = () => {
  const match = useRouteMatch();
  const { data: units, isLoading } = useFetchPblUnits();
  const firstUnit = units?.find((e) => e.pblVisible === true);

  return (
    <Container>
      <Switch>
        <Route exact path={`${match.url}`}>
          <Redirect to={`${match.url}/${firstUnit?.id}`} />
        </Route>

        <PersonalRoute
          exact
          units={units}
          isLoading={isLoading}
          path={`${match.url}/:unitId`}
        />

        <Route
          exact
          path={`${match.url}/:unitId/detail`}
          render={() => <PblProblemDetail />}
        />

        <Route
          exact
          path={`${match.url}/edit/:unitId/:problemId`}
          render={() => <PblDetailEdit />}
        />
      </Switch>
    </Container>
  );
};

const PersonalRoute = ({ units, isLoading, ...rest }) => {
  const idArray = units?.map((e) => e.id);

  return isLoading ? (
    <></>
  ) : (
    <Route
      {...rest}
      render={({ match }) => {
        const { unitId } = match.params;

        if (idArray?.length === 0 || unitId === 'undefined') {
          warningMessage({ text: '활성화된 단원이 존재하지 않습니다.' });
          return <Redirect to="/" />;
        }

        if (!idArray?.includes(Number(unitId))) {
          warningMessage({ text: '해당하지 않는 기수입니다.' });
          return <Redirect to="/" />;
        } else {
          return <PblMain />;
        }
      }}
    />
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 500px;
`;

export default Board;
