import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import AuthContext, { SetAuthContext } from 'context/Auth.context';
import Message from 'utilities/Message';
import { useAuth } from '../hooks';

// var process = require("../myProcess.json");

/**
 * https://ourcodeworld.com/articles/read/257/how-to-get-the-client-ip-address-with-javascript-only
 *
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
function getUserIP(onNewIP) {
  //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
      iceServers: [],
    }),
    noop = function () {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel('');

  // create offer and set local description
  pc.createOffer()
    .then(function (sdp) {
      sdp.sdp.split('\n').forEach(function (line) {
        if (line.indexOf('candidate') < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function (reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

function parseJWT(token) {
  const removedBearer = token.replace('Bearer', '');
  const base64Url = removedBearer.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

let clientIP;
getUserIP(function (ip) {
  clientIP = ip;
});

//사용하고자 하는 컴포넌트 최상위에 지정할 Provider컴포넌트
const AuthProvider = ({ children, history }) => {
  const { userLogout } = useAuth();

  const onLogin = (token, refreshToken, data) => {
    //  TODO: clientIP를 서버로 전달해서 토큰 생성 및 검증에 활용
    if (token && refreshToken) {
      const parsedData = parseJWT(token);
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('initialPw', String(parsedData.initialPw));

      setAuth((prev) => {
        return {
          ...prev,
          auth: true,
          token: String(token),
          refreshToken: String(refreshToken),
          userId: String(parsedData.userId),
          name: String(parsedData.userName),
          type: String(parsedData.userType),
          email: String(parsedData.userEmail),
          status: String(parsedData.status),
          freeYn: String(parsedData.freeYn),
          allowLanguage: String(parsedData.allowLanguages),
          classId: String(parsedData.classId),
          subClassId: 0,
          groupId: String(parsedData.groupId),
          initialPw: String(parsedData.initialPw),
          authToken: { Authorization: `Bearer ${token}` },
        };
      });
    } else {
      setAuth({
        ...auth,
        auth: false,
        userId: '',
        name: '',
        type: -1,
        email: '',
        status: '',
        token: '',
        refreshToken: '',
        authToken: '',
        freeYn: '',
        allowLanguages: '',
        classId: '',
        subClassId: '',
        initialPw: '',
      });
    }
  };

  const onLogout = async () => {
    const token = localStorage.getItem('token');

    await userLogout.mutateAsync({ token });

    setAuth((auth) => {
      return {
        ...auth,
        auth: false,
        userId: '',
        name: '',
        type: -1,
        email: '',
        status: '',
        token: '',
        refreshToken: '',
        authToken: '',
        freeYn: '',
        allowLanguages: '',
        classId: '',
        subClassId: '',
        initialPw: '',
      };
    });

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('initialPw');
  };

  let initialState = {
    auth: false,
    userId: '',
    name: '',
    type: -1,
    email: '',
    status: '',
    token: '',
    refreshToken: '',
    authToken: '',
    freeYn: '',
    allowLanguages: '',
    classId: '',
    subClassId: '',
    groupId: '',
    initialPw: '',
    onLogin,
    onLogout,
  };
  if (localStorage.getItem('token') !== null) {
    const token = localStorage.getItem('token');
    const parsedData = parseJWT(token);

    initialState = {
      ...initialState,
      auth: true,
      userId: String(parsedData.userId),
      name: String(parsedData.userName),
      type: String(parsedData.userType),
      email: String(parsedData.userEmail),
      status: String(parsedData.status),
      token: String(parsedData.token),
      refreshToken: String(parsedData.refreshToken),
      authToken: { Authorization: `${parsedData.token}` },
      freeYn: String(parsedData.freeYn),
      allowLanguages: String(parsedData.allowLanguages),
      classId: String(parsedData.classId),
      subClassId: 0,
      groupId: String(parsedData.groupId),
      initialPw: String(parsedData.initialPw),
    };
  }

  const [auth, setAuth] = useState(initialState);

  if (auth.auth) {
    const userPath = document.location.href.split('/')[3];
    const userType = auth.type; // 1: 학습자, 2: 출제자
    const status = auth.status; // 0: 대기, 1: 승인, 2: 거절, 3: 탈퇴
    // userPath 에 myclass 옵션을 추가함 -2021 12 27
    if (userPath === 'teacher' && userType === '1') {
      toast.error(':: ' + Message.SERVICE_TEACHER_ONLY);
      history.push('/myclass');
    } else if (userPath === 'sgroup' && userType !== '1') {
      toast.error(Message.SERVICE_STUDENT_ONLY);
      history.push('/myclass');
    }
  }

  return (
    <SetAuthContext.Provider value={setAuth}>
      <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
    </SetAuthContext.Provider>
  );
};

export default withRouter(AuthProvider);
