import { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchInquiriesList } from 'apis/customer';

const useFetchInquiriesList = ({ page, pageSize, type, searchContent }) => {
  const [totalValue, setTotalValue] = useState(0);

  const queryReturns = useQuery({
    queryKey: ['fetchInquiriesList', { page, pageSize, searchContent }],
    queryFn: async () => {
      const response = await fetchInquiriesList({
        page,
        pageSize,
        type,
        searchContent,
      });

      setTotalValue(response.data.data.total);

      return response;
    },
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
  });
  return { ...queryReturns, totalValue };
};

export default useFetchInquiriesList;
