import Axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import FileSaver from 'file-saver';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Header from '../../components/header/Header';
import Navbar from '../Navbar';
import {
  Btn,
  BtnWrapper,
  DisabledTableRow,
  Table,
  TableHeader,
  TableRow,
  Wrapper,
} from '../style';
import { useFetchTaskSubmittedStudentList } from 'hooks';

const StudentList = () => {
  const { taskId } = useParams();

  /* asc = 오름차순, desc = 내림차순 */
  const [dateSort, setDateSort] = useState('asc');
  const [isFileLoading, setIsFileLoading] = useState(false);

  const orderIcon =
    dateSort === 'asc' ? (
      <i className="fa-solid fa-chevron-down" />
    ) : (
      <i className="fa-solid fa-chevron-up" />
    );

  const { data } = useFetchTaskSubmittedStudentList({
    taskId,
    dateSort,
  });
  const { items: list } = data || {};

  const handleDownload = async (taskId) => {
    const url = `${process.env.REACT_APP_IP}/teachers/lc-posts/submit-tasks/downloadAll/${taskId}`;

    try {
      setIsFileLoading(true);
      const response = await Axios.get(url, {
        responseType: 'blob',
      });

      if (response) {
        FileSaver.saveAs(
          response.data,
          decodeURIComponent(
            response.headers['content-disposition'].split('filename=')[1]
          )
        );
      }
      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
    }
  };

  const handleChangeOrder = () =>
    setDateSort((prev) => (prev === 'asc' ? 'desc' : 'asc'));

  return (
    <>
      <Header
        data={{
          title: '과제 관리',
          content: data?.title || '',
        }}
      />
      <Navbar title={'과제 관리'} />
      <Wrapper>
        <ControlBox>
          <StyledButton onClick={() => handleDownload(taskId)} color="#fc9918">
            {isFileLoading ? (
              <LinearProgress style={{ backgroundColor: '#eee' }} />
            ) : (
              '제출된 과제 모두 다운로드'
            )}
          </StyledButton>
        </ControlBox>

        <Table>
          <TableHeader>
            <div className="sm-div">No.</div>
            <div>학습자</div>
            <SubmitDate className="md-div" onClick={handleChangeOrder}>
              제출시각 {orderIcon}
            </SubmitDate>
            <div className="sm-div">점수</div>
          </TableHeader>

          {!list && <NoResult />}

          {list &&
            list.map((item, i) =>
              item.submitDateTime ? (
                <Link
                  key={item.userId}
                  to={`/teacher/task/${taskId}/${item.id}`}
                >
                  <TableRow>
                    <div className="sm-div">{i + 1}</div>
                    <div>{item.userName}</div>
                    <div className="md-div">
                      {item.submitDateTime.replace('T', ' ')}
                    </div>
                    <div className="sm-div">
                      {item.score ? `${item.score}/100` : '채점중'}
                    </div>
                  </TableRow>
                </Link>
              ) : (
                <DisabledTableRow key={item.userId}>
                  <div className="sm-div">{i + 1}</div>
                  <div>{item.userName}</div>
                  <div className="md-div">-</div>
                  <div className="sm-div">-</div>
                </DisabledTableRow>
              )
            )}
        </Table>
        <BtnWrapper>
          <Link to={`/teacher/task`}>
            <Btn color="rgba(0, 0, 0, 0.3)">돌아가기</Btn>
          </Link>
        </BtnWrapper>
      </Wrapper>
    </>
  );
};

const NoResult = () => {
  return (
    <Box>
      <ImageBox>
        <img src="/assets/images/no-result.jpeg" alt="no result" />
      </ImageBox>
      <NoExamBox>과제물을 제출한 학생이 존재하지 않습니다.</NoExamBox>
    </Box>
  );
};

const ControlBox = styled.div`
  margin-bottom: 10px;
`;

const StyledButton = styled(Btn)`
  min-width: 170px;
  min-height: 37px;

  margin: 0;

  div.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
    background-color: #fff !important;
  }
`;

const SubmitDate = styled.div`
  cursor: pointer;
`;

const Box = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const NoExamBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 30px 0;
  text-shadow: 1px 1px 2px rgba(190, 187, 187, 0.65);
`;

const ImageBox = styled.div`
  width: 200px;
  animation: gelatine 1s infinite;
  animation-delay: 2s;
  margin: 0 auto;

  img {
    width: 100%;
  }

  @keyframes gelatine {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.95, 1.05);
    }
    50% {
      transform: scale(1.05, 0.95);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

export default StudentList;
