import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import { AppendixFile, TextEditor } from '../../../elements';
import {
  useCreateInquiry,
  useFetchInquiryDetail,
  useFetchQuestionCategories,
  useEditInquiryDetail,
} from 'hooks';
import { errorMessage, successMessage } from 'utilities';

const ContactWrite = ({ history }) => {
  const { id } = useParams();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const TITLE_MAX_LENGTH = 70;
  const CONTENT_MAX_LENGTH = 4000;

  const { data: categories } = useFetchQuestionCategories();

  const { mutateAsync: edit, isLoading: isEditLoading } = useEditInquiryDetail({
    id,
  });

  const { data, files } = useFetchInquiryDetail({ id });
  const { post } = data || {};

  const [deleteFileIds, setDeleteFileIds] = useState([]);
  const [file, setFile] = useState([]);
  const [textValue, setTextValue] = useState('');

  const { mutate: create, isLoading: isCreateLoading } = useCreateInquiry();

  const isLoading = id ? isEditLoading : isCreateLoading;

  const submit = async (form) => {
    let parsedTextValue = textValue
      .replaceAll('<p>', '')
      .replaceAll('</p>', '');
    const hasOnlyBr = /^<br>(<br>)*$/.test(parsedTextValue);
    if (textValue.trim() === '' || hasOnlyBr || parsedTextValue.trim() === '') {
      errorMessage({ text: '내용을 입력해주세요.' });
      return;
    }

    const formData = new FormData();

    const data = id
      ? {
          categoryId: form.category,
          title: form.title,
          content: textValue,
          deleteFileIds,
        }
      : {
          categoryId: form.category,
          title: form.title,
          content: textValue,
        };

    formData.append(
      'data',
      new Blob([JSON.stringify(data)], { type: 'application/json' })
    );
    if (!!file.length) {
      file.forEach((e) => {
        formData.append('file', e);
      });
    }

    if (id) {
      await edit(
        {
          formData,
        },
        {
          onSuccess: () => {
            successMessage({ text: '문의 글이 수정되었습니다.' });
            history.goBack();
          },
          onError: () => {
            errorMessage({
              text: '댓글이 달린 문의글은 수정이 불가능합니다.',
            });
          },
        }
      );
    } else {
      await create(
        { formData },
        {
          onSuccess: () => {
            successMessage({ text: '문의 글이 정상 등록되었습니다.' });
            history.push('/customer/contact');
          },
          onError: () => {
            errorMessage({ text: '문의 글 등록에 문제가 발생하였습니다.' });
          },
        }
      );
    }
  };

  const handleChangeFile = (fileList) => {
    setFile([...fileList]);

    if (!!data?.files.length) {
      setDeleteFileIds([data?.files[0].id]);
    }
  };

  const handleDeleteFile = (index, id) => {
    setFile((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });
    setDeleteFileIds([id]);
  };

  useEffect(() => {
    if (!(post && categories)) return;

    setValue('title', post.title);
    setValue('category', String(post.categoryId));
    setTextValue(post.content);
  }, [post, categories]);

  useEffect(() => {
    if (textValue.length < CONTENT_MAX_LENGTH) return;

    if (textValue.length > CONTENT_MAX_LENGTH) {
      errorMessage({ text: '3500자 이하로 입력해주세요' });
      setTextValue((prevTextValue) =>
        prevTextValue.slice(0, CONTENT_MAX_LENGTH - 50)
      );
    }
  }, [textValue.length]);

  if (!categories) return <></>;
  return (
    <form onSubmit={handleSubmit(submit)}>
      <TitleBox>
        <div>
          <div>
            <input
              name="title"
              type="text"
              {...register('title', {
                required: true,
                maxLength: TITLE_MAX_LENGTH,
              })}
              placeholder="제목을 입력해주세요."
              maxLength={TITLE_MAX_LENGTH + 10}
            />
            {errors.title?.type === 'required' && (
              <StyledError>제목을 입력해주세요.</StyledError>
            )}
            {errors.title?.type === 'maxLength' && (
              <StyledError>
                {TITLE_MAX_LENGTH}자 이하로 입력해주세요.
              </StyledError>
            )}
          </div>
        </div>

        <div>
          <div>
            <div className="categories">
              {categories.map((e) => (
                <label key={e.id}>
                  <input
                    name="category"
                    {...register('category', {
                      required: true,
                    })}
                    type="radio"
                    value={String(e.id)}
                  />
                  <CategoryItem $backColor={e.themeColor}>
                    {e.categoryName}
                  </CategoryItem>
                </label>
              ))}
            </div>
            {errors.category && (
              <StyledError>카테고리를 선택해주세요.</StyledError>
            )}
          </div>
        </div>
      </TitleBox>

      <Grid item xs={12}>
        <QuillBox>
          <TextEditor
            htmlStr={textValue}
            setHtmlStr={setTextValue}
            placeholder="문의사항을 입력해주세요."
          />
        </QuillBox>
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            margin: '-5px 0 10px 15px',
            fontFamily: 'Noto Sans KR',
          }}
        ></div>
      </Grid>

      <AppendixFile
        onChange={handleChangeFile}
        onDelete={handleDeleteFile}
        originalFileNames={files}
      />

      <Grid item xs={12}>
        <div
          style={{
            paddingTop: '10px',
            borderBottom: '1px solid #e3e3e3',
            margin: '15px 0 0',
            fontFamily: 'Noto Sans KR',
          }}
        />
      </Grid>
      <NoticeText>※ 댓글이 달린 문의글은 수정이 불가능합니다.</NoticeText>

      <ButtonBox>
        <Link to="/customer/contact">취소</Link>
        <button type="submit">
          {isLoading ? <CircularProgress size={30} /> : '글 등록하기'}
        </button>
      </ButtonBox>
    </form>
  );
};

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #333;

  > div {
    display: flex;
    min-height: 94px;
    align-items: center;
    gap: 14px;
    border-bottom: 1px solid rgb(230, 230, 230);

    &:last-child {
      border-bottom: none;
    }

    > span {
      width: 100px;
      display: inline-block;
      padding-left: 25px;
      box-sizing: border-box;
      font-weight: bold;
      font-size: 17px;
    }

    > div {
      width: 100%;
      padding: 13px 0;

      input[type='text'] {
        width: 80%;
        outline: none;
        height: 45px;
        border: 1px solid rgb(230, 230, 230);
        padding-left: 15px;
        border-radius: 3px;
        font-size: 15px;
      }

      > div.categories {
        display: flex;
        width: 100%;
        column-gap: 20px;

        > label {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-size: 14px;
          margin-bottom: 0;

          > input {
            margin-right: 2px;
            width: unset;
            height: unset;
          }
        }
      }
    }
  }
`;

const CategoryItem = styled.span`
  padding: 3px 7px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background-color: ${(p) => p.$backColor};
`;

const QuillBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0 50px;

  div {
    width: 100%;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;

  a,
  button {
    display: flex;
    width: 185px;
    height: 55px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 15px;
    font-weight: bold;

    &:hover {
      filter: brightness(90%);
    }
  }

  a {
    color: rgb(151, 151, 151);
    border: 1px solid rgb(212, 212, 212);
    text-decoration: none;
    font-size: 16px;
  }

  button {
    color: rgb(255, 255, 255);
    background: #ec7422;
    font-size: 16px;
    cursor: pointer;
  }

  svg {
    color: #fff;
  }
`;

const StyledError = styled.div`
  color: #ff1c1c;
  font-size: 14px;
  margin-top: 5px;
  width: 100%;
`;

const NoticeText = styled.span`
  font-size: 1.2rem;
  color: #ec7422;
  font-weight: 500;
`;

export default ContactWrite;
