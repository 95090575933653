import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';
import TeacherTask from './task/TeacherTask';
import StudentList from './task/StudentList';
import TaskResult from './task/TaskResult';
import UnitList from './unitList/UnitList';
import WriteDetail from './unitList/WriteDetail';
import GroupTestManager from './test/GroupTestManager';
import GroupTestUserList from './test/GroupTestUserList';
import GroupTestResult from './test/GroupTestResult';
import Students from './students/list';
import Detail from './students/detail';
import ProblemList from './test/addExam/ProblemList';
import Rewatch from './rewatch/Rewatch';

import GroupTestAnalysis from './test/GroupTestAnalysis';

const Teacher = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [title, setTitle] = useState('');

  useEffect(() => {
    const page = history.location.pathname.split('/');
    if (page[2] === 'task') {
      if (page[3]) {
      } else {
        setTitle('과제 관리');
      }
    } else if (page[2] === 'test') {
      setTitle('시험관리');
    }
  }, [history]);

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/students`} />
        </Route>
        <Route
          exact
          path={`${match.url}/task`}
          render={() => <TeacherTask />}
        />
        <Route
          exact
          path={`${match.url}/task/:taskId`}
          render={() => <StudentList />}
        />
        <Route
          exact
          path={`${match.url}/task/:taskId/:resultId`}
          render={() => <TaskResult />}
        />
        <Route
          exact
          path={`${match.url}/test`}
          render={() => <GroupTestManager />}
        />
        <Route
          exact
          path={`${match.url}/test/result`}
          render={() => <GroupTestUserList />}
        />
        <Route
          exact
          path={`${match.url}/test/analysis`}
          render={() => <GroupTestAnalysis />}
        />
        <Route
          exact
          path={`${match.url}/test/groupTestResult`}
          render={() => <GroupTestResult />}
        />
        <Route
          exact
          path={`${match.url}/test/add/:examId`}
          render={() => <ProblemList />}
        />
        <Route
          exact
          path={`${match.url}/unit-detail`}
          render={() => <UnitList />}
        />
        <Route
          exact
          path={`${match.url}/unit-detail/:unitId`}
          render={() => <WriteDetail />}
        />
        <Route
          exact
          path={`${match.url}/students`}
          render={() => <Students />}
        />
        <Route exact path={`${match.url}/rewatch`} render={() => <Rewatch />} />
        <Route
          exact
          path={`${match.url}/students/:userId`}
          render={() => <Detail />}
        />
      </Switch>
    </>
  );
};

export default Teacher;
