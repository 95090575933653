import { useMutation, useQueryClient } from 'react-query';

import { editGreetingComment } from 'apis/customer';

const useEditGreetingComment = ({ postId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ replyId, author, content }) => {
      return await editGreetingComment({ postId, replyId, author, content });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchGreetingDetail'],
      }),
  });
  return { ...queryReturns };
};

export default useEditGreetingComment;
