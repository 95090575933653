import { useState } from 'react';
import FileSaver from 'file-saver';
import { useQuery } from 'react-query';
import swal from 'sweetalert';

import { downloadClassFile, downloadTaskFile } from 'apis/classBoard';

const useDownloadClassFile = ({ type, id }) => {
  const [fileId, setFileId] = useState('');

  const queryReturns = useQuery(
    ['downloadClassFile'],
    async () => {
      try {
        let response;
        if (type !== 'task') {
          response = await downloadClassFile({ id, fileId });
        } else if (type === 'task') {
          response = await downloadTaskFile({ id, fileId });
        }

        if (response.headers['content-disposition']) {
          FileSaver.saveAs(
            response.data,
            decodeURIComponent(
              response.headers['content-disposition']
                .split('filename=')[1]
                .replaceAll('"', '')
            ).normalize('NFC')
          );

          setFileId('');
        } else {
          swal({ icon: 'error', title: '파일 저장에 실패하였습니다.' });
          setFileId('');
        }
      } catch (error) {
        if (error.response.status === 404) {
          swal({ icon: 'error', title: '해당 파일을 찾을 수 없습니다.' });
          setFileId('');
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!fileId,
    }
  );
  return { ...queryReturns, setFileId };
};

export default useDownloadClassFile;
