import Axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useParams, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from '../../components/header/Header';
import Navbar from '../Navbar';
import {
  Btn,
  BtnWrapper,
  Table,
  TableHeader,
  TableRow,
  Wrapper,
} from '../style';
import { handleErrorMessage } from 'utilities';

const WriteDetail = () => {
  const [list, setList] = useState();
  const [title, setTitle] = useState();
  const [detailsData, setDetailsData] = useState();
  let refs = useRef(new Array());

  const { unitId } = useParams();

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/users/myclass/units/${unitId}`;

    Axios.get(url)
      .then((response) => {
        setList(response.data.data.chapterDtoList);
        setTitle(response.data.data.title);
      })
      .catch(handleErrorMessage);
  }, []);

  useEffect(() => {
    if (list) {
      for (let i = 0; i < list.length; i++) {
        refs.current[i].value = list[i].content ? list[i].content : '';
      }
    }
  }, [list]);

  const handleSubmit = () => {
    if (window.confirm('상세내용을 저장하시겠습니까?')) {
      const obj = {};

      for (let i = 0; i < list.length; i++) {
        obj[list[i].id] = refs.current[i].value;
      }

      const url = `${process.env.REACT_APP_IP}/teachers/myclass/units/${unitId}`;

      Axios.put(url, obj)
        .then((response) => {
          setList(response.data.chapterDtoList);
          toast.success('목차상세가 정상적으로 저장되었습니다.');
        })
        .catch(handleErrorMessage);
    }
  };

  const handleChange = (e, i) => {
    const str = e.target.value;
    setDetailsData((prev) => {
      const newArr = prev.map((item, index) => {
        if (i === index) {
          return str;
        } else {
          return item;
        }
      });
      return [...newArr];
    });
  };

  const handlePush = (el) => {
    refs.current.push(el);
  };

  useEffect(() => {
    if (refs.current.length !== 0) {
      const arr = [];
      for (let i = 0; i < list.length; i++) {
        arr[i] = refs.current[i].value;
      }
      setDetailsData(arr);
    }
  }, [refs.current[0]]);

  return (
    <>
      <Header data={{ title: '목차 상세 작성', content: title }} />
      <Navbar title={'목차 상세 작성'} />
      <Wrapper>
        <Table>
          <TableHeader>
            <div className="sm-div">일차</div>
            <div>목차</div>
            <div className="md-div">강의일</div>
            <div className="inputWrapper">목차상세</div>
          </TableHeader>
          {list &&
            list.map((item, i) => (
              <TableRow key={item.id}>
                <div className="sm-div">{item.chapterDay}일차</div>
                <div>{item.title}</div>
                <div className="md-div">{item.chapterDate.split('T')[0]}</div>
                <div className="inputWrapper">
                  <input
                    value={detailsData && detailsData[i]}
                    ref={(element) => handlePush(element)}
                    type="text"
                    placeholder="상세 내용을 작성해주세요."
                    onChange={(e) => handleChange(e, i)}
                  />
                </div>
              </TableRow>
            ))}
        </Table>
        <BtnWrapper>
          <Link to={`/teacher/unit-detail`}>
            <Btn color="rgba(0, 0, 0, 0.3)">돌아가기</Btn>
          </Link>
          <Btn onClick={handleSubmit} color="#fc9918">
            전체저장
          </Btn>
        </BtnWrapper>
      </Wrapper>
    </>
  );
};

export default withRouter(WriteDetail);
