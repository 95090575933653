import React from 'react';
import styled from 'styled-components';

import { confirmSwal, successMessage } from 'utilities/swal';
import { useReservation } from 'hooks';

const ConsultingList = ({ list = [], isListLoading = false }) => {
  const { adminApplyReservation, adminFinishReservation } = useReservation({});

  const handleFinish = async (id) => {
    const confirm = await confirmSwal({
      title: '상담을 완료 하시겠습니까?',
      confirmButton: '완료',
    });

    if (confirm) {
      const { status } = await adminFinishReservation.mutateAsync({ id });
      if (status === 204) {
        successMessage({ text: '상담이 완료되었습니다.' });
      }
    }
  };

  const handleAccept = async (id) => {
    const confirm = await confirmSwal({
      title: '상담을 승인 하시겠습니까?',
      confirmButton: '승인',
    });

    if (confirm) {
      const { status } = await adminApplyReservation.mutateAsync({ id });
      if (status === 204) {
        successMessage({ text: '상담이 승인되었습니다.' });
      }
    }
  };

  return (
    <>
      {isListLoading ? (
        Array(5)
          .fill('')
          .map((_, index) => (
            <ListSkeleton key={index}>
              <div />
              <div />
            </ListSkeleton>
          ))
      ) : (
        <>
          <StyledHeader>
            <b className="author">교육생</b>
            <b className="createdAt">상담예정일자</b>
            <b>신청 이유</b>
            <b className="createdAt">신청 일자</b>
            <b className="state">상태</b>
          </StyledHeader>

          <StyledBoardList>
            {list.map((list, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td className="author">
                      <span>
                        {list.userName}({list.userId})
                      </span>
                    </td>

                    <td className="createdAt">
                      <span>{list.consultingDate.slice(0, 10)}</span>
                      <span>{list.consultingDate.slice(11, 16)}</span>
                    </td>

                    <td className="purpose">
                      <span>{list.purpose}</span>
                    </td>

                    <td className="createdAt">
                      <span>{list.regDate.slice(0, 10)}</span>
                    </td>

                    <td className="state">
                      {list.resultComment === '승인대기중' && (
                        <ButtonBox onClick={() => handleAccept(list.id)}>
                          <span className="before">상담 수락</span>
                        </ButtonBox>
                      )}
                      {list.resultComment === '상담 수락' && (
                        <ButtonBox onClick={() => handleFinish(list.id)}>
                          <span className="wait">상담 완료</span>
                        </ButtonBox>
                      )}
                      {list.resultComment === '상담 완료' && (
                        <ButtonBox>
                          <span className="done">상담 완료</span>
                        </ButtonBox>
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })}
            {list.length === 0 && (
              <NoResult>상담 신청 목록이 없습니다.</NoResult>
            )}
          </StyledBoardList>
        </>
      )}
    </>
  );
};

const ListSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &:first-child {
    border-top: 2px solid #000;
  }

  > div {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;

    &:first-child {
      background-color: #f8f8f8;
    }
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  > b {
    text-align: center;
    flex: 1;
    font-size: 1.6rem;
    font-weight: bold;

    &.state {
      flex: unset;
      display: inline-block;
      width: 100px !important;
    }

    &.createdAt {
      flex: unset;
      display: inline-block;
      width: 150px !important;
    }

    &.author {
      flex: unset;
      display: inline-block;
      width: 200px !important;
    }
  }
`;

const StyledBoardList = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-top: 2px solid #000;
  font-size: 16px;
  font-weight: bold;

  > tbody {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #333;
    transition: 0.2s;
    display: flex;
    text-align: center;

    &:nth-child(2n-1) {
      background-color: #f8f8f8;
    }

    > tr {
      width: 100%;
      color: #333;
      transition: 0.2s;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #eee;

      > td {
        flex: 1;
        font-size: 14px;
        height: 100%;

        > span {
          display: inline-block;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.purpose span {
          max-width: 300px;
        }

        p {
          color: #777;
          font-size: 12px;
        }
      }
    }

    td.state {
      max-width: 100px;
    }

    td.createdAt {
      display: flex;
      justify-content: center;
      column-gap: 4px;
      height: 100%;
      text-align: center;
      max-width: 160px;
    }

    td.author {
      flex: unset;
      display: inline-block;
      width: 200px !important;
    }
  }
`;

const ButtonBox = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 14px;

  span {
    padding: 3px 6px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;

    &.before {
      background-color: #9dd9d2;
    }

    &.wait {
      background-color: #ffb469;
    }

    &.done {
      background-color: transparent;
      color: #999;
      font-weight: bold;
      cursor: default;
      text-shadow: 1px 1px 2px rgba(206, 206, 206, 0.6);
    }

    &:hover {
      filter: contrast(70%);
    }
  }
`;

const NoResult = styled.div`
  width: 100%;
  min-height: 300px;
  display: grid;
  place-items: center;
  text-align: center;
  color: #ec7422;
`;

export default ConsultingList;
