import React from 'react';
import styled from 'styled-components';

import { useProblem } from 'hooks';
import { Modal2, ProblemDetailContent } from '../../../components';
import ShortAnswer from './InputComponents/ShortAnswer';
import OXAnswer from './InputComponents/OXAnswer';
import ChoiceAnswer from './InputComponents/ChoiceAnswer';

const ProblemDetail = ({ problemId, handleClose }) => {
  const { data } = useProblem(problemId);
  return (
    <>
      <div>
        <Modal2 cancelBtn="닫기" open={true} size="large" onClose={handleClose}>
          {data?.examType === 0 && (
            <ChoiceAnswer level={data?.examLevel} data={data} />
          )}
          {data?.examType === 2 && (
            <OXAnswer level={data?.examLevel} data={data} />
          )}
          {data?.examType === 3 && (
            <ShortAnswer level={data?.examLevel} data={data} />
          )}
          <ProblemDetailContent></ProblemDetailContent>
        </Modal2>
      </div>
    </>
  );
};
const Btn = styled.button`
  background-color: #fc9918;
  border: 1px solid #fc9918;
  padding: 7px;
  margin: 0 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #fc9918;
  }
`;
export default ProblemDetail;
