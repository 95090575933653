import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { useReservation } from 'hooks';
import ReserveModal from './ReserveModal';
import ReserveList from './ReserveList';
import styles from '../../../../modules/capability/Capatest.module.scss';

const Reserve = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { studentReservationList } = useReservation({});
  const { data: list, isLoading } = studentReservationList || {};

  return (
    <Fragment>
      <div className="mypageContents">
        <ReserveList list={list || []} isListLoading={isLoading} />
      </div>

      <StyledBox>
        <StyledButton onClick={() => setIsModalOpen(true)}>
          상담 신청하기
        </StyledButton>
      </StyledBox>

      {isModalOpen && (
        <div className={styles.modalWrapper}>
          <div
            className={styles.overlay}
            onClick={() => setIsModalOpen(false)}
          />
          <ReserveModal
            onClick={() => setIsModalOpen(false)}
            setModalOpen={setIsModalOpen}
          />
        </div>
      )}
    </Fragment>
  );
};

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > button {
    margin-left: auto;
  }
`;

const StyledButton = styled.button`
  display: grid;
  place-items: center;
  height: 40px;
  padding: 5px 50px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  background-color: #ec7422;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    filter: brightness(90%);
    color: #fff;
  }
`;

export default Reserve;
