import React from 'react';
import styled from 'styled-components';

import { useInput } from 'hooks';
import { errorMessage } from 'utilities';

const ClassBoardAppendixFile = ({
  originalFileNames,
  multiple,
  onChange,
  onDelete,
  placeholder = '파일을 업로드해주세요.(선택사항)',
  type,
}) => {
  const input = useInput({ originalFileNames, multiple: !!multiple });
  const filenames = input.preview;

  const libraryFileSize =
    type === 'library' ? 20 * 1024 * 1024 : 10 * 1024 * 1024;
  const libraryFileLimit = type === 'library' ? 20 : 10;

  const handleChange = (e) => {
    if ([...e.target.files].some(({ size }) => size > libraryFileSize)) {
      errorMessage({ text: `파일 용량 제한은 ${libraryFileLimit}MB 입니다.` });
      return;
    }

    const files = input.handleChange(e);

    if (files && onChange) {
      onChange(files);
    }
  };

  const deleteFile = (index, fileName, fileId) => {
    input.deleteFile(index);
    if (onDelete) {
      onDelete(index, fileName, fileId);
    }
  };

  return (
    <AddtionalFileBox>
      <div className="appendix">
        <div className="input">
          {(!filenames.length || !filenames) && (
            <span className="placeholder">{placeholder}</span>
          )}

          {!!filenames.length &&
            filenames.map((file, index) => (
              <span key={index}>
                {file.name || file.originalFileName || file}
                <i
                  className="delete-icon"
                  onClick={() => deleteFile(index, file.newFileName)}
                />
              </span>
            ))}
        </div>
        <input
          type="file"
          placeholder="파일 선택"
          ref={input.inputRef}
          name="file"
          accept=".zip, application/pdf, application/msword, image/*, .docx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, .hwp, .hwpx, .rtf"
          onChange={handleChange}
          multiple={!!multiple}
        />
        <button type="button" onClick={input.clickUpload}>
          <span>업로드</span>
        </button>
      </div>
    </AddtionalFileBox>
  );
};

const AddtionalFileBox = styled.div`
  width: 100%;
  align-items: center;
  gap: 10px;
  display: flex;
  font-size: 16px;

  div.appendix {
    width: 80%;
    display: flex;
    gap: 14px;
    align-items: center;

    > div.input {
      width: 60%;
      min-height: 45px;
      border: 1px solid rgb(206, 206, 206);
      border-radius: 3px;
      display: flex;
      padding: 6px 10px;
      justify-content: center;
      box-sizing: border-box;
      gap: 5px;
      flex-flow: column wrap;

      span {
        color: rgb(34, 34, 34);
        font-size: 15px;
        display: flex;
        gap: 8px;

        &.placeholder {
          font-size: 14px;
          color: #888;
        }

        > i.delete-icon {
          cursor: pointer;
          width: 17px;
          height: 18px;
          display: block;
          background-image: url(/icons/delete_icon.png);
        }
      }
    }

    > input {
      display: none;
    }

    > button {
      width: 111px;
      height: 45px;
      border: none;
      appearance: none;
      background: rgb(42, 33, 24);
      color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;

      span {
      }
    }
  }
`;

export default ClassBoardAppendixFile;
