import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Select from './select/Select';
import iconOne from './select/image/cat3.png';
import iconTwo from './select/image/cat11.png';
import iconThree from './select/image/cloud2.png';
import iconFour from './select/image/cat33.png';
import Cloud from './Cloud';

function Practice(props) {
  const match = useRouteMatch();

  const selectData = [
    {
      id: 0,
      icon: iconFour,
      subtitle: '라이브코딩실습장',
      text: '웹IDE 컨테이너 환경으로 바로 연결됩니다.',
      url: '',
    },
    {
      id: 1,
      icon: iconThree,
      subtitle: '라이브클라우드실습장',
      text: 'AWS클라우드 실습환경으로 바로 연결됩니다.',
      url: '/myclass/practice/cloud',
    },
    {
      id: 2,
      icon: iconOne,
      subtitle: 'EQST',
      text: '온라인 이론강의와 사이트 모의해킹을 학습합니다.',
      url: 'https://elms.skinfosec.co.kr/index.asp',
    },
    {
      id: 3,
      icon: iconTwo,
      subtitle: '시코랩',
      text: '온라인 이론강의와 사이트 SW개발보안을 학습합니다.',
      url: 'https://secolab.kr/',
    },
  ];

  const cloudData = [
    {
      cloudAccountId: 1,
      userName: 'ktae23',
      passWord: 'asdfasdfasdf',
      cloudUrl: 'https://github.com/ktae23',
      accessKeyId: 'adfsjihaosdfgaodsf',
      clientSecret: 'asdifjapsdfhia',
    },
  ];

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/select`} />
      </Route>
      <Route
        exact
        path={`${match.url}/select`}
        render={() => <Select data={selectData} />}
      />
      <Route
        exact
        path={`${match.url}/cloud`}
        render={() => <Cloud data={cloudData} />}
      />
    </Switch>
  );
}

export default Practice;
