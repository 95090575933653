import axios from 'axios';
import { useMutation } from 'react-query';

const useMutateCounseling = () => {
  const { mutate, isLoading } = useMutation(
    async ({ authorId, studentId, title, content }) => {
      return await axios
        .post(`${process.env.REACT_APP_IP}/teachers/exam/counseling/comments`, {
          authorId,
          studentId,
          title,
          content,
        })
        .then((res) => {
          return res.data;
        });
    }
  );
  return { mutate, isLoading };
};

export default useMutateCounseling;
