import { useMutation, useQueryClient } from 'react-query';

import { removeSubmittedResult } from 'apis/pbl';

const useRemoveSubmittedResult = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ problemId, submitId }) => {
      return await removeSubmittedResult({ problemId, submitId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSubmittedStudentList'],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveSubmittedResult;
