import { useQuery } from 'react-query';
import FileSaver from 'file-saver';

import { downloadStudentResult } from 'apis/pbl';

const useDownloadStudentResult = ({ problemId, userId }) => {
  const queryReturns = useQuery({
    queryKey: ['downloadStudentResult', problemId, userId],
    queryFn: async () => await downloadStudentResult({ problemId, userId }),
    enabled: !!userId,
    retry: 1,
    retryDelay: 500,
    onSuccess: (response) => {
      const fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .replaceAll('"', '');

      FileSaver.saveAs(
        response.data,
        decodeURIComponent(fileName).normalize('NFC')
      );
    },
  });
  return { ...queryReturns };
};

export default useDownloadStudentResult;
