import React, { useContext } from 'react';

import AuthContext from 'context/Auth.context';
import { useLogWatching } from 'hooks';
import styles from './chapter.module.scss';

const Replay = ({ url, onClick, urlList, chapter, day, courseName, index }) => {
  const { mutate, isLoading } = useLogWatching();
  const auth = useContext(AuthContext);
  let lecture = courseName[index].title;
  const onClickNthBtn = (v, i) => {
    onClick(v);

    let clickLog = `click.log - studentId:${
      auth.userId
    }, content:${chapter} - ${day}, ${i + 1}_class`;
    let userId = auth.userId;
    let lecture = courseName[index].title;

    let classCount = i + 1 + '_class';
    if (lecture.split('- ') === undefined) {
      lecture = lecture + ' - ' + chapter.chapterDay;
    }

    mutate({ clickLog, userId, lecture, classCount });
  };
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.videoModal}>
        <div onClick={() => onClick('')} className={styles.cancleBtn}>
          x
        </div>
        <iframe
          width="1050"
          height="610"
          src={`https://www.youtube.com/embed/${url}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        ></iframe>
        <div className={styles.btnWrapper}>
          {urlList?.map((v, i) => (
            <div
              onClick={() => onClickNthBtn(v, i)}
              className={v === url && styles.activeBtn}
            >
              {i + 1}교시
            </div>
          ))}
        </div>
      </div>

      <div className={styles.overlay}></div>
    </div>
  );
};

export default Replay;
