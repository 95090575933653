import React from 'react';

import styled from 'styled-components';

const ModalButtons = ({ onClose }) => {
  return (
    <Buttons>
      <button onClick={onClose}>닫기</button>
      <button type="submit">등록</button>
    </Buttons>
  );
};
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 20px;
    background-color: #fc9918;
    border: 1px solid #fc9918;
    padding: 7px;
    width: 100px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: #fc9918;
    }
    :nth-child(2) {
      background-color: #f14a16;
      border: 1px solid #f14a16;
      &:hover {
        background-color: white;
        color: #f14a16;
      }
    }
  }
`;

export default ModalButtons;
