import React from 'react';
import styled from 'styled-components';

const Badge = ({ size, color, children, ...rest }) => {
  return (
    <div {...rest}>
      <Type size={size} color={color}>
        {children}
      </Type>
    </div>
  );
};

const Type = styled.span`
  font-size: 0.7em !important;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 50rem;
  vertical-align: initial;
  color: #fff !important;
  padding: ${(props) =>
    props.size === 'medium'
      ? '4px 10px;'
      : props.size === 'small'
      ? '1px 8px;'
      : null};
  background-color: ${(props) => props.color};
`;

export default Badge;
