import axios from 'axios';

import { BASE_URL } from './config';

/* 유저 시험 응시 상태 조회 */
export const fetchUserExamStatus = async ({ userId }) => {
  return await axios.get(`${BASE_URL}/users/exam/taken/${userId}`);
};

/* 응시 과목 정보 조회 */
export const fetchExamInfo = async ({ userId }) => {
  return await axios.get(`${BASE_URL}/users/exam/started/${userId}`);
};
