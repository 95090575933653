import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactTooltip from 'react-tooltip';

import AuthContext from 'context/Auth.context';
import { useCounselingComments } from 'hooks';
import Header from '../../../components/header/Header';
import Navbar from '../../Navbar';
import { NameTag, Wrapper } from '../../style';
import Chart from './Chart';
import CounselingModal from '../modal';
import { Tr } from '../style';
import UserInfo from './UserInfo';

const Detail = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const auth = useContext(AuthContext);
  const { userId } = useParams();
  const { data: counselingData } = useCounselingComments(userId);

  useEffect(() => {
    if (counselingData) {
      setFilteredData(counselingData);
    }
  }, [counselingData]);

  const handleOpenModal = (value, type) => {
    setModalData(value);
    setModalType(type);
  };

  return (
    <>
      <Header data={{ title: '교육생 관리' }} />
      <Navbar title={'교육생 관리'} />
      <Wrapper>
        <UserInfo userId={userId} onClick={() => setModalType('write')} />
        <Chart userId={userId} />
        <div
          className="card"
          style={{ width: '100%', position: 'relative', marginTop: '80px' }}
        >
          <div className="card-body">
            <NameTag>학생 상담 일지</NameTag>
            {counselingData && (
              <table
                className="table"
                style={{ borderTop: 'none', marginTop: '20px' }}
              >
                <thead>
                  <Tr className="head">
                    <th scope="col">번호</th>
                    {[
                      '상담제목',
                      '상담내용 요약',
                      '상담자',
                      '상담일',
                      'More',
                    ].map((v, i) => (
                      <th
                        key={i}
                        scope="col"
                        style={
                          v === 'More'
                            ? { textAlign: 'center' }
                            : { textAlign: 'left' }
                        }
                      >
                        {v}
                      </th>
                    ))}
                  </Tr>
                </thead>
                <tbody>
                  {filteredData.length !== 0 ? (
                    filteredData.map((v, i) => {
                      const dataKeys = [
                        'title',
                        'content',
                        'authorName',
                        'regDate',
                        'More',
                      ];
                      return (
                        <Tr
                          title="userDetail"
                          key={i}
                          onClick={() => handleOpenModal(v, 'detail')}
                        >
                          <td>{i + 1 < 10 ? '0' + (i + 1) : i + 1}</td>
                          {dataKeys.map((item, idx) => {
                            let result = v[item];
                            if (item === 'regDate') {
                              result = result.split('T')[0];
                            }
                            if (item === 'More') {
                              result = (
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ReactTooltip />
                                  <div
                                    className="text-black cursor-type"
                                    data-tip="View Detail"
                                  >
                                    <i className="far fa-file-alt more-btn-icon-box-dark mr-1"></i>
                                  </div>
                                  {v.authorId === auth.userId && (
                                    <div
                                      className="text-black cursor-type"
                                      data-tip="Edit"
                                      title="editComment"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenModal(v, 'edit');
                                      }}
                                    >
                                      <i className="fas fa-pen more-btn-icon-box-dark mr-1"></i>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                            return <td key={'inner' + idx}>{result}</td>;
                          })}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <td
                        style={{ textAlign: 'center', cursor: 'default' }}
                        colSpan={6}
                      >
                        데이터가 없습니다.
                      </td>
                    </Tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Wrapper>
      {modalType && (
        <CounselingModal
          data={modalData}
          handleClose={() => {
            setModalType(null);
            setModalData(null);
          }}
          modalType={modalType}
        />
      )}
    </>
  );
};

export default Detail;
