import { useQuery } from 'react-query';
import { fetchLibraryDetail } from '../../apis/customer';

const useFetchLibraryDetail = ({ id }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchLibraryDetail', { id }],
    queryFn: async () => await fetchLibraryDetail({ id }),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!id,
  });
  return { ...queryReturns };
};

export default useFetchLibraryDetail;
