import React from 'react';
import styled, { css } from 'styled-components';

const CustomerBadge = ({
  text = '',
  backColor = '#007bff',
  size = 'default',
}) => {
  return (
    <Wrapper $backColor={backColor} $size={size}>
      <span>{text}</span>
    </Wrapper>
  );
};

const sizeStyles = {
  default: css`
    min-width: 80px;
  `,
  small: css`
    min-width: 50px;
  `,
};

const Wrapper = styled.div`
  text-align: center;

  span {
    display: inline-block;
    font-size: 11px;
    padding: 4px 7px;
    box-shadow: 1px 1px 2px #eee;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    background-color: ${(p) => p.$backColor};

    ${(p) => sizeStyles[p.$size] || sizeStyles.default}
  }
`;

export default CustomerBadge;
