import { useQuery } from 'react-query';

import { fetchExamInfo } from 'apis/capability';

/* 유저 역량평가 응시 상태 조회 hook */
const useFetchExamInfo = ({ userId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamInfo', { userId }],
    queryFn: async () => await fetchExamInfo({ userId }),
    select: (res) => res.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!userId,
  });
  return { ...queryReturns };
};

export default useFetchExamInfo;
