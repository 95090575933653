import React, { Fragment } from 'react';
import { withRouter } from 'react-router';

import { useReservation } from 'hooks';
import ConsultingList from './ConsultingList';

const Consulting = () => {
  const { adminReservationList } = useReservation({});
  const { data: list, isLoading } = adminReservationList || {};

  return (
    <Fragment>
      <div className="mypageContents">
        <ConsultingList list={list || []} isListLoading={isLoading} />
      </div>
    </Fragment>
  );
};

export default withRouter(Consulting);
