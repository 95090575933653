import { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchLibraryList } from 'apis/customer';

const useFetchLibraryList = ({
  pageNum,
  pageSize,
  searchOption,
  searchKeyword,
}) => {
  const [totalValue, setTotalValue] = useState(0);

  const queryReturns = useQuery({
    queryKey: ['fetchLibraryList', { pageNum, pageSize, searchKeyword }],
    queryFn: async () => {
      const response = await fetchLibraryList({
        pageNum,
        pageSize,
        searchOption,
        searchKeyword,
      });

      setTotalValue(response.data.data.total);

      return response;
    },
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
  });
  return { ...queryReturns, totalValue };
};

export default useFetchLibraryList;
