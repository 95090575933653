import React, { Fragment, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import AuthContext from 'context/Auth.context';
import { Badge } from '../components';
import JoinModal from './JoinModal';
import './detailStyle.css';

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;

  &:hover {
    background: rgba(240, 245, 249, 0.8);
    transition: all 0.2s ease;
  }
`;

const Contents = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;

  & > div {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    width: 15%;
  }

  & > div:nth-child(2) {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    width: 60%;
  }

  & > div:last-child {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    text-align: center;
  }
`;

const Btns = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 14px;
  }
`;

const Btn = styled.div`
  width: 78px;
  text-align: center;
  border: 1px solid
    ${(props) =>
      props.status === 'ON'
        ? props.memberStatus === 'Y'
          ? '#fc9918'
          : props.memberStatus === 'N'
          ? 'rgb(162, 210, 255)'
          : '#f14a16'
        : props.status === 'OFF'
        ? 'rgba(0,0,0,0.2)'
        : 'rgba(0,0,0,0.2)'};
  color: ${(props) =>
    props.status === 'ON'
      ? props.memberStatus === 'Y'
        ? '#fc9918'
        : props.memberStatus === 'N'
        ? 'rgb(162, 210, 255)'
        : '#f14a16'
      : props.status === 'OFF'
      ? 'rgba(0,0,0,0.2)'
      : 'rgba(0,0,0,0.2)'};
  ${(props) =>
    props.status === 'ON' &&
    css`
      cursor: pointer;

      &:hover {
        color: white;
        background-color: ${(props) =>
          props.memberStatus === 'Y'
            ? '#fc9918'
            : props.memberStatus === 'N'
            ? 'rgb(162, 210, 255)'
            : '#f14a16'};
      }
    `}
`;

const Name = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`;

const NameTags = styled.div`
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;

  div {
    font-size: 16px;
    width: 15%;
  }

  div:first-child {
    width: 5%;
  }

  div:nth-child(2) {
    width: 60%;
    text-align: center;
  }

  div:last-child {
    text-align: center;
  }
`;

const BtnTags = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
  }
`;

const SGroupList = ({ list, rerender, history, state }) => {
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(null);

  const openModal = (sGroupData) => {
    if (!auth.auth) return;
    setModal(sGroupData);
  };

  return (
    <Wrapper>
      <Name>
        <NameTags>
          <div>no</div>
          <div>그룹명</div>
          <div>참여인원</div>
          <div>그룹장</div>
          <div>개설일</div>
          <div>상태</div>
        </NameTags>
        <BtnTags>
          <div>More</div>
        </BtnTags>
      </Name>
      {list && list.length !== 0 ? (
        list.map((item, i) => (
          <Fragment key={i}>
            {item.memberStatus !== 'R' && (
              <Box key={item.id}>
                <>
                  <Contents>
                    <div style={{ width: '5%' }}>{i + 1}</div>
                    <div
                      style={{ textAlign: 'center' }}
                      data-for="tooltip"
                      data-tip={item.content}
                    >
                      {item.name}
                    </div>
                    <div
                      style={
                        item.memberCount < item.limitCount
                          ? { color: 'green' }
                          : { color: 'red' }
                      }
                    >
                      {item.memberCount}/{item.limitCount}
                    </div>
                    <div>{item.userName}</div>
                    <div>
                      {item.approvalDate
                        ? item.approvalDate.split('T')[0]
                        : '개설승인중'}
                    </div>
                    <div>
                      {item.status === 'ON' ? (
                        item.memberStatus === 'Y' ? (
                          <Badge color="#2bc155" size="medium">
                            참여중
                          </Badge>
                        ) : item.memberStatus === 'L' ? (
                          <Badge color="#2bc155" size="medium">
                            <i
                              className="fa-solid fa-crown"
                              style={{
                                marginBottom: '0',
                                color: '#ffd700',
                                top: '0',
                              }}
                            ></i>{' '}
                            참여중
                          </Badge>
                        ) : item.status === 'OFF' ||
                          item.memberCount === item.limitCount ? (
                          <Badge color="#3e4954" size="medium">
                            정원초과
                          </Badge>
                        ) : item.memberStatus === 'N' ? (
                          '-'
                        ) : item.memberStatus === 'W' ? (
                          <Badge color="#f35757" size="medium">
                            가입대기
                          </Badge>
                        ) : (
                          <Badge color="#f35757" size="medium">
                            블랙리스트
                          </Badge>
                        )
                      ) : (
                        <Badge color="#3e4954" size="medium">
                          개설승인중
                        </Badge>
                      )}
                    </div>
                  </Contents>
                  <ReactTooltip
                    id="tooltip"
                    getContent={(dataTip) => dataTip}
                    html
                    place="bottom"
                    backgroundColor={'gray'}
                  ></ReactTooltip>
                  <Btns>
                    <span
                      className="text-black cursor-type"
                      data-tip="View Detail"
                      onClick={() => {
                        openModal(item);
                      }}
                    >
                      <i
                        className="far fa-file-alt more-btn-icon-box-dark mr-1"
                        style={{ fontSize: '12px' }}
                      ></i>
                    </span>
                  </Btns>
                </>
              </Box>
            )}
          </Fragment>
        ))
      ) : (
        <Box>
          <div style={{ fontSize: '16px', textAlign: 'center', width: '100%' }}>
            등록된 스터디가 없습니다.
          </div>
        </Box>
      )}
      {modal && (
        <JoinModal
          state={state}
          rerender={rerender}
          sGroupData={modal}
          handleClose={() => setModal(false)}
        />
      )}
    </Wrapper>
  );
};

export default SGroupList;
