import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Typography, TextField, Grid } from '@material-ui/core';

import { Modal2 } from '../components';
import AuthContext from 'context/Auth.context';
import { useCheckAndChangeStudentPassword } from 'hooks';

const DefaultPwdChange = () => {
  const auth = useContext(AuthContext);
  const { userId } = auth;
  const localInitialPw = localStorage.getItem('initialPw');

  const { mutate: checkAndChangePassword } = useCheckAndChangeStudentPassword();

  const [valuesPass, setValuesPass] = useState({
    userId,
    password: '',
    newpassword: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    pwdError: '',
    confirmPwd: '',
    prevPwdError: '',
  });

  const isPwd = (pass) => {
    const pwdRegex =
      /^.*(?=.{8,15})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    return pwdRegex.test(pass);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };

  const onTextValidationPass = () => {
    let pwdError = '';
    let confirmPwd = '';
    let prevPwdError = '';

    if (!isPwd(valuesPass.newpassword))
      pwdError = '비밀번호 조건을 만족 할 수 없습니다.';
    if (!confirmPassword(valuesPass.newpassword, valuesPass.confirmPassword))
      confirmPwd = '비밀번호가 일치하지 않습니다.';
    if (valuesPass.userId === valuesPass.newpassword)
      pwdError = '아이디를 비밀번호로 사용 할 수 없습니다.';
    if (valuesPass.password === valuesPass.newpassword)
      prevPwdError = '이전과 동일한 비밀번호를 사용할 수 없습니다.';

    setError({
      pwdError,
      confirmPwd,
      prevPwdError,
    });

    return !(pwdError || confirmPwd || prevPwdError);
  };

  const handleChangeFormPass = (e) => {
    setValuesPass({ ...valuesPass, [e.target.name]: e.target.value });
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    const valid = onTextValidationPass();
    if (!valid) console.error('invalid');
    else {
      try {
        await checkAndChangePassword({
          userId,
          password: valuesPass.password,
          newPassword: valuesPass.newpassword,
        });
      } catch (error) {
        setValuesPass({ password: '', newpassword: '', confirmPassword: '' });
      }
    }
  };

  return (
    <Modal2 open={localInitialPw === 'true'} size="medium" maxHeight={'95'}>
      <ModalInner>
        <ToolBar>앞으로 사용하실 비밀번호를 변경해주세요.</ToolBar>
        <Typography component={'span'}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmitPassword}>
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                autoFocus
                autoComplete=""
                fullWidth
                label="기존 비밀번호를 입력하세요."
                name="password"
                type="password"
                value={valuesPass.password}
                onChange={handleChangeFormPass}
                style={{ marginBottom: '30px' }}
              />
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                autoComplete=""
                fullWidth
                helperText="영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요."
                label="신규 비밀번호를 입력하세요."
                name="newpassword"
                type="password"
                value={valuesPass.newpassword}
                onChange={handleChangeFormPass}
                style={{ marginBottom: '-10px' }}
              />
              {(error.pwdError || error.prevPwdError) && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    margin: '7px 0 10px 15px',
                    fontFamily: 'Noto Sans KR',
                  }}
                >
                  {error.pwdError}
                  {error.prevPwdError}
                </div>
              )}
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                autoComplete=""
                fullWidth
                label="비밀번호를 다시한번 입력하세요."
                name="confirmPassword"
                type="password"
                value={valuesPass.confirmPassword}
                onChange={handleChangeFormPass}
              />
              {error.confirmPwd && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    margin: '-5px 0 10px 15px',
                    fontFamily: 'Noto Sans KR',
                  }}
                >
                  {error.confirmPwd}
                </div>
              )}
              <StyledButton type="submit">비밀번호 변경</StyledButton>
            </form>
          </Grid>
        </Typography>
      </ModalInner>
    </Modal2>
  );
};

const ModalInner = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ToolBar = styled.h3`
  box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 20px;
  font-size: 18px;
  line-height: 30px;
  background-color: #999;
  color: #fff;
`;

const StyledTextField = styled(TextField)`
  & label {
    font-size: 1.4em !important;
    line-height: 0.75;

    &.Mui-focused {
      color: #ec7422;
    }
  }

  & .MuiOutlinedInput-root {
    > fieldset {
      font-size: 1.4em !important;
    }
    &:hover fieldset {
      border-color: #ec7422;
    }
    &.Mui-focused fieldset {
      border-color: #ec7422;
    }
  }

  .MuiFormHelperText-root {
    font-size: 12px !important;
  }
`;

const StyledButton = styled.button`
  display: block;
  width: auto;
  padding: 10px 30px;
  margin: 20px auto 10px;
  cursor: pointer;
  background-color: #ec7422;
  font-size: 14px;
  color: #fff;
  border-radius: 15px;
  border: none;
  transition: 0.2s;

  &:hover {
    background-color: #e56a17;
  }
`;

export default withRouter(DefaultPwdChange);
