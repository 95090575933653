import { useMutation, useQueryClient } from 'react-query';

import { deleteClassComment } from 'apis/classBoard';

const useRemoveClassComment = ({ type, id }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ commentId }) => {
      if (type !== 'task') {
        return await deleteClassComment({ id, commentId });
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveClassComment;
