import React, { useContext, useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import TestInfo from './test/TestInfo';
import TestPage from './test/TestPage';
import TestResult from './test/TestResult';
import CapabilityTestDetail from './test/capabilityTestDetail';

const Capability = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push('/login');
    }
  }, [auth]);

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/test`} render={() => <TestInfo />} />
        <Route
          exact
          path={`${match.path}/test-result`}
          render={() => <TestResult />}
        />
        <Route
          exact
          path={`${match.path}/test-result/detail`}
          render={() => <CapabilityTestDetail />}
        />
        <Route
          exact
          path={`${match.path}/test/testpage`}
          render={() => <TestPage />}
        />
      </Switch>
    </>
  );
};

export default Capability;
