import { useMutation, useQueryClient } from 'react-query';

import { editClassPost, createPost } from 'apis/classBoard';
import { BOARD_CATEGORY } from 'constant/class';

const useCreateEditClassBoard = ({ id, type }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      if (id) {
        /* id 값이 있을 때는 클래스 게시판 글 수정 */
        return await editClassPost({ id, formData });
      } else {
        /* id 값이 없을 때는 클래스 게시판 글 생성 */
        return await createPost({ formData, category: BOARD_CATEGORY[type] });
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassList', 'fetchClassDetail', { type, id }],
      }),
  });

  return { ...queryReturns };
};

export default useCreateEditClassBoard;
