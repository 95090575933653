import { useQuery } from 'react-query';
import swal from 'sweetalert';

import {
  fetchNoticeAndLibraryAndQnaDetail,
  fetchTaskDetail,
} from 'apis/classBoard';
import { useHistory } from 'react-router-dom';

const noticeToast = async (text) => {
  await swal({
    title: text,
    icon: 'warning',
    buttons: '확인',
    dangerMode: true,
  });
};

const useFetchClassDetail = ({ type, id }) => {
  const history = useHistory();

  const queryReturns = useQuery({
    queryKey: ['fetchClassDetail', { type, id }],
    queryFn: async () => {
      try {
        if (type !== 'task') {
          const response = await fetchNoticeAndLibraryAndQnaDetail({ id });
          return response.data.data;
        }

        if (type === 'task') {
          const response = await fetchTaskDetail({ id });
          return response.data;
        }
      } catch (error) {
        await noticeToast('잘못된 요청입니다.');
        history.push(`/myclass/board/${type}`);
      }
    },
    retry: 2,
    retryDelay: 1000,
    enabled: !!id,
  });

  return { ...queryReturns };
};

export default useFetchClassDetail;
