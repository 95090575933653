import { useMutation, useQueryClient } from 'react-query';

import { deleteGreetingComment } from 'apis/customer';

const useRemoveGreetingComment = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ postId, replyId }) => {
      return await deleteGreetingComment({ postId, replyId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchGreetingDetail'],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveGreetingComment;
