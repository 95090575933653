import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import { useFetchPblProblemDetailHook } from 'hooks';
import TextEditorWithImage from 'components/elements/TextEditor/TextEditorWithImage';

const PblDetailEdit = () => {
  const history = useHistory();
  const params = useParams();
  const { unitId, problemId } = params;
  const auth = useContext(AuthContext);
  const titleRef = useRef(null);
  const fileRef = useRef(null);

  const { data } = useFetchPblProblemDetailHook({ unitId, problemId });

  const [textValue, setTextValue] = useState('');
  const [contentImageFile, setContentImageFile] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState();

  useEffect(() => {
    if (!data) return;

    function replaceImageSources(htmlString, newSources) {
      const regex = /<img src="[^"]*"/g;

      return htmlString.replace(regex, () => {
        const newSource = newSources.shift();
        return `<img src="${process.env.REACT_APP_IP}/pbl-file/${problemId}/image/${newSource}"`;
      });
    }

    const newHtmlString = replaceImageSources(data.content, data.imgList);

    if (data) {
      setTextValue(newHtmlString);
      titleRef.current.value = data.title;
    }
  }, [data?.content]);

  useEffect(() => {
    if (auth.type === '1') {
      toast.error(Message.SERVICE_TEACHER_ONLY);
      history.push('/myclass/');
    }
  }, []);

  const handleUpdate = () => {
    if (window.confirm('문제를 수정하시겠습니까?')) {
    }
  };

  const handleInputChange = (e) => {
    if (fileRef.current.files[0]) {
      if (fileRef.current.files[0].size > 1024 * 1024 * 50) {
        alert('50메가 이하의 파일만 업로드할 수 있습니다.');
        fileRef.current.value = '';
        setSelectedFileName('');
      } else {
        setSelectedFileName(fileRef.current.files[0].name);
      }
    }
  };
  const handleUploadClick = () => {
    fileRef.current.click();
  };

  return (
    <Wrapper>
      <Title>PBL 문제 수정</Title>
      <div style={{ width: '100%' }}>
        <InputBox>
          <div>제목</div>
          <input ref={titleRef} type="text" placeholder="제목을 입력하세요." />
        </InputBox>

        <QuillBox>
          <span>내용</span>
          <div>
            <TextEditorWithImage
              htmlStr={textValue}
              setHtmlStr={setTextValue}
              imageFile={contentImageFile}
              setImageFile={setContentImageFile}
            />
          </div>
        </QuillBox>

        <InputBox>
          <div>첨부파일</div>
          <input
            style={{ display: 'none' }}
            ref={fileRef}
            type="file"
            name="task"
            id="task"
            accept=".zip,.7zip,application/pdf,.ppt,.pptx,.pptx,.doc,.docx,image/*"
            onChange={handleInputChange}
          />
          <FileBox>
            {selectedFileName ? (
              <FileName onClick={handleUploadClick}>
                <i className="fas fa-paperclip"></i>
                {selectedFileName}
              </FileName>
            ) : (
              <UploadBtn onClick={handleUploadClick}>
                <i className="fas fa-paperclip"></i>
                <span>파일첨부</span>
              </UploadBtn>
            )}
          </FileBox>
        </InputBox>
      </div>
      <BtnBox>
        <Link to={'/myclass/pbl'}>취소</Link>
        <button onClick={handleUpdate} type="button">
          문제 수정
        </button>
      </BtnBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-bottom: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
  border-radius: 10px;
`;

const InputBox = styled.div`
  width: 103%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;

  div {
    width: 100px;
    font-size: 16px;

    div {
      width: 100%;
    }
  }

  input {
    font-size: 14px;
    width: 85%;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
      font-size: 16px;
    }
  }
`;

const QuillBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0 50px;

  > span {
    width: 100px;
    font-size: 16px;
  }

  > div {
    width: calc(100% - 110px);
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const BtnBox = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  button {
    cursor: pointer;
    width: 100px;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    background-color: #fc9918;
    color: white;
    border: 1px solid #fc9918;

    &:focus {
      outline: none;
    }
  }

  a {
    text-align: center;
    cursor: pointer;
    width: 100px;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    color: rgba(36, 12, 12, 0.7);
    border: 1px solid rgba(171, 122, 122, 0.2);
  }
`;

const FileName = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  width: 100%;

  i {
    margin-right: 5px;
  }
`;

const FileBox = styled.span`
  width: 85%;
`;

const UploadBtn = styled.div`
  padding: 10px;
  width: 120px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
`;

export default PblDetailEdit;
