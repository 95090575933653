import React from 'react';
import { useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

import ClassBoardDetail from './ClassBoardDetail';
import TaskBoardDetail from './TaskBoardDetail';

const BoardDetail = () => {
  const { type } = useParams();

  return type !== 'task' ? <ClassBoardDetail /> : <TaskBoardDetail />;
};

export default BoardDetail;
