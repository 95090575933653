import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import AuthContext from 'context/Auth.context';
import CustomerBadge from './CustomerBadge';
import { useRemoveInquiry } from 'hooks';
import {
  confirmSwal,
  errorMessage,
  successMessage,
  warningMessage,
} from 'utilities/swal';

const CustomerList = ({
  list = [],
  categories = [],
  boardType = '',
  isListLoading = false,
  isQuiryBoard = false,
}) => {
  const auth = useContext(AuthContext);

  const history = useHistory();

  const { mutateAsync: removeInquiry } = useRemoveInquiry();

  const findThemeColor = (categoryName) => {
    if (!isQuiryBoard) return;
    return categories?.find((e) => e.categoryName === categoryName)?.themeColor;
  };

  const handleGoToDetailPage = (list) => {
    if (isQuiryBoard) {
      if (auth.type !== '1') {
        history.push(`/customer/${boardType}/${list.id}`);
        return;
      }

      if (auth.userId !== list.userId) {
        warningMessage({ text: '비밀글입니다.' });
        return;
      }

      history.push(`/customer/${boardType}/${list.id}`);
    } else {
      history.push(`/customer/${boardType}/${list.id}`);
    }
  };

  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '해당 문의글을 삭제 하시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      const { status } = await removeInquiry(
        { id },
        {
          onError: (error) => {
            if (
              error.response &&
              error.response.status >= 400 &&
              error.response.status < 500
            ) {
              errorMessage({ text: '게시글 삭제 중에 문제가 발생하였습니다.' });
            }
          },
        }
      );
      if (status === 204) {
        successMessage({ text: '문의글이 삭제되었습니다.' });
        history.push('/customer/contact');
      }
    }
  };

  const checkPermisson = (list) => {
    if (!isQuiryBoard) return true;

    const { type, userId } = auth;
    if (type === '1') {
      return list.userId === userId;
    } else {
      return true;
    }
  };

  return (
    <>
      {isListLoading ? (
        Array(5)
          .fill('')
          .map((_, index) => (
            <ListSkeleton key={index}>
              <div />
              <div />
            </ListSkeleton>
          ))
      ) : (
        <StyledBoardList>
          {list.map((list, index) => {
            return (
              <tbody key={index}>
                <tr>
                  {isQuiryBoard && (
                    <td className="badge">
                      <CustomerBadge
                        text={list.categoryName}
                        backColor={findThemeColor(list.categoryName)}
                      />
                    </td>
                  )}
                  <td
                    className={`title ${!isQuiryBoard ? 'more-padding' : ''}`}
                    onClick={() => handleGoToDetailPage(list)}
                  >
                    <span>
                      {!checkPermisson(list) && (
                        <img src="/icons/pw_icon.png" alt="lock icon" />
                      )}
                      <span>
                        {list.title}
                        {!!list.replyCount && list.replyCount !== 0 && (
                          <small>[{list.replyCount}]</small>
                        )}
                        {isQuiryBoard && list.commentCount !== 0 && (
                          <small>[{list.commentCount}]</small>
                        )}
                      </span>
                    </span>
                  </td>
                  {['library', 'contact'].includes(boardType) &&
                    !!list.attachedFile && (
                      <td
                        className="attached"
                        onClick={() => handleGoToDetailPage(list)}
                      >
                        <span>
                          <img src="/icons/appendix.png" alt="appendix icon" />
                        </span>
                      </td>
                    )}
                  <td
                    className="author"
                    onClick={() => handleGoToDetailPage(list)}
                  >
                    <span>{list.author}</span>
                  </td>
                  <td
                    className="date"
                    onClick={() => handleGoToDetailPage(list)}
                  >
                    <span>{list.createdAt.slice(0, 10)}</span>
                    <span>{list.createdAt.slice(11, 16)}</span>
                  </td>
                  {isQuiryBoard ? (
                    <td
                      className="reply"
                      onClick={() => handleGoToDetailPage(list)}
                    >
                      {list.adminComment ? (
                        <StateBox>
                          <span>답변 완료</span>
                        </StateBox>
                      ) : (
                        <StateBox>
                          <span className="wait">답변 대기중</span>
                        </StateBox>
                      )}
                    </td>
                  ) : (
                    <td
                      className="reply"
                      onClick={() => handleGoToDetailPage(list)}
                    >
                      <span>
                        <i className="fa-solid fa-eye"></i>
                        {list.hitCount || 0}
                      </span>
                    </td>
                  )}
                  {isQuiryBoard && auth.type !== '1' && (
                    <td
                      className="delete"
                      onClick={() => handleDelete(list.id)}
                    >
                      <i className="fa-solid fa-trash-can" />
                    </td>
                  )}
                </tr>
              </tbody>
            );
          })}

          {list.length === 0 && (
            <NoResult>
              <tr>
                <td>등록된 게시글이 없습니다.</td>
              </tr>
            </NoResult>
          )}
        </StyledBoardList>
      )}
    </>
  );
};

const ListSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &:first-child {
    border-top: 2px solid #000;
  }

  > div {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;

    &:first-child {
      background-color: #f8f8f8;
    }
  }
`;

const StyledBoardList = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-top: 2px solid #000;
  font-size: 16px;
  font-weight: bold;

  > tbody {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #333;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    text-align: center;

    &:nth-child(2n-1) {
      background-color: #f8f8f8;
    }

    &:hover {
      background-color: #e3e3e3;
    }

    > tr {
      width: 100%;
      color: #333;
      transition: 0.2s;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #eee;

      > td {
        flex: 1;
        font-size: 12px;
        height: 100%;

        > span {
          display: inline-block;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          color: #777;
          font-size: 12px;
        }
      }
    }

    td.title {
      display: flex;
      align-items: center;
      text-align: left;
      padding-left: 10px;
      overflow: hidden;
      font-size: 15px;
      max-width: 670px;

      &.more-padding {
        padding-left: 30px;
      }

      > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        small {
          color: #777;
          margin-left: 5px;
        }
      }

      img {
        max-width: 15px;
        vertical-align: middle;
        margin-right: 2px;
        padding-bottom: 2px;
      }
    }

    td.badge {
      display: grid;
      place-items: center;
      max-width: 120px;
    }

    td.author,
    td.date,
    td.reply {
      max-width: 110px;
      font-weight: 400;
      color: #777;
    }

    td.reply {
      max-width: 90px;
    }

    td.author {
      max-width: 90px;
    }

    td.attached {
      max-width: 40px;
    }

    td.delete {
      max-width: 20px;
      background-color: #f56767;
      color: #fff;
      height: 99%;

      &:hover {
        background-color: #fa3535;
      }
    }

    td.date {
      display: flex;
      justify-content: center;
      column-gap: 4px;
      height: 100%;
      text-align: center;
    }
  }
`;

const StateBox = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 12px;

  span {
    padding: 3px 6px;
    background-color: #28a745;
    border-radius: 5px;

    &.wait {
      background-color: #c0c0c0;
    }
  }
`;

const NoResult = styled.tbody`
  width: 100% !important;
  text-align: center !important;
  background-color: transparent !important;
  min-height: 300px !important;
  cursor: default !important;

  td {
    display: grid !important;
    place-items: center !important;
    color: #ec7422 !important;
    font-size: 1.5rem !important;
  }
`;

export default CustomerList;
