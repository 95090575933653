import React from 'react';

import THEME from 'state/theme';
import { useCustomState } from 'state/state';
import Layout from '../Layout/Layout';
import { Logo, Link } from '../../elements';
import styles from './Footer.module.scss';

const Footer = () => {
  const state = useCustomState()[0];

  // 하단 Services 링크 출력
  const servicesList = state.data.footerMenu
    .map((item, index) => (
      <Link key={index} url={item.url} hoverStyle={{ color: THEME.color }}>
        <i className="las la-angle-right" />
        <span className="secomin secodgray">{item.title}</span>
      </Link>
    ))
    .slice(0, 5);

  // 하단 Links 링크 출력
  const fastLinkList = state.data.fast_links.map((item, index) => (
    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
      <i className="las la-angle-right" />
      <span className="secomin secodgray">{item.title}</span>
    </a>
  ));
  return (
    <Layout col="1" padding style={{ background: '#e6e6e6', padding: '10px' }}>
      <div className={styles.footer}>
        <div className={styles.logo_area}>
          <Logo dark />
          <p className="secomin secogray">
            사업자등록번호 : 120 - 86 - 07747
            <br />
            주소 : 경기도 성남시 분당구 판교로 227번길 23, 4&5층
          </p>
          <div className={styles.social}>
            <a target="_blank" href="https://www.facebook.com/skshieldus">
              <i className="lab la-facebook-f" />
            </a>

            <a target="_blank" href="https://www.youtube.com/user/ADTKorea">
              <i className="fab fa-youtube"></i>
            </a>

            <a target="_blank" href="https://www.instagram.com/skshieldus/">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className={styles.link_area}>
          <div className={styles.links}>
            <h4 className="secodgray">Services</h4>
            {servicesList}
          </div>
          <div className={styles.links}>
            <h4 className="secodgray">Links</h4>
            {fastLinkList}
          </div>
        </div>
        <div className={styles.subscribe}>
          <h4 className="secodgray">교육운영사무국</h4>
          <div>
            <span className="secomin secodgray">
              전화번호 : 031 - 5180 - 5256
            </span>
            <span className="secomin secodgray">
              운영시간 : 09 : 00 ~ 18 : 00
            </span>
            <span className="secomin secodgray">이메일 : sk@openeg.co.kr</span>
            <span className="secomin secodgray">
              팩스번호 : 0508 - 927 - 4955
            </span>
          </div>
          <p>
            Copyright &copy; 2023. SK SHIELDUS ROOKIES. All rights reserved.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Footer;
