import React, { useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({
  htmlStr,
  setHtmlStr,
  placeholder = '내용을 입력하세요.',
}) => {
  const quillRef = useRef(null);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          [{ align: [] }, { color: [] }, { background: [] }],
          // ["link"],
          ['clean'],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
      keyboard: {
        bindings: {
          'list autofill': {
            prefix: /^\s{0,}(?![1])(\.|-|\*|\[ ?\]|\[x\])$/,
          },
        },
      },
    }),
    []
  );

  /* 에디터 전체 클릭 시 바로 입력창에 focus */
  useEffect(() => {
    const containerElement = document.querySelector('.ql-container');

    const handleClick = (event) => {
      if (event.target.classList.contains('ql-container')) {
        const editorElement = document.querySelector('.ql-editor');
        if (editorElement && editorElement.focus) {
          editorElement.focus();
        }
      }
    };

    containerElement.addEventListener('click', handleClick);

    return () => {
      containerElement.removeEventListener('click', handleClick);
    };
  }, []);

  const formats = [
    'size',
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'align',
    'color',
    'background',
    // "link",
  ];

  return (
    <CustomReactQuill
      ref={quillRef}
      theme="snow"
      modules={modules}
      formats={formats}
      value={htmlStr}
      placeholder={placeholder}
      onChange={(content, delta, source, editor) =>
        setHtmlStr(editor.getHTML())
      }
    />
  );
};

const CustomReactQuill = styled(ReactQuill)`
  height: 400px;
  padding-bottom: 50px;
  white-space: pre-wrap;

  .steperText h3,
  p {
    color: #212529 !important;
    font-size: 1.6rem !important;
  }

  li {
    color: #212529 !important;
    font-size: 1.6rem !important;
  }

  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0.7rem !important;

    > li {
      padding-left: 0.8rem !important;
    }
  }

  .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 1em !important;
  }
  .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 2em !important;
  }
  .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 3em !important;
  }
  .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 4em !important;
  }
  .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 5em !important;
  }
  .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 6em !important;
  }
  .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 7em !important;
  }
  .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 8em !important;
  }
`;

export default TextEditor;
