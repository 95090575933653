import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import TestMultipleChoices from './TestMultipleChoices';
import TestOx from './TestOx';
import TestChoice from './TestChoice';
import TestShortAnswer from './TestShortAnswer';
import AuthContext from '../../../context/Auth.context';
import Message from 'utilities/Message';
import { handleErrorMessage } from 'utilities';

const TestQuestion = (props) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { userId } = auth;

  const [testQuestions, setTestQuestions] = useState([]);
  const [answer, setAnswer] = useState();
  const [idx, setIdx] = useState();

  useEffect(() => {
    setAnswer('');
  }, [props.idx]);

  const setCheckAnswer = (param) => {
    if (param !== '') {
      setAnswer(param);
    }
    if (answer === param && idx !== props.problemId) {
      props.saveAnswerHandler(answer);
    }
  };

  const onClickEnterKey = () => {
    props.onClickEnterKey();
  };

  const fetchQuestion = async () => {
    const url = `${process.env.REACT_APP_IP}/users/exam/${userId}/${props.problemId}`;
    const result = await axios
      .get(url)
      .then((response) => {
        if (response.data !== null) {
          setTestQuestions(response.data);
        } else {
          if (response.status === 400) {
            toast.error(Message.ERROR_500);
            // history.push("/capability/test");
            history.push('/capability/test');
          }
        }
      })
      .catch(handleErrorMessage);
  };

  useEffect(() => {
    if (!auth.auth) return;
    props.problemId && fetchQuestion();
    setIdx(props.idx);
  }, [props.problemId]);

  useEffect(() => {
    if (!auth.auth) return;
    props.saveAnswerHandler(answer);
  }, [answer]);

  return (
    <div className="questions">
      <div className="capaQuestionClass">
        <p>
          {testQuestions.categoryName}&nbsp;/&nbsp;{testQuestions.unitTitle}
        </p>
      </div>
      <div className="capaQuestion">
        <p>
          {testQuestions.examQuestion &&
            testQuestions.examQuestion.split('\\n').map((line, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
        </p>
      </div>
      {testQuestions.examQuestionContent && (
        <pre className="capaQuestionContent">
          {testQuestions.examQuestionContent.split('\\n').map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          })}
        </pre>
      )}
      {!testQuestions.examImgPath1 ? null : (
        <div className="capaTestImage1">
          <img
            src={`${process.env.REACT_APP_IP}/examimage/${testQuestions.examProblemId}/${testQuestions.examImgPath1}`}
            alt="문제이미지1"
          />
        </div>
      )}
      {!testQuestions.examImgPath2 ? null : (
        <div className="capaTestImage2">
          <img
            src={`${process.env.REACT_APP_IP}/examimage/${testQuestions.examProblemId}/${testQuestions.examImgPath2}`}
            alt="문제이미지2"
          />
        </div>
      )}
      <div className="capaTestAnswer">
        {(() => {
          // eslint-disable-next-line default-case

          switch (testQuestions.examType) {
            case 0:
              return (
                <TestChoice
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  testOptions={testQuestions.examOptions}
                />
              );
            case 1:
              return (
                <TestMultipleChoices
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  testOptions={testQuestions.examOptions}
                />
              );
            case 2:
              return <TestOx setCheckAnswer={setCheckAnswer} idx={idx} />;
            case 3:
              return (
                <TestShortAnswer
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  handleNextButton={onClickEnterKey}
                  answerCount={testQuestions.answerCount}
                />
              );
          }
        })()}
      </div>
    </div>
  );
};

export default TestQuestion;
