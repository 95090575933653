import React from 'react';
import { Link } from 'react-router-dom';
import { NavWrapper, NavList, NavItem } from './style';

const Navbar = ({ title }) => {
  return (
    <NavWrapper>
      <NavList>
        <Link to={'/teacher/students'}>
          <NavItem selected={title === '교육생 관리'}>교육생 관리</NavItem>
        </Link>
        <Link to={'/teacher/task'}>
          <NavItem selected={title === '과제 관리'}>과제 관리</NavItem>
        </Link>
        <Link to={'/teacher/test'}>
          <NavItem selected={title === '시험 관리'}>시험 관리</NavItem>
        </Link>
        <Link to={'/teacher/unit-detail'}>
          <NavItem selected={title === '목차 상세 작성'}>
            목차 상세 작성
          </NavItem>
        </Link>
        <Link to={'/teacher/rewatch'}>
          <NavItem selected={title === '다시보기 관리'}>다시보기 관리</NavItem>
        </Link>
      </NavList>
    </NavWrapper>
  );
};

export default Navbar;
