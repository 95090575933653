import { useMutation, useQueryClient } from 'react-query';

import { editTeacherComment } from 'apis/pbl';

const useEditTeacherComment = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ pblCommentId, comment }) => {
      return await editTeacherComment({ pblCommentId, comment });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudentCommentList'],
      }),
  });
  return { ...queryReturns };
};

export default useEditTeacherComment;
