import { useMutation, useQueryClient } from 'react-query';

import { editGreetingPost } from '../../apis/customer';

const useEditGreetingPost = ({ id, categoryId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ title, content }) => {
      return await editGreetingPost({
        id,
        categoryId,
        title,
        content,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchGreetingDetail'] }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchGreetingDetail'] }),
  });

  return { ...queryReturns };
};

export default useEditGreetingPost;
