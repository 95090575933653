import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import AuthContext from 'context/Auth.context';
import Header1 from 'modules/components/header/Header';
import MyPageSideBar from '../Mypage/MyPageSideBar';
import AccountInfo from './AccountInfo';
import styles from './MypageMyInformation.module.scss';
import '../MyPage.css';

const MypageMyInformation = ({ sidebar = 'left' }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [active, setActive] = useState(2);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    window.addEventListener('resize', resizeListener);
    return window.removeEventListener('resize', resizeListener);
  }, [height]);

  const resizeListener = () => {
    setHeight(ref.current.clientHeight);
  };

  return (
    <>
      <Header1 data={{ title: '내 정보 관리' }} />

      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === 'left' && styles.with_sidebar + ' ' + styles.left,
          ].join(' ')}
        >
          <MyPageSideBar active={active} setActive={setActive} />
          <article className="mypage">
            <div>
              <AccountInfo />
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default MypageMyInformation;
