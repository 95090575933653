import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useParams, withRouter } from 'react-router';

import AuthContext from 'context/Auth.context';
import Chapter from './Chapter';
import styles from './chapter.module.scss';
import Replay from './Replay';
import { handleErrorMessage } from 'utilities';

function Chapters({ getZoomUrl, getTitle }) {
  const [replayUrl, setReplayUrl] = useState('');
  const [urlList, setUrlList] = useState([]);
  let params = useParams();
  let unitId = params.id;

  const auth = useContext(AuthContext);
  const [chapter, setChapter] = useState();
  const [ndDay, setNdDay] = useState();
  const [index, setIndex] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/myclass/units/${unitId}`;
    Axios.get(url)
      .then((response) => {
        getTitle(response.data.data.title || '');
        setChapter(response.data.data);
        getZoomUrl(response.data.data.zoomUrl);
        setCurrentTime(new Date(response.data.timestamp));
      })
      .catch(handleErrorMessage);
  }, [isUpdated]);

  const getStatus = (startDate) => {
    const start = new Date(startDate);
    if (start.toDateString() === currentTime.toDateString()) {
      return 'y';
    } else if (start.toDateString() < currentTime.toDateString()) {
      return 'd';
    } else {
      return 'n';
    }
  };

  const handleOpenReplay = (url) => {
    const list = url.split(',');
    setReplayUrl(list[0]);
    setUrlList(list);
  };

  const handleUrlChange = (url) => {
    setReplayUrl(url);
    if (!url) {
      setUrlList([]);
    }
  };

  const dayFunction = (ndDay) => {
    setNdDay(ndDay);
  };
  const indexFunction = (index) => {
    setIndex(index);
  };
  return (
    <div className={styles.listWrapper}>
      {chapter?.chapterDtoList?.map((item, i) => (
        <Chapter
          onReplayModal={handleOpenReplay}
          key={i}
          title={chapter.title}
          chapterDay={item.chapterDay}
          chapter={item}
          index={i}
          status={getStatus(item.chapterDate)}
          dayFunction={dayFunction}
          indexFunction={indexFunction}
          setIsUpdated={setIsUpdated}
          currentTime={currentTime}
        />
      ))}

      {replayUrl && (
        <Replay
          courseName={chapter?.chapterDtoList}
          chapter={chapter.title}
          day={ndDay}
          onClick={handleUrlChange}
          url={replayUrl}
          urlList={urlList}
          index={index}
        />
      )}
    </div>
  );
}

export default withRouter(Chapters);
