import React from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import './Board.css';

const BoardMenu = ({ showMenu }) => {
  const history = useHistory();

  const info = document.location.href.split('/');
  let sTitle = info[5];
  if (!sTitle) {
    sTitle = '';
  }

  let subTitle = '';
  if (sTitle.indexOf('task') >= 0) {
    subTitle = 'Task';
  } else if (sTitle.indexOf('qna') >= 0) {
    subTitle = 'QNA';
  } else if (sTitle.indexOf('library') >= 0) {
    subTitle = 'library';
  } else if (sTitle.indexOf('notice') >= 0) {
    subTitle = 'notice';
  }

  const handleClickMenu = (e, path) => {
    e.preventDefault();
    history.push(path);
  };

  return (
    <Container showMenu={showMenu}>
      <ul>
        <li
          className={subTitle === 'QNA' ? 'mypageLeftsideActive' : ''}
          onClick={(e) => handleClickMenu(e, '/myclass/board/qna')}
        >
          <div>
            <span>
              <b>강의질문</b>
            </span>
          </div>
        </li>
        <li
          className={subTitle === 'library' ? 'mypageLeftsideActive' : ''}
          onClick={(e) => handleClickMenu(e, '/myclass/board/library')}
        >
          <div>
            <span>
              <b>학습자료</b>
            </span>
          </div>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  opacity: 0;
  position: absolute;
  top: 50px;
  flex-direction: row;
  animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  padding: 20px;
  z-index: 10;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08);

    li {
      width: 100%;
    }

    li > div {
      color: rgba(0, 0, 0, 0.6);
      word-break: keep-all;
      text-align: center;
      padding: 5px 20px;
      font-family: inherit;

      &:last-child {
        margin-right: 0;
      }

      > span {
        padding: 0 10px;

        &:hover {
          color: #ec7422;
        }

        b {
          font-weight: inherit;
        }
      }
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(-30px);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: translateY(-8px);
      opacity: 1;
    }
  }
`;

export default withRouter(BoardMenu);
