import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

import AuthContext from 'context/Auth.context';
import { useCreateEditClassBoard, useFetchClassDetail } from 'hooks';
import { confirmSwal, errorMessage, successMessage } from 'utilities/swal';
import { BOARD_TITLE } from 'constant/class';
import { TextEditorWithImage } from 'components/elements';
import ClassBoardWriteButton from 'components/elements/ClassBoardWriteButton';
import { ClassWrapper, StyledError, WriteContentBox } from './ClassBoardCommon';
import ClassBoardAppendixFile from './ClassBoardAppendixFile';

const ClassBoardWrite = () => {
  const { type, id } = useParams();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const TITLE_MAX_LENGTH = 70;
  const CONTENT_MAX_LENGTH = 5800;

  const [deleteAttachments, setDeleteAttachments] = useState([]);
  const [deleteImgs, setDeleteImgs] = useState([]);
  const [textValue, setTextValue] = useState('');
  const [files, setFiles] = useState([]);

  const [imagesNames, setImagesNames] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imgList, setImgList] = useState([]);

  const { mutateAsync } = useCreateEditClassBoard({
    type,
    id,
  });

  const { data: detailData, isLoading: isDetailLoading } = useFetchClassDetail({
    type,
    id,
  });
  const { attachments, lcPost } = detailData || {};

  useEffect(() => {
    if (!detailData) return;
    if (id && detailData) {
      setValue('title', lcPost.title);
      setTextValue(lcPost.content);
      setFiles(attachments.map((e) => e));
      setImgList(lcPost.images.map((e) => e.storedName));
    }
  }, [id, isDetailLoading]);

  const submit = async (form) => {
    if (isImageLoading) return;
    const matchedCategory = {
      notice: '공지사항',
      qna: '강의질문',
      library: '학습자료',
    };

    const confirm = await confirmSwal({
      title: `${BOARD_TITLE[type]}을(를) ${id ? '수정' : '등록'} 하시겠습니까?`,
      confirmButton: '등록',
    });

    if (confirm) {
      let parsedTextValue = textValue
        .replaceAll('<p>', '')
        .replaceAll('</p>', '');
      const hasOnlyBr = /^<br>(<br>)*$/.test(parsedTextValue);
      if (
        textValue.trim() === '' ||
        hasOnlyBr ||
        parsedTextValue.trim() === ''
      ) {
        errorMessage({ text: '본문 내용을 입력해주세요.' });
        return;
      }

      const frm = new FormData();

      const data = id
        ? {
            category: matchedCategory[type],
            title: form.title,
            content: textValue,
            images: imagesNames,
            deleteImages: deleteImgs,
            deleteAttachments,
          }
        : {
            category: matchedCategory[type],
            title: form.title,
            content: textValue,
            images: imagesNames,
          };

      frm.append(
        'form',
        new Blob([JSON.stringify(data)], {
          type: 'application/json',
        })
      );

      if (files.length !== 0) {
        files.forEach((e) => {
          frm.append('files', e);
        });
      }

      try {
        const response = await mutateAsync({ formData: frm });
        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          successMessage({
            text: `게시글이 정상 ${id ? '수정' : '등록'}되었습니다.`,
          });
          history.push(`/myclass/board/${type}`);
        }
      } catch (error) {
        errorMessage({
          text: `게시글 ${id ? '수정' : '등록'}에 문제가 발생하였습니다.`,
        });
      }
    }
  };

  const handleChangeFile = (fileList) => {
    if ([...fileList].length > 5) {
      errorMessage({ text: '파일은 최대 5개까지 업로드 가능합니다.' });
      return;
    }

    setFiles((prev) => {
      if ([...prev, ...fileList].length > 5) {
        errorMessage({ text: '파일은 최대 5개까지 업로드 가능합니다.' });
        return [...prev];
      }

      return [...prev, ...fileList];
    });
  };

  const handleDeleteFile = (index, newFileName) => {
    setFiles((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });

    setDeleteAttachments((prev) => {
      if (attachments?.find((e) => e.newFileName === newFileName)) {
        return [...prev, newFileName];
      } else {
        return [...prev];
      }
    });
  };

  useEffect(() => {
    if (textValue.length < CONTENT_MAX_LENGTH) return;

    if (textValue.length > CONTENT_MAX_LENGTH) {
      errorMessage({
        text: `5000자 이하로 입력해주세요. (현재: ${textValue.length}자)`,
      });
      setTextValue((prevTextValue) =>
        prevTextValue.slice(0, CONTENT_MAX_LENGTH - 50)
      );
    }
  }, [textValue.length]);

  useEffect(() => {
    if (!lcPost?.content) return;

    function updateImagePaths(htmlString) {
      return htmlString.replace(/temp/g, `${auth.classId}/${id}`);
    }

    const parsingValue = updateImagePaths(lcPost?.content);

    setTextValue(parsingValue);
  }, [imgList]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <ClassWrapper>
        <h1>
          {BOARD_TITLE[type]}&nbsp;
          {id ? '수정' : '작성'}
        </h1>

        <WriteContentBox>
          <div>
            <div className="input-box">
              <input
                {...register('title', {
                  required: true,
                  maxLength: TITLE_MAX_LENGTH,
                })}
                type="text"
                placeholder="제목을 입력하세요."
              />
              {errors.title?.type === 'required' && (
                <StyledError>제목을 입력해주세요.</StyledError>
              )}
              {errors.title?.type === 'maxLength' && (
                <StyledError>
                  {TITLE_MAX_LENGTH}자 이하로 입력해주세요.
                </StyledError>
              )}
            </div>
          </div>

          <div>
            <div className="content-box">
              <TextEditorWithImage
                htmlStr={textValue}
                setHtmlStr={setTextValue}
                isEdit={!!id}
                setImageNames={setImagesNames}
                setIsImageLoading={setIsImageLoading}
                setDeleteImgs={setDeleteImgs}
                imgList={imgList}
              />
            </div>
          </div>

          <div className="files">
            <div className="content-box">
              <ClassBoardAppendixFile
                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
                originalFileNames={files}
                multiple
                type={type}
              />
            </div>
          </div>

          <ClassBoardWriteButton cancleUrl={`/myclass/board/${type}`} />
        </WriteContentBox>
      </ClassWrapper>
    </form>
  );
};

export default ClassBoardWrite;
