import React from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import Modal from '../modal/Modal';

const Header = ({
  data,
  onClick,
  state,
  tabs,
  onSuccess,
  onModalClick,
  groupId,
  location,
  refresh,
}) => {
  const tabRef = useRef(null);
  const handleClick = (e) => {
    onClick(e.currentTarget.id);
  };

  const handleTabClick = () => {
    tabRef.current.click();
  };

  return (
    <>
      <ContentBody>
        <Wrapper>
          <div>{data.title}</div>
          <h3>
            {data?.content?.length > 200
              ? data?.content?.substring(0, 200) + '...'
              : data?.content}
          </h3>
        </Wrapper>
      </ContentBody>
      {tabs && (
        <BtnBox>
          <BtnWrapper>
            <Tabs state={state}>
              {tabs &&
                tabs.map((item, i) =>
                  item.title === '스터디룸 나가기' ? (
                    <Link to={'/sgroup/list'} onClick={() => refresh()} key={i}>
                      <Tab
                        key={item.id}
                        seleted={item.id === state}
                        id={item.id}
                      >
                        {item.title}
                      </Tab>
                    </Link>
                  ) : i === 0 ? (
                    <Tab
                      ref={tabRef}
                      key={item.id}
                      seleted={item.id === state}
                      id={item.id}
                      onClick={item.id !== state ? handleClick : null}
                    >
                      {item.title}
                    </Tab>
                  ) : (
                    <Tab
                      key={item.id}
                      seleted={item.id === state}
                      id={item.id}
                      onClick={item.id !== state ? handleClick : null}
                    >
                      {item.title}
                    </Tab>
                  )
                )}
            </Tabs>
            {data.btns &&
              (data.btns.content === 'zoom' ? (
                location.pathname.split('/')[
                  location.pathname.split('/').length - 1
                ] === 'zoom' ? (
                  <Link
                    to={`/sgroup/detail/${
                      location.pathname.split('/')[
                        location.pathname.split('/').length - 2
                      ]
                    }`}
                  >
                    <Btn>그룹메인</Btn>
                  </Link>
                ) : (
                  <Link to={`/sgroup/detail/${groupId}/zoom`}>
                    <Btn>스터디룸 예약현황</Btn>
                  </Link>
                )
              ) : (
                <Btn
                  onClick={() =>
                    data.btns.text !== '승인대기중' &&
                    onModalClick(data.btns.content)
                  }
                >
                  {data.btns.text}
                </Btn>
              ))}
          </BtnWrapper>
          {data.btns && data.btns.content === 'zoom'
            ? data.btns.status === 'N' &&
              data.modal && (
                <ModalWrapper>
                  <Overlay id="overlay" onClick={() => onModalClick('')} />
                  <Modal
                    onClick={() => onModalClick('')}
                    title={data.btns.text}
                  />
                </ModalWrapper>
              )
            : data.btns &&
              data.modal && (
                <ModalWrapper>
                  <Overlay id="overlay" onClick={() => onModalClick('')} />
                  <Modal
                    onSuccess={onSuccess}
                    onClick={() => onModalClick('')}
                    title={data.btns.text}
                    onTabClick={handleTabClick}
                  />
                </ModalWrapper>
              )}
        </BtnBox>
      )}
    </>
  );
};

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fbfcfd;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1100px;
  margin: 30px 0;
  height: 70px;

  div {
    font-size: 34px;
    font-weight: 700;
  }

  div:nth-child(2) {
    margin-top: 10px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
  }

  div:nth-child(3) {
    margin-top: 10px;
    font-size: 18px;

    i {
      color: #fc9918;
      margin-left: 5px;
    }
  }
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  height: 55px;
  margin-bottom: 50px;
`;

const BtnWrapper = styled.div`
  padding: 10px 0;
  width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Tab = styled.div`
  color: ${(props) => (props.seleted ? '#fc9918' : 'rgba(0, 0, 0, 0.6)')};
  border-bottom: ${(props) =>
    props.seleted ? '3px solid #fc9918' : '3px solid transparent'};
  font-size: 16px;
  margin-right: 15px;
  font-weight: 500;
  padding: 15px 0;
  transition: border-bottom 0.2s linear;
  cursor: pointer;

  &:hover {
    color: #fc9918;
  }
`;

const Btn = styled.div`
  border-radius: 5px;
  font-size: 14px;
  padding: 7px;
  border: 1px solid #f14a16;
  background-color: #f14a16;
  color: white;
  cursor: pointer;

  &:hover {
    color: #f14a16;
    background-color: white;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 60;
  width: 100%;
  height: 100%;
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  position: fixed;
`;

export default withRouter(Header);
