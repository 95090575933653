import React, { useContext, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { useParams, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import Header from '../../components/header/Header';
import Navbar from '../Navbar';
import { Btn, BtnWrapper, DetailWrapper, Input } from '../style';
import { createStudentTaskGrading } from 'apis';
import { errorMessage, handleErrorMessage, successMessage } from 'utilities';

const TaskResult = ({ history }) => {
  const auth = useContext(AuthContext);
  const token = localStorage.getItem('token');
  const { taskId, resultId } = useParams();
  const [comment, setComment] = useState('');
  const [score, setScore] = useState('');
  const [data, setData] = useState();
  const commentRef = useRef(null);
  const scoreRef = useRef(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/teachers/lc-posts/tasks-result/${resultId}`;
    Axios.get(url)
      .then((response) => {
        setData(response.data);
        setComment(response.data.comment);
        setScore(response.data.score);
      })
      .catch(handleErrorMessage);
  }, [auth, history, resultId, token]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleScoreChange = (e) => {
    if (e.keyCode === 69) return;
    if (e.target.value[0] === '0') {
      setScore(e.target.value.slice(1));
    } else if (Number(e.target.value) > 100) {
      setScore(100);
    } else {
      setScore(e.target.value);
    }
  };

  const handleDownload = (fileId, originalFileName) => {
    const url = `${process.env.REACT_APP_IP}/teachers/lc-posts/submit-tasks/download/${fileId}`;
    Axios.get(url, {
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURIComponent(originalFileName));
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(handleErrorMessage);
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const handleSubmit = async () => {
    if (!comment) {
      errorMessage({ text: '댓글을 입력해주세요.' });
      return;
    }

    if (!score) {
      errorMessage({ text: '점수를 입력해주세요.' });
      return;
    }

    try {
      const response = await createStudentTaskGrading({
        taskScoreId: resultId,
        score,
        comment,
      });

      if (response.status === 200) {
        successMessage({ text: '채점이 등록되었습니다.' });
      }
    } catch (error) {
      errorMessage({
        text: '채점 등록에 실패하였습니다. \n 문제가 반복될 경우 관리자에게 문의해주세요.',
      });
    }
  };

  return (
    <>
      <Header
        data={{
          title: '과제 관리',
          content: data?.title,
        }}
      />
      <Navbar title={'과제 관리'} />
      <DetailWrapper>
        <div></div>
        <div className="row detailBox">
          <div className="col-md-2 detailLeft secotext">학습자</div>
          <div className="col-md-10 detailRight secotext">
            {data && data.userName}
          </div>
        </div>
        <div className="row detailBox">
          <div className="col-md-2 detailLeft secotext">과제 제출일</div>
          <div className="col-md-10 detailRight secotext">
            {data && data.submitDateTime.split('T')[0]}
          </div>
        </div>
        <div className="row detailBox">
          <div className="col-md-2 detailLeft secotext">과제 파일</div>
          <div className="col-md-10 detailRight secotext">
            {data && data.submitFile.originalFileName && (
              <div
                onClick={() =>
                  handleDownload(
                    data.submitFile.id,
                    data.submitFile.originalFileName
                  )
                }
                style={{ cursor: 'pointer', color: 'rgb(44, 149, 210)' }}
              >
                <i className="las la-file-download"></i>&nbsp;
                {data.submitFile.originalFileName}
                <br />
              </div>
            )}
          </div>
        </div>
        <div className="row detailBox">
          <div className="col-md-2 detailLeft secotext">점수</div>
          <div className="col-md-4 detailRight secotext">
            <Input
              ref={scoreRef}
              value={score}
              onChange={handleScoreChange}
              onKeyDown={formatInput}
              type="number"
              placeholder="점수입력"
              max="100"
              min="1"
            />
            / 100
          </div>
        </div>
        <div className="row detailcontentBox">
          <div className="col-md-2 detailLeft secotext">코멘트</div>
          <div className="col-md-10 detailRight secotext">
            <Input
              ref={commentRef}
              onChange={handleCommentChange}
              value={comment ? comment : ''}
              type="text"
              placeholder="코멘트를 입력해주세요."
            />
          </div>
        </div>
        <BtnWrapper>
          <Link to={`/teacher/task/${taskId}`}>
            <Btn color="rgba(0, 0, 0, 0.3)">돌아가기</Btn>
          </Link>
          <Btn onClick={handleSubmit} color="#fc9918">
            {data?.score ? '채점 수정' : '채점 등록'}
          </Btn>
        </BtnWrapper>
      </DetailWrapper>
    </>
  );
};

export default withRouter(TaskResult);
