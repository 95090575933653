import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/fs/css/all.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import ScrollToTop from './utilities/scrollToTop';
import App from './App';
import { QueryClientProvider, QueryClient } from 'react-query';
import Mock from './state/mock';
import './state/database';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  enabled:
    process.env.REACT_APP_MODE === 'development' ||
    process.env.REACT_APP_MODE_PROD === 'production',
});

Mock.onAny().passThrough();

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </QueryClientProvider>,
  document.getElementById('root')
);
