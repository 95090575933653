import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import Header1 from '../components/header/Header';
import SGroupDetail from './SGroupDetail';
import SGroupList from './SGroupList';
import SGroupMembers from './SGroupMembers';
import SGroupPostDetail from './SGroupPostDetail';
import SGroupWrite from './SGroupWrite';
import SGroupZoom from './SGroupZoom';
import { handleErrorMessage } from 'utilities';

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding-bottom: 300px;
`;

const Wrapper = styled.div`
  width: 1100px;
`;

const subBtns = {
  list: [
    {
      id: 'allSGroup',
      title: '전체',
    },
    {
      id: 'mySGroup',
      title: '내그룹',
    },
    {
      id: 'waitingSGroup',
      title: '가입승인대기',
    },
  ],
  detail: [
    {
      id: 0,
      title: '스터디룸 나가기',
    },
  ],
};

const SGroup = ({ location, history, posts, data }) => {
  const match = useRouteMatch();
  const auth = useContext(AuthContext);
  const [filtering, setFiltering] = useState('allSGroup');
  const [tabs, setTabs] = useState(subBtns.list);
  const [btns, setBtns] = useState(null);
  const [title, setTitle] = useState('스터디그룹 목록');
  const [modal, setModal] = useState('');
  const [list, setList] = useState();
  const [sGroupList, setSGroupList] = useState();
  const [sGroupPosts, setSGroupPost] = useState(null);
  const [sGroupinfo, setSGroupinfo] = useState(null);
  const [isLeader, setIsLeader] = useState(false);
  const [content, setContent] = useState();
  const [leader, setLeader] = useState();
  const [groupId, setGroupId] = useState();
  const [update, setUpdate] = useState();

  const sgroupListReq = (state) => {
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms`;
    Axios.get(url)
      .then((response) => {
        setList(response.data);
        setSGroupList(response.data.filter((item) => item.status !== 'READY'));

        if (state) {
          setFiltering(state);
          if (state === 'allSGroup') {
            const newList = response.data.filter(
              (item) => item.status !== 'READY'
            );
            setSGroupList([...newList]);
          } else if (state === 'mySGroup') {
            setSGroupList((prev) => {
              const newMyList = response.data.filter(
                (item) => item.memberStatus === 'Y' || item.memberStatus === 'L'
              );
              return [...newMyList];
            });
          } else if (state === 'waitingSGroup') {
            setSGroupList((prev) => {
              const newList = response.data.filter(
                (item) => item.memberStatus === 'W'
              );
              return [...newList];
            });
          }
        }
      })
      .catch(handleErrorMessage);
  };

  useEffect(() => {
    sgroupListReq();
  }, []);

  const handleRefresh = (state) => {
    sgroupListReq(state);
  };

  const handleModalClick = (state) => {
    setModal(state);
  };

  const getSGroupInfo = (info) => {
    setIsLeader(info.userId === auth.userId);

    // const today = new Date();
    // const reserveDate = new Date(info.data.zoomReserveDate);

    const text =
      info.reserveYn === 'Y'
        ? '줌 입장'
        : info.reserveYn === 'N'
        ? '줌 예약'
        : '승인대기중';
    // const status =
    //   info.reserveYn === 'Y'
    //     ? today === reserveDate
    //       ? 'Y'
    //       : 'S'
    //     : info.reserveYn;

    setTitle(info.name);
    setContent(info.content);
    setLeader(info.userName);
    setSGroupinfo({
      text,
      // status,
      content: 'zoom',
    });
  };

  const handleRegRoom = (msg) => {
    if (msg === 'SUCCESS') {
      toast.success('스터디그룹 개설 신청 되었습니다.');
      sgroupListReq();
    }
  };

  useEffect(() => {
    if (location.pathname.split('/')[2] === 'list') {
      setTabs([...subBtns.list]);
      setBtns({ text: '개설신청', content: 'regi' });
      setTitle('스터디그룹 목록');
      setContent('');
      setLeader('');
    } else if (
      location.pathname.split('/')[2] === 'detail' ||
      location.pathname.split('/')[2] === 'post' ||
      location.pathname.split('/')[2] === 'write'
    ) {
      setTabs([...subBtns.detail]);
      setBtns(sGroupinfo);
      const id = location.pathname.split('/')[3];
      setGroupId(id);
      setSGroupPost(posts.filter((item) => item.sgroupId === +id));
    }
  }, [location, sGroupinfo]);

  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push('/');
    }
  }, [auth]);

  return (
    <>
      {tabs && (
        <Header1
          state={filtering}
          data={{
            title,
            btns,
            modal,
            content,
          }}
          refresh={handleRefresh}
          onClick={handleRefresh}
          tabs={tabs}
          onModalClick={handleModalClick}
          onSuccess={handleRegRoom}
          groupId={groupId}
        />
      )}
      <ContentBody>
        <Wrapper>
          <Switch>
            <Route exact path={`${match.path}`}>
              <Redirect to={`${match.path}/list`} />
            </Route>
            <Route
              exact
              path={`${match.url}/list`}
              render={() => (
                <SGroupList
                  rerender={handleRefresh}
                  list={sGroupList}
                  state={filtering}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id`}
              render={() => (
                <SGroupDetail
                  getSgroupInfo={getSGroupInfo}
                  posts={sGroupPosts}
                  deleteFn={handleRefresh}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id/post/:postid`}
              render={() => (
                <SGroupPostDetail
                  getSgroupInfo={getSGroupInfo}
                  isLeader={isLeader}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id/write`}
              render={() => (
                <SGroupWrite
                  getSgroupInfo={getSGroupInfo}
                  isLeader={isLeader}
                  type="write"
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id/update/:postid`}
              render={() => (
                <SGroupWrite
                  getSgroupInfo={getSGroupInfo}
                  isLeader={isLeader}
                  type="update"
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id/members`}
              render={() => (
                <SGroupMembers
                  getSgroupInfo={getSGroupInfo}
                  isLeader={isLeader}
                  page="member"
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id/join`}
              render={() => (
                <SGroupMembers
                  getSgroupInfo={getSGroupInfo}
                  isLeader={isLeader}
                  page="join"
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/detail/:id/zoom`}
              render={() => (
                <SGroupZoom getSgroupInfo={getSGroupInfo} isLeader={isLeader} />
              )}
            />
          </Switch>
        </Wrapper>
      </ContentBody>
    </>
  );
};

export default withRouter(SGroup);
