import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import Axios from 'axios';

import AuthContext from 'context/Auth.context';
import { handleErrorMessage } from 'utilities';
import styles from './comment.module.scss';
import './pagination.scss';

const Comments = ({
  comments,
  author,
  lcPostId,
  onCommentSubmit,
  greetingPostId,
  isUpdated,
  setIsUpdated,
}) => {
  const { id, postid } = useParams();
  const inputRef = useRef();
  const inputEditRef = useRef();
  const auth = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState();
  const [displayed, setDisplayed] = useState();
  const [isEditClicked, setIsEditClicked] = useState();

  useEffect(() => {
    if (comments) {
      setItems(comments);
      setDisplayed(comments.slice(0, 5));
      setIsEditClicked(null);
    }
  }, [comments, isUpdated]);

  const handlePageChange = (page) => {
    setPage(page);
    setIsEditClicked(null);
  };

  useEffect(() => {
    if (items) {
      const start = page * 5 - 5;
      const end = 5 * page;
      setDisplayed(items.slice(start, end));
    }
  }, [page, items]);

  const handleSubmit = (e) => {
    const lcPostSubmitReq = () => {
      if (inputRef.current.value.length < 250) {
        const inputVal = inputRef.current.value.trim();
        if (inputVal !== '') {
          const url = `${process.env.REACT_APP_IP}/users/lc-posts/files/reply`;
          Axios.post(url, {
            lcPostId: +lcPostId,
            userId: auth.userId,
            content: inputRef.current.value,
          })
            .then((response) => {
              setItems(response.data.replies);
              setDisplayed(response.data.replies.slice(0, 5));
              inputRef.current.value = '';
            })
            .catch(handleErrorMessage);
        }
      } else {
        toast.error('250자 이상은 작성할 수 없습니다.');
        inputRef.current.focus();
      }
    };
    const greetingCommentSubmitReq = () => {
      if (inputRef.current.value.length < 250) {
        const inputVal = inputRef.current.value.trim();
        if (inputVal !== '') {
          const url = `${process.env.REACT_APP_IP}/users/greeting/${id}/comments`;
          Axios.post(url, {
            author: auth.name,
            content: inputRef.current.value,
          })
            .then((response) => {
              setIsUpdated((prev) => !prev);
              inputRef.current.value = '';
            })
            .catch(handleErrorMessage);
        }
      } else {
        toast.error('250자 이상은 작성할 수 없습니다.');
        inputRef.current.focus();
      }
    };
    const submitReq = () => {
      if (inputRef.current.value.length < 250) {
        const inputVal = inputRef.current.value.trim();
        if (inputVal !== '') {
          const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/reply`;

          Axios.post(url, {
            studyPostId: +postid,
            userId: auth.userId,
            content: inputRef.current.value,
          })
            .then((response) => {
              setItems(response.data.studyGroupPostReplyDtoList);
              setDisplayed(
                response.data.studyGroupPostReplyDtoList.slice(0, 5)
              );
              onCommentSubmit();
              inputRef.current.value = '';
            })
            .catch(handleErrorMessage);
        }
      } else {
        toast.error('250자 이상은 작성할 수 없습니다.');
        inputRef.current.focus();
      }
    };

    if (lcPostId) {
      if (e.target.className === 'submitInput') {
        if (e.keyCode === 13) {
          if (window.confirm('댓글을 등록하시겠습니까?')) {
            lcPostSubmitReq();
          }
        }
      } else {
        if (window.confirm('댓글을 등록하시겠습니까?')) {
          lcPostSubmitReq();
        }
      }
    } else if (greetingPostId) {
      if (e.target.className === 'submitInput') {
        if (e.keyCode === 13) {
          if (window.confirm('댓글을 등록하시겠습니까?')) {
            greetingCommentSubmitReq();
          }
        }
      } else {
        if (window.confirm('댓글을 등록하시겠습니까?')) {
          greetingCommentSubmitReq();
        }
      }
    } else {
      if (e.target.className === 'submitInput') {
        if (e.keyCode === 13) {
          if (window.confirm('댓글을 등록하시겠습니까?')) {
            submitReq();
          }
        }
      } else {
        if (window.confirm('댓글을 등록하시겠습니까?')) {
          submitReq();
        }
      }
    }
  };

  const handleDelete = (postId, replyId) => {
    if (window.confirm('해당 댓글을 삭제하시겠습니까?')) {
      if (lcPostId) {
        const url = `${process.env.REACT_APP_IP}/users/lc-posts/files/reply/${replyId}`;

        Axios.delete(url)
          .then((response) => {
            if (response.data === 'SUCCESS') {
              toast.success('삭제되었습니다.');
              setItems((prev) => {
                const newArr = prev.filter((item) => item.id !== replyId);
                return [...newArr];
              });
              setDisplayed(items.filter((item) => item.id !== replyId));
            } else {
              toast.error('삭제에 실패하였습니다.');
            }
          })
          .catch(handleErrorMessage);
      } else if (greetingPostId) {
        const url = `${process.env.REACT_APP_IP}/users/greeting/${postId}/comments/${replyId}`;

        Axios.delete(url)
          .then((response) => {
            if (response.status === 204) {
              toast.success('삭제되었습니다.');
              setIsUpdated((prev) => !prev);
            } else {
              toast.error('삭제에 실패하였습니다.');
            }
          })
          .catch(handleErrorMessage);
      } else {
        const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/reply/${replyId}`;
        Axios.delete(url)
          .then((response) => {
            if (response.data === 'SUCCESS') {
              toast.success('삭제되었습니다.');
              onCommentSubmit();
              setItems((prev) => {
                const newArr = prev.filter((item) => item.id !== replyId);
                return [...newArr];
              });
              setDisplayed(items.filter((item) => item.id !== replyId));
            } else {
              toast.error('삭제에 실패하였습니다.');
            }
          })
          .catch(handleErrorMessage);
      }
    }
  };

  const handleEditBtn = (commentId, replyIdx, content) => {
    setIsEditClicked({ ...isEditClicked, replyIdx, content });
  };

  const editSubmitReq = (postId, replyId) => {
    if (inputEditRef.current.value.length < 250) {
      const inputVal = inputEditRef.current.value.trim();
      if (inputVal !== '') {
        const url = `${process.env.REACT_APP_IP}/users/greeting/${postId}/comments/${replyId}`;
        Axios.put(url, {
          author: auth.name,
          content: inputEditRef.current.value,
        })
          .then((response) => {
            if (response.status === 204) {
              toast.success('수정되었습니다.');
              setIsUpdated((prev) => !prev);
              setIsEditClicked(null);
              inputEditRef.current.value = '';
              setIsEditClicked(null);
            } else {
              toast.error('수정에 실패하였습니다.');
            }
          })
          .catch(handleErrorMessage);
      }
    } else {
      toast.error('250자 이상은 작성할 수 없습니다.');
      inputEditRef.current.focus();
    }
  };

  const handleEditSubmit = (postId, commentId) => {
    if (window.confirm('해당 댓글을 수정하시겠습니까?')) {
      if (greetingPostId) {
        editSubmitReq(postId, commentId);
      }
    }
  };

  const handleEditSubmitKeyDown = (e, commentId) => {
    if (greetingPostId) {
      if (e.target.className === 'submitEditInput') {
        if (e.keyCode === 13) {
          if (window.confirm('댓글을 등록하시겠습니까?')) {
            editSubmitReq(commentId);
          }
        }
      } else {
        if (window.confirm('댓글을 등록하시겠습니까?')) {
          editSubmitReq(commentId);
        }
      }
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.count}>
        <span>댓글</span>
        <span>{items?.length}</span>
      </div>
      <div className={styles.inputWrapper}>
        <input
          className="submitInput"
          onKeyDown={handleSubmit}
          ref={inputRef}
          type="text"
          placeholder="댓글을 입력해주세요"
        />
        <button className="submitBtn" onClick={handleSubmit}>
          댓글쓰기
        </button>
      </div>
      <div className={styles.commentWrapper}>
        {displayed &&
          displayed.map((item, index) => (
            <div key={item.id} className={styles.comment}>
              <div className={styles.header}>
                <span>{item.userName}</span>
                <span> · {item.regDate.split('T')[0]}</span>
                {author === item.userId && (
                  <>
                    <span> · </span>
                    <span className={styles.author}>작성자</span>
                  </>
                )}
              </div>
              <div className={styles.content}>
                {greetingPostId &&
                isEditClicked &&
                index === isEditClicked.replyIdx ? (
                  <>
                    <div
                      className={styles.inputWrapper}
                      style={{ gap: '10px' }}
                    >
                      <input
                        type="text"
                        className="submitEditInput"
                        onKeyDown={(e) => handleEditSubmitKeyDown(e, item.id)}
                        defaultValue={item.content}
                        ref={inputEditRef}
                      />
                      <button
                        className="submitBtn"
                        // className={styles.deleteBtn}
                        onClick={() => handleEditSubmit(id, item.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        등록
                      </button>
                      <button
                        className="submitBtn"
                        onClick={() => setIsEditClicked(false)}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: 'rgb(225, 3, 42)',
                        }}
                      >
                        취소
                      </button>
                    </div>
                  </>
                ) : (
                  <span>{item.content}</span>
                )}

                {item.userId === auth.userId && (
                  <>
                    {isEditClicked &&
                    index === isEditClicked.replyIdx ? null : (
                      <>
                        {' '}
                        {greetingPostId && (
                          <span
                            className={styles.deleteBtn}
                            onClick={() => {
                              handleEditBtn(item.id, index, item.content);
                            }}
                          >
                            수정
                          </span>
                        )}
                        <span
                          className={styles.deleteBtn}
                          onClick={() => handleDelete(id, item.id)}
                        >
                          삭제
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
      {items && items.length !== 0 && (
        <div>
          <Pagination
            activePage={page}
            itemsCountPerPage={5}
            totalItemsCount={items.length}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Comments;
