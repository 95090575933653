import React, {
  useContext,
  Fragment,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import dompurify from 'dompurify';
import { CircularProgress } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';

import AuthContext from 'context/Auth.context';
import {
  useFetchClassDetail,
  useRemoveClassBoard,
  useDownloadClassFile,
} from 'hooks';
import {
  ClassDetailContainer,
  ClassDetailTitleBox,
  ClassDetailWrapper,
  DetailContentBox,
  DetailFileBox,
  DetailFormButtons,
} from './ClassBoardCommon';
import { Loading } from 'components/ui';
import BoardComment from './BoardComment';
import { BOARD_TITLE } from 'constant/class';
import { confirmSwal, successMessage } from 'utilities/swal';

const ClassBoardDetail = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const auth = useContext(AuthContext);

  const { data, isLoading } = useFetchClassDetail({ type, id });
  const { lcPost, comments, attachments } = data || {};
  const { mutateAsync: deletePost, isLoading: isRemoving } =
    useRemoveClassBoard({ type });

  const { setFileId } = useDownloadClassFile({ type, id });

  const [textValue, setTextValue] = useState('');

  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '해당 게시물을 삭제 하시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      const { status } = await deletePost({ id });
      if (status === 204 || status === 200) {
        successMessage({ text: `${BOARD_TITLE[type]}이(가) 삭제되었습니다.` });
        history.push(`/myclass/board/${type}`);
      }
    }
  };

  const handleGoToEdit = () =>
    history.push(`/myclass/board/write/${type}/${id}`);

  const handleDownloadFile = (fileId) => {
    setFileId(fileId);
  };

  useEffect(() => {
    if (!lcPost?.content) return;

    function updateImagePaths(htmlString) {
      return htmlString.replace(/temp/g, `${auth.classId}/${id}`);
    }

    const parsingValue = updateImagePaths(lcPost?.content);

    const aTags = document.querySelectorAll('.ql-editor > p > a');
    aTags.forEach((e) => {
      e.setAttribute('target', '_blank');
      e.setAttribute('rel', 'noreferrer');
    });

    setTextValue(parsingValue);
  }, [lcPost, isLoading]);

  const isModified = useCallback(() => {
    return lcPost?.createdAt === lcPost?.modifiedAt
      ? `${lcPost?.createdAt.slice(0, 10)} ${lcPost?.createdAt.slice(11, 16)}`
      : `${lcPost?.modifiedAt.slice(0, 10)} ${lcPost?.modifiedAt.slice(
          11,
          16
        )}(수정됨)`;
  }, [data, lcPost, type]);

  const handleGoBack = () => {
    history.goBack();
  };

  if (isLoading) return <Loading height="600px" />;
  return (
    <ClassDetailContainer>
      <ClassDetailWrapper>
        <ClassDetailTitleBox>
          <div className="title">
            <h2>{lcPost?.title || data?.data.title}</h2>
          </div>

          <div className="sub-info">
            <div>
              <span>{isModified()} |</span>
              <span>조회 {lcPost?.hitCount || data?.data.hitCount}</span>
            </div>
            <div>
              <span>
                <i className="fa-solid fa-user" />
                {lcPost?.author || data?.data.userName}
              </span>
            </div>
          </div>
        </ClassDetailTitleBox>

        <DetailContentBox
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(textValue || data?.data?.content || ''),
          }}
        />

        {!!attachments?.length && (
          <DetailFileBox>
            {attachments.map((file, index) => {
              return (
                <p key={index}>
                  <span className="file-title">첨부파일{index + 1}</span>
                  <span
                    onClick={() => handleDownloadFile(file.id)}
                    style={{ cursor: 'pointer', color: '#1e88e5' }}
                  >
                    <i className="las la-file-download"></i>&nbsp;
                    {file.originalFileName}
                  </span>
                </p>
              );
            })}
          </DetailFileBox>
        )}

        <DetailFormButtons>
          {(type === 'notice' && ['3', '4', '5'].includes(auth.type)) ||
          (type !== 'notice' && lcPost?.userId === auth.userId) ? (
            <>
              <div className="edit" onClick={handleGoToEdit}>
                수정하기
              </div>
              <div className="delete" onClick={handleDelete}>
                {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
              </div>
            </>
          ) : (
            auth.type !== '1' && (
              <div className="delete" onClick={handleDelete}>
                {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
              </div>
            )
          )}
        </DetailFormButtons>

        <BoardComment replyList={comments} />

        <div className="go-list" onClick={handleGoBack}>
          목록으로
        </div>
      </ClassDetailWrapper>
    </ClassDetailContainer>
  );
};

export default ClassBoardDetail;
