import { useQuery } from 'react-query';

import { fetchStudentCommentList } from 'apis/pbl';

const useFetchStudentCommentList = ({ problemId, studentId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchStudentCommentList', { problemId, studentId }],
    queryFn: async () =>
      await fetchStudentCommentList({ problemId, studentId }),
    select: (res) => res.data.data.comments,
    retry: 2,
    retryDelay: 500,
    enabled: !!problemId,
  });
  return { ...queryReturns };
};

export default useFetchStudentCommentList;
