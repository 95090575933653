import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import Tabs from './Tabs';
import styles from './topbar.module.scss';

const btns = [
  {
    id: 0,
    name: 'roadmap',
    text: '로드맵',
    url: '/myclass/roadmap',
    page: 'class-main',
    active: false,
  },
  {
    id: 1,
    name: 'classdetail',
    text: '과정상세',
    url: '/myclass/classdetail',
    page: 'class-main',
    active: false,
  },
  {
    id: 2,
    name: 'unit',
    text: '단원상세일정',
    url: '/myclass/unit/',
    page: 'class-unit',
    active: false,
  },
  {
    id: 3,
    name: 'notice',
    text: '공지사항',
    url: '/myclass/board/notice',
    page: 'class-main',
    active: false,
  },
  {
    id: 4,
    name: 'task',
    text: '과제',
    url: '/myclass/board/task',
    page: 'class-main',
    active: false,
  },
  {
    id: 7,
    name: 'practice',
    text: '스마트실습',
    url: '/myclass/practice',
    page: 'class-main',
    active: false,
  },
  {
    id: 6,
    name: 'pbl',
    text: 'PBL',
    url: '/myclass/pbl',
    page: 'class-main',
    active: false,
  },
  {
    id: 5,
    name: 'board',
    text: '학습게시판',
    url: '/myclass/board',
    page: 'class-main',
    active: false,
  },
];

const Topbar = ({
  history,
  location,
  info,
  urlname,
  zoomUrl,
  unitTitle,
  currentTime,
}) => {
  const auth = useContext(AuthContext);
  const [pathname, setPathname] = useState(location.pathname.split('/'));
  const [tabBtns, setTabBtns] = useState(btns);

  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push('/');
    }
  }, [auth, history]);

  useEffect(() => {
    setPathname(location.pathname.split('/'));
  }, [location]);

  // myclass 전용
  useEffect(() => {
    if (pathname[2]) {
      const page = btns.filter((item) => item.name === pathname[2])[0].page;
      const btnList = btns.filter(
        (item) =>
          item.page === page || item.page === `${page.split('-')[0]}-public`
      );
      if (auth.type !== '1') {
        setTabBtns(
          btnList.map((item) =>
            item.name === pathname[2]
              ? { ...item, active: true }
              : { ...item, active: false }
          )
        );
      } else {
        setTabBtns(
          btnList.map((item) =>
            item.name === pathname[2]
              ? { ...item, active: true }
              : { ...item, active: false }
          )
        );
      }

      if (pathname[3] && ['task', 'notice'].includes(pathname[3])) {
        setTabBtns(
          btnList.map((item) =>
            item.name === pathname[3]
              ? { ...item, active: true }
              : { ...item, active: false }
          )
        );
      }
    }
  }, [pathname, auth.type]);

  return (
    <>
      {info && (
        <>
          <div className={styles.contentBody}>
            <div className={styles.wrapper}>
              <div className={styles.title}>{info?.title}</div>
              <div className={styles.infoWrapper}>
                <div className={styles.keywords}>
                  {info?.keyWord?.split(',').map((item) => (
                    <div key={item}>{item}</div>
                  ))}
                </div>
                {unitTitle && (
                  <div className={styles.unitTitle}>{unitTitle}</div>
                )}
              </div>
              <div className={styles.startEnd}>
                {`${info?.startDate} ~ 
                ${info?.endDate} (${info?.totalTime}H)`}
              </div>
            </div>
          </div>
          <Tabs
            noteUrl={info?.noteUrl}
            todayUnitId={info && info.todayUnitId}
            urlname={urlname}
            tabBtns={tabBtns}
            currentTime={currentTime}
          />
        </>
      )}
    </>
  );
};

export default withRouter(Topbar);
