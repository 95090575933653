import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Badge, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { BtnLg } from '../teacher/style';
import { handleErrorMessage } from 'utilities';

const JoinModal = ({ handleClose, sGroupData, rerender, state }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${sGroupData.id}`;
    Axios.get(url).then((res) => {
      setData(res.data.data);
    });
  }, [sGroupData.id]);

  const handleJoin = () => {
    if (window.confirm('가입 신청하시겠습니까?')) {
      //해당 스터디 그룹의 정보 요청
      const url = `${process.env.REACT_APP_IP}/users/sgroup/join/${sGroupData.id}`;
      Axios.post(url, {})
        .then((response) => {
          if (response.data === 'SUCCESS') {
            toast.success('가입신청되었습니다.');
            rerender(state);
          } else {
            toast.error('가입신청에 실패하였습니다.');
            rerender(state);
          }
          handleClose();
        })
        .catch(handleErrorMessage);
    }
  };

  const handleCancel = () => {
    if (window.confirm('가입신청을 취소하시겠습니까?')) {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/join/${sGroupData.id}`;
      Axios.delete(url)
        .then((response) => {
          if (response.data === 'SUCCESS') {
            toast.success('가입신청이 취소되었습니다.');
            rerender(state);
          } else {
            toast.error('취소에 실패하였습니다.');
            rerender(state);
          }
          handleClose();
        })
        .catch(handleErrorMessage);
    }
  };

  return (
    <Modal className="fade modal" size="md" show={true} onHide={handleClose}>
      <Modal.Header>
        <div style={{ width: '100%', marginTop: '10px' }}>
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '24px', margin: '10px 0' }}>{data?.name}</h2>
          </div>
          <Title>
            <SGroupUser>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '42px',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <div>그룹장:</div>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '10px',
                    flexDirection: 'column',
                  }}
                >
                  <i
                    className="fa-solid fa-crown"
                    style={{
                      marginBottom: '0',
                      color: '#ffd700',
                    }}
                  ></i>
                  <Badge
                    pill
                    bg={'info'}
                    style={{ color: 'white', marginRight: '5px' }}
                  >
                    {data.userName}
                  </Badge>
                </div>
              </div>
            </SGroupUser>
            <div>
              <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.5)' }}>
                참여인원: {`${data?.memberCount}/${data?.limitCount} 명`}
              </div>
              <div>
                <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.5)' }}>
                  개설신청일: {data?.regDate?.substring(0, 10)}
                </div>
                {data?.approvalDate && (
                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.5)' }}>
                    승인일: {data?.approvalDate?.substring(0, 10)}
                  </div>
                )}
              </div>
            </div>
          </Title>
        </div>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'auto' }}>
        <div style={{ color: 'rgba(0,0,0,0.4)', fontSize: '14px' }}>소개</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'gray',
            margin: '0 40px 0 40px',
          }}
        >
          <i className="fa fa-quote-left"></i>
          <SGroupContent>
            <h1 className="penFont" style={{ whiteSpace: 'pre-wrap' }}>
              {data?.content}
            </h1>
          </SGroupContent>
          <i className="fa fa-quote-right"></i>
        </div>
        {data?.meetingTime && (
          <>
            <div style={{ color: 'rgba(0,0,0,0.4)', fontSize: '14px' }}>
              일정
            </div>
            <SGroupContent>{data?.meetingTime}</SGroupContent>
          </>
        )}
        {data?.members && (
          <>
            <div style={{ color: 'rgba(0,0,0,0.4)', fontSize: '14px' }}>
              멤버
            </div>
            <SGroupContent>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginTop: '-6px' }}>
                  {data?.members &&
                    data.members?.split(',').map((v) => {
                      return (
                        <Badge
                          pill
                          bg={v === data?.userName ? 'info' : 'light'}
                          style={
                            v === data?.userName
                              ? {
                                  color: 'white',
                                  marginRight: '5px',
                                }
                              : { marginRight: '5px' }
                          }
                        >
                          {v === data?.userName && (
                            <i
                              className="fa-solid fa-crown"
                              style={{
                                marginBottom: '0',
                                color: '#ffd700',
                                top: '0',
                              }}
                            ></i>
                          )}
                          {v}
                        </Badge>
                      );
                    })}
                </div>
              </div>
            </SGroupContent>
          </>
        )}
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          {data?.status === 'ON' ? (
            data?.memberStatus === 'Y' || data?.memberStatus === 'L' ? (
              <Link
                to={{
                  pathname: `/sgroup/detail/${data?.id}`,
                  state: { memberStatus: data?.memberStatus, test: 'test' },
                }}
              >
                <BtnLg color="#3e4954" type="button">
                  스터디입장
                </BtnLg>
              </Link>
            ) : data?.status === 'OFF' ||
              data?.memberCount === data?.limitCount ? (
              <BtnLg color="rgba(0,0,0,0.3)" type="button" disabled>
                정원초과
              </BtnLg>
            ) : data?.memberStatus === 'N' ? (
              <BtnLg color="#2bc155" type="button" onClick={handleJoin}>
                가입신청
              </BtnLg>
            ) : (
              <BtnLg color="#d9534f" type="button" onClick={handleCancel}>
                신청취소
              </BtnLg>
            )
          ) : (
            <BtnLg color="rgba(0,0,0,0.3)" type="button" disabled>
              승인중
            </BtnLg>
          )}
          <BtnLg color="rgba(0,0,0,0.3)" onClick={handleClose} type="button">
            닫기
          </BtnLg>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
`;

const SGroupUser = styled.div`
  font-size: 14px;
  color: #ec7807;
  margin: 5px 0;
`;

const SGroupContent = styled.div`
  font-size: 16px;
  padding: 10px 10px 30px 10px;
`;

export default withRouter(JoinModal);
