import { useQuery } from 'react-query';
import { fetchPblProblems } from 'apis/pbl';

const useFetchPblProblemsHook = ({ unitId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPblProblems', unitId],
    queryFn: async () => await fetchPblProblems({ unitId }),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    refetchInterval: 5000,
  });
  return { ...queryReturns };
};

export default useFetchPblProblemsHook;
