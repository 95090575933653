import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

import { useSubmitTaskFile, useDeleteTaskFile } from 'hooks';
import AuthContext from 'context/Auth.context';
import { confirmSwal, errorMessage, successMessage } from 'utilities/swal';

const TaskSubmit = ({ data, realTime }) => {
  const { id, type } = useParams();
  const auth = useContext(AuthContext);
  const taskInputRef = useRef(null);
  const [taskFileName, setTaskFileName] = useState('');

  const isValidTime =
    new Date(data.endDate) >= new Date(realTime.slice(0, 10)) &&
    new Date(data.startDate) <= new Date(realTime.slice(0, 10));

  const isBefore = new Date(data.startDate) > new Date(realTime.slice(0, 10));
  const isAfter = new Date(data.endDate) < new Date(realTime.slice(0, 10));

  const { mutateAsync: submitTask, isLoading: isSubmitting } =
    useSubmitTaskFile({
      type,
      id,
    });
  const { mutateAsync: removeTask, isLoading: isRemoving } = useDeleteTaskFile({
    type,
    id,
  });

  const handleTaskSubmitButton = () => {
    taskInputRef.current.click();
  };
  const handleInputChange = () => {
    if (taskInputRef.current.files[0]) {
      if (taskInputRef.current.files[0].size > 1024 * 1024 * 20) {
        taskInputRef.current.value = '';
        setTaskFileName('');
        errorMessage({ text: '파일 용량 제한은 20MB 입니다.' });
      } else {
        setTaskFileName(taskInputRef.current.files[0].name);
      }
    }
  };
  const cancleInputFile = () => {
    taskInputRef.current.value = '';
    setTaskFileName('');
  };

  const submitTaskFile = async () => {
    if (isSubmitting) return;

    const confirm = await confirmSwal({
      title: '해당 과제를 제출하시겠습니까?',
      confirmButton: '제출',
    });

    if (confirm) {
      const formData = new FormData();
      formData.append('userId', auth.userId);
      formData.append('classId', data.classId);
      formData.append('subClassId', data.subClassId);
      formData.append('taskId', data.id);
      formData.append('file', taskInputRef.current.files[0]);

      const { status } = await submitTask({ formData });
      setTaskFileName('');
      if (status === 201) {
        successMessage({ text: `해당 과제가 정상적으로 제출되었습니다.` });
      }
    }
  };

  const deleteSubmittedTaskFile = async () => {
    if (isRemoving) return;

    const confirm = await confirmSwal({
      title: '제출한 과제를 삭제하시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      const { status } = await removeTask({ id });
      if (status === 200) {
        successMessage({ text: `제출된 과제가 정상적으로 삭제되었습니다.` });
      }
    }
  };

  return (
    <DetailTaskSubmitBox>
      <div className="title">
        <h2>과제 제출 기간 :</h2>
        <span>
          <i className="fa-regular fa-clock mr-2" />
          {data?.startDate || ''.slice(0, 10)} ~{' '}
          {data?.endDate || ''.slice(0, 10)}
        </span>
      </div>

      {isValidTime ? (
        <div className="file-upload">
          {!taskFileName && !data?.taskSubmitFile.originalFileName && (
            <span onClick={handleTaskSubmitButton}>파일 업로드</span>
          )}

          {data?.taskSubmitFile.originalFileName && (
            <TaskFileBox>
              <span>
                <i className="fa-solid fa-file-circle-check mr-2" />
                {data?.taskSubmitFile?.originalFileName}
              </span>
              <span className="delete-task" onClick={deleteSubmittedTaskFile}>
                {isRemoving ? <CircularProgress size={20} /> : '제출 취소'}
              </span>
            </TaskFileBox>
          )}

          {!data?.taskSubmitFile.originalFileName && taskFileName && (
            <TaskFileBox>
              <span>{taskFileName}</span>
              <div>
                <span onClick={cancleInputFile}>취소</span>
                <span className="submit-button" onClick={submitTaskFile}>
                  {isSubmitting ? <CircularProgress size={20} /> : '제출'}
                </span>
              </div>
            </TaskFileBox>
          )}
        </div>
      ) : (
        <NotValidTime>
          {isBefore && '과제 제출 기간이 아닙니다.'}
          {isAfter && (
            <TaskSubmitFileBox>
              <span>마감된 과제입니다.</span>
              <div>
                <span>
                  <i className="fa-solid fa-file-circle-check submited" />
                  <b>제출된 과제물 : </b>
                  {data?.taskSubmitFile?.originalFileName || '미제출'}
                </span>
              </div>
            </TaskSubmitFileBox>
          )}
        </NotValidTime>
      )}

      <input
        style={{ display: 'none' }}
        ref={taskInputRef}
        type="file"
        name="task"
        id="task"
        accept="application/x-zip,
              application/msword,
              application/pdf,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.ms-powerpoint,
              application/mspowerpoint,
              application/x-mspowerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              application/vnd.hancom.hwp,
              application/vnd.hancom.hwt,
              application/vnd.hancom.hml,
              application/vnd.hancom.hwpx,
              application/zip,
              application/x-zip-compressed,
              application/octet-stream"
        onChange={handleInputChange}
      />
    </DetailTaskSubmitBox>
  );
};

const DetailTaskSubmitBox = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 15px;

  > div.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 10px;

    h2 {
      margin-bottom: 0;
    }
    span {
      font-size: 1.8rem;
    }
  }

  > div.file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

    > span {
      max-width: 200px;
      font-size: 14px;
      padding: 8px 20px;
      color: #333;
      background-color: #aae787;
      border-radius: 5px;
      box-shadow: 1px 2px 2px #eee;
      cursor: pointer;
      font-weight: 500;
      transition: 0.3s;
      text-align: center;

      &:hover {
        background-color: #92de66;
        color: #000;
      }

      &.disabled {
        background-color: #afafaf;
        cursor: not-allowed;
        color: #fff;
      }
    }
  }
`;

const TaskFileBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    display: inline-block;
    width: fit-content;
    font-size: 1.5rem;
    margin-bottom: 20px;

    > i.submited {
      margin-right: 5px;
    }
  }

  > span.delete-task {
    font-size: 1.3rem;
    padding: 10px;
    display: grid;
    place-items: center;
    width: 100px;
    border: 1px solid #eee;
    color: #fff;
    background-color: #ff7878;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 0;

    &:hover {
      filter: brightness(90%);
    }
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 10px;

    span {
      padding: 10px;
      display: grid;
      place-items: center;
      width: 100px;
      border: 1px solid #eee;
      color: #fff;
      background-color: #ff7878;
      border-radius: 15px;
      font-size: 1.3rem;
      cursor: pointer;
      font-weight: 500;

      &.submit-button {
        background-color: #92de66;
        color: #333;
      }

      &:hover {
        filter: brightness(90%);
      }
    }
  }
`;

const NotValidTime = styled.div`
  color: #777;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
`;

const TaskSubmitFileBox = styled.div`
  div {
    > span {
      display: inline-block;
      width: fit-content;
      font-size: 1.5rem;
      margin-top: 20px;
      color: #555;

      > i {
        margin-right: 3px;
        color: #555;
      }
    }
  }
`;

export default TaskSubmit;
