import React, { useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import { useSubmitGreetingComment, useSubmitInquiryComment } from 'hooks';
import AuthContext from 'context/Auth.context';
import CustomerCommentItem from './CustomerCommentItem';
import { successMessage } from 'utilities';
import { useFetchInquiryComments } from '../../../hooks';

const CustomerComment = ({ replyList = [], boardType = '' }) => {
  const { id } = useParams();
  const { name } = useContext(AuthContext);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: inquiryComments } = useFetchInquiryComments({ id });

  const comments = boardType === 'contact' ? inquiryComments || [] : replyList;

  const { mutateAsync: submitInquiryComment, isLoading: isInquiryLoading } =
    useSubmitInquiryComment({
      postId: id,
    });
  const { mutateAsync: submitGreetingComment, isLoading: isGreetingLoading } =
    useSubmitGreetingComment({
      postId: id,
    });

  const handleSubmitComment = async (form) => {
    let response;
    if (boardType === 'contact') {
      response = await submitInquiryComment({
        author: name,
        content: form.commentText,
      });
    } else if (boardType === 'greeting') {
      response = await submitGreetingComment({
        author: name,
        content: form.commentText,
      });
    }
    if (response.status === 201) {
      setValue('commentText', '');
      successMessage({ text: '댓글이 등록되었습니다.' });
    }
  };

  const isLoadingState =
    boardType === 'contact' ? isInquiryLoading : isGreetingLoading;

  return (
    <Wrapper>
      {boardType === 'contact' && (
        <WriteCommentBox>
          <form onSubmit={handleSubmit(handleSubmitComment)}>
            <div>
              <textarea
                name="comment"
                maxLength={500}
                placeholder="댓글을 입력해주세요."
                {...register('commentText', { required: true })}
              ></textarea>

              <button type="submit">
                {isLoadingState ? <CircularProgress size={30} /> : '등록하기'}
              </button>
            </div>
            {errors.commentText?.type && (
              <StyledError>댓글을 입력해주세요.</StyledError>
            )}
          </form>
        </WriteCommentBox>
      )}

      {boardType === 'greeting' && (
        <WriteCommentBox>
          <form onSubmit={handleSubmit(handleSubmitComment)}>
            <div>
              <textarea
                name="comment"
                maxLength={500}
                placeholder="댓글을 입력해주세요."
                {...register('commentText', { required: true })}
              ></textarea>

              <button type="submit">
                {isLoadingState ? <CircularProgress size={30} /> : '등록하기'}
              </button>
            </div>
            {errors.commentText?.type && (
              <StyledError>댓글을 입력해주세요.</StyledError>
            )}
          </form>
        </WriteCommentBox>
      )}

      <CommentList>
        <div className="count">
          댓글<span>{comments.length || 0}</span>개
        </div>

        <div className="comment-list">
          {!!comments.length ? (
            comments.map((e, index) => (
              <CustomerCommentItem
                key={index}
                replyInfo={e}
                boardType={boardType}
              />
            ))
          ) : (
            <div className="no-comment">댓글이 존재하지 않습니다.</div>
          )}
        </div>
      </CommentList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const WriteCommentBox = styled.div`
  padding: 18px;
  background: rgb(246, 246, 246);

  form {
    width: 100%;

    > div {
      display: flex;

      textarea {
        flex: 1 1 0;
        border: 1px solid #e4e4e4;
        outline: none;
        padding: 10px;
        resize: none;
        font-size: 14px;
      }

      button {
        width: 200px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        background: rgb(42, 34, 23);
        font-size: 16px;
        appearance: none;
        border: none;
        cursor: pointer;

        svg {
          color: #fff;
        }
      }
    }
  }
`;

const CommentList = styled.div`
  margin-top: 50px;

  div.count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

    span {
      margin-left: 5px;
      color: #ec7422;
    }
  }

  div.comment-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;

    div.no-comment {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      font-size: 16px;
      color: rgb(34, 34, 34);
    }

    div.comment {
      padding: 26px 22px;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
    }
  }
`;

const StyledError = styled.div`
  color: #ff1c1c;
  font-size: 12px;
  margin-top: 0;
  width: 100%;
`;

export default CustomerComment;
