import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { birthdayFormatter } from 'modules/libs/util';
import AuthContext from 'context/Auth.context';
import { handleErrorMessage } from 'utilities';

const useStudents = () => {
  const token = localStorage.getItem('token');
  const auth = useContext(AuthContext);
  const queryReturns = useQuery(
    ['students', token],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/${auth.classId}`
        )
        .then((res) => {
          return res.data.map((v) => {
            if (v.regDate) {
              return { ...v, regDate: v.regDate.split('T')[0] };
            }
            if (v.birthday) {
              return { ...v, birthday: birthdayFormatter(v.birthday) };
            }
            return v;
          });
        })
        .catch(handleErrorMessage);
    },
    { enabled: !!token }
  );

  return { ...queryReturns };
};

export default useStudents;
