import React from 'react';
import { Link, useParams } from 'react-router-dom';
import dompurify from 'dompurify';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';

import { useDownload, useFetchLibraryDetail } from 'hooks';
import { linkify } from 'utilities/common';
import { Loading } from '../../../ui';

const Detail = ({ history }) => {
  const { id } = useParams();

  const { data: detailData, isLoading: isDetailLoading } =
    useFetchLibraryDetail({ id });
  const { post, files, adjacentPostInfo } = detailData || {};

  const { refetch, isLoading } = useDownload(
    `${process.env.REACT_APP_IP}/users/library/${id}/files/${
      detailData?.files.length !== 0 && detailData?.files[0].id
    }`
  );

  const handleDownload = () => {
    if (isLoading) return;
    refetch();
  };

  const handleMoveToOtherDetail = (id) => {
    history.push(`/customer/library/${id}`);
  };

  if (isDetailLoading) return <Loading />;
  return (
    <Wrapper>
      <TitleBox>
        <div className="title">
          <h2>{post?.title}</h2>
        </div>

        <div className="sub-info">
          <div>
            <span>{post?.createdAt.slice(0, 10)} |</span>
            <span>조회 {post?.hitCount}</span>
          </div>
          <div>
            <span>
              <i className="fa-solid fa-user" />
              {post?.author}
            </span>
          </div>
        </div>
      </TitleBox>

      <ContentBox
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(linkify(post?.content || '')),
        }}
      />

      <FileBox>
        {files?.map((file, index) => {
          return (
            <p key={index}>
              <span className="file-title">첨부파일{index + 1}</span>
              <span
                onClick={handleDownload}
                style={{ cursor: 'pointer', color: '#1e88e5' }}
              >
                <i className="las la-file-download"></i>&nbsp;
                {file.originalFileName}
              </span>
            </p>
          );
        })}
      </FileBox>
      <span>
        ※ 자료실에 올려놓은 자료는 저희 SSLC의 자산입니다. 사전 승낙없이 복제
        또는 상업적으로 이용하실 수 없습니다.
      </span>

      <MoveDetailBox>
        <div>
          {adjacentPostInfo?.prevId && (
            <span
              onClick={() => handleMoveToOtherDetail(adjacentPostInfo?.prevId)}
            >
              <i className="fa-solid fa-chevron-left" />
              이전글
            </span>
          )}
        </div>

        <div>
          {adjacentPostInfo?.nextId && (
            <span
              onClick={() => handleMoveToOtherDetail(adjacentPostInfo?.nextId)}
            >
              다음글
              <i className="fa-solid fa-chevron-right" />
            </span>
          )}
        </div>
      </MoveDetailBox>

      <Link className="go-list" to="/customer/library">
        목록으로
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-top: 2px solid #333;

  padding-bottom: 200px;

  > span {
    padding-left: 10px;
    font-size: 12px;
    color: #777;
  }

  > a {
    font-size: 16px;
    width: 150px;
    height: 40px;
    background: #ec7422;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    margin: 50px auto 0;

    &:hover {
      filter: brightness(105%);
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  min-height: 60px;
  padding: 10px;
  overflow: hidden;

  > div.title {
    display: flex;
    max-width: 75%;

    h2 {
      max-width: 100%;
      margin-bottom: unset;
      margin-left: 5px;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  > div.sub-info {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;

      i {
        margin-right: 5px;
      }
    }
  }
`;

const ContentBox = styled.div`
  padding: 20px;
  font-size: 16px;
  min-height: 300px;

  border-bottom: 1px solid #eee;

  p {
    font-size: 16px;
    color: #333;
  }
`;

const FileBox = styled.div`
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 0 10px;

  > p {
    font-size: 16px;
    color: #333;

    > span.file-title {
      display: inline-block;
      min-width: 100px;
      font-weight: bold;
    }
  }
`;

const MoveDetailBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  span {
    display: inline-block;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 15px;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
`;

export default Detail;
