import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from '../../components';

const TaskBoardList = ({ list = [], isListLoading = false, realTime }) => {
  const history = useHistory();
  const { type } = useParams();

  const isSubmitDateAfter = (endDate, startDate) => {
    return (
      new Date(realTime.slice(0, 10)) > new Date(endDate || '') ||
      new Date(realTime.slice(0, 10)) < new Date(startDate || '')
    );
  };

  const handleGoToDetailPage = (id) => {
    history.push(`/myclass/board/${type}/${id}`);
  };

  return (
    <>
      {isListLoading ? (
        Array(5)
          .fill('')
          .map((_, index) => (
            <ListSkeleton key={index}>
              <div />
              <div />
            </ListSkeleton>
          ))
      ) : (
        <StyledBoardList>
          {list.map((list, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td
                    className="title"
                    onClick={() => handleGoToDetailPage(list.id)}
                  >
                    <span>{list.title}</span>
                  </td>

                  {!!list.attachedFile && (
                    <td
                      className="attached"
                      onClick={() => handleGoToDetailPage(list.id)}
                    >
                      <span>
                        <img src="/icons/appendix.png" alt="appendix icon" />
                      </span>
                    </td>
                  )}

                  <td
                    className="submitDate"
                    onClick={() => handleGoToDetailPage(list.id)}
                  >
                    <span>
                      <Badge
                        size="small"
                        style={{ fontSize: '1.3em' }}
                        color={
                          isSubmitDateAfter(list.endDate, list.startDate)
                            ? '#c0c0c0'
                            : '#28a745'
                        }
                      >
                        {(list.startDate || '').slice(5, 10)} ~{' '}
                        {(list.endDate || '').slice(5, 10)}
                      </Badge>
                    </span>
                  </td>

                  <td
                    className="author"
                    onClick={() => handleGoToDetailPage(list.id)}
                  >
                    <span>{list.author || list.userName}</span>
                  </td>

                  <td
                    className="date"
                    onClick={() => handleGoToDetailPage(list.id)}
                  >
                    <span>{(list.createdAt || list.regDate).slice(0, 10)}</span>
                    <span>
                      {(list.createdAt || list.regDate).slice(11, 16)}
                    </span>
                  </td>

                  <td
                    className="hits"
                    onClick={() => handleGoToDetailPage(list.id)}
                  >
                    <span>
                      <i className="fa-solid fa-eye"></i>
                      {list.hitCount || 0}
                    </span>
                  </td>
                </tr>
              </tbody>
            );
          })}

          {list.length === 0 && (
            <NoResult>
              <tr>
                <td>등록된 게시글이 없습니다.</td>
              </tr>
            </NoResult>
          )}
        </StyledBoardList>
      )}
    </>
  );
};

const ListSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &:first-child {
    border-top: 2px solid #000;
  }

  > div {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;

    &:first-child {
      background-color: #f8f8f8;
    }
  }
`;

const StyledBoardList = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-top: 2px solid #000;
  font-size: 16px;
  font-weight: bold;
  background-color: #fdfdfd;

  > tbody {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #333;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    text-align: center;

    &:nth-child(2n-1) {
      background-color: #f8f8f8;
    }

    &:hover {
      background-color: #e3e3e3;
    }

    > tr {
      width: 100%;
      color: #333;
      transition: 0.2s;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #eee;
      padding: 0 20px;

      > td {
        flex: 1;
        font-size: 12px;
        height: 100%;

        > span {
          display: inline-block;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          color: #777;
          font-size: 12px;
        }
      }
    }

    td.title {
      display: flex;
      align-items: center;
      text-align: left;
      overflow: hidden;
      font-size: 15px;
      max-width: 710px;

      &.more-padding {
        padding-left: 30px;
      }

      > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        small {
          color: #777;
        }
      }

      img {
        max-width: 15px;
        vertical-align: middle;
        margin-right: 2px;
        padding-bottom: 2px;
      }
    }

    td.badge {
      display: grid;
      place-items: center;
      max-width: 120px;
    }

    td.submitDate,
    td.author,
    td.date,
    td.reply,
    td.hits {
      max-width: 110px;
      font-weight: 400;
      color: #777;
    }

    td.reply {
      max-width: 110px;

      span {
        margin-left: 20px;

        i {
          margin-right: 3px;
        }
      }
    }

    td.hits {
      max-width: 50px;

      span {
        margin-left: 20px;

        i {
          margin-right: 3px;
        }
      }
    }

    td.submitDate {
      max-width: 90px;

      > p {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        line-height: 1;
        font-weight: bold;
      }

      p.after {
        font-weight: normal;
      }
    }

    td.author {
      max-width: 90px;
    }

    td.attached {
      max-width: 40px;
    }

    td.delete {
      max-width: 20px;
      background-color: #f56767;
      color: #fff;
      height: 99%;

      &:hover {
        background-color: #fa3535;
      }
    }

    td.date {
      display: flex;
      justify-content: center;
      column-gap: 4px;
      height: 100%;
      text-align: center;
    }
  }
`;

const NoResult = styled.tbody`
  width: 100%;
  min-height: 300px;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: transparent !important;
  cursor: default !important;

  > tr {
    border-bottom: none !important;
  }

  tr > td {
    color: #ec7422 !important;
    font-size: 16px !important;
  }
`;

export default TaskBoardList;
