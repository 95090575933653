import { useMutation, useQueryClient } from 'react-query';

import { deleteInquiryComment } from 'apis/customer';

const useRemoveInquiryComment = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ postId, replyId }) => {
      return await deleteInquiryComment({ postId, replyId });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetchInquiryComments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetchContactReplyNotification'],
      });
    },
  });
  return { ...queryReturns };
};

export default useRemoveInquiryComment;
