import { default as styled } from 'styled-components';
import { BtnLg } from '../../../teacher/style';

export const BtnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`;

export const ImgWrapper = styled.div`
  padding: 20px;
`;

export const IconBtn = styled.div`
  width: 130px;
  height: 130px;
  background-color: ${(props) =>
    props.disabled ? 'rgba(0,0,0,0.4)' : '#fc9918'};
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
  transition: all 0.1s linear;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'rgba(0,0,0,0.4)' : '#f14a16'};
  }

  img {
    margin-top: 5px;
    width: 60%;
    height: 60%;
  }
`;

export const Title = styled.h3`
  text-align: center;
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? '700' : '500')};
  color: ${(props) => props.color};
`;

export const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }

  span:not(.disabled) {
    position: absolute;
    right: 10px;
    top: 30%;
    color: #fc9918;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
    &:hover {
      filter: brightness(90%);
    }
  }
  span.disabled {
    position: absolute;
    right: 10px;
    top: 30%;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export const Msg = styled.div`
  text-align: center;
  font-size: ${(props) => (props.size ? props.size : '12px')};
  color: ${(props) => props.color};
  margin-bottom: 10px;
`;

export const AttendBtn = styled(BtnLg)`
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AttendBtnWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const ResultMsg = styled.div`
  border-radius: 5px;
  width: 100%;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 12px;
  margin-bottom: 40px;
`;
