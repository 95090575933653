import { useEffect, useRef, useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ContactReplyAlarmUrl } from '../../apis';

const useSSE = () => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();
  const eventSourceRef = useRef(null);

  const [_errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    const sseConnect = () => {
      const eventSource = new EventSourcePolyfill(ContactReplyAlarmUrl, {
        headers: {
          Authorization: token,
        },
      });

      eventSource.addEventListener('newComment', (event) => {
        const parsedData = JSON.parse(event.data);
        if (!!parsedData.postId) {
          toast('문의글에 새 댓글이 등록되었습니다. ✉️', {
            hideProgressBar: true,
            style: {
              color: '#fff',
              fontWeight: 'bold',
              backgroundColor: '#fc9918',
            },
            onClick: () => {
              const { postId } = parsedData;
              if (postId) {
                history.push(`/customer/contact/${postId}`);
              }
            },
          });
          queryClient.invalidateQueries({
            queryKey: ['fetchContactReplyNotification'],
          });
        }
      });

      eventSource.onerror = (_error) => {
        eventSource.close();
        setErrorCount((prevCount) => {
          const newCount = prevCount + 1;

          if (newCount === 3) {
            eventSource.close();
            Sentry.captureException(
              new Error('SSE connection error occurred 3 times')
            );

            return newCount;
          }

          sseConnect();
          return newCount;
        });
      };

      eventSourceRef.current = eventSource;
    };

    sseConnect();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [history]);

  return null;
};

export default useSSE;
