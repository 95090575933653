import React, { useContext, useRef } from 'react';
import { withRouter } from 'react-router';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import ReactLoading from 'react-loading';

import styles from './select.module.scss';
import THEME from 'state/theme';
import Message from 'utilities/Message';
import AuthContext from 'context/Auth.context';
import { handleErrorMessage, warningMessage } from 'utilities';

const Select = ({ data, history }) => {
  const auth = useContext(AuthContext);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleOpenLiveCoding = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_IP}/users/practice/live-coding`;
    Axios.post(url, {})
      .then((response) => {
        if (response.data === 'FAIL') {
          toast.error(
            '라이브 코딩 시작에 실패하였습니다. 관리자에게 문의 해주시기 바랍니다.'
          );
        } else {
          window.open(
            `${process.env.REACT_APP_HTTP_IP}:${response.data}/?folder=/home/coder/projects/`,
            '_blank'
          );
        }
        setLoading(false);
      })
      .catch(handleErrorMessage);
  };

  const handleAws = (urldata) => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/practice/cloud`;
    Axios.get(url)
      .then((response) => {
        if (!response.data.data.id) {
          warningMessage({
            text: '등록된 AWS 계정이 없습니다.',
          });
        } else {
          history.push(urldata);
        }
      })
      .catch(handleErrorMessage);
  };

  let listStatistic = data
    .map((item, index) => (
      <div
        key={index}
        className={styles.wrapper}
        style={{
          borderColor: THEME.color,
        }}
      >
        <div className={styles.card}>
          <div className={styles.icon}>
            <img src={item.icon} alt="card_icon" />
          </div>

          <h3
            className="secomain"
            style={{
              color: THEME.color,
              fontFamily: 'Noto Sans KR',
              textAlign: 'center',
              fontSize: '18px',
            }}
          >
            {item.subtitle}
          </h3>
          <p className="secotext" style={{ fontSize: '14px' }}>
            {item.text.replace(/^(.{60}[^\s]*).*/, '$1')}
          </p>
          <div className={styles.button}>
            {item.id === 1 ? (
              <div
                onClick={() => handleAws(item.url)}
                data-before="&#xf105;"
                className={styles.linkBtn}
              >
                <span className="secosub2">시작하기</span>
              </div>
            ) : item.id === 0 ? (
              loading ? (
                <div className={styles.loadingBtn}>
                  <ReactLoading
                    type="spin"
                    color="#2c95d2"
                    height={30}
                    width={30}
                  />
                </div>
              ) : (
                <div
                  onClick={handleOpenLiveCoding}
                  data-before="&#xf105;"
                  className={styles.linkBtn}
                >
                  <span className="secosub2">시작하기</span>
                </div>
              )
            ) : (
              <a
                data-before="&#xf105;"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.linkBtn}
              >
                <span className="secosub2">시작하기</span>
              </a>
            )}
          </div>
        </div>
      </div>
    ))
    .slice(0, 4);

  return (
    <div ref={ref}>
      <div className={styles.statistic}>{listStatistic}</div>
    </div>
  );
};

export default withRouter(Select);
