import React from 'react';
import { Btn } from '../../../teacher/style';
import { ResultMsg, Wrapper } from './style';

const Result = ({ onClose, selectedBtn }) => {
  return (
    <Wrapper style={{ marginBottom: '30px' }}>
      <ResultMsg>{`${selectedBtn}인증이 완료되었습니다.`}</ResultMsg>
      <Btn color="#fc9918" style={{ width: '40%' }} onClick={onClose}>
        닫기
      </Btn>
    </Wrapper>
  );
};

export default Result;
