export const colTitles = [
  '번호',
  '아이디',
  '이름',
  '이메일',
  '전화번호',
  '성별',
  '생년월일',
  '상담횟수(1차/2차/3차)',
];

export const sortedKeys = [
  'userId',
  'name',
  'email',
  'phone',
  'gender',
  'birthday',
  'interviewCount',
];

export const colTitlesRewatch = [
  // "번호",
  '시청일자',
  '이름',
  '수업',
  '1차시',
  '2차시',
  '3차시',
  '4차시',
  '5차시',
  '6차시',
  '7차시',
];
export const sortedKeysRewatch = [
  'regDate',
  'name',
  'lecture',
  'class1',
  'class2',
  'class3',
  'class4',
  'class5',
  'class6',
  'class7',
];
