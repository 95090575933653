import { useQuery } from 'react-query';

import { fetchPblUnits } from 'apis/pbl';

const useFetchPblUnits = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchPblUnits'],
    queryFn: async () => await fetchPblUnits(),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    keepPreviousData: true,
    staleTime: 5000,
    cacheTime: 5000,
    refetchInterval: 60000,
  });
  return { ...queryReturns };
};

export default useFetchPblUnits;
