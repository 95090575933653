import axios from 'axios';
import { useMutation } from 'react-query';

const useEditProblem = () => {
  const { mutate, isLoading } = useMutation(async (data) => {
    return await axios
      .put(`${process.env.REACT_APP_IP}/teachers/exam/creation/problem`, data)
      .then((res) => {
        return res.data;
      });
  });

  return { mutate, isLoading };
};

export default useEditProblem;
