import React from 'react';
import styled from 'styled-components';

import { usePagination } from 'hooks';

const Pagination = ({ page, total, perPage, move }) => {
  const maxPagingCount = 5;
  const {
    paging,
    page: currentPage,
    last,
  } = usePagination({
    page,
    total,
    perPage,
    maxPagingCount,
  });

  return (
    <StyledPagination>
      <div>
        <div className="item left-item arrow double" onClick={() => move(1)}>
          <i className="left-arrow icon" />
        </div>
        <div
          className="item left-item arrow"
          onClick={() => move(Math.max(currentPage - 1, 1))}
        >
          <i className="left-arrow icon" />
        </div>
        {!!paging.length &&
          paging.map((value) => (
            <div
              key={value}
              className={`item ${value === currentPage ? 'active' : ''}`}
              onClick={() => move(value)}
            >
              {value}
            </div>
          ))}
        <div
          className="item right-item arrow"
          onClick={() => move(Math.min(currentPage + 1, last))}
        >
          <i className="right-arrow icon" />
        </div>
        <div
          className="item right-item arrow double"
          onClick={() => move(last)}
        >
          <i className="right-arrow icon" />
        </div>
      </div>
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  > div {
    display: inline-flex;
    position: relative;
    gap: 20px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    cursor: pointer;
    color: #b6b6b6;
    font-size: 14px;
  }

  .item.active {
    background: #b6b6b6;
    color: #fff;
  }

  .arrow.item {
    width: 11px;
    height: 10px;
    position: relative;
    padding: 0;
    border-radius: 0;
    top: 50%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }

  .left-item {
    left: -10px;
    background-image: url('/icons/before_arrow02.png');
  }

  .left-item.double {
    background-image: url('/icons/before_arrow01.png');
  }

  .right-item {
    right: -10px;
    background-image: url('/icons/after_arrow01.png');
  }

  .right-item.double {
    right: -10px;
    background-image: url('/icons/after_arrow02.png');
  }

  .item.left-item.arrow.double:before {
    content: '';
    width: 13px;
    height: 13px;
    position: relative;
    padding: 0;
    border-radius: 0;
    top: 50%;
    left: -1.5px;
  }

  .item.right-item.arrow.double:before {
    content: '';
    width: 13px;
    height: 13px;
    position: relative;
    padding: 0;
    background: transparent;
    border-radius: 0;
    top: 50%;
    right: -1.5px;
  }
`;

export default Pagination;
