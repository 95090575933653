import axios from 'axios';

import { BASE_URL } from './config';

/* 사용자 로그인 */
export const login = async ({ userId, password }) => {
  return await axios.post(`${BASE_URL}/users/login`, { userId, password });
};

/* 사용자 로그아웃 */
export const logout = async ({ token }) => {
  return await axios.post(
    `${BASE_URL}/users/logout`,
    {},
    {
      headers: {
        authorization: token,
      },
    }
  );
};

/* 새 공지사항 알림 조회 */
export const fetchNewNotice = async () => {
  return await axios.get(`${BASE_URL}/users/myclass/alarms`);
};

/* 학생 문의 게시글에 관리자 댓글 알림 */
export const fetchContactReplyNotification = async () => {
  return await axios.get(`${BASE_URL}/users/inquiries/alarms`);
};

/* [관리자 롤]학생 문의 게시글에 신규 학생 댓글 알림 */
export const fetchContactStudentReplyNotification = async () => {
  return await axios.get(`${BASE_URL}/manager/inquiries/alarms`);
};

/* 학생 첫 접속 시 기존 비밀번호 체크 */
export const checkStudentpassword = async ({ userId, password }) => {
  return await axios.post(`${BASE_URL}/users/sign/auth`, { userId, password });
};

/* 학생 첫 접속 시 비밀번호 변경 */
export const changeStudentPassword = async ({ userId, password }) => {
  return await axios.put(`${BASE_URL}/users/sign/pw`, { userId, password });
};

/* 구글 캘린더 대한민국 공휴일 정보 fetch */
export const fetchKoreaHolidays = async ({
  calendarId,
  apiKey,
  startDate,
  endDate,
}) => {
  return await axios.get(
    `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}&orderBy=startTime&singleEvents=true&timeMin=${startDate}&timeMax=${endDate}`,
    {
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
    }
  );
};

/* [sse]문의하기 글에 신규 댓글 알림 URL */
export const ContactReplyAlarmUrl = `${process.env.REACT_APP_IP}/users/inquiries/notifications`;

/* 신규 댓글 읽음 처리 */
export const checkNewReply = async ({ postId }) => {
  return await axios.delete(
    `${process.env.REACT_APP_IP}/users/inquiries/notifications/${postId}`
  );
};
