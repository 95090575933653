import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

import { confirmSwal, errorMessage, successMessage } from 'utilities/swal';
import { useReservation } from 'hooks';
import styles from '../../../../modules/capability/Capatest.module.scss';
import ReserveModal from './ReserveModal';

const ReserveList = ({ list = [], isListLoading = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reservationData, setReservationData] = useState({});

  const { cancelReservation } = useReservation({});

  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '해당 상담 신청을 취소하시겠습니까?',
      confirmButton: '확인',
    });

    if (confirm) {
      try {
        const { status } = await cancelReservation.mutateAsync({ id });
        if (status === 204) {
          successMessage({ text: '상담 예약이 취소되었습니다.' });
        }
      } catch (error) {
        errorMessage({
          text: '상담 취소가 실패하였습니다. 문제가 반복될 경우 관리자에게 문의해주세요.',
        });
      }
    }
  };

  const handleEdit = async (data) => {
    setIsModalOpen(true);
    setReservationData(data);
  };

  useEffect(() => {
    if (isModalOpen) return;
    if (!isModalOpen) {
      setReservationData({});
    }
  }, [isModalOpen]);

  return (
    <>
      {isListLoading ? (
        Array(5)
          .fill('')
          .map((_, index) => (
            <ListSkeleton key={index}>
              <div />
              <div />
            </ListSkeleton>
          ))
      ) : (
        <>
          <StyledHeader>
            <b className="createdAt">신청일자</b>
            <b>상담예정일자</b>
            <b>신청 이유</b>
            <b className="state">상태</b>
            <b className="more"></b>
          </StyledHeader>
          <StyledBoardList>
            {list.map((list, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td className="createdAt">
                      <span>
                        <span>{list.regDate.slice(0, 10)}</span>
                      </span>
                    </td>

                    <td className="date">
                      <span>{list.consultingDate.slice(0, 10)}</span>
                      <span>{list.consultingDate.slice(11, 16)}</span>
                    </td>

                    <td className="purpose">
                      <span>{list.purpose}</span>
                    </td>

                    <td className="state">
                      {list.resultComment === '승인대기중' && (
                        <StateBox>
                          <span className="before">{list.resultComment}</span>
                        </StateBox>
                      )}
                      {list.resultComment === '상담 수락' && (
                        <StateBox>
                          <span className="wait">{list.resultComment}</span>
                        </StateBox>
                      )}
                      {list.resultComment === '상담 완료' && (
                        <StateBox>
                          <span className="done">{list.resultComment}</span>
                        </StateBox>
                      )}
                    </td>

                    <td className="more">
                      {list.resultComment === '승인대기중' ? (
                        <span className="edit" onClick={() => handleEdit(list)}>
                          <Tooltip
                            arrow
                            title={<TooltipMessage>신청 수정</TooltipMessage>}
                          >
                            <i className="fa-solid fa-pen-fancy" />
                          </Tooltip>
                        </span>
                      ) : (
                        <span />
                      )}

                      {list.resultComment !== '상담 완료' ? (
                        <span
                          className="delete"
                          onClick={() => handleDelete(list.id)}
                        >
                          <Tooltip
                            arrow
                            title={<TooltipMessage>신청 취소</TooltipMessage>}
                          >
                            <i className="fa-solid fa-trash-can" />
                          </Tooltip>
                        </span>
                      ) : (
                        <span />
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })}
            {list.length === 0 && (
              <NoResult>신청한 상담 내역이 없습니다.</NoResult>
            )}
          </StyledBoardList>
        </>
      )}

      {isModalOpen && (
        <div className={styles.modalWrapper}>
          <div
            className={styles.overlay}
            onClick={() => setIsModalOpen(false)}
          />
          <ReserveModal
            onClick={() => setIsModalOpen(false)}
            setModalOpen={setIsModalOpen}
            reservationData={reservationData}
            title="상담 수정"
          />
        </div>
      )}
    </>
  );
};

const ListSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &:first-child {
    border-top: 2px solid #000;
  }

  > div {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;

    &:first-child {
      background-color: #f8f8f8;
    }
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  > b {
    text-align: center;
    flex: 1;
    font-size: 1.6rem;
    font-weight: bold;

    &.more {
      flex: unset;
      display: inline-block;
      width: 80px !important;
    }

    &.state {
      flex: unset;
      display: inline-block;
      width: 100px !important;
    }

    &.createdAt {
      flex: unset;
      display: inline-block;
      width: 150px !important;
    }
  }
`;

const StyledBoardList = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-top: 2px solid #000;
  font-size: 16px;
  font-weight: bold;

  > tbody {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #333;
    transition: 0.2s;
    display: flex;
    text-align: center;

    &:nth-child(2n-1) {
      background-color: #f8f8f8;
    }

    &:hover {
      background-color: #e3e3e3;
    }

    > tr {
      width: 100%;
      color: #333;
      transition: 0.2s;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #eee;

      > td {
        flex: 1;
        font-size: 14px;
        height: 100%;

        > span {
          display: inline-block;
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          color: #777;
          font-size: 12px;
        }
      }
    }

    td.date {
      display: flex;
      justify-content: center;
      column-gap: 4px;
      height: 100%;
      text-align: center;
    }

    td.state {
      max-width: 100px;
    }

    td.createdAt {
      max-width: 160px;
    }

    td.more {
      max-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;

      > span {
        display: grid;
        place-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
        color: #fff;

        &:hover {
          filter: contrast(90%);
        }
      }

      > span.delete {
        border: 1px solid #555;
        i {
          color: #555;
        }
      }
      > span.edit {
        border: 1px solid #555;
        i {
          color: #555;
        }
      }
    }
  }
`;

const StateBox = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 12px;

  span {
    padding: 3px 6px;
    border-radius: 5px;

    &.before {
      background-color: #c0c0c0;
    }
    &.wait {
      background-color: #f4d06f;
    }
    &.done {
      background-color: #28a745;
    }
  }
`;

const NoResult = styled.div`
  width: 100%;
  min-height: 300px;
  display: grid;
  place-items: center;
  text-align: center;
  color: #ec7422;
`;

const TooltipMessage = styled.span`
  font-size: 1.1rem;
  padding: 8px 5px;
`;

export default ReserveList;
