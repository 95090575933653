import React, { Fragment } from 'react';
import styled from 'styled-components';

const TaskResultList = ({ list = [], isListLoading = false }) => {
  return (
    <>
      {isListLoading ? (
        Array(5)
          .fill('')
          .map((_, index) => (
            <ListSkeleton key={index}>
              <div />
              <div />
            </ListSkeleton>
          ))
      ) : (
        <StyledBoardList>
          {list.map((list, index) => {
            return (
              <Fragment key={index}>
                <tbody
                  className={index % 2 !== 0 ? 'submitted-task' : ''}
                  style={{
                    backgroundColor: index % 2 !== 0 ? '#f8f8f8' : '#fff',
                  }}
                >
                  <tr>
                    <td className="title">
                      <span className="title">{list.title}</span>
                    </td>

                    <td className="date">
                      <span>제출일시</span>
                      <span>
                        {list.submitDate.slice(0, 10)}{' '}
                        {list.submitDate.slice(11, 16)}
                      </span>
                    </td>

                    <td className="author">
                      <span>
                        {!list.comment && !list.score ? '-' : `${list.score}점`}
                      </span>
                    </td>

                    <td className="reply">
                      <span className="comment">
                        {list.comment && list.score ? (
                          <StateBox>
                            <span>채점 완료</span>
                          </StateBox>
                        ) : (
                          <StateBox>
                            <span className="wait">채점 대기중</span>
                          </StateBox>
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
                {list.comment && list.score && (
                  <CommentBox className="comment-list">
                    <tr className="comment">
                      <td className="icon">
                        <i className="fa-solid fa-reply" />
                      </td>
                      <td>{list.comment}</td>
                    </tr>
                  </CommentBox>
                )}
              </Fragment>
            );
          })}
          {list.length === 0 && (
            <NoResult>
              <tr>
                <td>제출한 과제가 없습니다.</td>
              </tr>
            </NoResult>
          )}
        </StyledBoardList>
      )}
    </>
  );
};

const CommentBox = styled.tbody`
  min-height: unset !important;

  > tr.comment {
    width: 100%;
    display: flex !important;
    align-items: flex-start !important;
    column-gap: 10px;
    padding: 10px;
    background: #eff4e1;
    filter: contrast(105%);
  }

  > tr.comment > td.icon {
    padding: 5px;

    i {
      transform: rotate(180deg);
    }
  }

  > tr.comment > td {
    flex: unset;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
`;

const ListSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  &:first-child {
    border-top: 2px solid #000;
  }

  > div {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;

    &:first-child {
      background-color: #f8f8f8;
    }
  }
`;

const StyledBoardList = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-top: 2px solid #000;
  font-size: 16px;
  font-weight: bold;
  background-color: #fdfdfd;

  > tbody.task {
    &:nth-child(2n - 1) {
      background-color: #f8f8f8 !important;
    }
  }

  > tbody {
    width: 100%;
    height: auto;
    color: #333;
    transition: 0.2s;
    display: flex;
    text-align: center;
    align-items: center;

    > tr {
      width: 100%;
      color: #333;
      transition: 0.2s;
      padding: 10px 0;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #eee;

      > td {
        flex: 1;
        font-size: 12px;
        height: 100%;

        > span:not(.comment, .title) {
          display: inline-block;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          color: #777;
          font-size: 12px;
        }
      }
    }

    td.title {
      display: flex;
      align-items: center;
      text-align: left;
      padding-left: 30px;
      overflow: hidden;
      font-size: 15px;
      max-width: 800px;

      > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        small {
          color: #777;
        }
      }
    }

    td.author,
    td.date,
    td.reply {
      max-width: 110px;
      font-weight: 400;
      color: #777;
    }

    td.reply {
      max-width: 120px;
    }

    td.author {
      max-width: 70px;
    }

    td.date {
      max-width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      column-gap: 4px;
      height: 100%;
      text-align: center;
    }
  }
`;

const NoResult = styled.tbody`
  width: 100%;
  min-height: 300px;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: transparent !important;
  cursor: default !important;

  > tr {
    border-bottom: none !important;
  }

  tr > td {
    color: #ec7422 !important;
    font-size: 16px !important;
  }
`;

const StateBox = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 12px;

  span {
    padding: 3px 6px;
    background-color: #28a745;
    border-radius: 5px;

    &.wait {
      background-color: #c0c0c0;
    }
  }
`;

export default TaskResultList;
