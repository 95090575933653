import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { useProblem } from 'hooks';
import { Modal2 } from '../../../components';
import Select from '../../../components/select/Select';
import ChoiceProblem from './InputComponents/ChoiceProblem';
import OXProblem from './InputComponents/OXProblem';
import ShortProblem from './InputComponents/ShortProblem';
import ChoiceProblemEmpty from './InputComponents/ChoiceProblemEmpty';
import OXProblemEmpty from './InputComponents/OXProblemEmpty';
import ShortProblemEmpty from './InputComponents/ShortProblemEmpty';

const EditProblem = ({
  examType,
  problemId,
  unitId,
  level,
  type,
  handleClose,
  examId,
}) => {
  const { data, isLoading } = useProblem(problemId);
  const [selected, setSelected] = useState(examType);

  useEffect(() => {
    if (!isLoading) {
      setSelected(data?.examType);
    }
  }, [isLoading, data]);

  const handleTypeChange = (e) => {
    setSelected(e);
  };

  return (
    <div>
      {
        <Modal2
          open={true}
          size="large"
          // large, medium or else
          onClose={handleClose}
        >
          {data ? (
            <>
              <Select defaultValue={examType} onChange={handleTypeChange}>
                <Select.Trigger>
                  {data.examType === 0
                    ? '객관식'
                    : data.examType === 2
                    ? 'O X 퀴즈'
                    : '주관식'}
                </Select.Trigger>
                <Select.OptionList>
                  <Select.Option key={0} value={0}>
                    객관식
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    O X 퀴즈
                  </Select.Option>
                  <Select.Option key={3} value={3}>
                    주관식
                  </Select.Option>
                </Select.OptionList>
              </Select>

              {selected === 2 ? (
                <OXProblem
                  onClose={handleClose}
                  problem={data}
                  unitId={unitId}
                  examId={examId}
                  level={level}
                  type={type}
                  examType={selected}
                />
              ) : selected === 0 ? (
                <ChoiceProblem
                  onClose={handleClose}
                  problem={data}
                  unitId={unitId}
                  examId={examId}
                  level={level}
                  type={type}
                  examType={selected}
                />
              ) : (
                <ShortProblem
                  problem={data}
                  onClose={handleClose}
                  unitId={unitId}
                  examId={examId}
                  level={level}
                  type={type}
                  examType={selected}
                />
              )}
            </>
          ) : (
            <>
              <Select
                defaultValue={examType}
                defaultText={
                  selected === 3
                    ? '주관식'
                    : selected === 2
                    ? 'O X 퀴즈'
                    : selected === 0
                    ? '객관식'
                    : '유형을 선택해주세요'
                }
                onChange={handleTypeChange}
              >
                <Select.Trigger></Select.Trigger>
                <Select.OptionList>
                  <Select.Option key={0} value={0}>
                    객관식
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    O X 퀴즈
                  </Select.Option>
                  <Select.Option key={3} value={3}>
                    주관식
                  </Select.Option>
                </Select.OptionList>
              </Select>
              <div>
                {selected === 0 && (
                  <ChoiceProblemEmpty
                    problem={data}
                    unitId={unitId}
                    examId={examId}
                    examType={selected}
                    level={level}
                    onClose={handleClose}
                  />
                )}

                {selected === 2 && (
                  <OXProblemEmpty
                    problem={data}
                    unitId={unitId}
                    examId={examId}
                    examType={selected}
                    level={level}
                    onClose={handleClose}
                  />
                )}
                {selected === 3 && (
                  <ShortProblemEmpty
                    problem={data}
                    unitId={unitId}
                    examId={examId}
                    examType={selected}
                    level={level}
                    onClose={handleClose}
                  />
                )}
              </div>
            </>
          )}
        </Modal2>
      }
    </div>
  );
};
const Btn = styled.button`
  background-color: #fc9918;
  border: 1px solid #fc9918;
  padding: 7px;
  margin: 0 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #fc9918;
  }
`;
export default EditProblem;
