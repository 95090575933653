import React, { useCallback, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dompurify from 'dompurify';
import { CircularProgress } from '@material-ui/core';

import AuthContext from 'context/Auth.context';
import {
  useDownloadClassFile,
  useFetchClassDetail,
  useRemoveClassBoard,
} from 'hooks';
import { confirmSwal, successMessage } from 'utilities';
import { Loading } from 'components/ui';
import {
  ClassDetailContainer,
  ClassDetailTitleBox,
  ClassDetailWrapper,
  DetailContentBox,
  DetailFileBox,
  DetailFormButtons,
} from './ClassBoardCommon';
import TaskSubmit from './TaskSubmit';

const TaskBoardDetail = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const auth = useContext(AuthContext);

  const { data, isLoading } = useFetchClassDetail({ type, id });
  const { data: detailData } = data || {};
  const { mutateAsync: deletePost, isLoading: isRemoving } =
    useRemoveClassBoard({ type });

  const { setFileId } = useDownloadClassFile({ type, id });

  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '해당 게시물을 삭제 하시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      const { status } = await deletePost({ id });
      if (status === 204 || status === 200) {
        successMessage({
          text: `과제가 삭제되었습니다.`,
        });
        history.push(`/myclass/board/${type}`);
      }
    }
  };

  const handleGoToEdit = () =>
    history.push(`/myclass/board/write/${type}/${id}`);

  const handleDownloadFile = (fileId) => {
    setFileId(fileId);
  };

  const isModified = useCallback(() => {
    return detailData?.regDate.slice(0, 10);
  }, [data, type]);

  const handleGoBack = () => {
    history.goBack();
  };

  if (isLoading) return <Loading height="600px" />;
  return (
    <ClassDetailContainer>
      <ClassDetailWrapper>
        <ClassDetailTitleBox>
          <div className="title">
            <h2>{detailData?.title}</h2>
          </div>

          <div className="sub-info">
            <div>
              <span>{isModified()} |</span>
              <span>조회 {detailData?.hitCount}</span>
            </div>
            <div>
              <span>
                <i className="fa-solid fa-user" />
                {detailData?.userName}
              </span>
            </div>
          </div>
        </ClassDetailTitleBox>
        <DetailContentBox
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(detailData?.content),
          }}
        />

        {detailData?.taskAttachment.originalFileName && (
          <DetailFileBox>
            <p>
              <span className="file-title">첨부파일</span>
              <span
                onClick={() =>
                  handleDownloadFile(detailData?.taskAttachment.id)
                }
                style={{ cursor: 'pointer', color: '#1e88e5' }}
              >
                <i className="las la-file-download"></i>&nbsp;
                {detailData?.taskAttachment.originalFileName}
              </span>
            </p>
          </DetailFileBox>
        )}

        {/* 과제 제출 관리 컴포넌트 */}
        <TaskSubmit data={detailData || {}} realTime={data?.timestamp || ''} />

        <DetailFormButtons>
          {['3', '4', '5'].includes(auth.type) ? (
            <>
              <div className="edit" onClick={handleGoToEdit}>
                수정하기
              </div>
              <div className="delete" onClick={handleDelete}>
                {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
              </div>
            </>
          ) : (
            auth.type !== '1' && (
              <div className="delete" onClick={handleDelete}>
                {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
              </div>
            )
          )}
        </DetailFormButtons>
        <div className="go-list" onClick={handleGoBack}>
          목록으로
        </div>
      </ClassDetailWrapper>
    </ClassDetailContainer>
  );
};

export default TaskBoardDetail;
