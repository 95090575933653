import { useMutation, useQueryClient } from 'react-query';

import { checkNewReply } from 'apis';

const useContactManage = () => {
  const queryClient = useQueryClient();

  const changeContactReplyReadStatus = useMutation({
    mutationFn: async ({ postId }) => {
      return await checkNewReply({ postId });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchContactReplyNotification'],
      }),
  });

  return { changeContactReplyReadStatus };
};

export default useContactManage;
