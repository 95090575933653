import { useMutation, useQueryClient } from 'react-query';

import { submitTeacherComment } from 'apis/pbl';

const useSubmitTeacherComment = ({ problemId, studentId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ comment }) => {
      return await submitTeacherComment({ problemId, studentId, comment });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudentCommentList'],
      }),
  });
  return { ...queryReturns };
};

export default useSubmitTeacherComment;
