import React, { useEffect, Fragment } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import AuthProvider from './components/AuthProvider';
import Customer from './components/pages/Customer/index';
import './assets/css/exam.css';
import './assets/css/userModal.css';
import './assets/css/scrollNav.css';
import './assets/css/customSlider.css';
import './assets/css/educational.css';
import './assets/css/registerForm.css';
import './assets/css/table.css';
import './assets/css/filtering.css';
import './assets/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/login.css';

import { Home, MypageMyInformation, MyQuestion } from './components/pages';

import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import Sitemap from './components/pages/Sitemap';

import { Footer, Header } from './components/layouts';
import { useCustomState } from './state/state';
import { ToastContainer } from 'react-toastify';
import AxiosConfig from './utilities/Axios.js';
import Room from './modules/myclass/room/Room';
import SGroup from './modules/studyGroup/SGroup';
import Capability from './modules/capability/Capability';
import PreCourse from './modules/preCourse/PreCourse';
import Teacher from './modules/teacher/Teacher';
import './Videomain.css';
import Login from './modules/login/Login';
import { Spinner, TopButton } from './modules/components';
import { useVersionCheck } from './hooks';

const App = () => {
  const { pathname } = useLocation();
  const [state, actions] = useCustomState();

  /* 해시 체크 후 새로운 버전이 확인되면 새로고침 */
  const { data: latestHash } = useVersionCheck();

  useEffect(() => {
    if (latestHash) {
      const currentHash = localStorage.getItem('app-hash');

      if (currentHash && currentHash !== latestHash.trim()) {
        window.location.reload();
        localStorage.setItem('app-hash', latestHash.trim());
      } else {
        localStorage.setItem('app-hash', latestHash.trim());
      }
    }
  }, [latestHash]);

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
      <AuthProvider>
        <ToastContainer
          style={{ fontSize: '1.3rem' }}
          autoClose="2000"
          position="top-right"
        />
        {/*<Sidebar data={state.data.menu} />*/}
        <AxiosConfig />

        {pathname !== '/login' && <Header data={state.data.menu} />}

        <Switch>
          <Route
            exact
            path={`/`}
            render={() => <Home data={state.data.menu} />}
          />
          <Route
            path="/mypage/my-information"
            exact
            render={() => <MypageMyInformation data={state.data.menu} />}
          />
          <Route
            path="/mypage/my-question"
            exact
            render={() => <MyQuestion data={state.data.menu} />}
          />
          <Route path="/login" exact component={Login} />
          <Route
            path="/customer"
            render={() => <Customer data={state.data.menu} />}
          />

          {/* 마이클래스 */}
          <Route
            path="/myclass"
            render={() => <Room data={state.data.menu} />}
          />

          {/* 스터디그룹 */}
          <Route
            path="/sgroup"
            render={() => (
              <SGroup
                list={state.data.sgroupList}
                posts={state.data.sgroupPostList}
                data={state.data.menu}
              />
            )}
          />

          {/* 역량평가 */}
          <Route
            path={`/capability`}
            render={() => <Capability data={state.data.menu} />}
          />

          {/* 지식컨텐츠 */}
          <Route
            path={`/pre-course`}
            render={() => <PreCourse data={state.data.menu} />}
          />

          {/* 교강사 */}
          <Route
            path={`/teacher`}
            render={() => <Teacher data={state.data.menu} />}
          />

          <Route
            path="/terms"
            render={() => <Terms data={state.data.menu} />}
          />
          <Route
            path="/privacy"
            render={() => <Privacy data={state.data.menu} />}
          />
          <Route
            path="/sitemap"
            render={() => <Sitemap data={state.data.menu} />}
          />
        </Switch>

        {pathname !== '/login' && <Footer />}
        <TopButton />
      </AuthProvider>
    );
  }

  return <Fragment>{app}</Fragment>;
};

export default App;
