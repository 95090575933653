import React from 'react';

const ProblemDetailContent = ({ data }) => {
  return (
    <>
      <h2>{data?.examQuestion}</h2>
      <div>
        <h2>{data?.examOption1}</h2>
        <h2>{data?.examOption2}</h2>
        <h2>{data?.examOption3}</h2>
        <h2>{data?.examOption4}</h2>
      </div>
      <h2>{data?.examCurrentAnswer}</h2>
    </>
  );
};

export default ProblemDetailContent;
