import React, { Fragment, useContext, useRef } from 'react';
import { withRouter } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import Header from '../../../modules/components/header/Header';
import AuthContext from 'context/Auth.context';
import '../MyPage.css';
import styles from './CustomerIndex.module.scss';

const CustomerLayout = (props) => {
  const { pathname } = useLocation();
  const ref = useRef(null);
  const { children } = props;
  const { type } = useContext(AuthContext);

  const isActive = (board) => pathname.includes(board);

  const getTitle = () => {
    const titleObj = {
      library: '자료실',
      faq: 'FAQ',
      greeting: '우리들이야기',
      contact: '문의하기',
      reserve: '상담 신청 현황',
      consulting: '상담 관리',
    };
    const keys = Object.keys(titleObj);
    const findKey = keys.find((e) => pathname.includes(e));
    return titleObj[findKey];
  };
  const mainTitle = getTitle();

  return (
    <Fragment>
      <Header data={{ title: mainTitle }} />
      <div className={styles.mypage} ref={ref} style={{ margin: '0 0 60px 0' }}>
        <section
          className={[styles.wrapper, styles.with_sidebar, styles.left].join(
            ' '
          )}
        >
          <aside className={styles.sidebar}>
            <div className="myPageLeftSide">
              <ul>
                <li
                  className={isActive('library') ? 'mypageLeftsideActive' : ''}
                >
                  <Link to="/customer">
                    <span className="secotext">
                      <i className="las la-book fa-2x"></i>
                      <br />
                      자료실
                    </span>
                  </Link>
                </li>

                <li className={isActive('faq') ? 'mypageLeftsideActive' : ''}>
                  <Link to="/customer/faq">
                    <span className="secotext">
                      <i className="las la-comment fa-2x"></i>
                      <br />
                      FAQ
                    </span>
                  </Link>
                </li>

                <li
                  className={isActive('greeting') ? 'mypageLeftsideActive' : ''}
                >
                  <Link to="/customer/greeting">
                    <span className="secotext">
                      <i className="las la-clipboard-list fa-2x"></i>
                      <br />
                      우리들이야기
                    </span>
                  </Link>
                </li>

                <li
                  className={isActive('contact') ? 'mypageLeftsideActive' : ''}
                >
                  <Link to="/customer/contact">
                    <span className="secotext">
                      <i className="las la-envelope-open fa-2x"></i>
                      <br />
                      문의하기
                    </span>
                  </Link>
                </li>

                {type === '1' && (
                  <li
                    className={
                      isActive('reserve') ? 'mypageLeftsideActive' : ''
                    }
                  >
                    <Link to="/customer/reserve">
                      <span className="secotext">
                        <i className="fa-regular fa-comments fa-2x" />
                        <br />
                        상담예약
                      </span>
                    </Link>
                  </li>
                )}

                {type !== '1' && (
                  <li
                    className={
                      isActive('consulting') ? 'mypageLeftsideActive' : ''
                    }
                  >
                    <Link to="/customer/consulting">
                      <span className="secotext">
                        <i className="fa-regular fa-comments fa-2x" />
                        <br />
                        상담관리
                      </span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </aside>

          <article className=" mypage">
            <div>{children}</div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

export default withRouter(CustomerLayout);
