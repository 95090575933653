import { useMutation, useQueryClient } from 'react-query';

import { deleteGreetingDetail } from 'apis/customer';

const useRemoveGreeting = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ id }) => {
      return await deleteGreetingDetail({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchGreetingList'],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveGreeting;
