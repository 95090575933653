import axios from 'axios';

import { BASE_URL } from './config';

/* 과제 제출 학생 리스트 (교강사 과제 관리 페이지) */
export const fetchTaskSubmittedStudentList = async ({ taskId, dateSort }) => {
  const params = {};
  if (dateSort) params.dateSort = dateSort;

  return await axios.get(`${BASE_URL}/teachers/lc-posts/tasks/${taskId}`, {
    params,
  });
};

/* 교강사 학생 과제 채점 등록 */
export const createStudentTaskGrading = async ({
  taskScoreId,
  score,
  comment,
}) => {
  const body = { taskScoreId, score, comment };

  return await axios.post(`${BASE_URL}/teachers/lc-posts/tasks-result`, {
    ...body,
  });
};
