import React from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import swal from 'sweetalert';

import { useSendProblem } from 'hooks';
import { ModalButtons } from '../../../../components';

// 주관식
const onKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
const ShortProblemEmpty = ({
  problem,
  onClose,
  unitId,
  examId,
  examType,
  level,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutate } = useSendProblem();
  const queryClient = useQueryClient();
  return (
    <>
      <form
        onSubmit={handleSubmit(
          (data) => {
            mutate(
              {
                ...data,
                unitId,
                examId,
                examType,
                examLevel: level,
              },
              {
                onSuccess: (data) => {
                  if (data) {
                    queryClient.invalidateQueries(['unitList', unitId]);
                    queryClient.invalidateQueries(['examList', examId]);
                    swal('문제가 등록되었습니다.');
                    onClose();
                  } else {
                    swal('최대글자수를 초과하였습니다');
                  }
                },
              }
            );
          },
          (err) => {
            swal('문제등록에 실패하였습니다.');
          }
        )}
      >
        <>
          <QuestionTypes>
            <QuestionType>
              <h3>주관식</h3>
            </QuestionType>
            <QuestionType>
              {level === 0 && <h3>난이도: 하</h3>}
              {level === 1 && <h3>난이도: 중</h3>}
              {level === 2 && <h3>난이도: 상</h3>}
            </QuestionType>
          </QuestionTypes>
          <h3>문제 분류 입력</h3>
          <Objective>
            <ExamOption
              onKeyPress={onKeyPress}
              value={problem?.objectiveName}
              type="text"
              id="objective"
              placeholder="문제 분류를 기입하세요"
              {...register('objective', { required: '필수 입력입니다' })}
            />
          </Objective>
          <ErrorMsg>{errors.objective?.message}</ErrorMsg>
          <Title>문제 입력</Title>
        </>
        <TextArea
          type="text"
          id="examQuestion"
          placeholder="다음 빈칸에 알맞은 것은?(첫자만 대문자로 입력하시오)"
          rows="10"
          {...register('examQuestion', {
            required: '필수 입력입니다',
          })}
        ></TextArea>
        <ErrorMsg style={{ marginBottom: '25px' }}>
          {errors.examQuestion?.message}
        </ErrorMsg>
        <div>
          <h3>문제 부가 내용 입력</h3>
        </div>
        <TextArea
          type="text"
          id="examQuestionContent"
          placeholder="예시) 대표적인 클라우드 서비스로 아마존의 _______와 마이크로소프트의 _______가 있다."
          rows="10"
          {...register('examQuestionContent')}
        />
        <div>
          <h3>정답 입력</h3>
        </div>
        <TextArea
          type="text"
          name="examCorrectAnswer"
          id="examCorrectAnswer"
          placeholder="답안을 입력하세요(최대 글자수 50자) 띄어쓰기로 답안을 구분합니다. &#13;&#10;예시)Aws Azure"
          rows="10"
          {...register('examCorrectAnswer', {
            required: '필수 입력입니다',
            pattern: {
              value: /^((?!,).)*$/g,
              message: '입력할 수 없는 문자(,)가 포함되어 있습니다',
            },
            maxLength: { value: 50, message: '최대 글자수를 초과했습니다' },
          })}
        ></TextArea>
        <ErrorMsg>{errors.examCorrectAnswer?.message}</ErrorMsg>

        <ModalButtons onClose={onClose} />
      </form>
    </>
  );
};
const Title = styled.h3`
  padding-top: 30px;
`;

const QuestionType = styled.div`
  justify-content: center;
  background-color: rgb(231, 235, 238);
  width: 160px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px silver;
  display: flex;
  align-items: center;
  float: right;

  span {
    display: inline-block;
  }

  h3 {
    font-weight: 300;
  }
`;
const Objective = styled.div`
  display: flex;
  align-items: center;
`;
const ExamOption = styled.input`
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  height: 32px;
  padding-left: 12px;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid silver;
  background: #eeeeee66;
`;
const QuestionTypes = styled.div`
  padding-bottom: 48px;
`;
const ErrorMsg = styled.span`
  display: block;
  color: red;
`;
const TextArea = styled.textarea`
  box-sizing: border-box;
  padding: 12px;
  display: block;
  width: 100%;
  min-height: 20vh;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin: 10px auto;
  border: 2px solid silver;
  background: #eeeeee66;
`;

export default ShortProblemEmpty;
