import axios from 'axios';
import { useMutation } from 'react-query';
import { useContext } from 'react';

import AuthContext from 'context/Auth.context';

const useMutatePrevExam = () => {
  const auth = useContext(AuthContext);
  const { classId } = auth;
  const { mutate, isLoading } = useMutation(
    async ({ examId, problemId, unitId }) => {
      return await axios
        .put(
          `${process.env.REACT_APP_IP}/teachers/exam/creation/pre-question`,
          {
            examId,
            problemId,
            unitId,
          },
          {
            classId,
          }
        )
        .then((res) => {
          return res.data;
        });
    }
  );
  return { mutate, isLoading };
};

export default useMutatePrevExam;
