import { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchInquiryDetail } from '../../apis/customer';

const useFetchInquiryDetail = ({ id }) => {
  const [files, setFiles] = useState([]);

  const queryReturns = useQuery({
    queryKey: ['fetchInquiryDetail', { id }],
    queryFn: async () => {
      const response = await fetchInquiryDetail({ id });

      setFiles(response.data.data.files);

      return response;
    },
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 1000,
    enabled: !!id,
    staleTime: 0,
    cacheTime: 0,
  });
  return { ...queryReturns, files };
};

export default useFetchInquiryDetail;
