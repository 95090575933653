import { useQuery } from 'react-query';

import { fetchInquiryComments } from '../../apis';

const useFetchInquiryComments = ({ id }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchInquiryComments', { id }],
    queryFn: async () => {
      return await fetchInquiryComments({ id });
    },
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 1000,
    enabled: !!id,
    staleTime: 0,
    cacheTime: 0,
  });
  return { ...queryReturns };
};

export default useFetchInquiryComments;
