import { useMutation } from 'react-query';
import { login, logout } from 'apis/common';

const useAuth = () => {
  const userLogin = useMutation({
    mutationFn: async ({ userId, password }) => {
      return await login({ userId, password });
    },
  });

  const userLogout = useMutation({
    mutationFn: async ({ token }) => {
      return await logout({ token });
    },
  });

  return { userLogin, userLogout };
};

export default useAuth;
