import { useMutation, useQueryClient } from 'react-query';

import { deleteInquiryDetail } from 'apis/customer';

const useRemoveInquiry = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ id }) => {
      return await deleteInquiryDetail({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchInquiriesList'],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveInquiry;
