import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import BoardList from './BoardList';
import BoardDetail from './BoardDetail';
import BoardWrite from './BoardWrite';
import TaskResult from './task/TaskResult';

import AuthContext from 'context/Auth.context';
import { errorMessage } from 'utilities';

const Board = () => {
  const auth = useContext(AuthContext);
  const { url } = useRouteMatch();

  const STUDENT_ACCESS_BOARD = ['qna', 'library'];

  return (
    <Container>
      <Switch>
        <Route exact path={`${url}`}>
          <Redirect to={`${url}/qna`} />
        </Route>
        {/*공통 리스트 페이지*/}
        <Route exact path={`${url}/:type`} render={() => <BoardList />} />

        {/*공통 작성 페이지*/}
        <Route
          exact
          path={`${url}/write/:type`}
          render={({ match: { params } }) => {
            if (
              !STUDENT_ACCESS_BOARD.includes(params.type) &&
              auth.type === '1'
            ) {
              errorMessage({ text: '관리자만 접근이 가능합니다.' });
              return <Redirect to={`${url}/${params.type}`} />;
            }

            return <BoardWrite />;
          }}
        />

        {/*공통 수정 페이지*/}
        <Route
          path={`${url}/write/:type/:id`}
          render={({ match: { params } }) => {
            if (
              !STUDENT_ACCESS_BOARD.includes(params.type) &&
              auth.type === '1'
            ) {
              errorMessage({ text: '관리자만 접근이 가능합니다.' });
              return <Redirect to={`${url}/${params.type}`} />;
            }

            return <BoardWrite />;
          }}
        />

        <Route
          exact
          path={`${url}/task/result`}
          render={() => <TaskResult />}
        />

        {/*공통 상세 페이지*/}
        <Route path={`${url}/:type/:id`} render={() => <BoardDetail />} />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 500px;
`;

export default Board;
