import { useQuery } from 'react-query';

import { fetchTaskSubmittedStudentList } from 'apis';

const useFetchTaskSubmittedStudentList = ({ taskId, dateSort }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchTaskSubmittedStudentList', { taskId, dateSort }],
    queryFn: async () =>
      await fetchTaskSubmittedStudentList({ taskId, dateSort }),
    select: (res) => res.data.data,
    enabled: !!taskId,
    keepPreviousData: true,
    retry: 1,
    retryDelay: 1000,
  });

  return { ...queryReturns };
};

export default useFetchTaskSubmittedStudentList;
