import React, { useState, Fragment } from 'react';
import styled from 'styled-components';

import { useFetchGreetingList, useDebounce, useQueryValue } from 'hooks';
import CustomerList from '../CustomerList';
import { Pagination, WriteButton, SearchInput } from '../../../elements';

const Greeting = ({ history }) => {
  const pageQuery = useQueryValue('page');

  const [pageSize] = useState(10);
  const [page, setPage] = useState(+pageQuery || 1);
  const [searchType] = useState('all');
  const [searchContent, setSearchContent] = useState('');
  const debouncedSearchContent = useDebounce(searchContent, 300);

  const {
    data: searchedData,
    isLoading: searchedDataLoading,
    totalValue,
    refetch: refetchList,
  } = useFetchGreetingList({
    page,
    pageSize,
    type: searchType,
    searchContent: debouncedSearchContent,
  });

  const move = (num) => {
    setPage(num);
    history.replace({
      search: `page=${num}`,
    });
  };

  return (
    <Fragment>
      <InputBox>
        <SearchInput
          value={searchContent}
          setValue={setSearchContent}
          onClickSearch={refetchList}
        />
      </InputBox>

      <div className="mypageContents">
        <CustomerList
          list={searchedData?.list}
          isListLoading={searchedDataLoading}
          boardType="greeting"
        />
      </div>

      <StyledBox>
        <WriteButton text="글 등록하기" to="/customer/greetingform" />
      </StyledBox>

      <StyledBox>
        <Pagination
          page={page}
          total={totalValue}
          perPage={pageSize}
          move={move}
        />
      </StyledBox>
    </Fragment>
  );
};

const InputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > a {
    margin-left: auto;
  }
`;

export default Greeting;
