import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';
import { toast } from 'react-toastify';

import AuthContext from 'context/Auth.context';
import Message from 'utilities/Message';
import Header1 from '../components/header/Header';
import PreCourseDetail from './PreCourseDetail';
import PreCourseList from './PreCourseList';
import { handleErrorMessage } from 'utilities';

const PreCourse = () => {
  const history = useHistory();
  const [title, setTitle] = useState('사전학습실');
  const [preCourse, setPreCourse] = useState();
  const match = useRouteMatch();

  const auth = useContext(AuthContext);

  const sgroupListReq = () => {
    const url = `${process.env.REACT_APP_IP}/users/myclass/pre-course`;

    Axios.get(url)
      .then((response) => {
        setPreCourse(response.data);
      })
      .catch(handleErrorMessage);
  };

  useEffect(() => {
    sgroupListReq();
  }, []);

  const getTitle = (val) => {
    setTitle(val);
  };

  useEffect(() => {
    if (!auth.auth) {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push('/');
    }
  }, [auth]);

  return (
    <>
      <Header1 data={{ title }} />
      <Switch>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/list`} />
        </Route>
        <Route
          exact
          path={`${match.url}/list`}
          render={() => <PreCourseList getTitle={getTitle} list={preCourse} />}
        />
        <Route
          exact
          path={`${match.url}/detail/:id`}
          render={() => (
            <PreCourseDetail getTitle={getTitle} list={preCourse} />
          )}
        />
      </Switch>
    </>
  );
};

export default PreCourse;
