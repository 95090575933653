import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import FileSaver from 'file-saver';
import { useLocation } from 'react-router';

import { useFetchPblProblemDetailHook, useSubmitPblResult } from 'hooks';
import authContext from 'context/Auth.context';
import { changePblProblemState, downloadAttachmentFiles } from 'apis/pbl';
import ProblemResultView from './ProblemResultView';
import { confirmSwal, errorMessage, successMessage } from 'utilities';

const PblProblemDetail = () => {
  const { state } = useLocation();
  const { problemId } = state;

  const auth = useContext(authContext);
  const history = useHistory();
  const params = useParams();
  const { unitId } = params;
  const fileRef = useRef(null);
  const taskFileRef = useRef(null);

  const { data } = useFetchPblProblemDetailHook({ unitId, problemId });
  const { mutateAsync, isLoading: isTaskSubmitting } = useSubmitPblResult({
    problemId,
  });

  const [problemState, setProblemState] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const [openResultView, setOpenResultView] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploadedResult, setUploadedResult] = useState('');

  useEffect(() => {
    if (!data) return;
    setProblemState(data?.state);
  }, [data]);

  const handleGoBack = () => history.goBack();

  const handleChangeState = async (e) => {
    const result = await confirmSwal({
      title: '문제의 상태를 변경 하시겠습니까?',
      confirmButton: '변경',
    });

    if (result) {
      try {
        const response = await changePblProblemState({
          problemId,
          state: e.target.value,
        });
        if (response.status === 204) {
          setProblemState(e.target.value);
          successMessage({ text: `문제의 상태가 변경되었습니다.` });
        }
      } catch (error) {
        errorMessage({ text: `문제 상태 변경에 실패하였습니다.` });
      }
    }
  };

  const handleSelectClose = () => setOpenSelect(false);
  const handleSelectOpen = () => setOpenSelect(true);

  const handleControlResultView = () => setOpenResultView((prev) => !prev);

  const handleUploadResult = () => {
    if (problemState === 'CLOSE') return;

    taskFileRef.current.click();
  };
  const handleTaskResultChange = (e) => {
    if (taskFileRef.current.files) {
      if (taskFileRef.current.files[0].size > 1024 * 1024 * 10) {
        alert('10메가 이하의 파일만 업로드할 수 있습니다.');
        taskFileRef.current.value = '';
        setUploadedResult('');
      } else {
        setUploadedResult(taskFileRef.current.files[0].name);
      }
    }
  };

  const handleInputChange = (e) => {
    if (fileRef.current.files[0]) {
      if (fileRef.current.files[0].size > 1024 * 1024 * 50) {
        alert('50메가 이하의 파일만 업로드할 수 있습니다.');
        fileRef.current.value = '';
        setSelectedFileName('');
      } else {
        setSelectedFileName(fileRef.current.files[0].name);
      }
    }
  };
  // const handleUploadResource = () => {
  //   fileRef.current.click();
  // };

  const handleSubmitTaskResult = async () => {
    if (isTaskSubmitting) return;

    const result = await confirmSwal({
      title: '결과물을 제출하시겠습니까?',
      confirmButton: '제출',
    });

    if (result) {
      try {
        const form = new FormData();
        form.append('file', taskFileRef.current.files[0]);
        const response = await mutateAsync({ formData: form });
        if (response.status === 201) {
          successMessage({ text: `결과물이 제출되었습니다.` });
          history.push({
            pathname: `/myclass/pbl/${unitId}`,
            state: {
              congratulation: true,
            },
          });
        }
      } catch (err) {
        if (err.response.status === 400) {
          errorMessage({
            text: `ZIP, PDF, MS Office, hwp, JPEG, PNG 파일만 업로드 가능합니다.`,
          });
        }
      }
    }
  };

  const handleRemoveUploadedTaskResult = () => {
    setUploadedResult(null);
    taskFileRef.current.value = '';
  };

  // const handleGoToEditPage = () =>
  //   history.push(`/myclass/pbl/edit/${unitId}/${problemId}`);

  const stateToChange = {
    OPEN: '표출',
    HIDE: '숨김',
    CLOSE: '마감',
  };

  const handleDownloadAttachmentFiles = async ({ problemId, fileName }) => {
    try {
      const response = await downloadAttachmentFiles({ problemId, fileName });
      if (response) {
        const fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');

        FileSaver.saveAs(response.data, decodeURIComponent(fileName));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!data) return;

    if (data?.submitFile.originalFileName) {
      setUploadedResult(data?.submitFile.originalFileName);
    }
  }, [data]);

  const replaceImageSrc = (htmlString, filenames) => {
    if (!htmlString) return;
    const regex = /<img\s+[^>]*src="([^"]*)"/g;

    let index = 0;
    return htmlString.replace(regex, (match, src) => {
      if (index < filenames.length) {
        return match.replace(
          src,
          `${process.env.REACT_APP_IP}/pbl-file/${problemId}/image/${
            filenames[index++]
          }`
        );
      }
      return match;
    });
  };

  const parsedLevel =
    data?.level < 4 ? `기초 ${data?.level}` : `응용 ${Number(data?.level) - 3}`;

  return (
    <Container>
      <BackBtn onClick={handleGoBack}>
        <img src="/icons/back.png" alt="back icons" />
      </BackBtn>

      {auth.type !== '1' && (
        <StateBtn>
          <Select
            variant="outlined"
            open={openSelect}
            onClose={handleSelectClose}
            onOpen={handleSelectOpen}
            value={problemState}
            onChange={handleChangeState}
            renderValue={(e) => `${stateToChange[e]} 중`}
          >
            <MenuItem value="OPEN">표출</MenuItem>
            <MenuItem value="HIDE">숨김</MenuItem>
            <MenuItem value="CLOSE">마감</MenuItem>
          </Select>
        </StateBtn>
      )}

      <TitleBox>
        <span>{parsedLevel}</span>
        <h1>{data?.title}</h1>
      </TitleBox>

      <ContentBox>
        <p
          dangerouslySetInnerHTML={{
            __html: replaceImageSrc(data?.content, data?.imgList),
          }}
        ></p>
      </ContentBox>

      <ResourceBox>
        {data?.attachmentFiles.length !== 0 && (
          <div>
            <span>참고 자료</span>
            <div>
              <div className="resource">
                <input
                  style={{ display: 'none' }}
                  ref={fileRef}
                  type="file"
                  name="task"
                  id="task"
                  accept=".zip,.7zip"
                  onChange={handleInputChange}
                />
                <div className="files">
                  {data?.attachmentFiles.map((e, i) => (
                    <p
                      className="files"
                      key={i}
                      onClick={() =>
                        handleDownloadAttachmentFiles({
                          problemId,
                          fileName: e.newFileName,
                        })
                      }
                    >
                      <i className="fa-regular fa-file-lines" />
                      {e.originalFileName ?? '자료없음'}
                    </p>
                  ))}
                </div>
                {selectedFileName && (
                  <p className="files">
                    <i className="fa-regular fa-file-lines" />
                    {selectedFileName}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        <div>
          <input
            style={{ display: 'none' }}
            ref={taskFileRef}
            type="file"
            name="taskResult"
            id="taskResult"
            accept=".zip, application/pdf, application/msword, image/png, image/jpeg, .docx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, .hwp, .hwpx, .rtf, .txt"
            onChange={handleTaskResultChange}
          />
          <span>결과물</span>
          <div>
            {auth.type !== '1' ? (
              <>
                <ResultViewButton onClick={handleControlResultView}>
                  결과물 제출 내역 {openResultView ? '숨김' : '보기'}
                  <i
                    className={`fa-solid fa-chevron-down ${
                      openResultView ? 'result-active' : ''
                    }`}
                  />
                </ResultViewButton>
                <TaskResultBox
                  className={openResultView ? 'result-active' : ''}
                >
                  <ProblemResultView handleClose={handleControlResultView} />
                </TaskResultBox>
              </>
            ) : uploadedResult ? (
              <>
                <UploadCompleteBox>
                  <span>
                    {data?.submitFile.originalFileName ? '제출' : '업로드'}
                    완료
                  </span>
                  {!data?.submitFile.originalFileName && (
                    <span
                      onClick={handleSubmitTaskResult}
                      className={isTaskSubmitting ? 'disabled' : ''}
                    >
                      {isTaskSubmitting ? (
                        <CircularProgress size={20} />
                      ) : (
                        '제출하기'
                      )}
                    </span>
                  )}
                </UploadCompleteBox>
                <p>
                  {uploadedResult || '제출된 과제'}
                  {!data?.submitFile.originalFileName && (
                    <span onClick={handleRemoveUploadedTaskResult}>
                      <i className="fa-solid fa-xmark" />
                    </span>
                  )}
                </p>
              </>
            ) : (
              <>
                <UploadButton
                  onClick={handleUploadResult}
                  className={problemState === 'CLOSE' ? 'disabled' : ''}
                >
                  {problemState === 'CLOSE' ? '문제 마감' : '결과물 업로드'}
                </UploadButton>
              </>
            )}
          </div>
        </div>

        {auth.type === '1' && data?.commentList.length > 0 && (
          <div>
            <span>강사 의견</span>
            <div className="comment-wrapper">
              {data?.commentList.length > 0 &&
                data?.commentList.map((comment, index) => (
                  <CommentBox key={index}>
                    <div>
                      <b>{comment.modifiedBy}</b>
                      <span>{comment.modifiedAt.slice(0, 10)}</span>
                    </div>
                    <p className="instructor-reply">{comment.comment}</p>
                  </CommentBox>
                ))}
            </div>
          </div>
        )}
      </ResourceBox>

      {/*{auth.type !== '1' && (*/}
      {/*  <div className="edit-button" onClick={handleGoToEditPage}>*/}
      {/*    수정하기*/}
      {/*  </div>*/}
      {/*)}*/}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  border: 1px solid #eee;
  margin-bottom: 100px;
  padding: 100px;
  border-radius: 20px;
  box-shadow: 1px 2px 5px #eee;

  .edit-button {
    font-size: 18px;
    padding: 5px 15px;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-left: auto;
    margin-top: 50px;
    background-color: #fc9918;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #ee982c;
    }
  }
`;

const BackBtn = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

const StateBtn = styled.div`
  position: absolute;
  top: 30px;
  right: 50px;

  .MuiOutlinedInput-input {
    padding: 10px 20px 10px 10px !important;
  }
  .MuiSelect-icon {
    top: unset;
  }
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    display: inline-block;
    width: auto;
    height: 40px;
    background-color: #fc9918;
    border-radius: 15px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-right: 10px;
    padding: 0 10px;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  margin-top: 50px;

  p {
    font-size: 1.6rem;
    line-height: 1.6;

    img {
      max-width: fit-content;
      width: 100%;
    }
  }
`;

const ResourceBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;

  > div {
    width: 100%;
    display: flex;
    gap: 30px;
    padding-bottom: 50px;
    margin-top: 30px;

    > span {
      min-width: 70px;
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }

    &:nth-child(2) span {
      padding-top: 5px;
    }

    > div {
      max-width: 100%;
      min-width: 200px;
      width: 100%;
    }

    .notice {
      font-size: 12px;
      margin-top: -15px;
    }
  }

  .files {
    cursor: pointer;
    font-size: 16px;
    color: #979797;

    &:hover {
      color: #a6a6a6;
    }

    i {
      margin-right: 5px;
    }
  }

  .resource {
    max-width: 70%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  .text-input {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    textarea {
      width: 100%;
      height: 150px;
      resize: none;
      font-size: 14px;
    }

    button {
      width: 80px;
      height: 30px;
      font-size: 12px;
      margin-left: auto;
    }
  }

  .comment-wrapper {
    width: 100%;
  }
`;

const UploadButton = styled.div`
  max-width: 200px;
  font-size: 14px;
  padding: 8px 20px;
  color: #333;
  background-color: #aae787;
  border-radius: 5px;
  box-shadow: 1px 2px 2px #eee;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s;
  text-align: center;
  margin-bottom: 15px;

  &:hover {
    background-color: #92de66;
    color: #000;
  }

  &.disabled {
    background-color: #afafaf;
    cursor: not-allowed;
    color: #fff;
  }
`;

const UploadResourceButton = styled.div`
  font-size: 14px;
  padding: 3px 10px;
  color: #fff;
  background-color: #fc7a3c;
  border-radius: 5px;
  box-shadow: 1px 2px 2px #eee;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s;
  margin-left: 20px;

  &:hover {
    background-color: #f86c2a;
  }
`;

const ResultViewButton = styled.div`
  max-width: 200px;
  width: 100%;
  font-size: 14px;
  padding: 8px 20px;
  color: #fff;
  background-color: #b96ff8;
  border-radius: 5px;
  box-shadow: 1px 2px 2px #eee;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s;
  text-align: center;

  &:hover {
    background-color: #ad5af1;
  }

  i {
    margin-left: 5px;
    transition: 0.3s;
  }
  .result-active {
    transform: rotate(-0.5turn);
  }
`;

const CommentBox = styled.div`
  width: 100%;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;

  > div {
    display: inline-block;
    border-bottom: 2px dashed #eee;
    margin-bottom: 10px;

    b {
      font-size: 16px;
      margin-right: 10px;
      color: #555;
    }

    span {
      font-size: 12px;
      color: #777;
    }
  }

  .instructor-reply {
    font-size: 16px;
    color: #333;
  }
`;

const UploadCompleteBox = styled.div`
  display: flex;
  gap: 10px;

  span {
    min-width: 100px;
    font-size: 14px;
    padding: 8px 20px;
    color: #333;
    background-color: #aae787;
    border-radius: 5px;
    box-shadow: 1px 2px 2px #eee;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    text-align: center;

    &:hover {
      background-color: #92de66;
      color: #000;
    }
  }

  span.disabled {
    display: grid;
    place-items: center;
    cursor: wait;
  }

  span:first-child {
    background-color: #fc9918;
    cursor: not-allowed;
    color: #fff;
  }

  + p {
    margin-top: 10px;
    font-size: 16px;

    span {
      margin-left: 5px;
      cursor: pointer;
    }
  }
`;

const TaskResultBox = styled.div`
  width: 100%;
  opacity: 0;
  margin-top: 15px;
  height: 0;
  border: 1px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s;
  padding: 10px;

  &.result-active {
    border: 1px solid #eee;
    height: 90%;
    margin-bottom: 40px;
    opacity: 1;
  }
`;

export default PblProblemDetail;
