import { useQuery } from 'react-query';

import { fetchRoadmap } from 'apis/classBoard';

const useRoadmap = () => {
  const queryReturns = useQuery({
    queryKey: ['roadmap'],
    async queryFn() {
      const response = await fetchRoadmap();

      return response.data;
    },
  });

  return {
    ...queryReturns,
  };
};

export default useRoadmap;
