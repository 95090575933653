import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import dompurify from 'dompurify';
import { CircularProgress } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';

import AuthContext from 'context/Auth.context';
import { linkify } from 'utilities/common';
import { useFetchGreetingDetail, useRemoveGreeting } from 'hooks';
import CustomerComment from '../CustomerComment';
import Loading from '../../../ui/Loading';
import { confirmSwal, successMessage } from 'utilities/swal';

const Detail = ({ history }) => {
  const { id } = useParams();
  const auth = useContext(AuthContext);

  const { data: detailData, isLoading } = useFetchGreetingDetail({ id });
  const { adjacentPostInfo, post, comments } = detailData || {};
  const { mutateAsync: removeGreeting, isLoading: isRemoving } =
    useRemoveGreeting();

  const handleGoToEdit = () => {
    history.push(`/customer/greetingform/${id}`);
  };

  const handleClickDelete = async () => {
    const confirm = await confirmSwal({
      title: '해당 글을 삭제 하시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      const { status } = await removeGreeting({ id });
      if (status === 204) {
        successMessage({ text: '문의글이 삭제되었습니다.' });
        history.push('/customer/greeting');
      }
    }
  };

  const handleMoveToOtherDetail = (id) => {
    history.push(`/customer/greeting/${id}`);
  };

  const handleGoback = () => history.goBack();

  if (isLoading) return <Loading />;
  return (
    <Wrapper>
      <TitleBox>
        <div className="title">
          <h2>{post?.title}</h2>
        </div>

        <div className="sub-info">
          <div>
            <span>{(post?.createdAt || '').slice(0, 10)} |</span>
            <span>조회 {post?.hitCount}</span>
          </div>
          <div>
            <span>
              <i className="fa-solid fa-user" />
              {post?.author}
            </span>
          </div>
        </div>
      </TitleBox>

      <ContentBox
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(linkify(post?.content || '')),
        }}
      />

      <FormButtons>
        {post?.userId === auth.userId ? (
          <>
            <div className="edit" onClick={handleGoToEdit}>
              수정하기
            </div>
            <div className="delete" onClick={handleClickDelete}>
              {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
            </div>
          </>
        ) : (
          auth.type !== '1' && (
            <div className="delete" onClick={handleClickDelete}>
              {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
            </div>
          )
        )}
      </FormButtons>

      {/* 댓글 컴포넌트*/}
      <CustomerComment replyList={comments} boardType="greeting" />

      <MoveDetailBox>
        <div>
          {adjacentPostInfo?.prevId && (
            <span
              onClick={() => handleMoveToOtherDetail(adjacentPostInfo?.prevId)}
            >
              <i className="fa-solid fa-chevron-left" />
              이전글
            </span>
          )}
        </div>

        <div>
          {adjacentPostInfo?.nextId && (
            <span
              onClick={() => handleMoveToOtherDetail(adjacentPostInfo?.nextId)}
            >
              다음글
              <i className="fa-solid fa-chevron-right" />
            </span>
          )}
        </div>
      </MoveDetailBox>

      <div className="go-list" onClick={handleGoback}>
        목록으로
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-top: 2px solid #333;
  padding-bottom: 200px;

  > a,
  div.go-list {
    font-size: 16px;
    width: 150px;
    height: 40px;
    background: #ec7422;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    margin: 50px auto 0;
    cursor: pointer;

    &:hover {
      filter: brightness(105%);
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  min-height: 60px;
  padding: 10px;
  overflow: hidden;

  > div.title {
    display: flex;
    max-width: 75%;

    h2 {
      max-width: 100%;
      margin-bottom: unset;
      margin-left: 5px;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  > div.sub-info {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;

      i {
        margin-right: 5px;
      }
    }
  }
`;

const ContentBox = styled.div`
  padding: 20px;
  font-size: 16px;
  min-height: 300px;

  border-bottom: 1px solid #eee;

  p {
    font-size: 16px;
    color: #333;
  }
`;

const MoveDetailBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  span {
    display: inline-block;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 15px;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
`;

const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin: 60px 0 30px;

  > div {
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(206, 206, 206);
    color: #fff;
    font-size: 16px;
    border-radius: 15px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: bold;

    &.edit {
      border: 2px solid #ec7422;
      color: #ec7422;
    }
    &.delete {
      background-color: #ec7422;
      svg {
        color: #fff;
      }
    }

    &:hover {
      filter: brightness(95%);
    }
  }
`;

const DeleteBtn = styled.div`
  background: transparent;
  color: rgb(44, 149, 210);
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 1.3rem 2.9rem;
  border: 2px solid rgb(44, 149, 210);
  border-radius: 5px;

  :hover {
    background-color: rgb(44, 149, 210);
    border-color: rgb(44, 149, 210);
    color: rgb(255, 255, 255);
  }
`;

export default Detail;
