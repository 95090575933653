import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { useFetchStudentCommentList, useSubmitTeacherComment } from 'hooks';
import { PBLComment } from './PblCommon';
import { errorMessage, successMessage } from 'utilities';

const CommentModal = ({ openModal, handleClose, studentId }) => {
  const textRef = useRef(null);
  const params = useParams();
  const { problemId } = params;

  const { data: comments } = useFetchStudentCommentList({
    problemId,
    studentId,
  });
  const { mutateAsync, isLoading } = useSubmitTeacherComment({
    problemId,
    studentId,
  });

  useEffect(() => {
    const modal = document.querySelector('.modal-content');
    modal.style.top = '10vh';
    modal.style.minHeight = '80vh';
    modal.style.overflow = 'hidden';
    modal.style.overscrollBehavior = 'contain';
    modal.style.padding = 'unset';
  }, []);

  const handleSubmitReply = async () => {
    if (isLoading) return;

    if (textRef.current.value.length > 3000) {
      errorMessage({ text: `3000자를 넘길 수 없습니다.` });
      return;
    }

    const response = await mutateAsync({ comment: textRef.current.value });
    if (response.status === 201) {
      successMessage({ text: `${studentId}님에게 의견을 반영하였습니다.` });
    }
    textRef.current.value = '';
  };

  return (
    <Modal className="fade modal" show={openModal} onHide={handleClose}>
      <Container>
        <Modal.Header>
          <TitleBox>
            <h3>{studentId}에게 강사 의견 달기</h3>
          </TitleBox>
          <Button className="close" onClick={handleClose}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <TextBox>
          <h4>새 댓글</h4>
          <textarea
            ref={textRef}
            cols="30"
            rows="5"
            placeholder="내용을 입력해주세요."
          />
          <span onClick={handleSubmitReply}>
            {isLoading ? <CircularProgress size={20} /> : '등록'}
          </span>
        </TextBox>

        <CommentBox>
          {comments?.map((comment, index) => (
            <PBLComment
              key={index}
              author={comment?.createdBy}
              modifiedAt={comment?.modifiedAt}
              comment={comment?.comment}
              pblCommentId={comment?.id}
            />
          ))}
        </CommentBox>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 20px 20px 10px 20px;
  height: 100vh;
  overflow-y: scroll;
`;

const TitleBox = styled.div`
  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  textarea {
    resize: none;
    overscroll-behavior: contain;
    font-size: 14px;
  }

  span {
    display: grid;
    place-items: center;
    min-width: 150px;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
    background-color: #fc9918;
    border: 1px solid #eee;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      background-color: #e79733;
    }
  }
`;

const CommentBox = styled.div`
  padding: 20px;
`;

export default CommentModal;
