import { useQuery } from 'react-query';
import { fetchQuestionCategories } from '../../apis/customer';

const useFetchQuestionCategories = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchQuestionCategories'],
    queryFn: async () => await fetchQuestionCategories(),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
  });
  return { ...queryReturns };
};

export default useFetchQuestionCategories;
