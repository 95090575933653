import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useCustomState } from '../state/state';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';

import AuthContext, { SetAuthContext } from 'context/Auth.context';
import { errorMessage } from './swal';

function parseJWT(token) {
  const removedBearer = token.replace('Bearer', '');
  const base64Url = removedBearer.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

let refreshSubscribers = [];

export default function AxiosConfig() {
  const history = useHistory();
  let isTokenRefreshing = false;
  const auth = useContext(AuthContext);
  const setAuth = useContext(SetAuthContext);
  const actions = useCustomState()[1];
  let tokenRefreshTimeout;

  const onTokenRefreshed = async (accessToken) => {
    refreshSubscribers.map((callback) => {
      callback(accessToken);
    });
    refreshSubscribers = [];
  };
  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  const setAccessToken = (token) => {
    localStorage.setItem('token', token);
    scheduleTokenRefresh(token);
  };

  const scheduleTokenRefresh = (token) => {
    if (tokenRefreshTimeout) {
      clearTimeout(tokenRefreshTimeout);
    }

    const decodedToken = parseJWT(token);
    const expiryTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    const timeoutDuration = expiryTime - currentTime - 2000; // 2초 전에 갱신

    tokenRefreshTimeout = setTimeout(() => {
      refreshToken().then((newToken) => {
        setAccessToken(newToken);
      });
    }, timeoutDuration);
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(
      `${process.env.REACT_APP_IP}/users/refreshAccessToken`,
      {}, // token refresh api
      {
        headers: {
          RefreshToken: `${refreshToken}`,
        },
        transformRequest: (data, headers) => {
          delete headers.Authorization;
        },
      }
    );

    const newAccessToken = response.headers.authorization;
    localStorage.setItem('token', newAccessToken);
    const parsedData = parseJWT(newAccessToken);

    // 여기에 setAuth를 사용하는 로직을 추가
    setAuth({
      ...auth,
      auth: true,
      userId: String(parsedData.userId),
      name: String(parsedData.userName),
      type: String(parsedData.userType),
      email: String(parsedData.userEmail),
      status: String(parsedData.status),
      refreshToken: refreshToken,
      token: newAccessToken,
      authToken: { Authorization: `${newAccessToken}` },
      freeYn: String(parsedData.freeYn),
      allowLanguages: String(parsedData.allowLanguages),
      classId: String(parsedData.classId),
      subClassId: 0,
      groupId: String(parsedData.groupId),
      initialPw: String(parsedData.initialPw),
    });

    onTokenRefreshed(newAccessToken);
    scheduleTokenRefresh(newAccessToken);
    return newAccessToken;
  };

  useEffect(() => {
    if (auth.auth) {
      if (JSON.stringify(refreshSubscribers) !== '[]') {
        onTokenRefreshed(localStorage.getItem('token'));
      }
    }
  }, []);

  // Request Interceptor
  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('token');

      if (accessToken) {
        config.headers.Authorization = accessToken;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let accessToken = '';
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 500)
      ) {
        Sentry.captureException(error);
        //모든 400에러와 500 에러를 수집
      }

      if (error.config.url.includes('https://www.googleapis.com')) return;

      if (
        status === 401 &&
        error.config.url !== `${process.env.REACT_APP_IP}/users/login`
      ) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          try {
            const newAccessToken = await refreshToken();
            isTokenRefreshing = false;
            originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
            return axios(originalRequest);
          } catch (refreshError) {
            isTokenRefreshing = false;
            localStorage.clear();
            auth.onLogout();
            errorMessage({
              text: '로그인이 만료되었습니다. \n 다시 로그인을 해주세요.',
            });
            history.push('/login');
            return Promise.reject(refreshError);
          }
        } else {
          isTokenRefreshing = false;
          if (
            error.config.url ===
            `${process.env.REACT_APP_IP}/users/refreshAccessToken`
          ) {
            localStorage.clear();
            auth.onLogout();
            errorMessage({
              text: '로그인이 만료되었습니다. \n 다시 로그인을 해주세요.',
            });
            history.push('/login');
          }
        }

        //   token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve, reject) => {
          addRefreshSubscriber(function (accessToken) {
            originalRequest.headers.Authorization = 'Bearer ' + accessToken;
            axios(originalRequest)
              .then((res) => resolve(res))
              .catch((error) => reject(error));
          });
        });
        return retryOriginalRequest;
      }
      // 400, 404, 500
      else if (status === 500) {
        Sentry.captureException('500 error : ' + status);
      } else if (status === 404) {
        Sentry.captureException('404 error : ' + status);
      } else if (status === 400) {
        Sentry.captureException('400 error : ' + status);
      }

      return Promise.reject(error);
    }
  );

  // 초기화 시 첫 토큰 갱신 스케줄링
  useEffect(() => {
    const init = () => {
      const token = localStorage.getItem('token');
      if (token) {
        scheduleTokenRefresh(token);
      }
    };

    init();
  }, []);

  return <></>;
}
