import { useMutation, useQueryClient } from 'react-query';

import { editInquiryComment } from 'apis/customer';

const useEditInquiryComment = ({ postId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ replyId, author, content }) => {
      return await editInquiryComment({ postId, replyId, author, content });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchInquiryComments'],
      }),
  });
  return { ...queryReturns };
};

export default useEditInquiryComment;
