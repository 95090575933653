import axios from 'axios';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import AuthContext from 'context/Auth.context';

const useProblemList = (examId, unitId) => {
  const auth = useContext(AuthContext);
  const [problems, setProblems] = useState([]);

  const { data, isLoading } = useQuery(
    ['unitList', unitId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/creation/unit/${auth?.classId}/${examId}/${unitId}`
        )
        .then((response) => {
          setProblems(response.data.problems);
          return response.data;
        });
    },
    { enabled: !!unitId }
  );

  const sortedProblems = problems.sort((a, b) => b.examLevel - a.examLevel);

  return { data, isLoading, sortedProblems };
};

export default useProblemList;
