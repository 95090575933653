import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useParams, withRouter } from 'react-router';

import AuthContext from 'context/Auth.context';
import Modal from '../components/modal/Modal';
import styles from './sGroup.module.scss';
import StatusBox from './StatusBox';
import { handleErrorMessage } from 'utilities';

const CapabilityReserve = ({ getSgroupInfo, isLeader }) => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const [historyList, setHistoryList] = useState();
  const [zoomUrl, setZoomUrl] = useState();
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayed, setDisplayed] = useState();

  const getHisotries = () => {
    const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/reserve`;

    Axios.get(url)
      .then((response) => {
        setHistoryList(response.data);
        setDisplayed(response.data.slice(0, 10));
        const todayZoom = response.data.filter(
          (item) =>
            new Date(item.reservationDate).toDateString() ===
              new Date().toDateString() && item.approvalDate !== null
        );
        if (todayZoom.length !== 0) {
          setZoomUrl('https://explore.zoom.us/ko/products/meetings/');
        } else {
          setZoomUrl(null);
        }
      })
      .catch((error) => {
        handleErrorMessage(error);
      });
  };

  useEffect(() => {
    if (!auth.auth) return;

    //해당 스터디 그룹의 정보 요청
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${id}`;
    Axios.get(url)
      .then((response) => {
        getSgroupInfo(response.data.data);
      })
      .catch((error) => {
        handleErrorMessage(error);
      });
  }, []);

  useEffect(() => {
    getHisotries();
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (historyList) {
      const start = (page - 1) * 10;
      const end = 10 * page;
      setDisplayed(historyList.slice(start, end));
    }
  }, [page]);

  const onSuccess = (msg) => {
    if (msg === 'SUCCESS') {
      getHisotries();
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <StatusBox isLeader={isLeader} />
      <div className={styles.resultWrapper}>
        <div className={styles.headerWrapper}>
          <h3 className={styles.resultTitle}>예약현황</h3>
          <div className={styles.btns}>
            <button
              className={styles.submitBtn}
              onClick={() => setIsModalOpen(true)}
            >
              예약신청
            </button>
            {zoomUrl && (
              <a href={zoomUrl} target="_blank">
                <button className={styles.zoomBtn}>스터디룸 입장</button>
              </a>
            )}
          </div>
        </div>
        <div className={styles.resultTable}>
          <div className={styles.resultHead}>
            <div>사용예정일</div>
            <div>확정 일자</div>
            <div>신청 이유</div>
          </div>
          {displayed && displayed.length !== 0 ? (
            displayed.map((item) => (
              <div className={styles.resultRow}>
                <div>{`${item.reservationDate.split('T')[0]} ${
                  item.reservationDate.split('T')[1].split(':')[0]
                }:${item.reservationDate.split('T')[1].split(':')[1]}`}</div>
                <div>
                  {item.approvalDate
                    ? `${item.approvalDate.split('T')[0]} ${
                        item.approvalDate.split('T')[1].split(':')[0]
                      }:${item.approvalDate.split('T')[1].split(':')[1]}`
                    : '-'}
                </div>
                <div>{item.purpose}</div>
              </div>
            ))
          ) : (
            <div className={styles.resultRow} style={{ fontSize: '16px' }}>
              스터디 예약내역이 없습니다.
            </div>
          )}
        </div>
        {historyList && historyList.length > 10 && (
          <div style={{ width: '100%' }}>
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={historyList.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        )}
        {isModalOpen && (
          <div className={styles.modalWrapper}>
            <div
              className={styles.overlay}
              onClick={() => setIsModalOpen(false)}
            />
            <Modal
              onSuccess={onSuccess}
              onClick={() => setIsModalOpen(false)}
              title={'스터디룸 예약'}
              // onTabClick={handleTabClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CapabilityReserve);
