import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import BoardCommentReply from './BoardCommentReply';
import { useCreateAndEditClassComment } from 'hooks';
import { successMessage } from 'utilities';

const BoardComment = ({ replyList = [] }) => {
  const { type, id } = useParams();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutateAsync: submitComment, isLoading: isSubmitLoading } =
    useCreateAndEditClassComment({ type, id });

  const handleSubmitComment = async (form) => {
    let response = await submitComment({
      comment: form.commentText,
    });

    if (response.status === 201) {
      successMessage({ text: '댓글이 등록되었습니다.' });
      setValue('commentText', '');
    }
  };

  return (
    <Wrapper>
      <WriteCommentBox>
        <form onSubmit={handleSubmit(handleSubmitComment)}>
          <div>
            <textarea
              name="comment"
              maxLength={500}
              placeholder="댓글을 입력해주세요."
              {...register('commentText', { required: true })}
            />

            <button type="submit">
              {isSubmitLoading ? <CircularProgress size={30} /> : '등록하기'}
            </button>
          </div>
          {errors.commentText?.type && (
            <StyledError>댓글을 입력해주세요.</StyledError>
          )}
        </form>
      </WriteCommentBox>

      <CommentList>
        <div className="count">
          댓글<span>{replyList.length || 0}</span>개
        </div>

        <div className="comment-list">
          {!!replyList.length ? (
            replyList.map((e, index) => (
              <BoardCommentReply key={index} replyInfo={e} />
            ))
          ) : (
            <div className="no-comment">댓글이 존재하지 않습니다.</div>
          )}
        </div>
      </CommentList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const WriteCommentBox = styled.div`
  padding: 18px;
  background: rgb(246, 246, 246);

  form {
    width: 100%;

    > div {
      display: flex;

      textarea {
        flex: 1 1 0;
        border: 1px solid #e4e4e4;
        outline: none;
        padding: 10px;
        resize: none;
        font-size: 14px;
      }

      button {
        width: 200px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        background: rgb(42, 34, 23);
        font-size: 16px;
        appearance: none;
        border: none;
        cursor: pointer;

        svg {
          color: #fff;
        }
      }
    }
  }
`;

const CommentList = styled.div`
  margin-top: 50px;

  div.count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

    span {
      margin-left: 5px;
      color: #ec7422;
    }
  }

  div.comment-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;

    div.no-comment {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      font-size: 16px;
      color: rgb(34, 34, 34);
    }

    div.comment {
      padding: 26px 22px;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
    }
  }
`;

const StyledError = styled.div`
  color: #ff1c1c;
  font-size: 12px;
  margin-top: 0;
  width: 100%;
`;

export default BoardComment;
