import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useRoadmap } from 'hooks';

const OfflineText = styled.p`
  margin-bottom: 5px;
  font-size: 1.5rem;
  color: #919191;
`;

const StyledRoadMapView = styled.div`
  width: 1280px;
  margin: 0 auto;
  letter-spacing: -1px;
  padding-bottom: 50px;
  font-weight: bold;

  > div:not(:last-child) {
    margin-bottom: 15px;
  }

  span {
    display: block;
  }
`;

const StyledRow = styled.div`
  min-width: 100%;
  display: flex;
  gap: 15px;
`;

const StyledTitle = styled(StyledRow)`
  > div {
    min-height: 40px;
  }

  > div:last-child {
    width: 100%;
    font-size: 15px;
    background-color: #919191;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;

const StyledBootcamp = styled(StyledRow)``;

const StyledBasic = styled(StyledRow)``;

const StyledCore = styled(StyledRow)``;

const StyledFinal = styled(StyledRow)`
  .roadmap-list {
    flex-direction: column;
    width: 100%;
  }

  .roadmap-item {
    width: 100%;
    display: flex;
    padding: 10px 12px;
    box-sizing: border-box;
    position: relative;
    min-height: 100px;
    flex-direction: column;
    align-items: center;
  }

  .roadmap-item:not(:last-child):after {
    left: 50%;
    bottom: -22px;
    transform: translateX(-50%);
    top: auto;
    border-bottom: 10px solid transparent;
    border-top: 10px solid #212121;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  .roadmap-item:nth-child(2) {
    background-color: #ffdded;
  }

  .roadmap-item:nth-child(3) {
    background-color: #ffcee4;
  }
`;

const StyledWork = styled(StyledFinal)``;

const StyledFinalAnnouncement = styled(StyledFinal)``;

const StyledType = styled.div`
  width: 57px;
  min-height: 80px;
  background-color: ${(prop) => css`
    ${prop.$background}
  `};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  font-size: 15px;
  padding: 0px 10px;
  box-sizing: border-box;
  flex-direction: column;
`;

const StyledSubType = styled.div`
  width: 87px;
  background-color: ${(prop) => css`
    ${prop.$background}
  `};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  font-size: 15px;
  padding: 0px 10px;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &:after {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 10px solid #dcdcdc;
    border-right: 10px solid transparent;
    right: -23px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    border-bottom: 10px solid transparent;
    border-top: 10px solid #212121;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    left: 50%;
    bottom: -22px;
    transform: translateX(-50%);
  }
`;

const StyledRoadMapList = styled.div`
  display: flex;
  gap: 15px;
`;

const StyledRoadMapItem = styled.div`
  width: 170px;
  padding: 10px 12px;
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  background-color: ${(prop) => css`
    ${prop.$background}
  `};
  overflow: hidden;
  cursor: pointer;

  &.active {
    border: 3px solid
      ${(prop) => css`
        ${prop.$color}
      `};
  }

  &:not(:last-child):after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 10px solid #dcdcdc;
    border-right: 10px solid transparent;
    right: -23px;
    top: 50%;
    transform: translateY(-50%);
  }

  & span.date {
    font-size: 12px;
    border: 1px solid #333;
    display: inline-flex;
    color: #333;
    padding: 5px 8px;
    border-radius: 20px;
    margin-bottom: 12px;
  }

  span.title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4;
    word-break: keep-all;
    white-space: pre-wrap;
  }

  ::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    backdrop-filter: contrast(105%);
    transition: 0.5s;
  }

  :hover::before {
    left: 0;
  }
`;

const RoadMapItem = ({ data, background, color, time }) => {
  const history = useHistory();
  const startDate = new Date(`${data.startYear}-${data.date.split('~')[0]}`);
  const endDate = new Date(
    `${data.endYear}-${data.date.split('~')[1] || data.date.split('~')[0]}`
  );
  const check =
    (startDate <= new Date(time) && new Date(time) <= endDate) ||
    moment().format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD');
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && check) {
      const target =
        ref.current.closest('.roadmap')?.previousElementSibling || ref.current;

      window.scrollTo({
        top: ref.current.closest('.roadmap')
          ? target.offsetTop - 200
          : target.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [ref.current]);

  return (
    <StyledRoadMapItem
      onClick={() => history.push(`/myclass/unit/${data.id}`)}
      ref={ref}
      className={`roadmap-item ${check ? 'active' : ''}`}
      $background={background}
      $color={color}
    >
      <span className="date">{data.date}</span>
      <span className="title">{data.title}</span>
    </StyledRoadMapItem>
  );
};

const RoadMapList = ({ data, background, color, time }) => {
  return (
    <StyledRoadMapList className="roadmap-list">
      {data.map((data, index) => (
        <RoadMapItem
          background={background}
          color={color}
          key={index}
          data={data}
          time={time}
        />
      ))}
    </StyledRoadMapList>
  );
};

const RoadMapView = () => {
  const { data } = useRoadmap();

  const format = (name, data) => {
    if (!data) return [];

    const result = data.find(({ title }) => title === name);

    if (!result) return [];

    return result.elements.map(({ startDate, endDate, title, id }) => {
      const sDate = new Date(startDate);
      const eDate = new Date(endDate);
      const parsedEndDateUsingSetDate = eDate.setDate(eDate.getDate() - 1);
      const parsedStartDate = `${String(sDate.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(sDate.getDate()).padStart(2, '0')}`;
      const parsedEndDate = `${String(eDate.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(new Date(parsedEndDateUsingSetDate).getDate()).padStart(
        2,
        '0'
      )}`;

      return {
        title,
        date:
          parsedStartDate === parsedEndDate
            ? parsedStartDate
            : `${parsedStartDate} ~ ${parsedEndDate}`,
        id,
        startYear: sDate.getFullYear(),
        endYear: eDate.getFullYear(),
      };
    });
  };

  return (
    <StyledRoadMapView>
      <OfflineText>
        ※ 오프라인 교육: <b>입학식/OT</b>, <b>인문특강</b>, <b>현장실습</b>,{' '}
        <b>클라우드 모의해킹 실무</b>, <b>모듈프로젝트2</b>, <b>최종프로젝트</b>
        , <b>최종발표회&수료식</b>.
        <br />그 외의 교육은 실시간 온라인 교육으로 진행 됩니다.
      </OfflineText>
      <StyledTitle>
        <StyledType $background="#919191">구분</StyledType>
        <div>교과목 및 단원</div>
      </StyledTitle>
      <StyledBootcamp className="roadmap">
        <StyledType $background="#fdb900">
          <span>부트</span>
          <span>캠프</span>
        </StyledType>
        <StyledSubType $background="#fdc733">부트캠프</StyledSubType>
        <RoadMapList
          background="#FFF4D8"
          color="#fdb900"
          data={format('특강', data?.data)}
          time={data?.timestamp}
        />
      </StyledBootcamp>
      <StyledBasic className="roadmap">
        <StyledType $background="#99c437">
          <span>기본</span>
          <span>역량</span>
        </StyledType>
        <StyledSubType $background="#adcf5e">
          <span>클라우드</span> <span>기반</span> <span>보안 기술</span>
        </StyledSubType>
        <RoadMapList
          background="#EFF4E1"
          color="#99c437"
          data={format('클라우드 기반 보안 기술', data?.data)}
          time={data?.timestamp}
        />
      </StyledBasic>
      <StyledCore className="roadmap">
        <StyledType $background="#fc6962">
          <span>핵심</span>
          <span>역량</span>
        </StyledType>
        <StyledSubType $background="#ff847d">
          <span>클라우드</span> <span>기반</span> <span>보안 실무</span>
        </StyledSubType>
        <RoadMapList
          background="#FFF2F2"
          color="#fc6962"
          data={format('클라우드 기반 보안 실무', data?.data)}
          time={data?.timestamp}
        />
      </StyledCore>
      <StyledFinal className="roadmap">
        <StyledType $background="#d11a6c">최종프로젝트</StyledType>
        <RoadMapList
          background="#FFF7FF"
          color="#d11a6c"
          data={format('최종 실무 프로젝트', data?.data)}
          time={data?.timestamp}
        />
      </StyledFinal>
      <StyledWork className="roadmap">
        <StyledType $background="#5b36a0">현장실무</StyledType>
        <RoadMapList
          background="#FAF7FF"
          color="#5b36a0"
          data={format('현장 실무', data?.data)}
          time={data?.timestamp}
        />
      </StyledWork>
      <StyledFinalAnnouncement className="roadmap">
        <StyledType $background="#2e47b8">최종발표</StyledType>
        <RoadMapList
          background="#f0f6ff"
          color="#2e47b8"
          data={format('프로젝트 발표회', data?.data)}
          time={data?.timestamp}
        />
      </StyledFinalAnnouncement>
    </StyledRoadMapView>
  );
};

export default RoadMapView;
