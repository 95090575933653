import React, {
  Fragment,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useLocation, withRouter } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';
import {
  Container,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';

import AuthContext from 'context/Auth.context';
import Header from '../../components/header/Header';
import Navbar from '../Navbar';
import styles from '../Mypage.module.scss';
import './GroupTestAnalysis.css';
import { handleErrorMessage } from 'utilities';

const GroupTestAnalysis = () => {
  const auth = useContext(AuthContext);
  const { name } = auth;

  const ColorTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: 'white',
    },
    selected: { color: 'black', backgroundColor: '#ffebd4 !important' },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#868686',
      color: theme.palette.common.white,
      fontSize: '1.45rem',
      fontWeight: '600',
      textAlign: 'center',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const [groupId, setGroupId] = useState();
  const location = useLocation();

  const [groupCourseInfo, setGroupCourseInfo] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [categoryName, setCategoryName] = useState();

  const tableToExcel = () => {
    // work_book 생성
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.table_to_sheet(
      document.getElementById('groupTestAnalysisForPrint'),
      { raw: true }
    );

    // work_book에 work_sheet 추가
    XLSX.utils.book_append_sheet(wb, ws1, groupCourseInfo.groupCourse);

    // work_sheet 컬럼(순서대로) 너비 설정
    ws1['!cols'].push(
      { width: 7 },
      { width: 15 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 }
    );

    XLSX.writeFile(
      wb,
      `SECOLAB_역량평가_문항별_결과분석_${categoryName}_report.xlsx`
    );
  };

  useEffect(() => {
    if (!auth.auth) return;
    setGroupId(location.state.groupId);
    const url = `${process.env.REACT_APP_IP}/teachers/exam/analysis/${auth.classId}/${location.state.classCategoryId}/${location.state.subClassId}/${auth.groupId}`;
    axios
      .get(url)
      .then((response) => {
        setAnalysis(response.data);
        setGroupCourseInfo(location.state.groupCourseInfo);
        setCategoryName(location.state.categoryName);
      })
      .catch(handleErrorMessage);
  }, []);

  const ref = useRef(null);
  const sidebar = 'left';

  const values = Object.values(analysis);

  return (
    <>
      <Header data={{ title: '문항별 분석' }} />
      <Navbar title={'시험 관리'} />
      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === 'left'
              ? styles.with_sidebar + ' ' + styles.left
              : sidebar === 'right'
              ? styles.with_sidebar + ' ' + styles.right
              : null,
          ].join(' ')}
        >
          <article className="groupAnalysisResult">
            <div className="mypageTable">
              <Container>
                <div className="row detailBox">
                  <div className="col-md-2 detailLeft secotext">교육명</div>
                  <div className="col-md-6 detailRight secotext">
                    {groupCourseInfo.groupCourse}
                  </div>
                  <div className="col-md-2 detailLeft secotext">차수</div>
                  <div className="col-md-2 detailRight secotext">
                    {groupCourseInfo.groupCourseCount}
                  </div>
                  {/* <div className="col-md-4 detailRight secotext">{list.postTitle}</div> */}
                </div>
                <div className="row detailBox">
                  <div className="col-md-2 detailLeft secotext">평가 과목</div>
                  <div className="col-md-4 detailRight secotext">
                    {categoryName}
                  </div>
                  <div className="col-md-2 detailLeft secotext">
                    교육 담당자
                  </div>
                  <div className="col-md-4 detailRight secotext">{name}</div>
                </div>
                <div
                  className="row detailcontentBox"
                  style={{
                    marginBottom: '30px',
                    borderRadius: '0 0 5px 5px',
                    borderBottom: '1px solid #e7e7e7',
                  }}
                >
                  <div className="col-md-2 detailLeft secotext">
                    교육 시작일
                  </div>
                  <div className="col-md-4 detailRight secotext">
                    {moment(groupCourseInfo.groupStartDate).format(
                      'YYYY-MM-DD'
                    )}
                  </div>
                  <div className="col-md-2 detailLeft secotext">
                    교육 종료일
                  </div>
                  <div className="col-md-4 detailRight secotext">
                    {moment(groupCourseInfo.groupDueDate).format('YYYY-MM-DD')}
                  </div>
                </div>
              </Container>
              <p
                style={{
                  color: '#2e94d2',
                  fontSize: '1.6rem',
                  fontWeight: '500',
                  marginBottom: '8px',
                }}
              >
                <i class="fas fa-sort-amount-down-alt"></i>&nbsp;사전 역량평가
                출제순
              </p>
              <div style={{ fontSize: '2em', textAlign: 'center' }}>
                <TableContainer
                  component={Paper}
                  id="groupTestAnalysisForPrint"
                >
                  <Table aria-label="customized table">
                    <TableHead rowcount={analysis.length}>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          style={{ width: '10%' }}
                          rowSpan="2"
                        >
                          <span className="secotext">No.</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: '26%' }}
                          rowSpan="2"
                        >
                          <span className="secotext">분류</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: '24%' }}
                          rowSpan="2"
                        >
                          <span className="secotext">유형</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: '16%' }}
                          rowSpan="2"
                        >
                          <span className="secotext">난이도</span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ width: '24%' }}
                          colSpan="2"
                        >
                          <span className="secotext">정답률</span>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell
                          style={{ width: '12%' }}
                          align="center"
                        >
                          <span className="secotext">사전</span>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '12%' }}
                          align="center"
                        >
                          <span className="secotext">사후</span>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.map((v, index) => {
                        return (
                          <>
                            <ColorTableRow key={index}>
                              <TableCell align="center">
                                <span className="secotext">{index + 1}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="secotext">{v.unitTitle}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="secotext">{v.examType}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="secotext">{v.examLevel}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="secotext">
                                  {v.firstCorrectRate}%
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="secotext">
                                  {v.secondCorrectRate}%
                                </span>
                              </TableCell>
                            </ColorTableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div class="d-flex justify-content-center">
        <Link to={'/teacher/test/result'}>
          <button className="goBackButton">
            <i class="fas fa-angle-left"></i> 뒤로가기
          </button>
        </Link>
        <button onClick={() => tableToExcel()} className="excelBtn">
          <i class="far fa-file-excel"></i> Excel 다운로드
        </button>
      </div>
      <br />
      <br />
    </>
  );
};

export default withRouter(GroupTestAnalysis);
