import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import AuthContext from 'context/Auth.context';

const useMutateAttend = () => {
  const auth = useContext(AuthContext);
  const { mutate, isLoading } = useMutation(
    async ({ inputKey, selectedIdx }) => {
      return await axios
        .put(
          `${process.env.REACT_APP_IP}/users/myclass/attendance/check-in/${auth.userId}`,
          {
            inputKey,
            type: selectedIdx,
          }
        )
        .then((res) => {
          return res.data;
        });
    }
  );
  return { mutate, isLoading };
};

export default useMutateAttend;
