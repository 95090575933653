import React from 'react';

import CloudForm from './cloudForm/CloudForm';

function Cloud({ data }) {
  return (
    <>
      <CloudForm data={data} />
    </>
  );
}

export default Cloud;
