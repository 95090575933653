import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import AuthContext from 'context/Auth.context';

const useMutateNum = () => {
  const auth = useContext(AuthContext);
  const { mutate, isLoading } = useMutation(async (selectedIdx) => {
    return await axios
      .put(
        `${process.env.REACT_APP_IP}/users/myclass/attendance/checking/${auth.userId}`,
        {
          type: selectedIdx,
          classId: auth.classId,
        }
      )
      .then((res) => {
        return res.data;
      });
  });
  return { mutate, isLoading };
};

export default useMutateNum;
