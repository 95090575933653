import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useQueryValue = (paramKey) => {
  const searchParams = useQuery();
  return searchParams.get(paramKey);
};

export default useQueryValue;
