import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const TestChoice = (props) => {
  const [value, setValue] = useState('');
  const [tmpValue, setTmpValue] = useState(true);

  useEffect(() => {
    setValue('');
    let answerList = sessionStorage.getItem('saveAnswer');

    if (answerList !== null) {
      let answer = answerList.split(',')[props.idx];
      setValue(answer.trim());
      setTmpValue(!tmpValue);
    }
  }, [props.idx]);

  const changeRadio = (e) => {
    setValue(e.target.value);
    setTmpValue(!tmpValue);
  };

  useEffect(() => {
    value && props.setCheckAnswer(value);
    return function cleanup() {
      props.setCheckAnswer('');
    };
  }, [value]);

  return (
    <div className="choices">
      {/* 사지선다 */}
      <label htmlFor="chosenAnswer"></label>
      <ul>
        {props.testOptions &&
          props.testOptions.map((choice, option) => (
            <label key={option}>
              <li
                key={option}
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <input
                  type="radio"
                  name="chosenAnswer"
                  checked={parseInt(value) === parseInt(option + 1)}
                  onChange={changeRadio}
                  value={option + 1}
                  className="form-control"
                />
                <StyledQuestionOption>
                  {choice &&
                    choice.split('\\t').map((line, index) => {
                      return (
                        <span className="option-text" key={index}>
                          {line}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      );
                    })}
                </StyledQuestionOption>
              </li>
            </label>
          ))}
      </ul>
    </div>
  );
};

const StyledQuestionOption = styled.span`
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-left: 10px;

  span:first-child {
    padding-bottom: 3px;
  }

  span.option-text {
    white-space: pre-wrap;
    line-height: 1.2;
  }
`;

export default TestChoice;
