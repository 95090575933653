import React, { useEffect, useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { getQueryData } from 'utilities/common';
import { useFetchLibraryList, useDebounce } from 'hooks';
import CustomerList from '../CustomerList';
import { Pagination, SearchInput } from '../../../elements';

const LibraryList = () => {
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchOption] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const debouncedSearchContent = useDebounce(searchKeyword, 300);

  const {
    data: list,
    isLoading: isListLoading,
    totalValue,
    refetch: refetchList,
  } = useFetchLibraryList({
    pageNum: page,
    pageSize,
    searchOption,
    searchKeyword: debouncedSearchContent,
  });

  useEffect(() => {
    if (!getQueryData(location, 'pageNum')) return;

    if (Object.keys(location.search).length !== 0) {
      setPage(Number(getQueryData(location, 'pageNum')) - 1);
    }
  }, [location]);

  const move = (num) => {
    setPage(num);
  };

  return (
    <Fragment>
      <InputBox>
        <SearchInput
          value={searchKeyword}
          setValue={setSearchKeyword}
          onClickSearch={refetchList}
        />
      </InputBox>

      <div className="mypageContents">
        <CustomerList
          list={list?.list}
          isListLoading={isListLoading}
          boardType="library"
        />
      </div>

      <StyledBox>
        <Pagination
          page={page}
          total={totalValue}
          perPage={pageSize}
          move={move}
        />
      </StyledBox>
    </Fragment>
  );
};

const InputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > a {
    margin-left: auto;
  }
`;

export default LibraryList;
