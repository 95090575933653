import queryString from 'query-string';
import { toast } from 'react-toastify';

import Message from './Message';

export const linkify = (inputText) => {
  if (!inputText) return '<p>정보없음</p>';

  let replacedText;
  let urlPattern =
    /(\b(https?:\/\/|ftp:\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

  replacedText = inputText.replace(urlPattern, (url) => {
    if (url.startsWith('www.')) {
      return `<a href="https://${url}" target="_blank">${url}</a>`;
    } else {
      return `<a href="${url}" target="_blank">${url}</a>`;
    }
  });

  replacedText = replacedText.replaceAll(/\n/g, '<br>');

  return replacedText;
};

export const getQueryData = (location, queryName) => {
  if (Object.keys(location.search).length === 0) return;

  const queryData = queryString.parse(location.search);
  return queryData[queryName];
};

/* 에러 핸들링 - 알림 메시지  */
export const handleErrorMessage = (error) => {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 401:
        toast.error(Message.SERVICE_INVALID_TOKEN);
        break;
      case 403:
        toast.error(Message.ERROR_FORBIDDEN);
        break;
      case 500:
        toast.error(Message.ERROR_500);
        break;
      default:
        toast.error(Message.ERROR_UNKNOWN);
        break;
    }
  }
};
