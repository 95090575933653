import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useParams, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import swal from 'sweetalert';
import FileSaver from 'file-saver';

import AuthContext from 'context/Auth.context';
import { Button } from 'components/elements';
import StatusBox from './StatusBox';
import Comments from '../components/comment/Comments';
import './detailStyle.css';
import dompurify from 'dompurify';
import { handleErrorMessage, linkify } from 'utilities';

const SGroupPostDetail = ({ history, isLeader, getSgroupInfo }) => {
  const { id, postid } = useParams();
  const auth = useContext(AuthContext);
  const [post, setPost] = useState();
  const [content, setContent] = useState('');
  const [commentChange, setCommentChange] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.auth) return;

      //해당 스터디 그룹의 정보 요청
      const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${id}`;
      try {
        const response = await Axios.get(url);
        getSgroupInfo(response.data.data);
      } catch (error) {
        handleErrorMessage(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      const confirmModal = async (text) => {
        await swal({
          title: text,
          icon: 'warning',
          buttons: '확인',
          dangerMode: true,
        });
      };

      const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/${postid}`;
      try {
        const response = await Axios.get(url);
        setPost(response.data);
        setContent(response.data.content);
      } catch (error) {
        const msg = error.response.data.message;
        handleErrorMessage(error);
        confirmModal(msg).then(history.push('/sgroup/list'));
      }
    })();
  }, [id, postid]);

  const handleDownload = async (fileId, originalFileName) => {
    const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/download/${fileId}`;

    try {
      const response = await Axios.get(url, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', decodeURIComponent(originalFileName));
      link.style.cssText = 'display:none';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      handleErrorMessage(error);
    }
    Axios.get(url, {
      responseType: 'blob',
    })
      .then((response) => {
        if (response) {
          const fileName = response.headers['content-disposition']
            .split('filename=')[1]
            .replaceAll('"', '');

          FileSaver.saveAs(response.data, decodeURIComponent(fileName));
        }
      })
      .catch((error) => {
        handleErrorMessage(error);
      });
  };

  const handleDelete = () => {
    if (window.confirm('게시글을 삭제하시겠습니까?')) {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/${postid}`;
      Axios.delete(url, {})
        .then((response) => {
          if (response.data === 'SUCCESS') {
            toast.success('게시글이 삭제되었습니다.');
            history.push(`/sgroup/detail/${id}`);
          } else {
            toast.success('삭제에 실패하였습니다.');
          }
        })
        .catch((error) => {
          handleErrorMessage(error);
        });
    }
  };

  const onCommentSubmit = () => {
    setCommentChange((prev) => !prev);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <StatusBox isLeader={isLeader} onCommentChange={commentChange} />
      <div style={{ width: '97.5%', marginLeft: '50px' }}>
        {post && (
          <div style={{ width: '100%', display: 'block' }}>
            <div className="row">
              <div
                style={{
                  marginBottom: '2vh',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button
                  roundSmall
                  to={`/sgroup/detail/${id}`}
                  type="outline-color-cl"
                  hoverType="solid-color-tb"
                >
                  <span className="secotext">
                    목록<i className="las la-file-code"></i>
                  </span>
                </Button>
                {post.userId === auth.userId && (
                  <Btns>
                    <Link to={`/sgroup/detail/${id}/update/${postid}`}>
                      <Btn>수정</Btn>
                    </Link>
                    <Btn onClick={handleDelete}>삭제</Btn>
                  </Btns>
                )}
              </div>
            </div>
            <div className="row detailBox">
              <div className="col-md-2 detailLeft secotext">제목</div>
              <div className="col-md-10 detailRight secotext">{post.title}</div>
            </div>
            <div className="row detailBox">
              <div className="col-md-2 detailLeft secotext">등록일자</div>
              <div className="col-md-4 detailRight secotext">
                {post.regDate.split('T')[0]}
              </div>
              <div className="col-md-2 detailLeft secotext">조회수</div>
              <div className="col-md-4 detailRight secotext">
                {post.hitCount}
              </div>
            </div>
            <div className="row detailBox">
              <div className="col-md-2 detailLeft secotext">작성자</div>
              <div className="col-md-10 detailRight secotext">
                {post.userName}
              </div>
            </div>
            <div className="row detailcontentBox">
              <div className="col-md-2 detailLeft secotext">내용</div>
              <ContentBox
                className="col-md-10 detailRight secotext"
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(linkify(content)),
                }}
              ></ContentBox>
            </div>
            {post.studyGroupFileDtoList[0] && (
              <div className="row detailcontentBox">
                <div className="col-md-2 detailLeft secotext">첨부파일</div>
                <div className="col-md-10 detailRight secotext">
                  {post.studyGroupFileDtoList &&
                    post.studyGroupFileDtoList.map((file, index) => {
                      return (
                        <div
                          onClick={() =>
                            handleDownload(file.id, file.originalFileName)
                          }
                          key={file.id}
                          style={{
                            cursor: 'pointer',
                            color: 'rgb(44, 149, 210)',
                          }}
                        >
                          <i className="las la-file-download"></i>&nbsp;
                          {file.originalFileName}
                          <br />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {/* <div className="row navBox">
            <div className="col-md-2 detailLeft">
              다음글&nbsp;&nbsp;&nbsp;<i className="las la-angle-up"></i>
            </div>
            <div className="col-md-10 detailRight">{post.postAuthor}</div>
          </div>
          <div className="row navBox">
            <div className="col-md-2 detailLeft">
              이전글&nbsp;&nbsp;&nbsp;<i className="las la-angle-down"></i>
            </div>
            <div className="col-md-10 detailRight">{post.postAuthor}</div>
          </div> */}
            <Comments
              author={post.userId}
              comments={post.studyGroupPostReplyDtoList}
              onCommentSubmit={onCommentSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Btns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.button`
  font-size: 16px;
  margin-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }

  &:focus {
    outline: none;
  }
`;

const ContentBox = styled.div`
  white-space: pre-wrap;

  ol,
  ul {
    padding-left: 0.7em !important;

    li {
      padding-left: 0.8rem !important;
    }
  }
`;

export default withRouter(SGroupPostDetail);
