import React, { Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import AuthContext from 'context/Auth.context';

const Home = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.auth) {
      history.push('/myclass/roadmap');
    } else {
      history.push('/login');
    }
  }, []);

  return <Fragment></Fragment>;
};

export default Home;
