import React, { useEffect, useContext, Fragment, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import AuthContext from '../../../context/Auth.context';
import { handleErrorMessage } from 'utilities';
import './TakeTestModal.css';

const TakeTestModal = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const auth = useContext(AuthContext);
  const { userId } = auth;
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return window.removeEventListener('resize', resizeListener);
  }, []);

  const resizeListener = () => {};

  const handleClickStart = () => {
    if (!isClicked) {
      setIsClicked(true);
      const url = `${process.env.REACT_APP_IP}/users/exam/start/${userId}`;
      axios
        .post(url, {})
        .then((response) => {
          if (response.data === 'success') {
            history.push(`${match.url}/testpage`);
          } else if (response.data === 'time over') {
            toast.error('시험이 이미 종료되었습니다. 입장하실 수 없습니다.');
            props.closeModal();
          } else {
            toast.error(
              '페이지 접근 오류 발생했습니다. 서버가 정상적으로 작동되었는지 확인하세요. '
            );
            props.closeModal();
          }
        })
        .catch(handleErrorMessage);
    }
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };

  const handleClickCancel = () => {
    return props.closeModal();
  };

  const handleClickContinue = () => {
    history.push(`${match.url}/testpage`);
  };

  return (
    <Fragment>
      <div className="testConfirmModalContainer">
        {!props.testData.isStarted ? (
          <div className="testConfirmModal">
            <div className="testConfirmText">
              <p>
                <i className="fas fa-info-circle"></i>&nbsp;&nbsp;역량평가
                테스트를 시작하시겠습니까?
              </p>
            </div>
            <div className="btnGroup">
              <button
                onClick={() => handleClickCancel()}
                className="cancelBtn"
                title="취소"
              >
                취소
              </button>
              <button
                onClick={() => handleClickStart()}
                className="startBtn"
                title="시작"
              >
                시작
              </button>
            </div>
          </div>
        ) : (
          <div className="testConfirmModal">
            <div className="testConfirmText">
              <p>
                <i className="fas fa-info-circle"></i>&nbsp;&nbsp;역량평가
                테스트를 이어서 하시겠습니까?
              </p>
            </div>
            <div className="btnGroup">
              <button
                onClick={() => handleClickCancel()}
                className="cancelBtn"
                title="취소"
              >
                취소
              </button>
              <button
                onClick={() => handleClickContinue()}
                className="startBtn"
                title="계속"
              >
                계속
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TakeTestModal;
