import { useQuery } from 'react-query';
import { fetchPblProblemDetail } from 'apis/pbl';

const useFetchPblProblemDetailHook = ({ unitId, problemId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPblProblemDetail', unitId, problemId],
    queryFn: async () => await fetchPblProblemDetail({ unitId, problemId }),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!problemId,
  });
  return { ...queryReturns };
};

export default useFetchPblProblemDetailHook;
