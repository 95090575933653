import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';

import Attendance from './Attendance';
import AuthModal from './AuthModal';
import Result from './Result';
import { numberFormatter } from '../../../libs/util';
import { useAttendInfo } from 'hooks';
import { Msg, Title } from './style';

const btnData = ['입실', '외출', '복귀', '퇴실'];
const weekDay = ['일', '월', '화', '수', '목', '금', '토'];

const AttendModal = ({ isModalOpen, handleClose }) => {
  const [page, setPage] = useState('main');
  const today = new Date(Date.now());
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();
  const day = weekDay[today.getDay()];
  const openTime = moment(new Date(Date.now())).hour(8).minute(45);

  const [selectedIdx, setSelectedIdx] = useState(-1);

  const { data } = useAttendInfo();

  const handleBtnClick = (btn) => {
    setSelectedIdx(btn);
    setPage('auth');
  };

  const isBeforeOpenTime = () => {
    return moment(new Date(Date.now())).isBefore(openTime);
  };

  return (
    <Modal
      className="fade modal"
      show={isModalOpen}
      size="md"
      onHide={handleClose}
    >
      <Modal.Header style={{ borderBottom: 'none' }}>
        <Modal.Title
          className="mt-12 pr-4 pl-4"
          style={{ width: '100%', marginLeft: '5px', padding: '15px' }}
        >
          <Title
            color="#f14a16"
            size="26px"
            bold
            style={{ marginBottom: '20px' }}
          >
            {page === 'main' ? '오늘의 출석' : '본인인증'}
          </Title>
          {page === 'main' && (
            <>
              <Msg
                color="rgba(0,0,0,0.7)"
                size="20px"
              >{`${year}/${numberFormatter(month, 2)}/${numberFormatter(
                date,
                2
              )} ${day}요일`}</Msg>
              {isBeforeOpenTime() && (
                <Msg
                  color="rgba(0,0,0,0.7)"
                  size="20px"
                  style={{ marginTop: '10px' }}
                >
                  {`오늘 출석이 시작되지 않았습니다.`}
                </Msg>
              )}
              {data?.attendTime && !data?.leavingTime && (
                <Msg color="rgba(0,0,0,0.7)" size="20px">
                  {data?.outingStartTime && !data?.outingEndTime
                    ? '출석완료 (외출중)'
                    : '출석완료'}
                </Msg>
              )}
              {data?.leavingTime && (
                <Msg color="rgba(0,0,0,0.7)" size="20px">
                  퇴실완료
                </Msg>
              )}
            </>
          )}
        </Modal.Title>
        <Button variant="" className="close" onClick={handleClose}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'auto' }}>
        {page === 'main' && (
          <Attendance
            isBeforeOpenTime={isBeforeOpenTime}
            data={data}
            onClick={handleBtnClick}
          />
        )}
        {page === 'auth' && selectedIdx >= 0 && (
          <AuthModal selectedIdx={selectedIdx} onClick={setPage} />
        )}
        {page === 'result' && selectedIdx >= 0 && (
          <Result selectedBtn={btnData[selectedIdx]} onClose={handleClose} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AttendModal;
