import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useFetchPblUnits } from 'hooks';
import './PBLMenu.css';

const PBLMenu = ({ showMenu }) => {
  const history = useHistory();

  const { data, isLoading } = useFetchPblUnits();
  const [units, setUnits] = useState([]);

  useEffect(() => {
    setUnits(data);
  }, [isLoading, data]);

  const info = document.location.href.split('/');
  let sTitle = info[5];
  if (!sTitle) {
    sTitle = '';
  }

  const currentTitle = units?.find((e) => e.id === Number(sTitle))?.title;

  const handleClickMenu = (e, path, isVisible) => {
    e.preventDefault();
    if (!isVisible) return;

    history.push(path);
  };

  return (
    <Container
      showMenu={showMenu}
      className={units?.length === 0 ? 'none' : ''}
    >
      <ul>
        {units?.map((unit, index) => (
          <li
            className={`${currentTitle === unit.title ? 'pblMenuActive' : ''} ${
              !unit.pblVisible ? 'disabled' : ''
            }`}
            key={index}
            onClick={(e) =>
              handleClickMenu(e, `/myclass/pbl/${unit.id}`, unit.pblVisible)
            }
          >
            <div>
              <span>
                <b>{unit.title}</b>
              </span>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  opacity: 0;
  position: absolute;
  top: 50px;
  left: -20px;
  flex-direction: row;
  animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  padding: 15px !important;
  z-index: 10;

  &.none {
    display: none;
  }

  ul {
    max-width: 300px;
    width: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08);

    li {
      width: 100%;
    }

    li > div {
      color: rgba(0, 0, 0, 0.6);
      word-break: keep-all;
      text-align: center;
      padding: 5px 20px 0;
      font-family: inherit;

      &:last-child {
        margin-right: 0;
      }

      > span {
        padding: 0 10px;

        &:hover {
          color: #ec7422;
        }

        b {
          display: inline-block;
          font-weight: inherit;
          text-align: center;
          padding-bottom: 5px;
        }
      }
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(-30px);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: translateY(-8px);
      opacity: 1;
    }
  }
`;

export default withRouter(PBLMenu);
