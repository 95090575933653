import axios from 'axios';
import { useMutation } from 'react-query';

const useUpdateCounseling = () => {
  const { mutate, isLoading } = useMutation(
    async ({ counselingId, title, content }) => {
      return await axios
        .put(`${process.env.REACT_APP_IP}/teachers/exam/counseling/comments`, {
          counselingId,
          title,
          content,
        })
        .then((res) => {
          return res.data;
        });
    }
  );
  return { mutate, isLoading };
};

export default useUpdateCounseling;
