import { useQuery } from 'react-query';

import { fetchClassDetail } from '../apis';

const useClassDetail = () => {
  const queryReturns = useQuery({
    queryKey: ['classDetail'],
    queryFn: async () => fetchClassDetail(),
    select: (res) => res.data,
  });

  return { ...queryReturns };
};

export default useClassDetail;
