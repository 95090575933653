import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';

import { uploadContentImage } from 'apis/classBoard';
import { errorMessage } from 'utilities';

Quill.register('modules/imageResize', ImageResize);
const TextEditorWithImage = ({
  htmlStr,
  setHtmlStr,
  setDeleteImgs,
  imgList,
  isEdit = false,
  setImageNames,
  setIsImageLoading,
  placeholder = '내용을 입력하세요.',
}) => {
  const quillRef = useRef(null);
  const [imageSrc, setImageSrc] = useState([]);
  const imageTags = document.querySelectorAll('.ql-editor > p > img');

  const LIMITED_IMAGE_SIZE = 1024 * 1024 * 2;
  const LIMITED_IMAGE_COUNT = 5;

  const handleImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    const editor = quillRef.current.getEditor();

    input.addEventListener('change', () => {
      const files = input.files;
      const editorImages = editor.container.querySelectorAll('img');

      if (files[0].size > LIMITED_IMAGE_SIZE) {
        errorMessage({ text: '2MB 이하의 사진만 업로드가 가능합니다.' });
        return;
      }

      if (editorImages.length >= LIMITED_IMAGE_COUNT) {
        errorMessage({ text: '사진은 5개까지만 업로드가 가능합니다.' });
        return;
      }

      if (files.length) {
        setIsImageLoading(true);
        Array.from(files).forEach(async (file) => {
          try {
            const formData = new FormData();
            formData.append('img', file);

            const { data } = await uploadContentImage(formData);
            const imageName = data.message;

            setImageNames((prev) => [...prev, imageName]);

            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(
              range.index,
              'image',
              `${process.env.REACT_APP_IP}/lc-post/temp/${imageName}`
            );
            editor.setSelection(range.index + 1);
            setIsImageLoading(false);
          } catch (error) {
            setIsImageLoading(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (imageTags.length <= imageSrc.length) return;
    imageTags.forEach((e) => {
      const imageName = e.src.split('/').at(-1);
      setImageSrc((prevImageSrc) => [
        ...prevImageSrc,
        {
          name: imageName,
          src: e.src,
        },
      ]);
    });
  }, [imageTags.length]);

  useEffect(() => {
    if (imageTags.length === 0) {
      setImageSrc([]);
      setImageNames([]);
      return;
    }

    const updatedImageSrcs = imageSrc.filter((srcObj) =>
      Array.from(imageTags)
        .map((tag) => tag.src)
        .includes(srcObj.src)
    );
    if (imageTags.length < imageSrc.length) {
      setImageSrc(updatedImageSrcs);
    }
  }, [imageTags.length]);

  useEffect(() => {
    if (!isEdit) return;
    if (imgList?.length === 0) return;

    const newDeleteImgs = imgList?.filter(
      (name) => !imageSrc.some((obj) => decodeURIComponent(obj.name) === name)
    );

    setDeleteImgs(newDeleteImgs);
  }, [imageSrc, imgList?.length]);

  useEffect(() => {
    const extractedNames = [...imageTags].map((tag) =>
      decodeURIComponent(tag.src).split('/').pop()
    );

    if (isEdit) {
      const newImageNames = extractedNames.filter(
        (item) => !imgList.includes(item)
      );
      setImageNames([...newImageNames]);
      return;
    }

    setImageNames([...extractedNames]);
  }, [imageTags.length, imgList]);

  /* 에디터 전체 클릭 시 바로 입력창에 focus */
  useEffect(() => {
    const containerElement = document.querySelector('.ql-container');

    const handleClick = (event) => {
      if (event.target.classList.contains('ql-container')) {
        const editorElement = document.querySelector('.ql-editor');
        if (editorElement && editorElement.focus) {
          editorElement.focus();
        }
      }
    };

    containerElement.addEventListener('click', handleClick);

    return () => {
      containerElement.removeEventListener('click', handleClick);
    };
  }, []);

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          [{ align: [] }, { color: [] }, { background: [] }],
          ['link'],
          ['clean', 'image'],
        ],
        handlers: {
          image: handleImage,
        },
      },
      clipboard: {
        matchVisual: false,
      },
      keyboard: {
        bindings: {
          'list autofill': {
            prefix: /^\s{0,}(?![1])(\.|-|\*|\[ ?\]|\[x\])$/,
          },
        },
      },
    }),
    []
  );

  const formats = [
    'size',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'align',
    'color',
    'image',
    'link',
    'background',
    'float',
    'height',
    'width',
  ];

  return (
    <CustomReactQuill
      ref={quillRef}
      theme="snow"
      modules={modules}
      formats={formats}
      value={htmlStr}
      placeholder={placeholder}
      onChange={(content, delta, source, editor) =>
        setHtmlStr(editor.getHTML())
      }
    />
  );
};

const CustomReactQuill = styled(ReactQuill)`
  padding-bottom: 15px;
  white-space: pre-wrap;

  .ql-container {
    min-height: 400px !important;
    height: auto;
  }

  .steperText h3,
  p {
    color: #212529 !important;
    font-size: 1.6rem !important;
  }

  li {
    color: #212529 !important;
    font-size: 1.6rem !important;
  }

  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0.7rem !important;

    > li {
      padding-left: 0.8rem !important;
    }
  }

  .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 1em !important;
  }
  .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 2em !important;
  }
  .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 3em !important;
  }
  .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 4em !important;
  }
  .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 5em !important;
  }
  .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 6em !important;
  }
  .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 7em !important;
  }
  .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 8em !important;
  }
`;

export default TextEditorWithImage;
