import React, { useEffect, useState } from 'react';

const TestOx = (props) => {
  const [value, setValue] = useState('');
  const [tmpValue, setTmpValue] = useState(true);

  useEffect(() => {
    setValue('');
    let answerList = sessionStorage.getItem('saveAnswer');

    if (answerList !== null) {
      let answer = answerList.split(',')[props.idx];
      if (answer !== '') {
        setValue(answer.trim());
        setTmpValue(!tmpValue);
      }
    }
  }, [props.idx]);

  const changeRadio = (e) => {
    setValue(e.target.value);
    setTmpValue(!tmpValue);
  };

  useEffect(() => {
    value && props.setCheckAnswer(value);
    return function cleanup() {
      props.setCheckAnswer('');
    };
  }, [tmpValue]);

  return (
    <div className="choices">
      {/* OX 선택 */}
      <label>
        <input
          type="radio"
          name="chosenAnswer"
          checked={value === 'O'}
          onChange={changeRadio}
          value="O"
          className="form-control"
        />
        <span>&nbsp;O</span>
      </label>
      <label>
        <input
          type="radio"
          name="chosenAnswer"
          checked={value === 'X'}
          onChange={changeRadio}
          value="X"
          className="form-control"
        />
        <span>&nbsp;X</span>
      </label>
    </div>
  );
};

export default TestOx;
