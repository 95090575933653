import React, { useState, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import AuthContext from 'context/Auth.context';
import AttendModal from './modal';
import styles from './topbar.module.scss';
import BoardMenu from '../board/BoardMenu';
import PBLMenu from '../pbl/PBLMenu';
import { useFetchPblUnits } from 'hooks';

const Tabs = ({
  noteUrl,
  todayUnitId,
  location,
  tabBtns,
  urlname,
  currentTime,
}) => {
  const auth = useContext(AuthContext);

  const { data } = useFetchPblUnits();

  const [modal, setModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showPBLMenu, setShowPBLMenu] = useState(false);

  const handleModalOpen = () => {
    setModal(true);
  };

  const handleMouseEnter = (menuName) => {
    if (menuName === 'board') {
      setShowMenu(true);
      setShowPBLMenu(false);
    }
    if (menuName === 'pbl') {
      setShowMenu(false);
      setShowPBLMenu(true);
    }
  };
  const handleMouseLeave = () => {
    setShowMenu(false);
    setShowPBLMenu(false);
  };

  const downMenuTab = ['board', 'pbl'];

  return (
    <div className={styles.tabBody}>
      <div className={styles.tabBar}>
        <div className={styles.tabs}>
          {tabBtns.map((item, index) => (
            <Fragment key={index}>
              <Link
                key={item.id}
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={() => handleMouseLeave(item.name)}
                style={
                  downMenuTab.includes(item.name)
                    ? { position: 'relative' }
                    : {}
                }
                to={
                  item.page === 'class-unit'
                    ? item.url + location.pathname.split('/')[3]
                    : item.url
                }
              >
                <div className={item.active ? styles.selectedTab : ''}>
                  <span>{item.text}</span>
                  {downMenuTab.includes(item.name) &&
                    (item.name !== 'pbl' || data?.length !== 0) && (
                      <i className="fa-solid fa-chevron-down" />
                    )}
                </div>
                {item.name === 'board' && showMenu && (
                  <BoardMenu showMenu={showMenu} />
                )}
                {item.name === 'pbl' && showPBLMenu && (
                  <PBLMenu showMenu={showPBLMenu} />
                )}
              </Link>
            </Fragment>
          ))}
        </div>
        <div className={styles.btnWrapper}>
          <a
            href={noteUrl}
            target="_blank"
            className={noteUrl ? styles.noteBtn : styles.disabledBtn}
          >
            강의노트
          </a>
          {urlname === 'myclass' && (
            <>
              <Link
                to={
                  tabBtns[0].page === 'class-main'
                    ? todayUnitId === 0
                      ? '#'
                      : `/myclass/unit/${todayUnitId}`
                    : '/myclass/roadmap'
                }
                className={
                  tabBtns[0].page === 'class-main'
                    ? todayUnitId !== 0
                      ? styles.listBtn
                      : styles.disabledBtn
                    : styles.listBtn
                }
              >
                {tabBtns[0].page === 'class-main'
                  ? '오늘의 강의실'
                  : '강의실 나가기'}
              </Link>
              {auth.type === '1' && (
                <div onClick={handleModalOpen} className={styles.attendanceBtn}>
                  출석체크
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {modal && (
        <AttendModal isModalOpen={modal} handleClose={() => setModal(false)} />
      )}
    </div>
  );
};

export default withRouter(Tabs);
