export const BOARD_TITLE = {
  notice: '공지사항',
  task: '과제',
  qna: '강의 질문',
  library: '학습 자료',
  taskResult: '과제 채점 결과',
};

/* 클래스 게시판 api category에 사용되는 값 */
export const BOARD_CATEGORY = {
  notice: '공지사항',
  qna: '강의문의',
  library: '학습자료',
};
