import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from 'context/Auth.context';
import styles from './preCourse.module.scss';
import PrecourseNotice from './PrecourseNotice';

const PreCourseList = ({ list, getTitle }) => {
  const auth = useContext(AuthContext);
  const [preCourses, setPreCourses] = useState();
  const [MyPreCourses, setMyPreCourses] = useState();

  useEffect(() => {
    setPreCourses(list);
    setMyPreCourses(
      list?.filter((item) => item.classId === Number(auth.classId))
    );
    getTitle('지식컨텐츠');
  }, [list, auth.classId, getTitle]);

  return (
    <div className={styles.contentBody}>
      {/*{MyPreCourses && (*/}
      {/*  <>*/}
      {/*    <h3 className={styles.title}>{auth.name}님을 위한 사전학습</h3>*/}
      {/*    <div className={styles.myPreWrapper}>*/}
      {/*      {MyPreCourses.map((item) => (*/}
      {/*        <Link key={item.id} to={`/pre-course/detail/${item.id}`}>*/}
      {/*          <div className={styles.myCourseWrapper}>*/}
      {/*            <div className={styles.myPreImgWrapper}>*/}
      {/*              <img*/}
      {/*                src={`${process.env.REACT_APP_IP}/pre-learning/${item.imageName}`}*/}
      {/*                alt="사진"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*            <div className={styles.contentTitle}>{item.title}</div>*/}
      {/*            <div className={styles.time}>*/}
      {/*              <span>총강의시간 : </span>*/}
      {/*              <span>{item.totalTime}시간</span>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </Link>*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
      <h3 className={styles.title}>{auth.name}님을 위한 사전학습</h3>

      {/* 사전학습 안내문 컴포넌트 */}
      <PrecourseNotice />

      <div className={styles.preWrapper}>
        {preCourses &&
          preCourses.map((item) => (
            <Link key={item.id} to={`/pre-course/detail/${item.id}`}>
              <div className={styles.courseWrapper}>
                <div className={styles.preImgWrapper}>
                  <img
                    src={`${process.env.REACT_APP_IP}/pre-learning/${item.imageName}`}
                    alt="사진"
                  />
                </div>
                <div>
                  <div className={styles.contentTitle}>{item.title}</div>
                  <div className={styles.time}>
                    <span>총강의시간 : </span>
                    <span>{item.totalTime}시간</span>
                  </div>
                </div>
                <div className={styles.btn}>학습하기</div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default PreCourseList;
