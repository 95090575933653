import React from 'react';
import styled from 'styled-components';

const InputBox = ({ onChange }) => {
  return (
    <>
      <InputContainer>
        <Input
          onChange={onChange}
          type="text"
          placeholder="검색어를 입력해주세요."
        />
      </InputContainer>
    </>
  );
};

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 24px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
`;

const Input = styled.input`
  font-size: 14px;
  padding: 5px 0;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  border: none;
  border-radius: 0;
`;

export default InputBox;
