import axios from 'axios';
import FileSaver from 'file-saver';
import { useQuery } from 'react-query';
import swal from 'sweetalert';

const useDownload = (url) => {
  const { refetch, isLoading } = useQuery(
    ['download', url],
    async () => {
      return await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          if (res.headers['content-disposition']) {
            FileSaver.saveAs(
              res.data,
              decodeURIComponent(
                res.headers['content-disposition']
                  .split('filename=')[1]
                  .replaceAll('"', '')
              ).normalize('NFC')
            );
          } else {
            swal({ icons: 'error', title: '파일 저장에 실패하였습니다.' });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    { refetchOnWindowFocus: false, enabled: false }
  );
  return { refetch, isLoading };
};

export default useDownload;
