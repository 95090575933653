import Axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useParams, useLocation } from 'react-router';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AuthContext from 'context/Auth.context';
import StatusBox from './StatusBox';
import { TextEditor } from '../../components/elements';
import { handleErrorMessage } from 'utilities';

const SGroupWrite = ({ history, type, isLeader, getSgroupInfo }) => {
  const { id, postid } = useParams();
  const auth = useContext(AuthContext);
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const fileRef = useRef(null);

  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFileId, setSelectedFileId] = useState();
  const [textValue, setTextValue] = useState('');

  const location = useLocation();
  const memberStatus = location?.state?.memberStatus;

  useEffect(() => {
    if (!auth.auth) return;

    //해당 스터디 그룹의 정보 요청
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${id}`;
    Axios.get(url)
      .then((response) => {
        getSgroupInfo(response.data.data);
      })
      .catch((error) => {
        handleErrorMessage(error);
      });
  }, []);

  useEffect(() => {
    if (type === 'update') {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/${postid}`;

      Axios.get(url)
        .then((response) => {
          titleRef.current.value = response.data.title;
          setTextValue(response.data.content);
          setSelectedFileName(
            response.data.studyGroupFileDtoList[0].originalFileName
          );
          setSelectedFileId(response.data.studyGroupFileDtoList[0].id);
        })
        .catch((error) => {
          handleErrorMessage(error);
        });
    } else {
      titleRef.current.value = '';
      setTextValue('');
    }
  }, [type]);

  const handleWrite = () => {
    if (window.confirm('게시글을 등록하시겠습니까?')) {
      if (textValue.length < 3500) {
        const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts`;
        const title = titleRef.current.value;
        const content = textValue;
        const file = fileRef.current.files[0];
        if (title.trim() === '') {
          toast.error('제목을 입력해주세요.');
          titleRef.current.focus();
          return;
        } else if (textValue.trim() === '') {
          toast.error('내용을 입력해주세요.');
          return;
        }

        const frm = new FormData();
        frm.append('title', title);
        frm.append('content', content);
        frm.append('file', file);

        Axios.post(url, frm)
          .then((response) => {
            if (response.status === 201) {
              toast.success('글 작성 성공');
              history.push(`/sgroup/detail/${id}/post/${response.data.id}`);
            } else {
              toast.error('글 작성 실패');
              history.push(`/sgroup/detail/${id}`);
            }
          })
          .catch((error) => {
            handleErrorMessage(error);
          });
      } else {
        toast.error('2500자 이상은 작성할 수 없습니다.');
      }
    }
  };

  const handleUpdate = () => {
    if (window.confirm('게시글을 수정하시겠습니까?')) {
      if (textValue.length < 3500) {
        const url = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts/${postid}`;
        const title = titleRef.current.value;
        const content = textValue;
        const file = fileRef.current.files[0];
        if (title.trim() === '') {
          toast.error('제목을 입력해주세요.');
          titleRef.current.focus();
          return;
        } else if (textValue.trim() === '') {
          toast.error('내용을 입력해주세요.');
          return;
        }

        const frm = new FormData();
        frm.append('title', title);
        frm.append('content', content);
        if (file) {
          frm.append('file', file);
          frm.append('deleteFiles', [selectedFileId]);
        } else {
          frm.append('file', '');
          frm.append('deleteFiles', []);
        }

        Axios.put(url, frm)
          .then((response) => {
            if (response.status === 200) {
              toast.success('글 수정 성공');
              history.push(`/sgroup/detail/${id}/post/${response.data.id}`);
            } else {
              toast.error('글 수정 실패');
              history.push(`/sgroup/detail/${id}`);
            }
          })
          .catch((error) => {
            handleErrorMessage(error);
          });
      } else {
        toast.error('2500자 이상은 작성할 수 없습니다.');
        contentRef.current.focus();
      }
    }
  };

  const handleUploadClick = () => {
    fileRef.current.click();
  };

  const handleInputChange = (e) => {
    if (fileRef.current.files[0]) {
      if (
        ![
          'application/x-zip',
          'application/msword',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint',
          'application/mspowerpoint',
          'application/x-mspowerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.hancom.hwp',
          'application/vnd.hancom.hwt ',
          'application/vnd.hancom.hml',
          'application/vnd.hancom.hwpx',
          'application/zip',
          'application/x-zip-compressed',
          'application/octet-stream',
        ].includes(fileRef.current.files[0].type)
      ) {
        alert('ZIP, MS Word, Power Point, PDF 파일만 업로드가 가능합니다.');
        fileRef.current.value = '';
        setSelectedFileName('');
      } else if (fileRef.current.files[0].size > 1024 * 1024 * 10) {
        alert('10메가 이하의 파일만 업로드할 수 있습니다.');
        fileRef.current.value = '';
        setSelectedFileName('');
      } else {
        setSelectedFileName(fileRef.current.files[0].name);
      }
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      {isLeader ? (
        <StatusBox isLeader={isLeader} />
      ) : (
        <StatusBox isLeader={isLeader} />
      )}
      <Wrapper>
        <Title>{type === 'update' ? '글수정' : '글작성'}</Title>
        <div style={{ width: '100%' }}>
          <InputBox>
            <div>제목</div>
            <input
              ref={titleRef}
              type="text"
              placeholder="제목을 입력하세요."
            />
          </InputBox>

          <QuillBox>
            <span>내용</span>
            <div>
              <TextEditor htmlStr={textValue} setHtmlStr={setTextValue} />
            </div>
          </QuillBox>

          <InputBox>
            <div>첨부파일</div>
            <input
              style={{ display: 'none' }}
              ref={fileRef}
              type="file"
              name="task"
              id="task"
              accept=".zip,.rar,.7zip,application/pdf,.hwp,.ppt,.pptx,.pptx,pps,.hwpx,.doc,.docx"
              onChange={handleInputChange}
            />
            <FileBox>
              {selectedFileName ? (
                <FileName onClick={handleUploadClick}>
                  <i className="fas fa-paperclip"></i>
                  {selectedFileName}
                </FileName>
              ) : (
                <UploadBtn onClick={handleUploadClick}>
                  <i className="fas fa-paperclip"></i>
                  <span>파일첨부</span>
                </UploadBtn>
              )}
            </FileBox>
          </InputBox>
        </div>
        <BtnBox>
          <Link to={`/sgroup/detail/${id}`}>취소</Link>
          <button
            onClick={type === 'update' ? handleUpdate : handleWrite}
            type="button"
          >
            등록
          </button>
        </BtnBox>
      </Wrapper>
    </div>
  );
};

const FileBox = styled.div`
  width: 79%;
`;

const UploadBtn = styled.div`
  padding: 10px;
  width: 120px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
`;

const FileName = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;

  i {
    margin-right: 5px;
  }
`;

const Wrapper = styled.div`
  width: 96%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
  border-radius: 10px;
  margin-bottom: 50px;
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;

  div:first-child {
    width: 100px;
    font-size: 16px;
  }

  input {
    font-size: 14px;
    width: 79%;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
      font-size: 16px;
    }
  }
`;

const QuillBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0 50px;

  > span {
    width: 100px;
    font-size: 16px;
  }

  > div {
    width: calc(100% - 110px);
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const BtnBox = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  button {
    cursor: pointer;
    width: 100px;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: none;
    background-color: #fc9918;
    color: white;
    border: 1px solid #fc9918;

    &:focus {
      outline: none;
    }
  }

  a {
    text-align: center;
    cursor: pointer;
    width: 100px;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: none;
    background-color: white;
    color: rgba(36, 12, 12, 0.7);
    border: 1px solid rgba(171, 122, 122, 0.2);
  }
`;

export default withRouter(SGroupWrite);
