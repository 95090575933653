import { useMutation, useQueryClient } from 'react-query';

import { editInquiryDetail } from '../../apis/customer';

const useEditInquiryDetail = ({ id }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      return await editInquiryDetail({
        id,
        formData,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchInquiryDetail'] }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchInquiryDetail'] }),
  });

  return { ...queryReturns };
};

export default useEditInquiryDetail;
