import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

export const ClassWrapper = styled.div`
  width: 1000px;
  margin-bottom: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;

  h1 {
    font-size: 28px;
    font-weight: 700;
    margin: 10px 0 30px;
  }
`;

export const WriteContentBox = styled.div`
  width: 100%;
  border-top: 2px solid #000;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 94px;
    align-items: flex-start;
    justify-content: center;

    > span.title {
      width: 100px;
      display: inline-block;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: bold;
      font-size: 17px;
    }

    > div.input-box {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    > div.input-box > input[type='text'] {
      width: 100%;
      outline: none;
      height: 45px;
      border: 1px solid rgb(230, 230, 230);
      padding-left: 15px;
      border-radius: 3px;
      font-size: 15px;
    }

    > div.content-box {
      width: 100%;

      > span.appendix-info {
        font-size: 11px;
      }

      .react-datepicker {
        font-size: 1.5em;
      }
      .react-datepicker__header {
        padding-top: 0.8em;
      }
      .react-datepicker__month {
        margin: 0.4em 1em;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        width: 3em;
        height: 3em;
        line-height: 3em;
        margin: 0.166em;
      }
      .react-datepicker__current-month {
        font-size: 1em;
      }
      .react-datepicker__navigation {
        top: 0.3em;
        line-height: 1em;
        border: 0.45em solid transparent;
      }
      .react-datepicker__navigation--previous {
        left: 1em;
      }
      .react-datepicker__navigation--next {
        right: 1em;
      }
    }
  }
`;

export const ClassListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 20px 0 50px;
  text-align: center;

  h1 {
    font-weight: bold;
    margin-bottom: 30px;
  }

  > a.go-list {
    font-size: 16px;
    width: 150px;
    height: 40px;
    background: #ec7422;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    margin: 50px auto 0;

    &:hover {
      filter: brightness(105%);
    }
  }
`;

export const ClassListContentBox = styled.div`
  box-shadow: 0 5px 5px #e5e5e5;
`;

export const ClassListInputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;

  > span {
    font-weight: 500;
    font-size: 15px;
    b {
      color: #ec7422;
    }
  }

  div.search-box {
    display: flex;

    .search-icon {
      padding: 10px;
      background-color: #999;
      margin-left: 3px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        filter: brightness(105%);
      }
    }
  }
`;

export const ClassListStyledBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > a {
    margin-left: auto;
  }
`;

export const ClassDetailContainer = styled.div`
  width: 1000px;
  padding: 0 0 100px;
`;

export const ClassDetailWrapper = styled.div`
  border-top: 2px solid #333;

  > a,
  > div.go-list {
    font-size: 16px;
    width: 150px;
    height: 40px;
    background: #ec7422;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    margin: 50px auto 0;
    cursor: pointer;

    &:hover {
      filter: brightness(105%);
    }
  }
`;

export const ClassDetailTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  min-height: 60px;
  padding: 10px;
  overflow: hidden;

  > div.title {
    display: flex;
    max-width: 75%;

    h2 {
      max-width: 100%;
      margin-bottom: unset;
      margin-left: 5px;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  > div.sub-info {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;

      i {
        margin-right: 5px;
      }
    }
  }
`;

export const DetailContentBox = styled.div`
  padding: 20px;
  font-size: 16px;
  min-height: 300px;
  border-bottom: 1px solid #eee;
  white-space: pre-wrap;

  p {
    font-size: 16px;
    color: #333;
    width: 100%;

    img {
      max-width: 100%;
      object-fit: contain;
      cursor: default !important;
    }
  }

  ol,
  ul {
    padding-left: 0.7em !important;

    li {
      padding-left: 0.8rem !important;
    }
  }
`;

export const DetailTaskSubmitBox = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 30px auto 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 15px;

  > div.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 10px;

    h2 {
      margin-bottom: 0;
    }
    span {
      font-size: 1.8rem;
    }
  }

  > div.file-upload {
    display: flex;
    align-items: center;
    row-gap: 20px;

    > span {
      max-width: 200px;
      font-size: 14px;
      padding: 8px 20px;
      color: #333;
      background-color: #aae787;
      border-radius: 5px;
      box-shadow: 1px 2px 2px #eee;
      cursor: pointer;
      font-weight: 500;
      transition: 0.3s;
      text-align: center;

      &:hover {
        background-color: #92de66;
        color: #000;
      }

      &.disabled {
        background-color: #afafaf;
        cursor: not-allowed;
        color: #fff;
      }
    }
  }
`;

export const DetailFileBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > p {
    display: flex;
    align-items: center;
    min-height: 60px;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #eee;
    padding: 0 10px;

    > span.file-title {
      display: inline-block;
      min-width: 100px;
      font-weight: bold;
    }
  }
`;

export const DetailFormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin: 60px 0 30px;

  > div {
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(206, 206, 206);
    color: #fff;
    font-size: 16px;
    border-radius: 15px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: bold;

    &.edit {
      border: 2px solid #ec7422;
      color: #ec7422;
    }
    &.delete {
      background-color: #ec7422;
      svg {
        color: #fff;
      }
    }

    &:hover {
      filter: brightness(105%);
    }
  }
`;

export const StyledError = styled.div`
  color: #ff1c1c;
  font-size: 12px;
  margin-top: 0;
  width: 100%;
  padding-left: 10px;
`;
