import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';

import TestQuestion from './TestQuestion';
import TestTimer from './TestTimer';
import { handleErrorMessage } from 'utilities';
import AuthContext from 'context/Auth.context';
import Header from '../../components/header/Header';
import './TestPage.css';
import styles from '../Capatest.module.scss';

const TestPage = ({ sidebar = 'left', history }) => {
  const ref = useRef(null);
  const auth = useContext(AuthContext);
  const { userId } = auth;

  const [questionData, setQuestionData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [idx, setIdx] = useState(0);
  const [nowQNum, setNowQNum] = useState(1);
  const [saveAnswer, setSaveAnswer] = useState([]);
  const [timeOut, setTimeOut] = useState(false);

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/users/exam/${userId}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data !== null) {
          setQuestionData(response.data);
          setQuestionList(response.data.examProblemIdList);
          if (response.data.isSubmitted > 0) {
            history.push('/capability/test');
          }
          if (sessionStorage.getItem('saveAnswer') === null) {
            const arr = new Array(response.data.examProblemCount);
            arr.fill('');
            setSaveAnswer(arr);
            //   저장된 답안이 없는, 최초로 시험을 보는 상황일 경우 시험 문제 갯수 만큼의 빈스트링으로 이뤄진 배열을 생성
          } else {
            setSaveAnswer(sessionStorage.getItem('saveAnswer').split(','));
            // 세션 스토리지에 저장되어 있는 스트링을 가져다가 다시 split으로 나눠서 배열 생성
          }
        } else {
          history.push('/capability/test');
        }
      })
      .catch(handleErrorMessage);
  }, []);

  const handlePrevButton = () => {
    if (nowQNum > 1) {
      setNowQNum(nowQNum - 1);
      setIdx(idx - 1);
      window.scrollTo(0, 0);
    } else {
      alert('첫 번째 문제입니다.');
    }
  };

  const handleNextButton = () => {
    setNowQNum(nowQNum + 1);
    setIdx(idx + 1);
    window.scrollTo(0, 0);
  };

  const handleSubmitButton = (e) => {
    if (window.confirm('답안을 제출하시겠습니까?')) {
      if (saveAnswer.includes('')) {
        if (
          window.confirm(
            '답안을 작성하지 않은 문항이 있습니다. 정말로 제출하시겠습니까?'
          )
        ) {
          onSubmit(saveAnswer);
        } else {
          let NumofQ;
          NumofQ = saveAnswer.indexOf('');
          goToThisQuestion(NumofQ, e);
          return;
        }
      } else {
        onSubmit(saveAnswer);
        return;
      }
    } else {
      return;
    }
  };

  const timeOutSubmit = (e) => {
    const timeOver = new Promise((resolve) => {
      if (saveAnswer.length !== 0) {
        resolve(saveAnswer);
      }
    });
    // toast.warning("역량평가 응시 가능 시간이 끝났습니다.");

    timeOver.then((saveAnswer) => {
      onSubmit(saveAnswer);
    });
  };

  useEffect(() => {
    timeOut && timeOutSubmit();
  }, [timeOut]);

  const goToThisQuestion = (idx, e) => {
    setNowQNum(idx + 1);
    setIdx(idx);
  };

  const saveAnswerHandler = (checkedAnswer) => {
    if (saveAnswer && checkedAnswer !== '' && saveAnswer.length !== 0) {
      if (checkedAnswer === null) {
        saveAnswer[idx] = '';
      } else {
        saveAnswer[idx] = checkedAnswer;
      }
      sessionStorage.setItem('saveAnswer', saveAnswer.join(','));
    }
  };

  const onClickEnterKey = () => {
    if (nowQNum === questionData.examProblemCount) {
      handleSubmitButton();
    } else {
      handleNextButton();
    }
  };

  const onSubmit = (saveAnswer) => {
    const url = `${process.env.REACT_APP_IP}/users/exam`;
    axios
      .post(url, {
        examId: questionData.examId,
        userId: userId,
        examProblemIdList: questionList,
        examSubmitAnswer: saveAnswer,
      })
      .then((response) => {
        if (response.data === 'success') {
          toast.success('제출이 완료되었습니다.');
          sessionStorage.removeItem('saveAnswer');
          setSaveAnswer([]);
          history.push('/capability/test-result');
        } else {
          toast.error('제출되지 않았습니다. 다시 시도해주세요');
        }
      })
      .catch(handleErrorMessage);
  };
  return (
    <Fragment>
      <Header data={{ title: '역량평가' }} />
      <div className={styles.mypage} ref={ref}>
        <section
          className={[
            styles.wrapper,
            sidebar === 'left'
              ? styles.with_sidebar + ' ' + styles.left
              : sidebar === 'right'
              ? styles.with_sidebar + ' ' + styles.right
              : null,
          ].join(' ')}
        >
          <article className="capaTest">
            <div className="capaTestBox">
              <div className="capaTestBox2">
                <div
                  className={`capaTestTable${
                    questionList && questionList.length > 40 ? ' over40' : ''
                  }`}
                >
                  {questionList &&
                    questionList.map((nums, index) => (
                      <li key={index}>
                        <p
                          style={{
                            color:
                              idx === index
                                ? '#FE9E6E'
                                : saveAnswer.length &&
                                  saveAnswer[index] &&
                                  saveAnswer[index].length
                                ? '#2C95D2'
                                : '#868686',
                          }}
                          onClick={(e) => goToThisQuestion(index, e)}
                        >
                          {index + 1}
                        </p>
                        <input
                          type="checkbox"
                          value={nums}
                          id={'q' + index}
                          className="form-control"
                        />
                      </li>
                    ))}
                  <button
                    onClick={(e) => handleSubmitButton(e)}
                    className="submitButton2"
                  >
                    제출
                  </button>
                </div>
                <div className="capaTestTimer">
                  <TestTimer
                    questionData={questionData}
                    setTimeOut={setTimeOut}
                  />
                </div>
                <div className="capaTestQuestion1">
                  <h2>{questionData.subjectName}</h2>
                </div>
                <div className="capaTestQuestion2">
                  <p>문제 {nowQNum}.</p>
                  <TestQuestion
                    saveAnswerHandler={saveAnswerHandler}
                    questionList={questionList}
                    idx={idx}
                    problemId={parseInt(questionList[idx])}
                    onClickEnterKey={onClickEnterKey}
                  />
                </div>
                <div className="capaTestFooter">
                  <div className="capaQuestionNums">
                    <p style={{ color: '#868686' }}>
                      <strong style={{ color: '#2C95D2' }}>{nowQNum}</strong> /{' '}
                      {questionData.examProblemCount}
                    </p>
                  </div>
                  <div className="buttonsBox">
                    {nowQNum === 1 ? null : (
                      <button
                        onClick={() => handlePrevButton()}
                        className="prevButton"
                      >
                        <i className="fas fa-arrow-left"></i>&nbsp;이전
                      </button>
                    )}
                    {nowQNum === questionData.examProblemCount ? (
                      <button
                        onClick={(e) => handleSubmitButton(e)}
                        className="submitButton"
                      >
                        제출
                      </button>
                    ) : (
                      <button
                        onClick={() => handleNextButton()}
                        className="nextButton"
                      >
                        다음&nbsp;<i className="fas fa-arrow-right"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};
export default withRouter(TestPage);
