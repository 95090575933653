import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import Header from '../../../components/header/Header';
import Navbar from '../../Navbar';
import { Wrapper } from '../../style';
import { useStudents } from 'hooks';
import { Input, TableHead, Tr } from '../style';
import { filterFunction, numberFormatter } from '../../../libs/util';
import TableList from './TableList';
import { colTitles, sortedKeys } from './data';

const Students = ({ history }) => {
  const [filteredData, setFilteredData] = useState([]);
  const { data } = useStudents();

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const moveToDetail = (userId) => {
    history.push(`students/${userId}`);
  };

  const handleChange = (e) => {
    setFilteredData(filterFunction(e.target.value, data));
  };

  return (
    <>
      <Header data={{ title: '교육생 관리' }} />
      <Navbar title={'교육생 관리'} />
      <Wrapper>
        <TableHead>훈련생 관리</TableHead>
        <Input
          placeholder="검색어를 입력하세요."
          type="text"
          onChange={handleChange}
        />
        {filteredData && (
          <table
            className="table"
            style={{ borderTop: 'none', marginTop: '20px' }}
          >
            <thead>
              <Tr className="head">
                {colTitles.map((v, i) => (
                  <th key={i} scope="col">
                    {v}
                  </th>
                ))}
              </Tr>
            </thead>
            <tbody>
              {filteredData.length !== 0 ? (
                filteredData.map((v, i) => (
                  <TableList
                    key={i}
                    rowNum={numberFormatter(i + 1, 2)}
                    value={v}
                    sortedKeys={sortedKeys}
                    onClick={moveToDetail}
                  />
                ))
              ) : (
                <Tr>
                  <td
                    style={{ textAlign: 'center', cursor: 'default' }}
                    colSpan={8}
                  >
                    데이터가 없습니다.
                  </td>
                </Tr>
              )}
            </tbody>
          </table>
        )}
      </Wrapper>
    </>
  );
};

export default withRouter(Students);
