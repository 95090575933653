import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from 'context/Auth.context';
import { useLogWatching } from 'hooks';
import { handleErrorMessage } from 'utilities';
import AddVideo from '../addVideo/AddVideo';
import styles from './chapter.module.scss';

function Chapter({
  chapter,
  chapterDay,
  status,
  onReplayModal,
  index,
  dayFunction,
  indexFunction,
  setIsUpdated,
  currentTime,
}) {
  const { mutate } = useLogWatching();
  let lecture = chapter.title;

  const checkRewatch = (chapter, index) => {
    let clickLog = `click.log - studentId:${auth.userId}, content:${chapter.content}, 1_class`;
    let userId = auth.userId;

    let classCount = '1_class';
    dayFunction(chapter.chapterDay);
    indexFunction(index);

    if (lecture.split('- ') === undefined) {
      lecture = lecture + ' - ' + chapter.chapterDay;
    }

    mutate({ clickLog, userId, lecture, classCount });
  };

  const auth = useContext(AuthContext);
  const unitId = chapter.unitId;
  const chapterId = chapter.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();

  const handleVod = () => {
    setIsModalOpen(true);

    axios
      .get(
        `${process.env.REACT_APP_IP}/teachers/myclass/units/vod/${chapterId}`
      )
      .then((res) => {
        setData(res.data);
        return res.data;
      });
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleLogging = () => {
    if (!auth.auth) return;

    const url = `${process.env.REACT_APP_IP}/users/myclass/chapters/logging`;
    axios
      .post(url, { userId: auth.userId, chapterId: chapter.id })
      .then((response) => {})
      .catch(handleErrorMessage);
  };

  return (
    <>
      <div className={styles.unitBox}>
        <div className={styles.contents}>
          <div>{chapterDay}일차</div>
          <div>{chapter.title}</div>
          <div>{chapter.chapterDate}</div>
        </div>

        <div className={styles.unitBtns}>
          {auth.type !== '1' && auth.type !== '2' ? (
            <button
              style={
                new Date(chapter.chapterDate) <= currentTime
                  ? {
                      width: '35px',
                      height: '35px',
                      border: 'solid 1px white',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f14a16',
                      color: 'white',
                      cursor: 'pointer',
                    }
                  : {
                      display: 'none',
                    }
              }
              onClick={
                new Date(chapter.chapterDate) <= currentTime ? handleVod : null
              }
            >
              <i className="fa-solid fa-plus"></i>
            </button>
          ) : null}

          <div
            onClick={() => {
              chapter.replayYn === 'Y' &&
                chapter.videoName &&
                onReplayModal(chapter.videoName);
              checkRewatch(chapter, index);
            }}
            className={
              chapter.replayYn === 'Y' && chapter.videoName
                ? styles.replayBtn
                : styles.invalid
            }
          >
            {chapter.replayYn === 'Y' && !chapter.videoName
              ? '준비중'
              : '다시보기'}
          </div>

          {status === 'y' ? (
            <a
              target="_blank"
              href={chapter.zoomUrl ? chapter.zoomUrl : 'https://zoom.us/'}
              onClick={handleLogging}
            >
              <div className={styles.zoomBtn}>학습실</div>
            </a>
          ) : (
            <div className={styles.invalid}>학습실</div>
          )}
        </div>
      </div>
      <AddVideo
        isOpen={isModalOpen}
        unitId={unitId}
        chapterId={chapterId}
        handleClose={handleClose}
        setIsUpdated={setIsUpdated}
        data={data}
      />
    </>
  );
}

export default Chapter;
