import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import 'react-calendar/dist/Calendar.css';

import { useReservation } from 'hooks';
import { RESERVE_TIME } from 'constant/customer';
import { successMessage } from 'utilities';

const ReserveModal = ({ onClick, reservationData, setModalOpen }) => {
  const [date, setdate] = useState(() => {
    return reservationData
      ? moment(reservationData.consultingDate) || ''
      : moment().add(1, 'days') || '';
  });
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  const { alreadyTime, studentChangeReservation, studentCreateReservation } =
    useReservation({
      date: date.format('YYYY-MM-DD'),
    });
  const { data: notAvailabletime } = alreadyTime || {};
  const filterAvailableTime = () => {
    return RESERVE_TIME.map((item) => {
      if (notAvailabletime?.length === 0) return { ...item };

      let reserved = false;
      for (let i = 0; i < notAvailabletime?.length; i++) {
        if (
          notAvailabletime[i].time.split('T')[1].split('.')[0].slice(0, 5) ===
          item.time
        ) {
          reserved = true;
        }
      }
      return { ...item, status: reserved };
    });
  };
  const time = filterAvailableTime();

  // func
  const handleDayClick = (current) => setdate(current);
  const jumpToMonth = (num) =>
    num
      ? setdate(date.clone().add(30, 'day'))
      : setdate(date.clone().subtract(30, 'day'));

  // chalandar generate logic
  function generate() {
    // 님 날짜 뭐 눌렀어요? (초기값은 오늘)
    const today = date;

    // startOf('month') : 이번 달의 첫번 째 날로 설정 set to the first of this month, 12:00 am
    // week() : Week of Year. 이번 년도의 몇번째 주인가? => 3월 8일이면 10이겠죠?
    const startWeek = today.clone().startOf('month').week();

    // endOf('month').week() : 이번 달의 마지막 날로 설정 한 후 그것이 이번 년도의 몇번째 주인지 체크
    // 만약 이번 해의 첫번째 주(1월 1일이 속한 주)라면 53으로 세팅, 아니라면 그대로 유지
    // 이런 작업의 이유는 마지막 주가 첫 주가 될 수 없기 때문에 당연한 것임
    const endWeek =
      today.clone().endOf('month').week() === 1
        ? 53
        : today.clone().endOf('month').week();

    let calendar = [];

    // 시작 주부터 마지막 주까지 +1 씩 증가시킴
    // 이제 주마다 일을 표기해야 하므로 len이 7인 arr를 생성 후 index를 기반으로 day를 표기하자
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="row" key={week}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              // 오늘 => 주어진 주의 시작 => n + i일 만큼 더해서 각 주의 '일'을 표기한다.
              let current = today
                .clone()
                .week(week)
                .startOf('week')
                .add(n + i, 'day');

              let todayDate = moment().add(1, 'days');

              // 오늘이 current와 같다면 우선 '선택'으로 두자
              let isSelected =
                today.format('YYYYMMDD') === current.format('YYYYMMDD')
                  ? 'selected'
                  : '';

              // 만약, 이번 달이 아닌 다른 달의 날짜라면 회색으로 표시하자
              let isGrayed =
                current.format('MM') !== today.format('MM') ||
                todayDate.isAfter(current)
                  ? todayDate.format('YYYYMMDD') === current.format('YYYYMMDD')
                    ? ''
                    : 'grayed'
                  : '';

              let isSunday = i === 0 ? 'sunday' : '';
              let isfriday = i === 6 ? 'friday' : '';

              return (
                <div
                  className={`box ${isSelected} ${isGrayed} ${isSunday} ${isfriday}`}
                  key={i}
                  onClick={() => !isGrayed && handleDayClick(current)}
                >
                  <span className="text">{current.format('D')}</span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  }

  const handleSubmit = async () => {
    if (!reservationData && selectRef.current.value === '') {
      toast.error('예약시간을 선택해주세요.');
      return;
    } else if (inputRef.current.value.trim() === '') {
      toast.error('신청사유를 작성해주세요.');
      inputRef.current.focus();
      return;
    }

    if (!reservationData) {
      const { status } = await studentCreateReservation.mutateAsync({
        date: date.format('YYYY-MM-DD'),
        time: selectRef.current.value,
        purpose: inputRef.current.value,
      });
      if (status === 201) {
        successMessage({ text: '상담 신청이 완료되었습니다.' });
        setModalOpen(false);
      }
    } else {
      const { status } = await studentChangeReservation.mutateAsync({
        id: reservationData.id,
        date: date.format('YYYY-MM-DD'),
        time:
          selectRef.current.value ||
          reservationData.consultingDate.slice(11, 16),
        purpose: inputRef.current.value,
      });
      if (status === 204) {
        setModalOpen(false);
        successMessage({ text: '상담 신청이 수정되었습니다.' });
      }
    }
  };

  useEffect(() => {
    if (!reservationData) return;

    selectRef.current.value = reservationData.consultingDate.slice(11, 16);
    inputRef.current.value = reservationData.purpose;
  }, [reservationData]);

  return (
    <Wrapper>
      <Title>{reservationData ? '상담 수정' : '상담 신청'}</Title>

      <CalendarWrapper>
        <CalendarHead>
          <button
            onClick={() =>
              date.format('MM') !== moment().format('MM') && jumpToMonth(0)
            }
          >
            <i
              style={{
                color:
                  date.format('MM') !== moment().format('MM')
                    ? 'black'
                    : 'rgba(0,0,0,0.4)',
              }}
              className="fas fa-chevron-left"
            ></i>
          </button>
          <span className="title">{date.format('YYYY. MM')}</span>
          <button onClick={() => jumpToMonth(1)}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </CalendarHead>

        <CalendarBody selectColor="#59b3e7">
          <div className="row">
            {['일', '월', '화', '수', '목', '금', '토'].map((el) => (
              <div className="box" key={el}>
                <span className="text">{el}</span>
              </div>
            ))}
          </div>
          {generate()}
          <ColorInfo>
            <ColorBox color="#59b3e7"></ColorBox>
            <ColorDesc>선택</ColorDesc>
            <ColorBox color="rgba(0,0,0,0.2)"></ColorBox>
            <ColorDesc>불가</ColorDesc>
          </ColorInfo>
        </CalendarBody>

        <InfoBox>
          <div className="row">
            <div>날짜</div>
            <div>{date.format('YYYY. M. DD')}</div>
          </div>
          <div className="row">
            <div>시간</div>
            {reservationData && (
              <StyledPreviousTime>
                <span>{reservationData.consultingDate.slice(11, 16)}</span>
                <i className="fa-solid fa-arrow-right-long" />
              </StyledPreviousTime>
            )}
            <select ref={selectRef}>
              {time.length > 0 &&
                time.map((item) =>
                  item.status ? (
                    <option
                      key={item.id}
                      disabled
                      value={reservationData ? item.time : ''}
                    >
                      {reservationData &&
                      item.time === reservationData.consultingDate.slice(11, 16)
                        ? item.time
                        : '------'}
                    </option>
                  ) : (
                    <option key={item.id} value={item.time}>
                      {item.time}
                    </option>
                  )
                )}
            </select>
          </div>
          <div className="row">
            <div>신청 이유</div>
            <input ref={inputRef} type="text" />
          </div>
        </InfoBox>

        <Btns>
          <Btn color="rgba(0,0,0,0.2)" onClick={onClick}>
            취소
          </Btn>
          <Btn color="#59b3e7" onClick={handleSubmit}>
            {reservationData ? '상담 수정' : '상담 신청'}
          </Btn>
        </Btns>
      </CalendarWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: auto;
  right: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  border-radius: 5px;
  z-index: 150;

  textarea,
  input {
    width: 200px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &::placeholder {
      font-size: 14px;
    }
  }

  textarea {
    padding: 10px;
    resize: none;
    height: 150px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }
  }

  select {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 26px;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
`;

const CalendarHead = styled.div`
  span {
    font-size: 16px;
    margin: 0 20px;
  }
  button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
  }
`;

const StyledPreviousTime = styled.span`
  font-size: 16px;
  color: #212529;
  font-weight: bold;

  i {
    margin: 0 10px;
  }
`;

const CalendarBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .row {
    margin-left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .box {
    width: 50px;
    height: 50px;

    &:not(.grayed .sunday .friday) {
      cursor: pointer;
    }
  }

  .selected {
    color: white;
    background-color: ${(props) => props.selectColor};
  }

  .sunday {
    color: red;
  }

  .friday {
    color: blue;
  }

  .grayed {
    opacity: 0.2;
    /* color: rgba(0, 0, 0, 0.2); */
  }
`;

const ColorBox = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  margin-right: 5px;
`;

const InfoBox = styled.div`
  width: 100%;
  margin: 0 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .row {
    padding: 20px 0;
    padding-left: 10px;
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    div:first-child {
      width: 80px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.4);
    }
    div:last-child {
      font-size: 16px;
      font-weight: bold;
    }
    select {
      font-size: 16px;
      font-weight: bold;
      border: none;
    }
  }
`;

const ColorInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
`;

const ColorDesc = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 10px;
`;

const Btns = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Btn = styled.button`
  font-size: 14px;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  padding: 8px;
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
  width: 80px;
  &:focus {
    outline: none;
  }
`;

export default ReserveModal;
