import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useVideoUrl } from 'hooks';
import { Modal2, ModalButtons } from '../../../components';
import { errorMessage, successMessage } from 'utilities';

const onKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

const AddVideo = ({
  data,
  isOpen,
  handleClose,
  unitId,
  chapterId,
  setIsUpdated,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  const { mutateAsync } = useVideoUrl();

  const submit = async (form) => {
    const urlList = (Object.values(form) || [])
      .filter((url) => url !== '')
      .join(',');

    const data = {
      chapterId,
      unitId,
      urlList,
    };

    try {
      const { status } = await mutateAsync({ chapterId, unitId, urlList });
      if (status === 200) {
        successMessage({ text: '동영상이 등록되었습니다.' });
        handleClose();
        setIsUpdated((prev) => !prev);
      }
    } catch (error) {
      errorMessage({ text: '등록에 실패했습니다' });
    }
  };

  useEffect(() => {
    if (String(data)?.includes(',') === false) {
      setValue('vodUrl1', String(data));
    } else {
      setValue('vodUrl1', String(data)?.split(',')[0]);
      setValue('vodUrl2', data?.split(',')[1]);
      setValue('vodUrl3', data?.split(',')[2]);
      setValue('vodUrl4', data?.split(',')[3]);
      setValue('vodUrl5', data?.split(',')[4]);
      setValue('vodUrl6', data?.split(',')[5]);
      setValue('vodUrl7', data?.split(',')[6]);
      setValue('vodUrl8', data?.split(',')[7]);
    }
  }, [data]);

  return (
    <Modal2
      open={isOpen}
      size="medium"
      // large, medium or else
      onClose={handleClose}
      unitId={unitId}
      chapterId={chapterId}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Title>동영상 등록 / 수정</Title>
        <InputWrapper>
          <h3>1: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl1"
            id="vodUrl1"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl1', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>
        <ErrorMsg>{errors?.vodUrl1?.message}</ErrorMsg>
        <InputWrapper>
          <h3>2: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl2"
            id="vodUrl2"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl2', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>{' '}
        <ErrorMsg>{errors?.vodUrl2?.message}</ErrorMsg>
        <InputWrapper>
          <h3>3: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl3"
            id="vodUrl3"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl3', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>{' '}
        <ErrorMsg>{errors?.vodUrl3?.message}</ErrorMsg>
        <InputWrapper>
          <h3>4: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl4"
            id="vodUrl4"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl4', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>{' '}
        <ErrorMsg>{errors?.vodUrl4?.message}</ErrorMsg>
        <InputWrapper>
          <h3>5: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl5"
            id="vodUrl5"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl5', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>
        <ErrorMsg>{errors?.vodUrl5?.message}</ErrorMsg>
        <InputWrapper>
          <h3>6: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl6"
            id="vodUrl6"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl6', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>
        <ErrorMsg>{errors?.vodUrl6?.message}</ErrorMsg>
        <InputWrapper>
          <h3>7: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl7"
            id="vodUrl7"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl7', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>
        <ErrorMsg>{errors?.vodUrl7?.message}</ErrorMsg>
        <InputWrapper>
          <h3>8: https://youtu.be/</h3>
          <Input
            onKeyPress={onKeyPress}
            type="text"
            name="vodUrl8"
            id="vodUrl8"
            placeholder="비디오의 URL을 입력하세요"
            {...register('vodUrl8', {
              pattern: {
                value: /^[a-zA-Z0-9_.-]*$/gi,
                message: '특수문자를 사용할 수 없습니다',
              },
            })}
          ></Input>
        </InputWrapper>
        <ErrorMsg>{errors?.vodUrl8?.message}</ErrorMsg>
        <br></br>
        <br></br>
        <ModalButtons onClose={handleClose} />
      </form>
    </Modal2>
  );
};

export default AddVideo;

const Title = styled.h1`
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
`;

const ErrorMsg = styled.span`
  display: block;
  color: red;
  padding: 0 0 0 30px;
`;

const Input = styled.input`
  box-sizing: border-box;
  display: inline-block;
  width: 70%;
  height: 32px;
  padding-left: 12px;
  resize: none;
  overflow: auto;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid silver;
  background: #eeeeee66;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
