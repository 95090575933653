import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import AuthContext from 'context/Auth.context';

const useAttendInfo = () => {
  const auth = useContext(AuthContext);
  const { data, isLoading } = useQuery(
    ['attendInfo', auth.userId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/users/myclass/attendance/checking/${auth.userId}`
        )
        .then((res) => {
          return res.data;
        });
    },
    { enabled: !!auth.userId }
  );
  return { data, isLoading };
};

export default useAttendInfo;
