import React from 'react';
import styled from 'styled-components';

const PrecourseNotice = () => {
  return (
    <Wrapper>
      <h2>안녕하세요, SK쉴더스 루키즈 교육생 여러분!</h2>

      <p>
        정규 교육과정에서 필요한 기초 지식을 보완할 수 있는 강의 제공
        페이지입니다.
      </p>

      <TextBox>
        <h3>♧ 사전 학습 이용 방법 ♧</h3>

        <ul>
          <li>1. 원하는 과정의 '학습하러 가기' 버튼 클릭</li>
          <li>2. 국민평생직업능력개발(STEP) 사이트 접속하기</li>
          <li className="inside">
            - STEP 페이지 회원가입 후 로그인 하여 학습을 시작합니다.
          </li>
          <li className="inside">
            - 과목 검색 및 학습하기: 검색창에 배우고 싶은 과목을 입력합니다.
          </li>
          <li className="inside">
            - 과정 수강: 검색 결과에서 원하는 동영상을 선택하고 시청할 수
            있습니다.
          </li>
        </ul>
      </TextBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: auto;
  max-width: 500px;
  margin: 20px 0;

  > h2,
  p {
    text-align: center;
  }

  h2 {
    font-size: 18px;
  }

  p {
    color: #333;
    font-size: 1.4rem;
    margin-top: 20px;
  }
`;

const TextBox = styled.div`
  margin: 30px 0;
  padding: 30px;
  border: 2px solid rgba(42, 160, 225, 0.7);
  border-radius: 20px;

  h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
  }

  > ul {
  }

  li {
    padding: 5px 0;
    font-size: 14px;

    &.inside {
      padding-left: 10px;
    }
  }
`;

export default PrecourseNotice;
