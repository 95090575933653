import { useMutation, useQueryClient } from 'react-query';

import { createGreetingPost } from '../../apis/customer';

const useCreateGreetingPost = ({ categoryId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ title, content }) => {
      return await createGreetingPost({
        title,
        content,
        categoryId,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchGreetingList'] }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchGreetingList'] }),
  });

  return { ...queryReturns };
};

export default useCreateGreetingPost;
