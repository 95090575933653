import axios from 'axios';
import { useQuery } from 'react-query';

const useCounselingComments = (userId) => {
  const { data, isLoading } = useQuery(
    ['counselingComments', userId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/comments/${userId}`
        )
        .then((res) => {
          return res.data;
        });
    },
    { enabled: !!userId }
  );
  return { data, isLoading };
};

export default useCounselingComments;
