import { createContext } from 'react';

export const SetAuthContext = createContext(() => {});

const AuthContext = createContext({
  auth: false,
  userId: '',
  name: '',
  type: '',
  email: '',
  status: '',
  token: '',
  isOauth: '',
  authToken: '',
  freeYn: '',
  classId: '',
  subClassId: '',
  groupId: '',
  onLogin: () => {},
  onLogout: () => {},
});

export default AuthContext;
