import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import AuthContext from 'context/Auth.context';
import StatusBox from './StatusBox';
import { handleErrorMessage } from 'utilities';

const SGroupMembers = ({ isLeader, history, page, getSgroupInfo }) => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const [members, setMembers] = useState();
  const [sGroupId, setSGroupId] = useState();
  const [groupInfo, setGroupInfo] = useState();
  useEffect(() => {
    if (!auth.auth) return;

    //해당 스터디 그룹의 정보 요청
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${id}`;
    Axios.get(url)
      .then((response) => {
        getSgroupInfo(response.data.data);
        setSGroupId(response.data.data.id);
        setGroupInfo({
          ...groupInfo,
          memberCount: response.data.data.memberCount,
          limitCount: response.data.data.limitCount,
        });
      })
      .catch(handleErrorMessage);
  }, []);

  useEffect(() => {
    if (!isLeader) {
      toast.error('그룹관리는 그룹장만 가능합니다.');
      history.push(`/sgroup/detail/${id}`);
    }
  }, [isLeader]);

  useEffect(() => {
    if (page === 'join') {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/join/${id}`;

      Axios.get(url)
        .then((response) => {
          setMembers([]);
          setMembers(response.data);
        })
        .catch(handleErrorMessage);
    } else if (page === 'member') {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/members/${id}`;

      Axios.get(url)
        .then((response) => {
          setMembers([]);
          setMembers(response.data);
        })
        .catch(handleErrorMessage);
    }
  }, [history.location.pathname]);

  const handleKing = (userId) => {
    const url = `${process.env.REACT_APP_IP}/users/sgroup/delegate/${sGroupId}/${userId}`;
    const uid = auth.userId;
    if (window.confirm('해당유저에게 조장권한을 위임하겠습니까?')) {
      Axios.put(url, { uid }).then((response) => {
        if (response.data) {
          toast.success('조장을 위임했습니다');
          history.push(`/sgroup/detail/${sGroupId}`);
        }
      });
    }
  };
  const handleKick = (memberId) => {
    if (window.confirm('해당 유저를 강퇴하시겠습니까?')) {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/members/${memberId}`;

      Axios.delete(url)
        .then((response) => {
          if (response.data === 'SUCCESS') {
            toast.success('강퇴되었습니다.');
            setMembers((prev) => {
              const newMembers = prev.filter((item) => item.id !== memberId);
              return [...newMembers];
            });
          } else {
            toast.error('강퇴에 실패하였습니다.');
          }
        })
        .catch(handleErrorMessage);
    }
  };

  const handleSubmit = (accept, memberId) => {
    if (
      !window.confirm(
        accept === 'ACCEPT'
          ? '가입을 승인하시겠습니까?'
          : '가입을 거절하시겠습니까?'
      )
    ) {
      return;
    }
    const url = `${process.env.REACT_APP_IP}/users/sgroup/join`;

    Axios.post(url, { accept, id: memberId, sGroupId })
      .then((response) => {
        setMembers(response.data);
      })
      .catch(handleErrorMessage);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '50px',
      }}
    >
      <StatusBox isLeader={isLeader} />
      <div style={{ width: '100%' }}>
        <Title>{page === 'member' ? '그룹원 관리' : '가입신청 리스트'}</Title>
        <CardWrapper>
          {members &&
            members.map((item) => (
              <MemberCard key={item.id}>
                <CardHead>
                  <span>
                    {page === 'member'
                      ? item.userName
                      : `${item.userName}(${item.userId})`}
                  </span>
                  {item.status === 'L' && <i className="fas fa-crown"></i>}
                </CardHead>
                <CardRegDate>
                  {page === 'member'
                    ? item.approvalDate?.split('T')[0]
                    : item.regDate.split('T')[0]}
                </CardRegDate>
                <CardClassTitle>{item.classTitle}</CardClassTitle>
                {page === 'join' && (
                  <CardFoot>
                    {groupInfo?.limitCount > groupInfo?.memberCount ? (
                      <div
                        onClick={() =>
                          handleSubmit('ACCEPT', item.id, item.sGroupId)
                        }
                      >
                        승인
                      </div>
                    ) : (
                      <div style={{ cursor: 'default' }}>정원초과</div>
                    )}
                    <div
                      onClick={() =>
                        handleSubmit('DENY', item.id, item.sGroupId)
                      }
                    >
                      거절
                    </div>
                  </CardFoot>
                )}

                {page === 'member' && (
                  <CardFoot>
                    {item.status !== 'L' && (
                      <div onClick={() => handleKing(item.userId)}>
                        조장위임
                      </div>
                    )}
                    {item.status !== 'L' && (
                      <div onClick={() => handleKick(item.id)}>강퇴</div>
                    )}
                  </CardFoot>
                )}
              </MemberCard>
            ))}
        </CardWrapper>
      </div>
    </div>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 90%;
  margin-left: 30px;
`;

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardRegDate = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
`;

const CardClassTitle = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const CardFoot = styled.div`
  font-size: 14px !important;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;

  div:first-child {
    font-weight: 500;
    color: #1872e7;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  div:nth-child(2) {
    font-weight: 500;
    color: red;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const CardBtns = styled.div`
  gap: 5px;

  div:first-child {
    font-weight: 500;
    color: green;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  div:nth-child(2) {
    font-weight: 500;
    color: red;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
`;

const MemberCard = styled.div`
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  i {
    color: #fc9918;
    font-size: 16px;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  margin-left: 30px;
  margin-bottom: 20px;
`;

export default withRouter(SGroupMembers);
