import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import dompurify from 'dompurify';
import { CircularProgress } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';

import AuthContext from 'context/Auth.context';
import { linkify } from 'utilities/common';
import {
  useDownload,
  useFetchInquiryDetail,
  useRemoveInquiry,
  useContactManage,
} from 'hooks';
import CustomerBadge from '../CustomerBadge';
import CustomerComment from '../CustomerComment';
import Loading from '../../../ui/Loading';
import { confirmSwal, errorMessage, successMessage } from 'utilities/swal';

const ContactDetail = ({ history }) => {
  const { id } = useParams();
  const auth = useContext(AuthContext);

  const { changeContactReplyReadStatus } = useContactManage();
  const {
    data: list,
    isLoading: isListLoading,
    isError,
  } = useFetchInquiryDetail({
    id,
  });
  const { post, files, comments } = list || {};

  const { mutateAsync: removeInquiry, isLoading: isRemoving } =
    useRemoveInquiry();

  useEffect(() => {
    (async () => {
      await changeContactReplyReadStatus.mutateAsync({ postId: id });
    })();

    if (isError) {
      errorMessage({ text: '작성자만 열람이 가능합니다.' });
      history.push('/customer/contact');
    }
  }, [auth, history, isError]);

  const { refetch, isLoading } = useDownload(
    `${process.env.REACT_APP_IP}/users/inquiries/${
      files?.length !== 0 && id
    }/files/${files?.length !== 0 && files?.[0].id}`
  );

  const handleGoToEdit = () => {
    history.push(`/customer/contact/write/${id}`);
  };

  const handleDownload = () => {
    if (isLoading) return;
    refetch();
  };

  const handleClickDelete = async () => {
    const confirm = await confirmSwal({
      title: '해당 문의글을 삭제 하시겠습니까?',
      confirmButton: '삭제',
    });

    if (confirm) {
      const { status } = await removeInquiry(
        { id },
        {
          onError: (error) => {
            if (
              error.response &&
              error.response.status >= 400 &&
              error.response.status < 500
            ) {
              errorMessage({ text: '게시글 삭제 중에 문제가 발생하였습니다.' });
            }
          },
        }
      );
      if (status === 204) {
        successMessage({ text: '문의글이 삭제되었습니다.' });
        history.push('/customer/contact');
      }
    }
  };

  const badgeColor = {
    출결문의: '#FFB469',
    '온라인 교육': '#f4d06f',
    '오프라인 교육': '#9dd254',
    프로젝트: '#9dd9d2',
    기타: '#B8A7DF',
  };

  const handleGoback = () => history.goBack();

  if (isListLoading) return <Loading />;
  return (
    <>
      <Wrapper>
        <TitleBox>
          <div className="title">
            <CustomerBadge
              text={post?.categoryName}
              size="small"
              backColor={badgeColor[post?.categoryName]}
            />
            <h2>{post?.title}</h2>
          </div>

          <div className="sub-info">
            <div>
              <span>
                <i className="fa-solid fa-user" />
                {post?.author}
              </span>
              {post?.phone && (
                <span>
                  {' '}
                  | <i className="fa-solid fa-phone" />
                  {post?.phone}
                </span>
              )}
            </div>
            <div>
              <span>{post?.createdAt.slice(0, 10)} |</span>
              <span>조회 {post?.hitCount}</span>
            </div>
          </div>
        </TitleBox>

        <ContentBox
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(linkify(post?.content || '')),
          }}
        />

        {!!files?.length && (
          <FileBox>
            {files?.map((file, index) => {
              return (
                <p key={index}>
                  <span className="file-title">첨부파일{index + 1}</span>
                  <span
                    onClick={handleDownload}
                    style={{ cursor: 'pointer', color: '#1e88e5' }}
                  >
                    <i className="las la-file-download"></i>&nbsp;
                    {file.originalFileName}
                  </span>
                </p>
              );
            })}
          </FileBox>
        )}

        <FormButtons>
          {post?.userId === auth.userId && (
            <div className="edit" onClick={handleGoToEdit}>
              수정하기
            </div>
          )}
          <div className="delete" onClick={handleClickDelete}>
            {isRemoving ? <CircularProgress size={20} /> : '삭제하기'}
          </div>
        </FormButtons>

        {/*문의 댓글 컴포넌트*/}
        <CustomerComment replyList={comments} boardType="contact" />

        <div className="go-list" onClick={handleGoback}>
          목록으로
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border-top: 2px solid #333;
  padding-bottom: 200px;

  > a,
  div.go-list {
    font-size: 16px;
    width: 150px;
    height: 40px;
    background: #ec7422;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 15px;
    margin: 50px auto 0;
    cursor: pointer;

    &:hover {
      filter: brightness(105%);
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  min-height: 60px;
  padding: 10px;
  overflow: hidden;

  > div.title {
    display: flex;
    max-width: 75%;

    h2 {
      max-width: 100%;
      margin-bottom: unset;
      margin-left: 5px;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  > div.sub-info {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 5px;
      font-size: 14px;

      i {
        margin-right: 5px;
      }
    }
  }
`;

const ContentBox = styled.div`
  padding: 20px;
  border-bottom: 1px solid #eee;
  min-height: 300px;

  > p {
    font-size: 16px;
    color: #333;
  }
`;

const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin: 60px 0 30px;

  > div {
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(206, 206, 206);
    color: #fff;
    font-size: 16px;
    border-radius: 15px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: bold;

    &.edit {
      border: 2px solid #ec7422;
      color: #ec7422;
    }
    &.delete {
      background-color: #ec7422;
      svg {
        color: #fff;
      }
    }

    &:hover {
      filter: brightness(95%);
    }
  }
`;

const FileBox = styled.div`
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 0 10px;

  > p {
    font-size: 16px;
    color: #333;

    > span.file-title {
      display: inline-block;
      min-width: 100px;
      font-weight: bold;
    }
  }
`;

export default ContactDetail;
