import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useParams } from 'react-router';
import Axios from 'axios';
import TablePagination from '@material-ui/core/TablePagination';

import AuthContext from 'context/Auth.context';
import StatusBox from './StatusBox';
import { InputBox } from '../components';
import { handleErrorMessage } from 'utilities';

const ListWrapper = styled.div`
  margin-bottom: 50px;
  width: 100%;
  margin-left: 30px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Contents = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  div {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    width: 15%;
    text-align: center;
  }

  div:first-child {
    width: 5%;
    text-align: left;
  }

  div:nth-child(2) {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    width: 65%;
    text-align: left;
  }

  div:last-child {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
  }
`;

const Name = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`;

const NameTags = styled.div`
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  div {
    font-size: 16px;
    width: 15%;
    text-align: center;
  }

  div:first-child {
    width: 5%;
    text-align: left;
  }

  div:nth-child(2) {
    width: 65%;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;

  &:hover {
    background: rgba(240, 245, 249, 0.8);
    transition: all 0.2s ease;
  }
`;

const SGroupDetail = ({ getSgroupInfo, deleteFn }) => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const [info, setInfo] = useState();
  const [postList, setPostList] = useState();
  const [searchedData, setSearched] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState({
    selected: 'postTitle',
    title: '제목',
    content: '',
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = (e) => {
    if (search.selected === 'postTitle')
      setSearched(
        postList.filter((l) => {
          return l.title.indexOf(e.target.value) !== -1;
        })
      );
    else if (search.selected === 'postCategoryName')
      setSearched(
        postList.filter((l) => {
          return l.postCategoryName.indexOf(e.target.value) !== -1;
        })
      );
    else if (search.selected === 'state') {
      let content = e.target.value;
      if (!content) {
        setSearched(postList);
      } else {
        let userState;
        if (content === '대기') {
          userState = 0;
        } else if (content === '승인') {
          userState = 1;
        } else if (content === '거절') {
          userState = 2;
        }
        setSearched(
          postList.filter((l) => {
            return Number(l.isOauth) === Number(userState) && l.type !== 0;
          })
        );
      }
    }
    setSearch({ ...search, content: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!auth.auth) return;

    //해당 스터디 그룹의 정보 요청
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${id}`;
    Axios.get(url)
      .then((response) => {
        setInfo(response.data.data);
        getSgroupInfo(response.data.data);
      })
      .catch(handleErrorMessage);

    const url1 = `${process.env.REACT_APP_IP}/users/sgroup/${id}/posts`;
    Axios.get(url1)
      .then((response) => {
        setPostList(response.data);
        setSearched(response.data);
      })
      .catch(handleErrorMessage);
  }, []);

  return (
    <Wrapper>
      {auth && info && (
        <StatusBox
          isLeader={info.userId === auth.userId}
          deleteFn={deleteFn}
          memberStatus={info.memberStatus}
        />
      )}
      <ListWrapper>
        <InputBox onChange={handleChangeSearch} />
        <Name>
          <NameTags>
            <div>no</div>
            <div>제목</div>
            <div>작성자</div>
            <div>작성일</div>
            <div>조회수</div>
          </NameTags>
        </Name>
        {searchedData && searchedData.length !== 0 ? (
          searchedData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, i) => (
              <Link key={item.id} to={`/sgroup/detail/${id}/post/${item.id}`}>
                <Box>
                  <Contents>
                    <div>{i + 1}</div>
                    <div>{item.title}</div>
                    <div>{item.userName}</div>
                    <div>{item.regDate.split('T')[0]}</div>
                    <div>{item.hitCount}</div>
                  </Contents>
                </Box>
              </Link>
            ))
        ) : (
          <Box>
            <div
              style={{ width: '100%', fontSize: '16px', textAlign: 'center' }}
            >
              작성된 게시글이 없습니다.
            </div>
          </Box>
        )}
        {searchedData && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={searchedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </ListWrapper>
    </Wrapper>
  );
};

export default SGroupDetail;
