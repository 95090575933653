import { useMutation, useQueryClient } from 'react-query';

import { createClassComment, editClassComment } from 'apis/classBoard';

const useCreateAndEditClassComment = ({ type, id }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ commentId, comment }) => {
      if (type !== 'task') {
        if (!commentId) {
          return await createClassComment({ comment, id });
        } else {
          return await editClassComment({ id, commentId, comment });
        }
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
  });

  return { ...queryReturns };
};

export default useCreateAndEditClassComment;
