import React from 'react';

import { Wrapper } from '../../modules/teacher/style';
import Header1 from '../../modules/components/header/Header';

const Privacy = () => {
  const str = `
‘에스케이쉴더스 주식회사’는 개인정보보호법에 따라 고객님의 개인정보와 그 권리를 보호하고자
다음과 같은 처리방침을 두고 있습니다.
○ 본 방침은부터 2022년 2월 1일부터 시행됩니다.
제 1조 개인정보의 처리 목적
  ‘에스케이쉴더스 주식회사’는 개인정보를 다음의 목적을 위해 처리합니다.
  처리한 개인정보는 아래 목적 이외의 용도로는 사용되지 않습니다.
  만일 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
  ㆍ제공 서비스: 고객문의에 대한 답변제공 및 현황관리, 부정제보 민원처리 및 결과 회신 등을 목적으로 개인정보를 처리합니다.
제 2조 개인정보 수집현황
  수집하는 개인정보는 아래와 같습니다.
  ㆍ개인정보 항목 :
    – (필수항목) 이메일, 이름, 휴대전화번호, 회사명
    – (선택항목) 회사전화번호, 직책, 부서 등 명함상 정보
  ㆍ수집방법 : 홈페이지, 서면양식, 이메일
제 3조 개인정보의 처리 및 보유 기간
  ‘에스케이쉴더스 주식회사’는 법령에 따라 정보주체로부터 개인정보를 수집/처리합니다.
  개인정보는 처리목적달성이 완료되거나 정보주체의 파기요청이 있을 경우 지체없이 파기됩니다.
제 4조 개인정보의 제3자 제공에 관한 사항
  ‘에스케이쉴더스 주식회사’는 정보주체의 동의, 법률의 특별한 규정 등
  개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
제 5조 정보주체의 권리,의무 및 그 행사방법
  ① 고객님은 ‘에스케이쉴더스 주식회사’에 대해 언제든지 아래의 개인정보 보호관련 권리를 행사하실 수 있습니다.
    ㆍ 개인정보 열람요구
    ㆍ 오류 등이 있을 경우 정정 요구
    ㆍ 삭제요구
    ㆍ 처리정지 요구
  ② 제1항에 따른 권리행사는 개인정보보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있습니다.
    ‘에스케이쉴더스 주식회사’는 지체없이 조치하겠습니다.
    ㆍ팩스 :050-8090-9633, hello@openeg.co.kr
  ③ 고객님께서 개인정보의 정정 또는 삭제를 요구하신 경우,
    ‘에스케이쉴더스 주식회사’에서 정정 또는 삭제를 완료할 때까지 개인정보를 이용하거나 제공하지 않습니다.
  ④ 고객님의 권리 행사는 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.
제 6조 개인정보의 파기
  ‘에스케이쉴더스 주식회사’는 개인정보 처리목적이 달성된 경우나 정보주체가 요구하는 경우에는
  지체없이 해당 개인정보를 파기하며 그 방법은 다음과 같습니다.
  ㆍ파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
  종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
제 7조 개인정보의 안전성 확보 조치
  ‘에스케이쉴더스 주식회사’는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
  ① 개인정보 취급 직원의 최소화 및 교육
    개인정보 취급직원을 최소화 하였으며, 개인정보관리대책을 시행하고 있습니다.
  ② 내부관리계획의 수립 및 시행
    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
  ③ 해킹 등에 대비한 기술적 대책
    ‘에스케이쉴더스 주식회사’는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치, 업데이트를 하고 있습니다.
  ④ 개인정보의 암호화
    고객님의 개인정보는 암호화 되어 저장 및 관리되고 있습니다.
    중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
제 8조 개인정보의 권리침해 신고상담
  ‘에스케이쉴더스 주식회사’의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하셨거나
  자세한 도움이 필요하실 경우 아래 기관에 문의하여 주식기 바랍니다.
  아래의 기관은 ‘에스케이쉴더스 주식회사’와는 별개의 기관입니다.
    ① 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
    ② 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
    ③ 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
    ④ 경찰청 : (국번없이) 182 (cyberbureau.police.go.kr)
제 9조 개인정보 처리방침 변경
  이 개인정보처리방침은 시행일로부터 적용됩니다.
  법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
  `;

  return (
    <>
      <Header1 data={{ title: '개인정보 처리방침' }} />
      <Wrapper>
        {(str || '').split('\n').map((line, i) =>
          line[0] === '제' ? (
            <div key={i} style={{ fontSize: '18px', fontWeight: '700' }}>
              <br />
              {line}
              <br />
              <br />
            </div>
          ) : (
            <div key={i} style={{ fontSize: '16px' }}>
              {line}
              <br />
            </div>
          )
        )}
      </Wrapper>
    </>
  );
};

export default Privacy;
