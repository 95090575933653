import axios from 'axios';
import { useMutation } from 'react-query';

const useSelectedProblemCancel = () => {
  const { mutate, isLoading } = useMutation(async ({ examId, problemId }) => {
    return await axios
      .delete(
        `${process.env.REACT_APP_IP}/teachers/exam/creation/pre-question/${examId}/${problemId}`
      )
      .then((res) => {
        return res.data;
      });
  });
  return { mutate, isLoading };
};
export default useSelectedProblemCancel;
