import { useQuery } from 'react-query';

import { fetchSubmittedStudentList } from 'apis/pbl';

const UseFetchSubmittedStudentListHook = ({ problemId, pageNum }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchSubmittedStudentList', { problemId, pageNum }],
    queryFn: async () =>
      await fetchSubmittedStudentList({ problemId, pageNum }),
    select: (res) => res.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!problemId,
  });
  return { ...queryReturns };
};

export default UseFetchSubmittedStudentListHook;
