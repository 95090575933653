import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  PBLProblemClose,
  PBLProblemComingSoon,
  PBLProblemHide,
  PBLProblemOpen,
} from './PblCommon';
import { useHistory } from 'react-router-dom';

import AuthContext from 'context/Auth.context';

const ProblemCard = ({
  id,
  level,
  newThumbnailImgName,
  solvedCount,
  state,
  title,
  unitId,
}) => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(title === 'NOT AVAILABLE YET');

  useEffect(() => {
    setIsOpen(title === 'NOT AVAILABLE YET');
  }, [title]);

  const renderingComponent = {
    OPEN: (
      <PBLProblemOpen
        level={level}
        solvedCount={solvedCount}
        title={title}
        newThumbnailImgName={newThumbnailImgName}
      />
    ),
    HIDE:
      auth.type !== '1' ? (
        <PBLProblemOpen
          level={level}
          solvedCount={solvedCount}
          title={title}
          state={state}
          newThumbnailImgName={newThumbnailImgName}
        />
      ) : (
        <PBLProblemHide level={level} />
      ),
    CLOSE: (
      <PBLProblemClose
        level={level}
        solvedCount={solvedCount}
        title={title}
        newThumbnailImgName={newThumbnailImgName}
      />
    ),
    UNDEFINED: <PBLProblemComingSoon level={level} />,
  };

  const stateToChange = {
    OPEN: '표출',
    HIDE: '숨김',
    CLOSE: '마감',
  };

  const badgeColor = {
    OPEN: '#5E8FFB',
    HIDE: '#FCBE20',
    CLOSE: '#FB6862',
  };

  const handleGoToDetailPage = () => {
    if (!unitId || !id) return;
    if (
      (state === 'HIDE' && auth.type === '1') ||
      state === 'UNDEFINED' ||
      title === 'NOT AVAILABLE YET'
    )
      return;

    history.push({
      pathname: `/myclass/pbl/${unitId}/detail`,
      state: {
        problemId: id,
      },
    });
  };

  return (
    <Container className={isOpen ? 'lock' : ''}>
      <a onClick={handleGoToDetailPage}>{renderingComponent[state]}</a>
      {auth.type !== '1' && state !== 'UNDEFINED' && (
        <div
          className="hideOpenButton"
          style={{ backgroundColor: `${badgeColor[state] || '#eee'}` }}
        >{`${stateToChange[state]}`}</div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 300px;
  padding: 50px;
  transition: 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  &.lock {
    transform: none;
    cursor: default;
  }

  a {
    color: #333;
    cursor: pointer;
  }

  > div.hideOpenButton {
    position: absolute;
    right: 55px;
    top: 55px;
    font-size: 18px;
    border: 2px solid #fff;
    z-index: 1;
    padding: 5px 15px;
    transition: 0.3s;
    background-color: #af3ce7;
    border-radius: 10px;
    color: #fff;
    box-shadow: 1px 1px 2px #b9b9b9;
  }
`;

export default ProblemCard;
