import React, { useEffect, useState, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import AuthContext from 'context/Auth.context';
import {
  useFetchQuestionCategories,
  useFetchInquiriesList,
  useDebounce,
  useQueryValue,
} from 'hooks';
import CustomerList from '../CustomerList';
import { SearchInput, Pagination, WriteButton } from '../../../elements';

const ContactList = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const pageQuery = useQueryValue('page');

  const [pageSize] = useState(10);
  const [page, setPage] = useState(+pageQuery || 1);
  const [searchType, setSearchType] = useState('postCategoryName');
  const [searchContent, setSearchContent] = useState('');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchContent = useDebounce(searchText, 300);

  const { data: categories, isLoading: categoriesLoading } =
    useFetchQuestionCategories();

  const {
    data: searchedData,
    isLoading: searchedDataLoading,
    refetch: refetchList,
    totalValue,
  } = useFetchInquiriesList({
    page,
    pageSize,
    type: searchType,
    searchContent: searchContent || debouncedSearchContent,
  });

  const move = (num) => {
    setPage(num);
    history.replace({
      search: `page=${num}`,
    });
  };

  const handleClickCategory = (categoryName) => {
    if (searchType !== 'postCategoryName') setSearchType('postCategoryName');
    setSearchText('');
    setSearchContent(categoryName);
  };

  const handleClickNoComment = () => {
    setSearchType('noComment');
    setSearchContent('noComment');
  };

  const handleClickReset = () => {
    setSearchType('postCategoryName');
    setSearchContent('');
  };

  useEffect(() => {
    if (!searchText) return;

    if (searchText) {
      setSearchContent('');
      setSearchType('all');
    }
  }, [searchText]);

  return (
    <Fragment>
      <InputBox>
        <div className="categories">
          {categoriesLoading && (
            <>
              <CategoryItemSkeleton />
              <CategoryItemSkeleton />
              <CategoryItemSkeleton />
              <CategoryItemSkeleton />
              <CategoryItemSkeleton />
              <CategoryItemSkeleton />
            </>
          )}
          {!categoriesLoading && (
            <CategoryItem $backColor="#ea5d5d" onClick={handleClickReset}>
              전체
            </CategoryItem>
          )}
          {categories?.map((category, index) => (
            <CategoryItem
              key={index}
              $backColor={category.themeColor}
              onClick={() => handleClickCategory(category.categoryName)}
            >
              {category.categoryName}
            </CategoryItem>
          ))}
          {auth.type !== '1' && (
            <CategoryItem $backColor="#c0c0c0" onClick={handleClickNoComment}>
              미답변
            </CategoryItem>
          )}
        </div>

        <SearchInput
          value={searchText}
          setValue={setSearchText}
          onClickSearch={refetchList}
        />
      </InputBox>

      <div className="mypageContents">
        <CustomerList
          list={searchedData?.list}
          isListLoading={searchedDataLoading}
          categories={categories || []}
          boardType="contact"
          isQuiryBoard
        />
      </div>

      <StyledBox>
        <WriteButton text="문의글 작성" to="/customer/contact/write" />
      </StyledBox>

      <StyledBox>
        <Pagination
          page={page}
          total={totalValue}
          perPage={pageSize}
          move={move}
        />
      </StyledBox>
    </Fragment>
  );
};

const InputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;

  > div.categories {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const CategoryItem = styled.span`
  padding: 3px 7px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(p) => p.$backColor};
  transition: 0.2s;

  &:hover {
    filter: brightness(90%);
  }
`;
const CategoryItemSkeleton = styled.div`
  min-width: 65px;
  height: 29px;
  background-color: #eee;
  border-radius: 8px;
`;

const NoticeText = styled.span`
  font-size: 1.2rem;
  color: #ec7422;
  font-weight: 500;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > a {
    margin-left: auto;
  }
`;

export default ContactList;
