import React from 'react';
import { useParams } from 'react-router-dom';

import TaskBoardWrite from './TaskBoardWrite';
import ClassBoardWrite from './ClassBoardWrite';

const BoardWrite = () => {
  const { type } = useParams();

  return (
    <>
      {type !== 'task' && <ClassBoardWrite />}
      {type === 'task' && <TaskBoardWrite />}
    </>
  );
};

export default BoardWrite;
