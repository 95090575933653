import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './preCourse.module.scss';

const PreCourseDetail = ({ list, getTitle }) => {
  const { id } = useParams();
  const [course, setCourse] = useState();

  useEffect(() => {
    if (list) {
      const content = list.filter((item) => item.id === Number(id))[0];
      setCourse(content);
      getTitle(content.title);
    }
  }, [list, getTitle, id]);

  return (
    <div className={styles.contentBody}>
      {course && (
        <div className={styles.detailWrapper}>
          <div className={styles.detailImgWrapper}>
            <img
              src={`${process.env.REACT_APP_IP}/pre-learning/${course.imageName}`}
              alt="사진"
            />
          </div>
          <div className={styles.detailContent}>
            <div>{`${course.title}(${course.totalTime}h)`}</div>
            <div>
              {(course.content || '').split('\n').map((line) => (
                <>
                  {line}
                  <br />
                </>
              ))}
            </div>
          </div>
          <div>
            <Link to={'/pre-course/list'} className={styles.backBtn}>
              돌아가기
            </Link>
            <a
              target="_blank"
              href={course.url}
              className={styles.linkBtn}
              rel="noopener noreferrer"
            >
              학습하러가기
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreCourseDetail;
