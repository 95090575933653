import axios from 'axios';

import { BASE_URL } from './config';

/* 문의하기 게시판 카테고리 조회 */
export const fetchQuestionCategories = async () => {
  return await axios.get(`${BASE_URL}/users/inquiries/categories`);
};

/* 문의하기 게시판 리스트 조회 */
export const fetchInquiriesList = async ({
  page,
  pageSize,
  type,
  searchContent,
}) => {
  return await axios.get(
    `${BASE_URL}/users/inquiries?pageNum=${page}&pageSize=${pageSize}&searchType=${type}&searchContent=${searchContent}`
  );
};

/* 문의하기 나의 문의 리스트만 조회 */
export const fetchMyInquiryList = async ({ page, pageSize }) => {
  return await axios.get(
    `${BASE_URL}/users/my/inquiries?pageNum=${page}&pageSize=${pageSize}`
  );
};

/* 문의하기 글 작성 */
export const createInquiry = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/users/inquiries`, formData);
};

/* 문의하기 상세글 조회 */
export const fetchInquiryDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/inquiries/${id}`);
};

/* 문의하기 상세 댓글 조회 */
export const fetchInquiryComments = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/inquiries/${id}/comments`);
};

/* 문의하기 상세글 삭제 */
export const deleteInquiryDetail = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/users/inquiries/${id}`);
};

/* 문의하기 상세글 수정 */
export const editInquiryDetail = async ({ id, formData }) => {
  return await axios.put(`${BASE_URL}/users/inquiries/${id}`, formData);
};

/* 문의하기 상세글 댓글 달기 */
export const createInquiryComment = async ({ postId, author, content }) => {
  return await axios.post(`${BASE_URL}/users/inquiries/${postId}/comments`, {
    author,
    content,
  });
};

/* 문의하기 상세글 댓글 수정하기 */
export const editInquiryComment = async ({
  postId,
  replyId,
  author,
  content,
}) => {
  return await axios.put(
    `${BASE_URL}/users/inquiries/${postId}/comments/${replyId}`,
    {
      author,
      content,
    }
  );
};

/* 문의하기 상세글 댓글 삭제하기 */
export const deleteInquiryComment = async ({ postId, replyId }) => {
  return await axios.delete(
    `${BASE_URL}/users/inquiries/${postId}/comments/${replyId}`
  );
};

/* 우리들이야기 게시판 리스트 조회 */
export const fetchGreetingList = async ({
  page,
  pageSize,
  type,
  searchContent,
}) => {
  return await axios.get(
    `${BASE_URL}/users/greeting?pageNum=${page}&pageSize=${pageSize}&searchType=${type}&searchContent=${searchContent}`
  );
};

/* 우리들이야기 게시판 상세글 조회 */
export const fetchGreetingDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/greeting/${id}`);
};

/* 우리들이야기 게시판 상세글 작성 */
export const createGreetingPost = async ({ title, content, categoryId }) => {
  const body = { title, content, categoryId };
  return await axios.post(`${BASE_URL}/users/greeting`, { ...body });
};

/* 우리들이야기 게시판 상세글 수정 */
export const editGreetingPost = async ({ id, categoryId, title, content }) => {
  return await axios.put(`${BASE_URL}/users/greeting/${id}`, {
    categoryId,
    title,
    content,
  });
};

/* 우리들이야기 게시판 상세글 삭제 */
export const deleteGreetingDetail = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/users/greeting/${id}`);
};

/* 우리들이야기 게시판 상세글 댓글 작성 */
export const createGreetingComment = async ({ postId, author, content }) => {
  return await axios.post(`${BASE_URL}/users/greeting/${postId}/comments`, {
    author,
    content,
  });
};

/* 우리들이야기 게시판 상세글 댓글 삭제 */
export const deleteGreetingComment = async ({ postId, replyId }) => {
  return await axios.delete(
    `${BASE_URL}/users/greeting/${postId}/comments/${replyId}`
  );
};

/* 우리들이야기 게시판 상세글 댓글 수정 */
export const editGreetingComment = async ({
  postId,
  replyId,
  author,
  content,
}) => {
  return await axios.put(
    `${BASE_URL}/users/greeting/${postId}/comments/${replyId}`,
    {
      author,
      content,
    }
  );
};

/* 학습지원 자료실 게시판 리스트 조회 */
export const fetchLibraryList = async ({
  pageNum,
  pageSize,
  searchOption,
  searchKeyword,
}) => {
  return await axios.get(
    `${BASE_URL}/users/library?pageNum=${pageNum}&pageSize=${pageSize}&searchOption=${searchOption}&searchKeyword=${searchKeyword}`
  );
};

/* 학습지원 자료실 게시글 상세 조회 */
export const fetchLibraryDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/library/${id}`);
};

/* 학습지원 FAQ 게시판 리스트 조회 */
export const fetchFaqList = async ({
  pageNum,
  pageSize,
  searchOption,
  searchKeyword,
}) => {
  return await axios.get(
    `${BASE_URL}/users/faq?pageNum=${pageNum}&pageSize=${pageSize}&searchOption=${searchOption}&searchKeyword=${searchKeyword}`
  );
};

/* 학습지원 FAQ 상세글 조회 */
export const fetchFaqDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/faq/${id}`);
};

/* ========================= 상담 관련  ========================= */

/* 학습지원 상담 예약 목록 조회 [학생] */
export const fetchReserveList = async () => {
  return await axios.get(`${BASE_URL}/users/exam/consultation`);
};

/* 학습지원 상담 예약 신청 [학생] */
export const createReservation = async ({ date, time, purpose }) => {
  return await axios.post(`${BASE_URL}/users/exam/consultation/reservation`, {
    date,
    time,
    purpose,
  });
};

/* 학습지원 상담 예약 상담 신청 취소 [학생] */
export const cancleReservation = async ({ id }) => {
  return await axios.delete(
    `${BASE_URL}/users/exam/consultation/reservation/${id}`
  );
};

/* 학습지원 상담 예약 수정 [학생] */
export const changeReservation = async ({ id, date, time, purpose }) => {
  return await axios.put(
    `${BASE_URL}/users/exam/consultation/reservation/${id}`,
    { date, time, purpose }
  );
};

/* 학습지원 상담 예약 이미 예약된 시간 조회 */
export const fetchAlreadyTime = async ({ date }) => {
  return await axios.get(
    `${BASE_URL}/users/exam/consultation/reservation/${date}`
  );
};

/* 상담 예약 목록 조회 [관리자] */
export const fetchAdminReserveList = async () => {
  return await axios.get(`${BASE_URL}/teachers/exam/consultation`);
};

/* 상담 승인 [관리자] */
export const applyReservation = async ({ id }) => {
  return await axios.put(
    `${BASE_URL}/teachers/exam/consultation/approval/${id}`
  );
};

/* 상담 완료 [관리자] */
export const finishReservation = async ({ id }) => {
  return await axios.put(
    `${BASE_URL}/teachers/exam/consultation/complete/${id}`
  );
};
