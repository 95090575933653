import axios from 'axios';
import { useMutation } from 'react-query';

const useVideoUrl = () => {
  const queryReturns = useMutation({
    mutationFn: async ({ chapterId, unitId, urlList }) => {
      return await axios.patch(
        `${process.env.REACT_APP_IP}/teachers/myclass/units/vod`,
        { chapterId, unitId, urlList }
      );
    },
  });

  return { ...queryReturns };
};

export default useVideoUrl;
