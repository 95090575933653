import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useParams } from 'react-router';

const ClassBoardWriteButton = ({ cancleUrl }) => {
  const { id } = useParams();

  return (
    <BtnBox>
      <Link to={cancleUrl}>취소</Link>
      <button type="submit">{id ? '게시글 수정' : '게시글 등록'}</button>
    </BtnBox>
  );
};

const BtnBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
  flex-direction: row !important;

  a,
  button {
    display: flex;
    width: 185px;
    height: 55px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 15px;
    font-weight: bold;

    &:hover {
      filter: brightness(90%);
    }
  }

  a {
    color: rgb(151, 151, 151);
    border: 1px solid rgb(212, 212, 212);
    text-decoration: none;
    font-size: 16px;
  }

  button {
    color: rgb(255, 255, 255);
    background: #ec7422;
    font-size: 16px;
    cursor: pointer;
  }

  svg {
    color: #fff;
  }
`;

export default ClassBoardWriteButton;
