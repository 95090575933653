import swal from 'sweetalert';

export const confirmSwal = async ({ title, confirmButton }) => {
  return await swal({
    title: title,
    icon: 'info',
    buttons: {
      cancel: '취소',
      confirm: { text: confirmButton },
    },
  });
};

export const successMessage = async ({ text }) => {
  return await swal(text, {
    icon: 'success',
  });
};

export const errorMessage = async ({ text }) => {
  return await swal(text, {
    icon: 'error',
  });
};

export const warningMessage = async ({ text }) => {
  return await swal(text, {
    icon: 'warning',
  });
};
