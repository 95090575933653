import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const WriteButton = ({ text, backColor = '#ec7422', to }) => {
  return (
    <Wrapper to={to} $backColor={backColor}>
      {text}
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  display: grid;
  place-items: center;
  height: 40px;
  padding: 5px 50px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  text-decoration: none;
  background-color: ${(p) => p.$backColor};
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 1px 1px 3px #777;

  &:hover {
    filter: brightness(90%);
    color: #fff;
  }
`;

export default WriteButton;
