import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Fade, Menu } from '@material-ui/core';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import AuthContext from 'context/Auth.context';
import { useFetchNewNotification, useSSE } from 'hooks';
import Message from 'utilities/Message';
import { Logo } from '../../elements';
import Layout from '../Layout/Layout';
import styles from './Header.module.scss';
const cms_url = process.env.REACT_APP_CMS_URL;

const Header = ({ data = [], history }) => {
  const auth = useContext(AuthContext);

  /* SSE 연결 */
  useSSE();

  // 내정보 팝업
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [menuData, setMenuData] = useState();

  const { newNotice, newContactReply } = useFetchNewNotification();
  const { data: notificationData } = newNotice || {};
  const { data: newReplyData } = newContactReply || {};

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // 헤더 메뉴 sticky 설정
  const [sticky, setSticky] = useState(false);

  // 로그인 상태 정보
  const { name, email } = auth;

  useEffect(() => {
    window.addEventListener('scroll', handleResize);
    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, [auth]);

  const handleResize = () => {
    setSticky(window.pageYOffset || document.documentElement.scrollTop > 200);
  };

  useEffect(() => {
    const str = auth.type === '1' ? '교강사' : '스터디그룹';
    const str1 = auth.type === '1' ? '' : '역량 평가';
    let newArr = data.filter((item) => item.name !== str && item.name !== str1);

    const studentArr = newArr.map((item) => {
      return {
        ...item,
        children: item.children.filter((sub) => sub.name !== '상담 관리'),
      };
    });

    if (auth.type !== '1') {
      const adminMenu = newArr.map((item) => {
        if (item.name === '내클래스') {
          return { ...item, children: item.children.slice(0, 8) };
        } else if (item.name === '학습지원') {
          return { ...item, children: item.children.slice(0, 5) };
        } else {
          return item;
        }
      });

      setMenuData(adminMenu);
    } else {
      setMenuData(studentArr);
    }
  }, [data, auth]);

  const handleLogout = () => {
    auth.onLogout();
    setAnchorEl(null);
    history.push('/login');
  };

  const handleMypage = () => {
    if (auth.auth) {
      history.push('/mypage/my-information');
    } else {
      toast.error(Message.SERVICE_AUTHENTICATED_ONLY);
      history.push('/login');
    }
    handleClose();
  };

  // 메뉴 클릭 시 접근 권한 체크 후 이동
  const handleClick = (e) => {
    e.preventDefault();

    const { path, title } = e.target.dataset;

    const authOnlyServices = [
      '/student', // 학습자
      '/student/Course', // 학습실
      '/student/securelist', // 보안약점 진단훈련
      '/student/codediagnosis', // 프로젝트 개발훈련
      '/common/dashboard', // 대시보드
      '/submitterlist', // 문제출제
      '/customer', // 고객센터 & 자료실
      '/customer/faq', // 자주묻는질문
      '/customer/contact', // 문의하기
      '/customer/greeting', // 우리들이야기
    ];

    if (authOnlyServices.indexOf(path) !== -1 && !auth.auth) {
      toast.error(`"${title}" 메뉴는 ${Message.SERVICE_AUTHENTICATED_ONLY}`);
      return false;
    }

    if (path.indexOf('/student') === 0) {
      if (auth.type !== '1') {
        toast.error(`"${title}" 메뉴는 ${Message.SERVICE_STUDENT_ONLY}`);
        return false;
      } else if (auth.status !== '1') {
        toast.error(Message.STATUS_NOT_CONFIRMED);
        return false;
      }
    } else if (path.indexOf('/submitterlist') === 0 && auth.type !== '2') {
      toast.error(`"${title}" 메뉴는 ${Message.SERVICE_TRAINER_ONLY}`);
      return false;
    }

    history.push(path);
  };

  const handleGoToNewBoard = (id) => {
    setAnchorEl(null);
    history.push(`/myclass/board/notice/${id}`);
  };

  const handleGotoContact = (id) => {
    setAnchorEl(null);
    history.push(`/customer/contact/${id}`);
  };

  const menu = menuData?.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <a
            className="secosub2"
            data-path={item.url}
            data-title={item.name}
            href="#"
            onClick={handleClick}
          >
            {item.name}
          </a>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <a
              className="secosub2"
              data-path={item.url}
              data-title={item.name}
              href="#"
              onClick={handleClick}
            >
              {item.name}
            </a>
          </span>
          <ul>
            {item.children.map((subitem, subindex) => (
              <li key={subindex}>
                <a
                  className="secomin"
                  data-path={subitem.url}
                  data-title={subitem.name}
                  href="#"
                  onClick={handleClick}
                >
                  {subitem.name}
                </a>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  const header = (
    <Layout>
      <div className={styles.header} id="mainHeader">
        <Logo auth={auth.auth} dark />
        <ul className={styles.menu}>
          {menu}
          {!['1', '2'].includes(auth.type) && (
            <li>
              <a className="secosub2" href={cms_url}>
                Go to CMS
              </a>
            </li>
          )}
        </ul>

        <Fragment>
          <StyledUserInfoButton
            className="secotext"
            type="button"
            id="login"
            onClick={handleMenu}
          >
            <span style={{ marginBottom: '0' }}>{name}</span>
            <i className="fas fa-user" style={{ marginLeft: '10px' }} />
            {(!!notificationData?.length || !!newReplyData?.length) && (
              <NotificationIcon>
                <i className="fa-regular fa-bell" />
              </NotificationIcon>
            )}
          </StyledUserInfoButton>

          <Menu
            id="login"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <div className={styles.dwUserBox}>
              <div className={styles.uImg}>
                <img src="/assets/icons/people.png" alt="user" />
              </div>
              <div className={styles.uText}>
                <p className="secotext secodgray">{name}</p>
                <p className="secomin secogray">{email}</p>
              </div>
            </div>

            {(!!notificationData?.length || !!newReplyData?.length) && (
              <div>
                <li role="separator" className={styles.divider} />
                <NotificationBox>
                  {!!notificationData?.length && (
                    <>
                      <span>새 공지 알림</span>
                      <div className="new-list first">
                        {notificationData?.slice(0, 10).map((list) => (
                          <li
                            key={list.id}
                            onClick={() => handleGoToNewBoard(list.id)}
                          >
                            <span>[{list.createdAt.slice(5, 10)}]</span>
                            <span>{list.title}</span>
                          </li>
                        ))}
                      </div>
                    </>
                  )}

                  {!!newReplyData?.length && (
                    <>
                      <span>
                        {auth.type === '1'
                          ? '내 문의 답변 알림'
                          : '학생 문의글 댓글 알림'}
                      </span>
                      <div
                        className={`new-list ${
                          newReplyData.length > 10 ? 'scroll' : ''
                        }`}
                      >
                        {newReplyData?.map((list, index) => (
                          <li
                            key={index}
                            onClick={() => handleGotoContact(list.id)}
                          >
                            <span>
                              {!list.isCommentRead && <New>NEW</New>}
                              {list.title}
                            </span>
                          </li>
                        ))}
                      </div>
                    </>
                  )}
                </NotificationBox>
              </div>
            )}

            <li
              role="separator"
              className={styles.divider}
              style={{ marginBottom: '10px' }}
            />

            <ButtonBox>
              <button
                className="btn secotext my-page"
                type="button"
                id="button-addon2"
                onClick={handleMypage}
              >
                <i
                  className="far fa-address-book"
                  style={{ marginTop: '5px' }}
                ></i>{' '}
                Mypage
              </button>
              <button
                className="btn btn-outline-danger secotext"
                type="button"
                id="button-addon2"
                onClick={handleLogout}
              >
                <i
                  className="fas fa-power-off"
                  style={{ marginTop: '5px' }}
                ></i>{' '}
                Signout
              </button>
            </ButtonBox>
          </Menu>
        </Fragment>
      </div>
    </Layout>
  );

  return (
    <Fragment>
      <div className={styles.wrapper}>{header}</div>
      <div
        className={[
          styles.wrapper,
          sticky ? styles.sticky : styles.hidden,
        ].join(' ')}
      >
        {header}
      </div>
    </Fragment>
  );
};

const StyledUserInfoButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 12px 25px;
  border-radius: 5px;
  background: linear-gradient(89.85deg, #ee2424 10.06%, #ef5310 86.56%);
  color: white;
  outline: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;

  &:hover span > i {
    animation: tilt-shaking 0.25s infinite;
  }

  @keyframes tilt-shaking {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(8deg);
    }
    50% {
      transform: rotate(0);
    }
    75% {
      transform: rotate(-8deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const NotificationIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 50%;
  background-color: #ee2424;

  i {
    color: #fff;
    transition: 0.2s;
  }
`;

const NotificationBox = styled.div`
  width: 100%;
  padding: 5px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    width: auto;
    display: inline-block;
    padding: 5px 10px;
    color: #fc9918;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  > div.new-list {
    padding: 0 20px;
    width: 100%;

    &.scroll {
      max-height: 300px;
      overflow-y: scroll;
      overscroll-behavior: contain;
    }

    &.first {
      margin-bottom: 10px;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 3px 0;
      cursor: pointer;
      transition: 0.2s;

      i {
        width: 10px;
      }

      span {
        max-width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-child {
          margin-right: 2px;
        }

        &:nth-child(2) {
          margin-right: 3px;
        }
      }

      &:hover {
        color: #1e88e5;

        span {
          text-decoration: underline;
        }
      }
    }
  }
`;

const ButtonBox = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: center;

  button {
    padding: 5px 15px;

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  .my-page {
    border: 1px solid #fc9918;
    background-color: #fff;
    color: #fc9918;

    &:hover {
      background-color: #fc9918;
      color: #fff;
    }
  }
`;

const New = styled.i`
  font-style: normal;
  font-size: 0.8rem;
  color: #fff;
  background-color: #ec7422;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 3px;
`;

export default withRouter(Header);
