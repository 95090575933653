import Axios from 'axios';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import AuthContext from 'context/Auth.context';
import { handleErrorMessage } from 'utilities';

const Wrapper = styled.div`
  width: 350px;
  position: sticky;
  top: 120px;
  margin-bottom: 50px;
`;

const InfoBox = styled.div`
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const BtnBox = styled.div`
  width: 100%;
  padding: 0 20px;
`;

const Btn = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  color: white;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  div {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }

  div:first-child {
    margin-right: 10px;
  }
`;

const LeaderBox = styled.div`
  width: 100%;
  padding: 0 20px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;

  span {
    color: #333;
    font-size: 14px;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
`;

const StatusBox = ({
  isLeader,
  history,
  onCommentChange,
  deleteFn,
  memberStatus,
}) => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    const url = `${process.env.REACT_APP_IP}/users/sgroup/rooms/${id}/info`;
    Axios.get(url)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch(handleErrorMessage);
  }, [history, onCommentChange, id, auth]);

  const handleDelete = () => {
    if (
      window.confirm(
        isLeader
          ? `조장직을 위임하지 않고 탈퇴하면 게시판의 모든 정보가 사라집니다. 그래도 탈퇴하시겠습니까?`
          : `스터디 그룹에서 탈퇴하시겠습니까?`
      )
    ) {
      const url = `${process.env.REACT_APP_IP}/users/sgroup/join/${id}`;
      Axios.delete(url)
        .then((response) => {
          if (response.data === 'SUCCESS') {
            toast.success('탈퇴되었습니다.');
            deleteFn();
            history.push(`/sgroup/list`);
          } else {
            toast.success('탈퇴에 실패하였습니다.');
          }
        })
        .catch(handleErrorMessage);
    }
  };

  return (
    <Wrapper>
      <InfoBox>
        <Title>{auth.name} 님의 활동</Title>
        <Info>
          <div>댓글 {userInfo?.commentCount}</div>
          <div>게시글 {userInfo?.postCount}</div>
        </Info>
        <BtnBox>
          <Link
            to={{
              pathname: `/sgroup/detail/${id}/write`,
              state: { memberStatus: memberStatus || 'Y' },
            }}
          >
            <Btn color="#fc9918">게시글작성</Btn>
          </Link>
          <Btn onClick={handleDelete} color="#f14a16">
            그룹탈퇴
          </Btn>
        </BtnBox>
        {isLeader && (
          <LeaderBox>
            <Link to={`/sgroup/detail/${id}/join`}>
              <span>가입신청관리 </span>
            </Link>
            <span> · </span>
            <Link to={`/sgroup/detail/${id}/members`}>
              <span> 그룹원관리</span>
            </Link>
          </LeaderBox>
        )}
      </InfoBox>
    </Wrapper>
  );
};

export default withRouter(StatusBox);
