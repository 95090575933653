import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  applyReservation,
  cancleReservation,
  changeReservation,
  createReservation,
  fetchAdminReserveList,
  fetchAlreadyTime,
  fetchReserveList,
  finishReservation,
} from 'apis/customer';
import AuthContext from 'context/Auth.context';

const useReservation = ({ date = '' }) => {
  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();

  const studentReservationList = useQuery({
    queryKey: ['fetchReserveList'],
    queryFn: async () => {
      return await fetchReserveList();
    },
    select: (res) => res.data,
    retry: 2,
    retryDelay: 1000,
    enabled: auth.type === '1',
  });

  const studentCreateReservation = useMutation({
    mutationFn: async ({ date, time, purpose }) => {
      return await createReservation({ date, time, purpose });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchReserveList'],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchReserveList'],
      }),
  });

  const studentChangeReservation = useMutation({
    mutationFn: async ({ id, date, time, purpose }) => {
      return await changeReservation({ id, date, time, purpose });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchReserveList'],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchReserveList'],
      }),
  });

  const cancelReservation = useMutation({
    mutationFn: async ({ id }) => {
      return await cancleReservation({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchReserveList'],
      }),
  });

  const alreadyTime = useQuery({
    queryKey: ['fetchAlreadyTime', date],
    queryFn: async () => {
      return await fetchAlreadyTime({ date });
    },
    select: (res) => res.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!date,
  });

  const adminReservationList = useQuery({
    queryKey: ['fetchAdminReserveList'],
    queryFn: async () => {
      return await fetchAdminReserveList();
    },
    select: (res) => res.data,
    retry: 2,
    retryDelay: 1000,
    enabled: auth.type !== '1',
  });

  const adminApplyReservation = useMutation({
    mutationFn: async ({ id }) => {
      return await applyReservation({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchAdminReserveList'],
      }),
  });

  const adminFinishReservation = useMutation({
    mutationFn: async ({ id }) => {
      return await finishReservation({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchAdminReserveList'],
      }),
  });

  return {
    studentReservationList,
    studentCreateReservation,
    studentChangeReservation,
    cancelReservation,
    alreadyTime,
    adminReservationList,
    adminApplyReservation,
    adminFinishReservation,
  };
};

export default useReservation;
