import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TopButton = () => {
  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false);

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if (ScrollY > 100) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  };

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setScrollY(0);
    setBtnStatus(false);
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    };
  });

  return (
    <TopBtn active={!!BtnStatus} onClick={handleTop}>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
        </svg>
        TOP
      </span>
    </TopBtn>
  );
};

const TopBtn = styled.button`
  position: fixed;
  opacity: ${(props) => (props.active ? 1 : 0)};
  bottom: 100px;
  right: 25px;
  z-index: ${(props) => (props.active ? 200 : 0)};
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 0 none;
  background: white;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.06em;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  line-height: 15px;
  box-shadow:
    rgb(0 0 0 / 10%) 0 4px 6px,
    rgb(0 0 0 / 15%) 0 8px 30px,
    rgb(255 255 255 / 20%) 0 0 0 1px inset;
  &:focus {
    outline: none;
  }
  span {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      color: rgba(0, 0, 0, 0.4);
      width: 17px;
      height: 17px;
    }
    &:hover,
    &:focus,
    &:active {
      outline: 0 none;
    }
  }
`;

export default TopButton;
