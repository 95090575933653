import axios from 'axios';

import { BASE_URL } from './config';

/* 클래스 게시판 과정 상세 테이블 데이터 조회 */
export const fetchClassDetail = async () => {
  return axios.get(`${BASE_URL}/users/myclass/class-detail`);
};

/* 클래스 로드맵 데이터 조회 */
export const fetchRoadmap = async () => {
  return axios.get(`${process.env.REACT_APP_IP}/users/myclass/road-map`);
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 리스트 조회
 * @params category => '학습자료' || '공지사항' || '강의문의'
 */
export const fetchClassList = async ({
  pageNum,
  pageSize,
  searchContent,
  category,
}) => {
  const params = {
    pageNum,
    pageSize,
    searchContent,
    category,
  };

  return axios.get(`${BASE_URL}/users/lc-posts/v2`, { params });
};

/* 클래스 게시판 <공통> 첨부파일 다운로드 */
export const downloadClassFile = async ({ id, fileId }) => {
  return await axios.get(
    `${BASE_URL}/users/lc-posts/v2/${id}/attachments/${fileId}`,
    {
      responseType: 'blob',
    }
  );
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 글 작성 */
export const createPost = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/users/lc-posts/v2`, formData);
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 상세 조회 */
export const fetchNoticeAndLibraryAndQnaDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/lc-posts/v2/${id}`);
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 글 삭제 */
export const deleteClassPost = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/users/lc-posts/v2/${id}`);
};

/*
 * 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 글 수정
 */
export const editClassPost = async ({ id, formData }) => {
  return await axios.put(`${BASE_URL}/users/lc-posts/v2/${id}`, formData);
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 댓글 작성 */
export const createClassComment = async ({ id, comment }) => {
  return await axios.post(`${BASE_URL}/users/lc-posts/v2/${id}/comments`, {
    comment,
  });
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 댓글 수정 */
export const editClassComment = async ({ id, commentId, comment }) => {
  return await axios.put(
    `${BASE_URL}/users/lc-posts/v2/${id}/comments/${commentId}`,
    {
      comment,
    }
  );
};

/* 클래스 게시판 <공지사항>, <학습자료실>, <강의질문> 댓글 삭제 */
export const deleteClassComment = async ({ id, commentId }) => {
  return await axios.delete(
    `${BASE_URL}/users/lc-posts/v2/${id}/comments/${commentId}`
  );
};

/* 클래스 게시판 과제 리스트 조회 */
export const fetchTaskList = async ({ pageNum, pageSize, keyword }) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
  };

  return axios.get(`${BASE_URL}/users/lc-posts/tasks`, { params });
};

/* 클래스 게시판 과제 상세 조회 */
export const fetchTaskDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/users/lc-posts/tasks/${id}`);
};

/* 클래스 게시판 과제 수정 */
export const editTask = async ({ id, formData }) => {
  return await axios.put(`${BASE_URL}/teachers/lc-posts/tasks/${id}`, formData);
};

/* 클래스 게시판 과제 등록 */
export const createTask = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/teachers/lc-posts/tasks`, formData);
};

/* 클래스 게시판 과제 삭제 */
export const deleteTask = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/teachers/lc-posts/tasks/${id}`);
};

/* 클래스 게시판 과제 제출 */
export const submitTaskContent = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/users/lc-posts/tasks`, formData);
};

/* 클래스 게시판 제출한 과제 삭제 */
export const deleteTaskContent = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/users/lc-posts/tasks/${id}`);
};

/* 클래스 게시판 과제 첨부파일 다운로드 */
export const downloadTaskFile = async ({ id, fileId }) => {
  return await axios.get(
    `${BASE_URL}/users/lc-posts/tasks/${id}/attachments/${fileId}`,
    {
      responseType: 'blob',
    }
  );
};

/* 클래스 게시판 학생 과제 채점 결과 리스트 조회 */
export const fetchTaskResultList = async () => {
  return axios.get(`${BASE_URL}/users/lc-posts/tasks/result`);
};

/* 텍스트 에디터 본문에 이미지 업로드 */
export const uploadContentImage = async (formData) => {
  return await axios.post(`${BASE_URL}/users/lc-posts/v2/images`, formData);
};
