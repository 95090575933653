import { useMutation, useQueryClient } from 'react-query';

import { deleteClassPost, deleteTask } from 'apis/classBoard';

const useRemoveClassBoard = ({ type }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ id }) => {
      if (type !== 'task') {
        return await deleteClassPost({ id });
      }

      if (type === 'task') {
        return await deleteTask({ id });
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassList', { type }],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveClassBoard;
