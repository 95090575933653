import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import AuthContext from 'context/Auth.context';

const useStudentDetail = (userId) => {
  const auth = useContext(AuthContext);
  const { data, isLoading } = useQuery(
    ['studentDetail', userId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/teachers/exam/counseling/${auth.classId}/${userId}`
        )
        .then((res) => {
          return res.data;
        });
    },
    { enabled: !!userId }
  );
  return { data, isLoading };
};

export default useStudentDetail;
