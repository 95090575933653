import { useState } from 'react';
import { useQuery } from 'react-query';

import {
  fetchClassList,
  fetchTaskList,
  fetchTaskResultList,
} from 'apis/classBoard';

const useFetchClassBoardList = ({
  type,
  pageNum,
  pageSize,
  searchContent,
  keyword,
}) => {
  const [totalValue, setTotalValue] = useState(0);
  const [realTime, setRealTime] = useState('');
  const queryKeys = { type, pageNum, pageSize, searchContent };

  const queryReturns = useQuery({
    queryKey: ['fetchClassList', queryKeys],
    queryFn: async () => {
      let response;
      if (type === 'notice') {
        response = await fetchClassList({
          pageNum,
          category: '공지사항',
          pageSize,
          searchContent,
        });
        setTotalValue(response.data.data.total);
        return response.data.data.list;
      }

      if (type === 'task') {
        response = await fetchTaskList({ pageNum, pageSize, keyword });
        setTotalValue(response.data.data.total);
        setRealTime(response.data.timestamp);
        return response.data.data.list;
      }

      if (type === 'qna') {
        response = await fetchClassList({
          pageNum,
          category: '강의질문',
          pageSize,
          searchContent,
        });
        setTotalValue(response.data.data.total);
        return response.data.data.list;
      }

      if (type === 'library') {
        response = await fetchClassList({
          pageNum,
          category: '학습자료',
          pageSize,
          searchContent,
        });
        setTotalValue(response.data.data.total);
        return response.data.data.list;
      }

      if (type === 'taskResult') {
        response = await fetchTaskResultList();
        setTotalValue(response.data.length);
        return response.data;
      }
    },
    retry: 2,
    retryDelay: 1000,
  });
  return { ...queryReturns, totalValue, realTime };
};

export default useFetchClassBoardList;
