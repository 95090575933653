import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryValue, useDebounce, useFetchClassBoardList } from 'hooks';
import AuthContext from 'context/Auth.context';
import { BOARD_TITLE } from 'constant/class';
import {
  ClassListContentBox,
  ClassListInputBox,
  ClassListStyledBox,
  ClassListWrapper,
} from './ClassBoardCommon';
import { WriteButton, Pagination, SearchInput } from 'components/elements';
import ClassBoardList from './ClassBoardList';
import TaskBoardList from './TaskBoardList';

const BoardList = () => {
  const { type } = useParams();
  const auth = useContext(AuthContext);
  const history = useHistory();

  const pageQuery = useQueryValue('page');

  const [pageNum, setPageNum] = useState(Number(pageQuery) || 1);
  const [pageSize] = useState(10);
  const [searchContent, setSearchContent] = useState('');
  const debouncedSearchContent = useDebounce(searchContent, 500);

  const {
    data: list,
    isLoading,
    totalValue,
    realTime,
    refetch: refetchList,
  } = useFetchClassBoardList({
    type,
    pageNum,
    pageSize,
    searchContent: debouncedSearchContent,
    keyword: debouncedSearchContent,
  });

  const handleChangePage = (num) => {
    setPageNum(num);
    history.replace({
      search: `page=${num}`,
    });
  };

  useEffect(() => {
    if (pageQuery) return;

    setPageNum(1);
  }, [type]);

  return (
    <Fragment>
      <ClassListWrapper>
        <h1>{BOARD_TITLE[type]}</h1>

        <ClassListInputBox>
          <span>
            총 <b>{totalValue}</b>개
          </span>

          <SearchInput
            value={searchContent}
            setValue={setSearchContent}
            onClickSearch={refetchList}
          />
        </ClassListInputBox>

        <ClassListContentBox>
          {type === 'task' && (
            <TaskBoardList
              list={list}
              isListLoading={isLoading}
              realTime={realTime}
            />
          )}
          {type !== 'task' && (
            <ClassBoardList list={list} isListLoading={isLoading} />
          )}
        </ClassListContentBox>

        <ClassListStyledBox>
          {type === 'qna' ||
          type === 'library' ||
          (type !== 'qna' && auth.type !== '1') ? (
            <WriteButton
              text="글 등록하기"
              to={`/myclass/board/write/${type}`}
            />
          ) : null}
          {type === 'task' && auth.type === '1' && (
            <WriteButton
              text="과제 채점 결과"
              to={`/myclass/board/task/result`}
            />
          )}
        </ClassListStyledBox>

        <ClassListStyledBox>
          <Pagination
            page={pageNum}
            total={totalValue}
            perPage={pageSize}
            move={handleChangePage}
          />
        </ClassListStyledBox>
      </ClassListWrapper>
    </Fragment>
  );
};

export default BoardList;
