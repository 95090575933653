import React, { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';

import { useMutateNum, useMutateAttend } from 'hooks';
import { getTimeString } from '../../../libs/util';
import {
  AttendBtn,
  AttendBtnWrapper,
  InputWrapper,
  Msg,
  Wrapper,
} from './style';

const AuthModal = ({ onClick, selectedIdx }) => {
  const [time, setTime] = useState(60 * 3);
  const [resendCount, setResendCount] = useState(0);
  const [inputKey, setInputKey] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { mutate, isLoading } = useMutateNum();
  const { mutate: attend, isLoading: attendLoading } = useMutateAttend();

  const handleChange = (e) => {
    setInputKey(e.target.value);
  };

  const handleResend = () => {
    if (resendCount > 0) return;
    setErrMsg('');
    if (isLoading) return;
    mutate(selectedIdx, {
      onSuccess: (data) => {
        swal('인증번호가 재전송 되었습니다.');
        setTime(60 * 3);
        setResendCount(10);
      },
      onError: (err) => {
        console.error(err);
      },
    });
  };

  const handleSubmit = () => {
    if (attendLoading) return;
    if (!inputKey) return;
    attend(
      { inputKey, selectedIdx },
      {
        onSuccess: (data) => {
          if (data) {
            setErrMsg('');
            onClick('result');
          } else {
            setErrMsg('인증에 실패하였습니다. 확인후 다시 시도해 주세요.');
          }
        },
        onError: (err) => {
          setErrMsg('인증에 실패하였습니다. 확인후 다시 시도해 주세요.');
        },
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);

    if (time < 1) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  useEffect(() => {
    const interval = setInterval(() => {
      setResendCount((prev) => prev - 1);
    }, 1000);

    if (resendCount < 1) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [resendCount]);

  return (
    <Wrapper>
      <Msg color={time === 0 || errMsg ? '#f14a16' : 'rgba(0, 0, 0, 0.5)'}>
        {time === 0
          ? '인증번호를 재발송 하시거나 돌아가기를 눌러주세요.'
          : errMsg
          ? errMsg
          : '문자메시지로 발송된 인증번호를 입력해주세요.'}
      </Msg>
      <InputWrapper>
        <input
          placeholder="인증번호"
          type="text"
          value={inputKey}
          onChange={handleChange}
        />
        <span className={resendCount ? 'disabled' : ''} onClick={handleResend}>
          재발송하기 {resendCount ? `(${resendCount})` : ''}
        </span>
      </InputWrapper>
      <Msg color="#f14a16">남은시간 {getTimeString(time)}</Msg>
      <AttendBtnWrapper>
        <AttendBtn color="#4a4a4a" onClick={() => onClick('main')}>
          돌아가기
        </AttendBtn>
        <AttendBtn color="#fc9918" onClick={handleSubmit} disabled={time < 1}>
          {attendLoading ? (
            <ReactLoading type="spin" color="black" height={20} width={20} />
          ) : (
            '인증확인'
          )}
        </AttendBtn>
      </AttendBtnWrapper>
    </Wrapper>
  );
};

export default AuthModal;
