import axios from 'axios';
import { useMutation } from 'react-query';

const useLogWatching = () => {
  const { mutate, isLoading } = useMutation(
    async ({ clickLog, userId, lecture, classCount }) => {
      return await axios
        .patch(`${process.env.REACT_APP_IP}/users/myclass/logging/vod-click`, {
          clickLog,
          userId,
          lecture,
          classCount,
        })
        .then((res) => {
          return res.data;
        });
    }
  );

  return { mutate, isLoading };
};

export default useLogWatching;
