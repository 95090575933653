import { useMutation, useQueryClient } from 'react-query';

import { createInquiryComment } from 'apis/customer';

const useSubmitInquiryComment = ({ postId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ author, content }) => {
      return await createInquiryComment({
        author,
        content,
        postId,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchInquiryComments'],
      }),
  });
  return { ...queryReturns };
};

export default useSubmitInquiryComment;
