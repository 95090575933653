import { useMutation, useQueryClient } from 'react-query';

import { createGreetingComment } from 'apis/customer';

const useSubmitGreetingComment = ({ postId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ author, content }) => {
      return await createGreetingComment({
        author,
        content,
        postId,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchGreetingDetail'],
      }),
  });
  return { ...queryReturns };
};

export default useSubmitGreetingComment;
