import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { useClassDetail } from 'hooks';
import 'assets/css/course-table.css';

function Detail({ onClick }) {
  const history = useHistory();

  const [newData, setNewData] = useState();
  const [restDay, setRestDay] = useState('');

  const { data } = useClassDetail();

  const handleUnit = (unitId) => {
    history.push(`/myclass/unit/${unitId}`);
  };

  useEffect(() => {
    onClick('');
    if (data) {
      let newArr = [];
      let prevCategoryId;

      data.classDetailDtoList.forEach((v) => {
        if (prevCategoryId !== v.classCategoryId) {
          newArr.push({ classCategoryId: v.classCategoryId, elements: [v] });
        } else {
          newArr[newArr.length - 1].elements.push(v);
        }

        prevCategoryId = v.classCategoryId;
      });

      const filteredArray = newArr.filter(
        (e) => !['휴강'].includes(e.elements[0].classCategoryName)
      );

      const restData = newArr
        .filter((e) => e.elements[0].classCategoryName === '휴강')
        .map((e) => e.elements);

      /* 휴강 unitName에서 괄호 안에 값들 포맷 */
      const unitName = restData[0][0].unitName;
      const matches = unitName.match(/\(([^)]+)\)/);
      const restDays = matches ? matches[1] : '';

      setRestDay(restDays);
      setNewData(filteredArray);
    }
  }, [data]);

  const formatDateString = (dateString) => {
    const [_year, month, day] = dateString.split('T')[0].split('-');
    return `${month}.${day}`;
  };

  const getDay = (date) => {
    if (!date) return;

    const day = {
      0: '일',
      1: '월',
      2: '화',
      3: '수',
      4: '목',
      5: '금',
      6: '토',
    };

    return day[new Date(date).getDay()];
  };

  return (
    <Wrapper>
      <UpdateDate>
        {data?.modifiedDate && <h5>최근 업데이트: {data?.modifiedDate}</h5>}
      </UpdateDate>
      <table className="table course-detail">
        <thead>
          <tr>
            <th scope="col">교육일정</th>
            <th scope="col">시간</th>
            <th scope="col">교과목명</th>
            <th scope="col">능력단위명(단원명)</th>
          </tr>
        </thead>
        <tbody>
          {newData?.map((v) =>
            v.elements?.map((item, i) => (
              <tr key={i}>
                <td>
                  {item.startDate === item.endDate
                    ? `${formatDateString(item.startDate)}(${getDay(
                        item.startDate
                      )})`
                    : `${formatDateString(item.startDate)}(${getDay(
                        item.startDate
                      )}) - ${formatDateString(item.endDate)}(${getDay(
                        item.endDate
                      )})`}
                </td>
                <td style={{ minWidth: '70px' }}>{item.unitTime}H</td>
                {i === 0 && (
                  <td
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                    rowSpan={v.elements.length}
                  >
                    {item.classCategoryName}
                  </td>
                )}
                <td
                  style={
                    item.unitName.includes('(오프라인 시작)')
                      ? {
                          color: 'red',
                          cursor: 'pointer',
                          paddingLeft: '20px',
                          textAlign: 'left',
                        }
                      : {
                          cursor: 'pointer',
                          paddingLeft: '20px',
                          textAlign: 'left',
                        }
                  }
                  onClick={() => handleUnit(item.unitId)}
                >
                  {item.unitName}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {restDay && (
        <RestDayBox>
          <span>휴강일 :</span>
          <span>{restDay}</span>
        </RestDayBox>
      )}
    </Wrapper>
  );
}

const UpdateDate = styled.div`
  text-align: right;
  color: grey;
  h5 {
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  font-size: 16px;
  width: 1100px;
  margin-bottom: 50px;
  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .content {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 5px;
    margin-left: 10px;
  }
  td,
  th {
    text-align: center;
  }
`;

const RestDayBox = styled.div`
  padding-left: 20px;

  span:first-child {
    font-weight: bold;
    margin-right: 5px;
  }
`;

export default Detail;
