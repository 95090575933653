import { useMutation, useQueryClient } from 'react-query';

import { deleteTaskContent, submitTaskContent } from 'apis/classBoard';

const useSubmitTaskFile = ({ type, id }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      return await submitTaskContent({ formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
  });

  return { ...queryReturns };
};

const useDeleteTaskFile = ({ type, id }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ id }) => {
      return await deleteTaskContent({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { type, id }],
      }),
  });

  return { ...queryReturns };
};

export { useSubmitTaskFile, useDeleteTaskFile };
