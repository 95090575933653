import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import ConfettiExplosion from 'react-confetti-explosion';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import AuthContext from 'context/Auth.context';
import { useFetchPblProblemsHook, useFetchPblUnits } from 'hooks';
import ProblemCard from './ProblemCard';

const PblMain = () => {
  SwiperCore.use([Navigation, Pagination]);
  const { pathname } = useLocation();
  const { unitId } = useParams();
  const pageUrl = pathname?.split('/').at(-1);
  const auth = useContext(AuthContext);
  const { state } = useLocation();
  const history = useHistory();

  const [congratulation, setCongratulation] = useState(false);

  // 폭죽 트리거 한번만 터지게 제어
  useEffect(() => {
    if (!state) return;

    setCongratulation(true);

    const resetState = setTimeout(() => {
      setCongratulation(false);
      history.replace();
    }, 2000);

    return () => clearTimeout(resetState);
  }, [state]);

  const { data: units } = useFetchPblUnits();
  const currentUnit = units?.find((e) => e.id === Number(pageUrl));

  const { data, isLoading } = useFetchPblProblemsHook({ unitId });
  const findLastOpenIndex = () => {
    return data?.reduce((acc, current, index) => {
      if (
        acc === -1 &&
        current.state === 'OPEN' &&
        current.title === 'NOT AVAILABLE YET'
      ) {
        return index;
      }
      return acc;
    }, -1);
  };
  const swiperInitial = findLastOpenIndex() === -1 ? 1 : findLastOpenIndex();

  const iconObj = {
    '인프라 활용을 위한 파이썬': 'python',
    '애플리케이션 보안': 'application',
    '시스템-네트워크 보안 기술': 'system-network',
    '클라우드 보안 기술': 'cloud',
  };

  if (isLoading || !swiperInitial) return <></>;
  return (
    <Container>
      {congratulation && (
        <CongratulationBox>
          <ConfettiExplosion
            width={1600}
            duration={3000}
            zIndex={100}
            particleCount={250}
            force={0.8}
          />
        </CongratulationBox>
      )}

      <TitleBox>
        <img src={`/icons/${iconObj[currentUnit?.title]}.png`} alt="icon" />
        <h1>{currentUnit?.title}</h1>
      </TitleBox>

      {auth.type === '1' ? (
        <SwiperBox>
          <Swiper
            navigation={true}
            initialSlide={swiperInitial}
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={3}
            slidesPerGroup={3}
            pagination={{
              clickable: true,
            }}
          >
            {data?.map((problemContent, index) => (
              <SwiperSlide key={index}>
                <ProblemCard
                  id={problemContent.id}
                  level={problemContent.level}
                  newThumbnailImgName={
                    problemContent.newThumbnailImgName
                      ? `${process.env.REACT_APP_IP}/pbl-file/${problemContent.id}/thumbnail/${problemContent.newThumbnailImgName}`
                      : `/${iconObj[currentUnit?.title]}.jpg`
                  }
                  solvedCount={problemContent.solvedCount}
                  state={problemContent.state}
                  title={problemContent.title}
                  unitId={problemContent.unitId}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperBox>
      ) : (
        <ManageBox>
          {data?.map((problemContent, index) => (
            <ProblemCard
              key={index}
              id={problemContent.id}
              level={problemContent.level}
              newThumbnailImgName={
                problemContent.newThumbnailImgName
                  ? `${process.env.REACT_APP_IP}/pbl-file/${problemContent.id}/thumbnail/${problemContent.newThumbnailImgName}`
                  : `/${iconObj[currentUnit?.title]}.jpg`
              }
              solvedCount={problemContent.solvedCount}
              state={problemContent.state}
              title={problemContent.title}
              unitId={problemContent.unitId}
            />
          ))}
        </ManageBox>
      )}
    </Container>
  );
};

const CongratulationBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  display: flex;
  column-gap: 10px;
  padding-bottom: 50px;

  img {
    width: 40px;
    height: 40px;
  }

  h1 {
    font-size: 30px;
  }
`;

const SwiperBox = styled.div`
  max-width: 1100px;
  width: 130%;
  padding-bottom: 140px;

  .swiper-slide {
    height: auto !important;
  }
  .swiper-button-prev {
    left: 2px;
    transform: scale(1.3);
  }
  .swiper-button-next {
    right: 2px;
    transform: scale(1.3);
  }
`;

const ManageBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 50px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;

  > div {
    width: 31%;
    min-height: 420px;
  }
`;

export default PblMain;
