import React, { useState } from 'react';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';

import { BtnWrapper, IconBtn } from './style';
import calendarDone from '../../../../assets/images/attend/calendar1.svg';
import calendarStart from '../../../../assets/images/attend/calendar4.svg';
import calendarOut from '../../../../assets/images/attend/calendar3.svg';
import calendarIn from '../../../../assets/images/attend/calendar2.svg';
import { useMutateNum } from 'hooks';
import { errorMessage } from 'utilities';

const Attendance = ({ onClick, data, isBeforeOpenTime }) => {
  const [idx, setIdx] = useState(-1);
  const { mutate, isLoading } = useMutateNum();

  const handleClick = (btn) => {
    if (isLoading) return;
    setIdx(btn);
    mutate(btn, {
      onSuccess: (data) => {
        if (data) {
          onClick(btn);
        } else {
          swal('등록된 교육생이 아닙니다.');
        }
      },
      onError: () => {
        errorMessage({
          text: '알 수 없는 오류입니다. \n관리자에게 문의해주세요.',
        });
      },
    });
  };

  return (
    <BtnWrapper>
      <IconBtn
        onClick={() => {
          if (!isBeforeOpenTime() && (!data || !data.attendTime)) {
            handleClick(0);
            return;
          }
        }}
        disabled={isBeforeOpenTime() || data?.attendTime || isLoading}
      >
        {idx === 0 && isLoading ? (
          <ReactLoading type="spin" color="white" height={68} width={68} />
        ) : (
          <img src={calendarStart} alt="" />
        )}
        <div>입실</div>
      </IconBtn>
      <IconBtn
        onClick={() => {
          if (
            !isBeforeOpenTime() &&
            data?.attendTime &&
            !data?.outingStartTime &&
            !data?.leavingTime
          ) {
            handleClick(1);
            return;
          }
        }}
        disabled={
          isBeforeOpenTime() ||
          !data ||
          !data?.attendTime ||
          data?.outingStartTime ||
          data?.leavingTime ||
          isLoading
        }
      >
        {idx === 1 && isLoading ? (
          <ReactLoading type="spin" color="white" height={68} width={68} />
        ) : (
          <img src={calendarOut} alt="" />
        )}
        <div>외출시작</div>
      </IconBtn>
      <IconBtn
        onClick={() => {
          if (
            !isBeforeOpenTime() &&
            data?.outingStartTime &&
            !data?.outingEndTime
          ) {
            handleClick(2);
            return;
          }
        }}
        disabled={
          isBeforeOpenTime() ||
          !data?.outingStartTime ||
          (data?.outingStartTime && data?.outingEndTime) ||
          isLoading
        }
      >
        {idx === 2 && isLoading ? (
          <ReactLoading type="spin" color="white" height={68} width={68} />
        ) : (
          <img src={calendarIn} alt="" />
        )}
        <div>외출종료</div>
      </IconBtn>
      <IconBtn
        onClick={() => {
          if (
            data?.attendTime &&
            (data?.outingStartTime
              ? data?.outingEndTime
                ? true
                : false
              : data?.outingEndTime
              ? false
              : true) &&
            !data?.leavingTime
          ) {
            handleClick(3);
            return;
          }
        }}
        disabled={
          !data?.attendTime ||
          (data?.outingStartTime && !data?.outingEndTime) ||
          data?.leavingTime ||
          isLoading
        }
      >
        {idx === 3 && isLoading ? (
          <ReactLoading type="spin" color="white" height={68} width={68} />
        ) : (
          <img src={calendarDone} alt="" />
        )}
        <div>퇴실</div>
      </IconBtn>
    </BtnWrapper>
  );
};

export default Attendance;
