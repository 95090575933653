import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';

import AuthContext from 'context/Auth.context';
import { changeStudentPassword, checkStudentpassword } from 'apis/common';
import { errorMessage, successMessage } from 'utilities';

const useCheckAndChangeStudentPassword = () => {
  const history = useHistory();
  const { initialPw } = useContext(AuthContext);

  const queryReturns = useMutation({
    mutationFn: async ({ userId, password, newPassword }) => {
      const response = await checkStudentpassword({ userId, password });
      if (response.data === true) {
        const { status } = await changeStudentPassword({
          userId,
          password: newPassword,
        });
        if (status === 204) {
          return { success: true };
        } else {
          return { success: false };
        }
      }
      return { success: false };
    },

    onSuccess: (data) => {
      if (data.success) {
        successMessage({ text: '비밀번호가 성공적으로 변경되었습니다.' });
        localStorage.setItem('initialPw', false);
        axios.defaults.headers.common['initialPw'] = initialPw;
        setTimeout(() => {
          history.push('/myclass');
        }, 1000);
      } else {
        errorMessage({
          text: '비밀번호 변경에 실패하였습니다. 관리자에게 문의해주세요.',
        });
      }
    },

    onError: () => {
      errorMessage({
        text: '알 수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.',
      });
    },
  });

  return { ...queryReturns };
};

export default useCheckAndChangeStudentPassword;
