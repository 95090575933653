import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import FileSaver from 'file-saver';

import CommentModal from './CommentModal';
import {
  useDownloadStudentResult,
  useFetchSubmittedStudentListHook,
  useRemoveSubmittedResult,
} from 'hooks';
import { downloadTotalResult } from 'apis/pbl';
import { confirmSwal, errorMessage, successMessage } from 'utilities';

const ProblemResultView = ({ handleClose }) => {
  const { state } = useLocation();
  const { problemId } = state;

  const [pageNum, setPageNum] = useState(1);

  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [student, setStudent] = useState('');
  const [downloadName, setDownloadName] = useState('');

  const { data } = useFetchSubmittedStudentListHook({
    problemId: state.problemId,
    pageNum,
  });
  const studentList = data?.data.list;

  const { refetch, isSuccess, isError } = useDownloadStudentResult({
    problemId,
    userId: downloadName,
  });
  const { mutateAsync } = useRemoveSubmittedResult();

  const handlePageNum = (e, page) => {
    setPageNum(page);
  };

  const handleOpenCommentModal = async (studentId) => {
    await setStudent(studentId);
    setOpenCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setOpenCommentModal(false);
  };

  const handleDownloadPersonalResult = async (name) => {
    await setDownloadName(name);
  };

  const handleDownloadAll = async () => {
    try {
      const response = await downloadTotalResult({ problemId });
      if (response) {
        const fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');

        FileSaver.saveAs(response.data, decodeURIComponent(fileName));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveSubmittedResult = async (submitId) => {
    const result = await confirmSwal({
      title: '해당 결과물을 정말로 반려하시겠습니까?',
      confirmButton: '반려',
    });

    if (result) {
      try {
        const response = await mutateAsync({ problemId, submitId });
        if (response.status === 204) {
          successMessage({ text: `해당 결과물을 반려하였습니다.` });
        }
      } catch (error) {
        if (
          error.response.data.message ===
          'this problem cannot change the answer.'
        ) {
          errorMessage({
            text: `문제가 숨김 상태일 때 반려를 할 수 없습니다.`,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!downloadName) return;
    if (isSuccess || isError) {
      setDownloadName('');
      return;
    }

    refetch();
  }, [downloadName, isSuccess, isError]);

  useEffect(() => {
    if (openCommentModal) return;

    setStudent('');
  }, [openCommentModal]);

  return (
    <Container>
      <Modal.Header>
        <TitleBox>결과물 제출 내역</TitleBox>
        <Button variant="" className="close" onClick={handleClose}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <TableBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>순번</TableCell>
              <TableCell align="center">이름</TableCell>
              <TableCell align="center">제출일시</TableCell>
              <TableCell align="center">결과물</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList?.map((student, index) => (
              <TableRow key={index}>
                <TableCell scope="row">{index + 1}</TableCell>
                <TableCell align="center">{student.name}</TableCell>
                <TableCell align="center">
                  {student.modifiedAt.slice(0, 10)}
                </TableCell>
                <TableCell align="center">
                  <ResultBox>
                    <p>{student.originalFileName}</p>
                    <div>
                      <span
                        onClick={() =>
                          handleRemoveSubmittedResult(student.submitId)
                        }
                      >
                        반려
                      </span>
                      <span
                        onClick={() =>
                          handleDownloadPersonalResult(student.createdBy)
                        }
                      >
                        다운로드
                      </span>
                      <span
                        onClick={() =>
                          handleOpenCommentModal(student.createdBy)
                        }
                        className={student.commented ? 'commented' : ''}
                      >
                        의견
                      </span>
                    </div>
                  </ResultBox>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ControllBox>
          <span>총 {data?.data.total}명</span>
          <Pagination
            page={pageNum}
            onChange={handlePageNum}
            count={data?.data.pages}
          />
          <div className="download-button" onClick={handleDownloadAll}>
            전체 다운로드
          </div>
        </ControllBox>
      </TableBox>

      {openCommentModal && (
        <CommentModal
          openModal={openCommentModal}
          handleClose={handleCloseCommentModal}
          studentId={student}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  height: inherit;
`;

const TitleBox = styled.div`
  width: 100%;
  font-size: 20px;
  text-align: center;
`;

const TableBox = styled.div`
  th,
  td {
    font-size: 14px;
  }
`;

const ControllBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  > span {
    font-size: 16px;
    font-weight: bold;
  }

  div.download-button {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 15px;
    background-color: #b96ff8;
    border-radius: 5px;
    border: 1px solid #eee;
    box-shadow: 1px 1px 5px #eee;
    cursor: pointer;

    &:hover {
      background-color: #ad5af1;
    }
  }
`;

const ResultBox = styled.div`
  p {
    font-size: 14px;
  }

  div {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-top: 5px;

    span {
      padding: 5px 10px;
      border: 1px solid #eee;
      border-radius: 10px;
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      background-color: #63c04c;

      &:first-child {
        background-color: #ff7247;
      }

      &:last-child.commented {
        background-color: #6fc1fb;
      }

      &:last-child {
        background-color: #eee;
      }

      &:hover {
        filter: brightness(95%);
      }
    }
  }
`;

export default ProblemResultView;
