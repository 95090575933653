import Mock from './mock';

const photo = require('../assets/placeholders/photo.jpg').default;
const photoTeam = require('../assets/images/securecoding04.jpg').default;
const photo01 = require('../assets/images/securecoding01.jpg').default;
const photo02 = require('../assets/images/securecoding02.jpg').default;
const photo03 = require('../assets/images/securecoding03.jpg').default;
const photo04 = require('../assets/images/securecoding04.jpg').default;
// const logotype = require('../assets/placeholders/logotype.png').default;
// const icon = require('../assets/placeholders/icon.svg');
const icon01 = require('../assets/icons/cat4.png');
const icon02 = require('../assets/icons/cat11.png');
const icon03 = require('../assets/icons/cat22.png');
const icon04 = require('../assets/icons/cat33.png');

// const price01 = require('../assets/icons/price01.png').default;
// const price02 = require('../assets/icons/price02.png').default;
// const price03 = require('../assets/icons/price03.png').default;

const slideSub01 = require('../assets/images/logo-secolab.png').default;

const slideMain01 = require('../assets/images/head.png').default;
const slideMain02 = require('../assets/images/support.png').default;
const slideMain03 = require('../assets/images/write.png').default;
const slideMain04 = require('../assets/images/head.png').default;
const slideMain05 = require('../assets/images/support.png').default;

const database = {
  categories: [
    {
      id: '0',
      title: 'Product',
    },
    {
      id: '1',
      title: 'Project',
    },
    {
      id: '2',
      title: 'Review',
    },
    {
      id: '3',
      title: 'Article',
    },
  ],

  fast_links: [
    {
      title: '채용 정보',
      url: 'https://job.adtcaps.co.kr/index.asp',
    },
  ],
  footerMenu: [
    {
      title: '이용약관',
      subTitle: 'Terms of service',
      url: '/terms',
    },
    {
      title: '개인정보처리방침',
      subTitle: 'Privacy Policy',
      url: '/privacy',
    },
    {
      title: '사이트맵',
      subTitle: 'Site map',
      url: '/sitemap',
    },
  ],
  header_bgs: {
    about: photo,
    about_video_poster: photo,
    blog: photo,
    contacts: photo,
    contacts_w: photo,
    portfolio: photo,
    services: photo,
    team: photoTeam,
    team_w: photoTeam,
    facts: photo01,
    subscribe: photo,
    list: photo,
    signup: photo02,
    mypage: photo03,
    mypageinformation: photo04,
    mypageqanda: photo01,
    accountinfo: photo02,
    training: photo01,
  },
  menu: [
    {
      name: '내클래스',
      url: '/myclass',
      children: [
        {
          name: '내클래스룸',
          url: '/myclass',
        },
        {
          name: '공지사항',
          url: '/myclass/board/notice',
        },
        {
          name: '과제',
          url: '/myclass/board/task',
        },
        {
          name: '스마트실습',
          url: '/myclass/practice',
        },
        {
          name: 'PBL',
          url: '/myclass/pbl',
        },
        {
          name: '학습게시판',
          url: '/myclass/board',
        },
      ],
    },
    {
      name: '스터디그룹',
      url: '/sgroup',
      children: [
        {
          name: '스터디그룹',
          url: '/sgroup',
        },
      ],
    },
    {
      name: '교강사',
      url: '/teacher',
      children: [
        {
          name: '교육생 관리',
          url: '/teacher/students',
        },
        {
          name: '과제 관리',
          url: '/teacher/task',
        },
        {
          name: '시험 관리',
          url: '/teacher/test',
        },
        {
          name: '수업 관리',
          url: '/teacher/unit-detail',
        },
        {
          name: '다시보기 관리',
          url: '/teacher/rewatch',
        },
      ],
    },

    {
      name: '역량 평가',
      url: '/capability/test',
      children: [
        {
          name: '역량 평가',
          url: '/capability/test',
        },
        {
          name: '역량평가 결과보기',
          url: '/capability/test-result',
        },
      ],
    },
    {
      name: '지식컨텐츠',
      url: '/pre-course',
      children: [
        {
          name: '지식컨텐츠',
          url: '/pre-course',
        },
      ],
    },
    {
      name: '학습지원',
      url: '/customer',
      children: [
        {
          name: '자료실',
          url: '/customer',
        },
        {
          name: '자주묻는 질문',
          url: '/customer/faq',
        },
        {
          name: '우리들이야기',
          url: '/customer/greeting',
        },
        {
          name: '문의하기',
          url: '/customer/contact',
        },
        {
          name: '상담 관리',
          url: '/customer/consulting',
        },
        {
          name: '상담예약',
          url: '/customer/reserve',
        },
      ],
    },
  ],

  slides: [
    {
      title: '시큐어코딩 훈련시스템',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '개발자는 안전하게 동작되는 프로그램을 만들 책임이 있습니다. 코딩 기술 향상을 위해 시큐어코딩 훈련 시스템에 도전해 보세요.',
      mainImg: slideMain01,
      id: 'select',
    },
    {
      title: '보안약점 진단훈련',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 보안약점 진단 훈련과정은 소스 코드에 존재하는 보안 약점을 식별하고 시큐어 코딩 적용 방법을 학습합니다.',
      mainImg: slideMain02,
      id: 'securelist',
    },
    {
      title: '학습실',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 학습실에서는 SW보안약점과 취약점에 관련 공부를 위한 과정들이 동영상으로 배울수 있도록 준비되어 있습니다.',
      mainImg: slideMain03,
      id: 'Course',
    },
    {
      title: '프로젝트 개발훈련',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 프로젝트 개발훈련에서는 시큐어한 문제점등을 프로젝트 기반으로한 소프트웨어 개발을 시큐어하게 수행하는 방법을 학습합니다.',
      mainImg: slideMain04,
      id: 'codediagnosis',
    },
    {
      title: '역량평가',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 역량평가에서는 교육생의 사전 역량과 사후 이해도를 측정하여 학습 성취도를 객관적인 지표로 확인할 수 있도록 합니다.',
      mainImg: slideMain05,
      id: 'capabilitytest',
    },
  ],

  process: [
    {
      text: '회원가입 페이지에서 개인정보를 입력하고 회원가입 합니다.',
      title: '회원가입',
    },
    {
      text: '회원가입 시 결제정보를 등록하고 결제를 해야 학습자로 신청이 가능합니다.',
      title: '결제',
    },
    {
      text: '회원가입 시 입력한 아이디와 비밀번호를 이용하여 로그인합니다.',
      title: '로그인',
    },
    {
      text: '훈련장은 개발/보안 동영상 학습, 보안약점 진단훈련, 시큐어코딩 개발훈련 세개의 트랙으로 구성되어 있습니다.',
      title: '훈련장 이용',
    },
    {
      text: '마이페이지는 학습현황, 회원정보 수정하기 로 구성되어 있습니다.',
      title: '마이페이지 확인',
    },
  ],
  processSubmitter: [
    {
      text: '회원가입 페이지에서 개인정보를 입력하고 출제자로 회원가입 합니다.',
      title: '회원가입',
    },
    {
      text: '회원가입 시 입력한 아이디와 비밀번호를 이용하여 로그인합니다.',
      title: '로그인',
    },
    {
      text: '현재까지 출제한 문제 목록을 확인합니다.',
      title: '문제 출제',
    },
    {
      text: '본인 명의의 계좌번호를 등록합니다.',
      title: '계좌 등록',
    },
    {
      text: '출제한 문제에 대한 이용료 정산 정보를 제공합니다.',
      title: '비용 정산',
    },
  ],
  processPrice: [
    {
      text: '신청서를 다운로드 받아 작성합니다.',
      title: '신청서 다운로드',
    },
    {
      text: '작성한 신청서를 첨부하여 hello@openeg.co.kr로 보냅니다.',
      title: '신청 메일 발송',
    },
    {
      text: '신청 및 등록 절차가 완료되면 교육생 계정을 발급해 드립니다.',
      title: '교육생 계정 발급',
    },
    {
      text: '발급받은 교육생 계정으로 로그인하여 훈련장을 이용합니다.',
      title: '훈련장 이용',
    },
  ],
  reviews: [
    {
      author: '정 * *',
      id: 0,
      img: '/profiles/laptop.png',
      text: 'IT보안에 적용된 최신 트렌드를 반영한 커리큘럼이 인상적이었습니다. Docker와 Cloud를 배울 수 있는 좋은 기회였고, 최종 프로젝트를 통해 지원하려는 직무의 방향성을 정할 수 있었습니다. 또한 실무현장 방문을 통해 SK 쉴더스에 입사하기 위한 뚜렷한 목표를 정할 수 있었습니다.',
    },
    {
      author: '이 * *',
      id: 1,
      img: '/profiles/laptopw.png',
      text: '실무경력이 풍부한 강사님들을 통해 실제 업계 경험담과 inside 스토리들을 듣고, 모의해킹, 클라우드, 네트워크 학습을 진행할 수 있었습니다. 이를 통해 보안지식을 습득하고, 스스로의 보안 진로에 대해 결정하는데 큰 도움이 되었습니다.',
    },
    {
      author: '김 * *',
      id: 2,
      img: '/profiles/laptopw.png',
      text: '처음에는 취업해야 한다는 심리적압박이 있는 상황이었지만, 친절하고 상세하게 설명해주시는 강사님들로 인해 무사히 마칠 수 있었던 것 같습니다. 앞으로도 IT 보안 분야에 대한 공부에 더 노력해서 필요한 인재가 되도록 노력할 생각입니다.',
    },
    {
      author: '황 * *',
      id: 3,
      img: '/profiles/laptop.png',
      text: '수업을 들으면서 이런 것들을 조금 더 일찍 배웠더라면 이라는 아쉬움 뿐이었습니다. 수업을 들으면서 학교에서는 자세하게 알려주지 않았던 전문적인 지식을 채울 수 있었습니다. 초반에는 실습시간이 많다는 생각이 들었는데, 시간이 좀 흐른 뒤에는 실습에서 했던 기억이 가장 많이 남았습니다.',
    },
    {
      author: '박 * *',
      id: 4,
      img: '/profiles/laptop.png',
      text: '이 수업을 듣고 보안 쪽에 대한 기본을 알게 되고 접하고 익힐 수 있어서 정말 최고의 수업이라고 생각됩니다. 그리고 강사님들이 많은 경험을 토대로 가르쳐 주시기 때문에 분명 많은 도움이 될 거라 생각됩니다.',
    },
    {
      author: '김 * *',
      id: 5,
      img: '/profiles/laptopw.png',
      text: '관심 있었던 분야에 대해서 공부할 수 있는 좋은 기회였고, 교육 시간이 하나도 아깝지 않고 너무 많은 도움이 된 시간이었습니다. 팀으로 진행하는 프로젝트 또한 정말 큰 도움이 되었습니다.',
    },
  ],
  servicesIntro: [
    {
      icon: icon01,
      id: 'Course',
      img: icon02,
      subtitle: '개발/보안 동영상 학습',
      text01:
        '학습 동영상은 소프트웨어 개발보안, 정보보안, 프로그래밍 언어 총 세개의 카테고리로 구성되어 있습니다.',
      text02: '각 카테고리별로 원하는 과정을 선택하여 수강하면 됩니다.',
      text03: '',
      text04: '',
      text05: '',
    },
    {
      icon: icon02,
      id: 'securelist',
      img: icon02,
      subtitle: '보안약점 진단훈련',
      text01:
        '훈련하고 싶은 보안약점을 선택하고, 해당 보안약점에 출제된 문제를 풀어봅니다.',
      text02:
        '문제는 1단계, 2단계로 나뉘어져 있으며 1단계는 취약한 라인을 선택하면 통과되고, 2단계는 조건에 맞게 안전한 코드를 작성하면 통과됩니다.',
      text03:
        '문제 풀이 시 어려움을 겪을 경우 상단에 힌트 버튼을 통해 힌트를 열람합니다.',
      text04: '',
      text05: '',
    },
    {
      icon: icon03,
      id: 'codediagnosis',
      img: icon03,
      subtitle: '시큐어코딩 개발훈련',
      text01:
        '웹 브라우저에서 프로젝트를 생성하여 원하는 프로그램을 개발할 수 있습니다.',
      text02:
        '문제 목록에 있는 문제를 보고 조건에 맞게 프로젝트를 생성하여 개발할 수 있습니다.',
      text03: '프로젝트 목록에서 개인 프로젝트를 생성하여 개발할 수 있습니다.',
      text04:
        '개발을 완료하면 해당 프로젝트에 어떤 취약점이 있는지 진단하고, 진단 결과를 레포트로 받아볼 수 있습니다.',
      text05: '',
    },
  ],
  services: [
    {
      full: 'Continually incubate standards compliant customer service rather than professional initiatives. Intrinsicly whiteboard web-enabled potentialities via team building supply chains. Collaboratively integrate granular innovation and performance based intellectual capital. Compellingly redefine multimedia based paradigms for high-quality portals. Quickly build out-of-the-box e-tailers for customer directed opportunities.',
      icon: icon01,
      id: 'Course',
      img: icon01,
      steps: [
        {
          text: 'Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.',
          title: 'Construction project planning',
        },
        {
          text: 'Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.',
          title: '학습실',
        },
        {
          text: 'Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.',
          title: 'Post construction phase',
        },
      ],
      subtitle: '내클래스',
      text: 'SW보안약점과 취약점을 온라인 교육을 통해 학습합니다.',
      title: 'Construction',
    },
    {
      featured: 'yes',
      full: ' ',
      icon: icon02,
      id: 'securelist',
      img: photo,

      subtitle: '스마트실습',
      text: '프로젝트기반의 소프트웨어 개발을 시큐어하게 수행하는 방법을 학습합니다.',
      title: '',
    },
    {
      full: '',
      icon: icon03,
      id: 'codediagnosis',
      img: photo,

      subtitle: '역량평가',
      text: '교육생의 사전 역량과 사후 이해도를 평가하여 학습 성취도를 확인합니다.',
      title: '',
    },
    {
      full: '',
      icon: icon04,
      id: 'capabilitytest',
      img: photo,

      subtitle: '학습지원',
      text: '교육생의 궁금증을 실시간으로 대응하여 학습을 지원합니다.',
      title: '',
    },
  ],
  customers: [
    {
      id: 0,
      name: '한국인터넷진흥원',
      img: '/customer/customer1.png',
      description: [
        '시큐어코딩, 풀스택개발자를 위한 개발보안',
        'K-SHIELD 개발보안 설계/구현/테스트',
        'SW개발보안 교육 및 진단원양성과정',
        '정보보호 사전점검 자율이행을 위한 교육',
        '클라우드보안인증 교육 등 다수 보안교육',
      ],
    },
    {
      id: 1,
      name: '시코랩',
      img: '/customer/customer9.png',
      description: [
        '언제 어디서나 웹브라우저만 있으면 시코랩에',
        '접속하여 시큐어코딩 훈련을 할 수 있는 시스템',
      ],
    },
    {
      id: 2,
      name: '멀티캠퍼스',
      img: '/customer/customer2.png',
      description: [
        '혁신성장 사업(마이크로서비스 개발자)',
        '4차산업선도인력양성사업(딥러닝기반 AI엔지니어링)',
        '4차산업선도인력양성사업(시큐어코딩기반 블록체인개발)',
        '4차산업 선도기술 역량강화(빅데이터 전문가)',
        '4차산업 선도기술 역량강화(인공지능 전문가)',
        '4차산업 선도기술 역량강화(블록체인)등 다수 신기술 교육',
      ],
    },
    {
      id: 3,
      name: 'ADT 캡스/인포섹',
      img: '/customer/customer3.png',
      description: [
        '4차산업 선도인력 양성(클라우드보안 전문가)',
        '4차산업 선도인력 양성(클라우드기반 AI전문가)',
        'K디지털 핵심인재 양성(클라우드보안 전문가)',
        'K디지털 핵심인재 양성(클라우드기반 데이터보안 전문가)',
      ],
    },
    {
      id: 4,
      name: 'SK주식회사',
      img: '/customer/customer4.png',
      description: ['SW개발보안 컨설턴트 양성 교육로드맵 구축'],
    },
    {
      id: 5,
      name: '현대모비스',
      img: '/customer/customer5.png',
      description: ['ICT/보안 기술 역량 평가 4년 연속 수행'],
    },
    {
      id: 6,
      name: '삼성전자',
      img: '/customer/customer6.png',
      description: ['SW개발보안 온라인 콘텐츠 개발'],
    },
    {
      id: 7,
      name: 'KB데이터시스템즈',
      img: '/customer/customer7.png',
      description: ['ICT 기술역량 평가 컨설팅'],
    },
    {
      id: 8,
      name: '기타 유수의 기업들',
      img: '/customer/customer8.png',
      description: [
        '4차산업 선도기술(빅데이터, 인공지능',
        '클라우드, 마이크로서비스, 블록체인 등)',
        '교육 및 교육콘텐츠 개발',
      ],
    },
  ],
  sgroupList: [
    {
      id: 0,
      name: 'JAVA를 잡아라',
      content: '자바 공부를 함께 하는 모임입니다.',
      limitCount: 25,
      userId: 'tester002',
      userName: '김수현',
      regDate: '2021-11-16',
      approvalDate: '2021-11-18',
      memberCount: 3,
      status: 'ON',
      memberStatus: 'Y',
    },
    {
      id: 1,
      name: 'SQL 스터디',
      content: 'DBA를 목표로 공부하는 모임입니다.',
      limitCount: 25,
      userId: 'tester015',
      userName: '구교환',
      regDate: '2021-12-18',
      approvalDate: '2021-12-20',
      memberCount: 25,
      status: 'OFF',
      memberStatus: 'N',
    },
    {
      id: 2,
      name: 'REACT 공부를 위하여',
      content: '프론트 개발자 모여라',
      limitCount: 25,
      userId: 'hgs0529',
      userName: '황길성',
      regDate: '2022-01-04',
      approvalDate: null,
      memberCount: 0,
      status: 'READY',
      memberStatus: 'N',
    },
    {
      id: 3,
      name: 'REACT 공부를 위하여',
      content: '프론트 개발자 모여라',
      limitCount: 25,
      userId: 'tester016',
      userName: '원빈',
      regDate: '2022-01-04',
      approvalDate: '2021-1-13',
      memberCount: 2,
      status: 'ON',
      memberStatus: 'W',
    },
  ],
  sgroupPostList: [
    {
      id: 0,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 지키자',
      content: '잘 지켜라~',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 222,
    },
    {
      id: 1,
      sgroupId: 0,
      title: '안녕하세요 유재석입니다. 잘부탁드립니다',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 2,
      sgroupId: 0,
      title: '안녕 친구들?',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 3,
      sgroupId: 0,
      title: '오늘의 회의록 입니다.',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 4,
      sgroupId: 0,
      title: '오늘 스터디 모임 몇시인가요?',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 5,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 6,
      sgroupId: 0,
      title: '제 코드 확인 부탁드립니다',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 7,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 8,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 9,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 10,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 11,
      sgroupId: 0,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 12,
      sgroupId: 2,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 13,
      sgroupId: 2,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 14,
      sgroupId: 1,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 15,
      sgroupId: 1,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 16,
      sgroupId: 1,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
    {
      id: 17,
      sgroupId: 2,
      title: '스터디그룹 규칙을 잘 zz',
      content: '잘 지켜라zzz',
      author: '김연주',
      regDate: '2022-01-06',
      hitCount: 13,
    },
  ],
};

Mock.onGet('/api/data').reply((config) => {
  const response = database;
  return [200, response];
});

Mock.onGet('/api/sgroup/members').reply(() => {
  const response = [
    {
      id: 0,
      userId: 'hgs0529',
      userName: '황길성',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-21T00:00:00.000+00:00',
      approvalDate: '2021-12-21T00:00:00.000+00:00',
      status: 'L',
    },
    {
      id: 1,
      userId: 'tester017',
      userName: '박경태',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-21T00:00:00.000+00:00',
      approvalDate: '2021-12-22T00:00:00.000+00:00',
      status: 'Y',
    },
    {
      id: 2,
      userId: 'tester018',
      userName: '김연주',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-22T00:00:00.000+00:00',
      approvalDate: '2021-12-22T00:00:00.000+00:00',
      status: 'Y',
    },
    {
      id: 3,
      userId: 'tester020',
      userName: '이은주',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-22T00:00:00.000+00:00',
      approvalDate: '2021-12-22T00:00:00.000+00:00',
      status: 'Y',
    },
  ];

  return [200, response];
});

Mock.onAny('/api/sgroup/kick', {}).reply(() => {
  return [200, 'SUCCESS'];
});

Mock.onGet('/api/sgroup/join').reply(() => {
  const response = [
    {
      id: 0,
      userId: 'hgs0529',
      userName: '황길성',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-21T00:00:00.000+00:00',
      approvalDate: null,
      status: 'W',
    },
    {
      id: 1,
      userId: 'tester017',
      userName: '박경태',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-21T00:00:00.000+00:00',
      approvalDate: null,
      status: 'W',
    },
    {
      id: 2,
      userId: 'tester018',
      userName: '김연주',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-22T00:00:00.000+00:00',
      approvalDate: null,
      status: 'W',
    },
    {
      id: 3,
      userId: 'tester020',
      userName: '이은주',
      classTitle: '클라우드기반 데이터보안 전문가 양성과정',
      regDate: '2021-12-22T00:00:00.000+00:00',
      approvalDate: null,
      status: 'W',
    },
  ];

  return [200, response];
});
