const Message = {
  SERVICE_AUTHENTICATED_ONLY: '회원 전용 서비스입니다. 먼저 로그인해주세요.',
  SERVICE_INVALID_TOKEN:
    '로그인 토큰이 유효하지 않습니다. 다시 로그인해주세요.',
  SERVICE_EXPIRED_TOKEN: '로그인 토큰이 만료되었습니다. 다시 로그인해주세요.',
  SERVICE_STUDENT_ONLY: '학습자 전용 서비스입니다.',
  SERVICE_TRAINER_ONLY: '출제자 전용 서비스입니다.',
  SERVICE_TEACHER_ONLY: '교강사 전용 서비스입니다.',
  SERVICE_ADMIN_ONLY: '관리자 전용 서비스입니다.',
  STATUS_NOT_CONFIRMED: '사용 승인이 필요합니다.',
  SERVICE_STUDENT_EXAM_ONLY: '역량평가 응시 대상이 아닙니다.',
  SERVICE_INVALID_CREATE: '등록이 정상적으로 처리되지 않았습니다.',
  SERVICE_INVALID_READ: '조회가 정상적으로 처리되지 않았습니다.',
  SERVICE_INVALID_UPDATE: '수정이 정상적으로 처리되지 않았습니다.',
  SERVICE_INVALID_DELETE: '삭제가 정상적으로 처리되지 않았습니다.',
  ERROR_FORBIDDEN: '해당 페이지를 볼 수 있는 권한이 없습니다.',
  ERROR_500: '올바른 접근이 아닙니다',
  ERROR_UNKNOWN: '알 수 없는 오류입니다. 관리에게 문의해주세요.',
};

export default Message;
