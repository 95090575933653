import { useMutation, useQueryClient } from 'react-query';
import { createInquiry } from '../../apis/customer';

const useCreateInquiry = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      return await createInquiry({ formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchInquiriesList'] }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchInquiriesList'] }),
  });

  return { ...queryReturns };
};

export default useCreateInquiry;
