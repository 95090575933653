export const STUDY_TIME = [
  {
    id: 0,
    time: '09:00',
    status: false,
  },
  {
    id: 1,
    time: '10:00',
    status: false,
  },
  {
    id: 2,
    time: '11:00',
    status: false,
  },
  {
    id: 3,
    time: '13:00',
    status: false,
  },
  {
    id: 4,
    time: '14:00',
    status: false,
  },
  {
    id: 5,
    time: '15:00',
    status: false,
  },
  {
    id: 6,
    time: '16:00',
    status: false,
  },
  {
    id: 7,
    time: '17:00',
    status: false,
  },
  {
    id: 8,
    time: '18:00',
    status: false,
  },
  {
    id: 9,
    time: '19:00',
    status: false,
  },
  {
    id: 10,
    time: '20:00',
    status: false,
  },
];

export const RESERVE_TIME = [
  {
    id: 0,
    time: '09:00',
    status: false,
  },
  {
    id: 1,
    time: '09:30',
    status: false,
  },
  {
    id: 2,
    time: '10:00',
    status: false,
  },
  {
    id: 3,
    time: '10:30',
    status: false,
  },
  {
    id: 4,
    time: '11:00',
    status: false,
  },
  {
    id: 5,
    time: '11:30',
    status: false,
  },
  {
    id: 6,
    time: '13:00',
    status: false,
  },
  {
    id: 7,
    time: '13:30',
    status: false,
  },
  {
    id: 8,
    time: '14:00',
    status: false,
  },
  {
    id: 9,
    time: '14:30',
    status: false,
  },
  {
    id: 10,
    time: '15:00',
    status: false,
  },
  {
    id: 11,
    time: '15:30',
    status: false,
  },
  {
    id: 12,
    time: '16:00',
    status: false,
  },
  {
    id: 13,
    time: '16:30',
    status: false,
  },
  {
    id: 14,
    time: '17:00',
    status: false,
  },
  {
    id: 15,
    time: '17:30',
    status: false,
  },
];
