import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import logo from 'assets/images/sk_shieldus_comm_rgb_kr.png';
import loginbg from 'assets/images/bg-login.jpg';
import astroWorker from 'assets/images/astro.gif';
import astroThinking from 'assets/images/astroThinking.gif';

import AuthContext from 'context/Auth.context';
import { useAuth } from 'hooks';
import { useForm } from 'react-hook-form';

const Login = (props) => {
  const auth = useContext(AuthContext);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [astro, setAstro] = useState(astroWorker);

  const { userLogin } = useAuth();

  const handleLogin = async (form) => {
    const { userId, password } = form;

    try {
      const { status, headers, data } = await userLogin.mutateAsync({
        userId,
        password,
      });

      if (status === 200) {
        auth.onLogin(headers.authorization, headers.refreshtoken, data);
        props.history.push('/myclass');
      }
    } catch (error) {
      setValue('userId', '');
      setValue('password', '');
      setAstro(astroWorker);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAstro(astroThinking);
    }
  }, [errors]);

  useEffect(() => {
    console.log(`  _____ _  __   _____ _     _      _     _           
 / ____| |/ /  / ____| |   (_)    | |   | |          
| (___ | ' /  | (___ | |__  _  ___| | __| |_   _ ___ 
 \\___ \\|  <    \\___ \\| '_ \\| |/ _ \\ |/ _\` | | | / __|
 ____) | . \\   ____) | | | | |  __/ | (_| | |_| \\__ \\
|_____/|_|\\_\\ |_____/|_| |_|_|\\___|_|\\__,_|\\__,_|___/
                                                     
                                                     
 _____   ____   ____  _  _______ ______  _____ 
|  __ \\ / __ \\ / __ \\| |/ /_   _|  ____|/ ____|
| |__) | |  | | |  | | ' /  | | | |__  | (___  
|  _  /| |  | | |  | |  <   | | |  __|  \\___ \\ 
| | \\ \\| |__| | |__| | . \\ _| |_| |____ ____) |
|_|  \\_\\\\____/ \\____/|_|\\_\\_____|______|_____/ `);
  }, []);

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: 'url(' + loginbg + ')' }}
    >
      <div className="login-wrapper">
        <div className="login-aside-left">
          <span className="login-logo">
            <img src={logo} alt="SK실더스로고" className="mr-2" />
            <div
              className="mt-2"
              style={{ fontSize: '18px', color: 'rgba(0,0,0,0.5)' }}
            >
              Security와 Safety&Care
            </div>
            <img
              src={astro}
              className="astro"
              alt="astro"
              style={{ width: '50%' }}
            />
          </span>
          <div className="login-description">
            <h2 className="mb-2" style={{ fontSize: '28px', color: '#ec7807' }}>
              LMS
            </h2>
            <p style={{ fontSize: '14px' }} className="text-black">
              LMS 로그인 화면입니다.
            </p>
            <div className="mt-5">
              <div className="fs-12 text-gray">
                에스케이쉴더스 주식회사 | 사업자등록번호 120-86-07747
              </div>
              <div className="fs-12 text-gray">
                13486 경기도 성남시 분당구 판교로 227번길 23, 4&5층{' '}
              </div>
              <div className="fs-12 text-gray">
                {`COPYRIGHT © 2023 SK SHIELDUS. ALL RIGHTS RESERVED.`}
              </div>
            </div>
          </div>
        </div>

        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-9 col-xxl-9">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="mb-4">
                        <h3
                          style={{ fontSize: '28px' }}
                          className="text-white mb-1"
                        >
                          Welcome to LMS
                        </h3>
                        <p style={{ fontSize: '15px' }} className="text-white">
                          Sign in by entering information below
                        </p>
                      </div>
                      <StyledForm onSubmit={handleSubmit(handleLogin)}>
                        <div className="form-group">
                          <Label>ID</Label>
                          <input
                            type="text"
                            className="form-control"
                            name="userId"
                            {...register('userId', {
                              required: true,
                            })}
                          />
                          {errors.userId && (
                            <StyledError>아이디를 입력해주세요.</StyledError>
                          )}
                        </div>
                        <div className="form-group">
                          <Label className="mb-2 ">Password</Label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            {...register('password', {
                              required: true,
                            })}
                          />
                          {errors.password && (
                            <StyledError>비밀번호를 입력해주세요.</StyledError>
                          )}
                        </div>

                        <div className="text-center">
                          <button
                            style={{ fontSize: '15px' }}
                            type="submit"
                            className="btn bg-white text-primary btn-block"
                          >
                            Sign In
                          </button>
                        </div>
                      </StyledForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledForm = styled.form`
  input.form-control {
    height: 56px;
  }
`;

const Label = styled.label`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
`;

const StyledError = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 5px;
  width: 100%;
`;

export default Login;
