import { useContext } from 'react';
import { useQuery } from 'react-query';

import {
  fetchNewNotice,
  fetchContactReplyNotification,
  fetchContactStudentReplyNotification,
} from 'apis/common';
import AuthContext from 'context/Auth.context';

const useFetchNewNotification = () => {
  const auth = useContext(AuthContext);

  const newNotice = useQuery({
    queryKey: ['fetchNewNotice'],
    queryFn: async () => await fetchNewNotice(),
    select: (res) => res.data.data,
    refetchInterval: 300000,
    retry: 1,
    retryDelay: 1000,
  });

  const newContactReply = useQuery({
    queryKey: ['fetchContactReplyNotification'],
    queryFn: async () => {
      if (auth.type === '1') {
        return await fetchContactReplyNotification();
      } else if (['3', '4', '5'].includes(auth.type)) {
        return await fetchContactStudentReplyNotification();
      }
    },
    select: (res) => res.data.data,
    refetchInterval: 300000,
    retry: 1,
    retryDelay: 1000,
  });

  return { newNotice, newContactReply };
};

export default useFetchNewNotification;
